import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickCarouselWithPagination = ({ children }) => {
    const [slides, setSlides] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const slidesPerPage = 3; // Number of slides per page

    useEffect(() => {
        // Fetch or set your slide data here
        // For example:
        // const fetchedSlides = await fetchSlides();
        // setSlides(fetchedSlides);
        // For demonstration, setting dummy slide data
        const dummySlides = [
            { id: 1, content: 'Slide 1' },
            { id: 2, content: 'Slide 2' },
            { id: 3, content: 'Slide 3' },
            // Add more slides as needed
        ];
        setSlides(dummySlides);
    }, []);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    // Calculate starting and ending index of slides for the current page
    const startIndex = currentPage * slidesPerPage;
    const endIndex = startIndex + slidesPerPage;
    const visibleSlides = slides.slice(startIndex, endIndex);

    // Slick settings for the carousel
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,

    };

    return (
            <Slider {...settings}>
                {children}
            </Slider>

    );
};

export default SlickCarouselWithPagination;

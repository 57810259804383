import axiosClient from "api";
const Api ="/api/admin/locations"
class LocationsAPI {
    static allLocationsAPI(pageNumber,filterPerpage) {
      return axiosClient.get(`${Api}.json?page=${pageNumber}&per_page_count=${filterPerpage}`);
    }
    static viewLocationsAPI(data) {
      return axiosClient.get(`${Api}/${data.id}.json`);
    }
    static DeleteLocationAPI(id) {
        return axiosClient.delete(`${Api}/${id}.json`);
      }
    static createLocationsAPI(data) {
      return axiosClient.post(`${Api}.json`,data);
    }
    static updateLocation(id,data){
        return axiosClient.put(`${Api}/${id}.json`,data);
    }
    static allAdminUser() {
      return axiosClient.get(`/api/admin/users.json`);
    }
    static updateUserLocation(data){
        return axiosClient.put(`/api/admin/update-locations.json`,data);
    }
    static userLocationDetail(data){
      return axiosClient.get(`/api/admin/user-permit-locations/${data}.json`);
  }
  }
  export default LocationsAPI;
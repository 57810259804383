import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WorkWeekApi from "api/hrmapi/workweek";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import UpdateWorkWeek from "./UpdateWorkWeek";
function AllWorkWeek({ fetchWorkWeek, setFetchWorkWeek }) {
  const navigate = useNavigate();
  const [names, setNames] = useState([]);
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(true);
  const[upDateId,setUpDateId]=useState("")
  const [popupId, setPopupId] = useState("");
  const handleDeleteClick = (index) => {
    const itemToDelete = names[index];
    WorkWeekApi.deleteWorkWeekApi(itemToDelete.id)
      .then((response) => {
        setNames(names.filter((name, idx) => idx !== index));
        setDays(days.filter((day, idx) => idx !== index));
        const Message = response
          ? "Record Deleted Successfully From List"
          : "An error occurred";
        toast.success(`${Message}`);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";
        toast.error(`Error: ${errorMessage}`);
      });
  };

  const handleUpdateClick = (index) => {
    const itemToDelete = names[index];
    setUpDateId(itemToDelete.id)
    setPopupId("updateWorkweek");
  };
  useEffect(() => {
    WorkWeekApi.allWorkWeekApi()
      .then((res) => {
        const namesData = res.data.work_week;
        const daysData = res.data.work_week.map((item) => item.work_days);
        setNames(namesData);
        setDays(daysData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, [fetchWorkWeek]);

  return (
    <>
      <ShowComponent condition={popupId === "updateWorkweek"}>
        <Popup setPopup={() => setPopupId("")} className="w-[50%]">
          <UpdateWorkWeek setPopupId={setPopupId} upDateId={upDateId}  setFetchWorkWeek={setFetchWorkWeek} />
        </Popup>
      </ShowComponent>
      <div className="w-full">
        {names.map((name, index) => (
          <div key={index} className="w-full mb-4">
            <div className=" p-2 text-22 ">{name.name}</div>
            <div className="flex w-full justify-between">
              {days[index].map((day) => (
                <div
                  key={day.id}
                  className="border border-gray-300 p-2 rounded gap-2"
                >
                  {day.day_type === "Full Day" ? (
                    <div className="justity-center w-full items-center text-center">
                      <div className="w-full text-center  mb-2">
                        {day.day.substring(0, 3).toUpperCase()}
                      </div>
                      <div className="w-10 h-10 bg-blue rounded-full"></div>
                    </div>
                  ) : day.day_type === "Half Day" ? (
                    <div className="half-day-circle">
                      <div className="w-full text-center  mb-2">
                        {day.day.substring(0, 3).toUpperCase()}
                      </div>
                      <div className="relative w-10 h-10">
                        <div className="absolute inset-0 rounded-full bg-blue-500"></div>

                        <div
                          className="absolute inset-0 overflow-hidden rounded-full"
                          style={{
                            clipPath: "polygon(0 0, 50% 0, 50% 100%, 0 100%)",
                          }}
                        >
                          <div className="absolute top-0 left-0 w-full h-full bg-blue"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="justity-center w-full items-center text-center">
                      <div className="w-full text-center  mb-2">
                        {" "}
                        {day.day.substring(0, 3).toUpperCase()}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="flex">
                <div className="mr-2">
                  <button
                    className="text-red"
                    onClick={() => handleDeleteClick(index)}
                  >
                    Delete
                  </button>
                </div>
                <div>
                  <button
                    className="text-blue"
                    onClick={() => handleUpdateClick(index)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AllWorkWeek;

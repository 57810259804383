import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { useNavigate } from "react-router-dom";

function PayrollProcessing() {
  const navigate = useNavigate();
  const handleNavgetAllAssetType = () => {
    navigate(`/all-asset-type`);
  };
  const handleNavgetAssetType = () => {
    navigate(`/create-asset-type`);
  };
  const handleNavgetAllCompanyAssets = () => {
    navigate(`/all-company-assets`);
  };
  const handleNavgetCompanyAssets = () => {
    navigate(`/create-company-assets`);
  };
  const handleNavgetAllEmployeeData = () => {
    navigate(`/all-employee-data`);
  };
  const handleNavgetAllBranch = () => {
    navigate(`/all-branches`);
  };
  const handleNavgetBranch = () => {
    navigate(`/create-branch`);
  };
  const handleNavgetAllDocument = () => {
    navigate(`/all-documents`);
  };
  const handleNavgetDocument = () => {
    navigate(`/create-document`);
  };
  const handleNavgetAllContracts = () => {
    navigate(`/all-contracts`);
  };
  const handleNavgetAllowance = () => {
    navigate(`/create-allowance`);
  };
  const handleNavgetAllAllowances = () => {
    navigate(`/all-allowances`);
  };
  const handleNavgetDeparment = () => {
    navigate(`/create-department`);
  };
  const handleNavgetAllDeparment = () => {
    navigate(`/all-departments`);
  };
  const handleNavgetContracts = () => {
    navigate(`/create-contract`);
  };
  const handleNavgetEmployeeDataManagement = () => {
    navigate(`/create-employee-data-management`);
  };
  const handleNavgetAllPayrolltab = () => {
    navigate(`/all-payroll`);
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24 mb-5 font-semibold">Payroll Processing</div>
        </div>
        <div className="w-full flex flex-wrap gap-5">
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Payroll Data</div>
                <div className="text-blue font-semibold">30 Payrolls</div>
              </div>
              <div className="bg-blue rounded-xl bg-opacity-20 p-2">
                <img
                  src="./icons/employee.png"
                  alt="employee"
                  className="text-blue"
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              {/* <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetEmployeeDataManagement}
              >
                Create New Employee
              </div> */}
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllPayrolltab}
              >
                All Payrolls Table
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Allowance</div>
                <div className="text-blue font-semibold">20 Allowance</div>
              </div>
              <div className="bg-body rounded-xl  p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-lg leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllowance}
              >
                Create Allowance
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-lg leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllAllowances}
              >
                All Allowances
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Work Expense</div>
                <div className="text-blue font-semibold">20 Work Expenses</div>
              </div>
              <div className="bg-green rounded-xl bg-opacity-20 p-2">
                <img src="./icons/employee.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                // onClick={handleNavgetDeparment}
              >
                Create Work Expense
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                // onClick={handleNavgetAllDeparment}
              >
                All Work Expenses
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Transactions</div>
                <div className="text-blue font-semibold">20 Transactions</div>
              </div>
              <div className="bg-orange rounded-xl bg-opacity-20 p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                // onClick={handleNavgetBranch}
              >
                Create Transaction
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                // onClick={handleNavgetAllBranch}
              >
                All Transactions
              </div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default PayrollProcessing;

import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DocumentApi from "api/hrmapi/document";
import { useNavigate } from "react-router-dom";
function CreateDocument({ contentOnly = false, setPopupId }) {
  const navigate = useNavigate();
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [issueDateLabel, setIssueDateLabel] = useState("");
  const [issueDateValue, setIssueDateValue] = useState(null);
  const [expiryDateLabel, setExpiryDateLabel] = useState("");
  const [expiryDateValue, setExpiryDateValue] = useState(null);
  const [requiredLabel, setRequiredLabel] = useState("");
  const [requiredValue, setRequiredValue] = useState(null);
  const [documentNoLabel, setDocumentNoLabel] = useState("");
  const [documentNoValue, setDocumentNoValue] = useState(null);
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-documents`);
    }, 2000);
  };
  const handleCreateDocument = (e) => {
    e.preventDefault();
   
    if (
      !issueDateLabel ||
      !expiryDateLabel ||
      !documentNoLabel ||
      !requiredLabel
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    DocumentApi.createDocument({
      document: {
        name: name,
        has_issue_date: issueDateValue,
        has_expiry_date: expiryDateValue,
        has_document_no: documentNoValue,
        required: requiredValue,
        company_id: company_id,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        handleNavgetAll();
        toast.success(`${Message}`);
        if (contentOnly) {
          setPopupId("");
        }
        setName("");
        setIssueDateLabel("");
        setIssueDateValue(null);
        setExpiryDateLabel("");
        setExpiryDateValue(null);
        setDocumentNoLabel("");
        setDocumentNoValue(null);
        setRequiredLabel("");
        setRequiredValue(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };
  const issueDateOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const expiryDateOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const requiredOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const documentNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Document</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreateDocument}>
            <div className="w-full flex flex-wrap gap-5 p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Issue Date</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  required
                  value={{
                    value: issueDateValue,
                    label: issueDateLabel,
                  }}
                  onChange={(option) => {
                    setIssueDateLabel(option.label);
                    setIssueDateValue(option.value);
                  }}
                  options={issueDateOptions}
                  styles={customStyles}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Expiry Date</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: expiryDateValue,
                    label: expiryDateLabel,
                  }}
                  required
                  onChange={(option) => {
                    setExpiryDateLabel(option.label);
                    setExpiryDateValue(option.value);
                  }}
                  options={expiryDateOptions}
                  styles={customStyles}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Document No</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  required
                  value={{
                    value: documentNoValue,
                    label: documentNoLabel,
                  }}
                  onChange={(option) => {
                    setDocumentNoLabel(option.label);
                    setDocumentNoValue(option.value);
                  }}
                  options={documentNoOptions}
                  styles={customStyles}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Required</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  required
                  value={{
                    value: requiredValue,
                    label: requiredLabel,
                  }}
                  onChange={(option) => {
                    setRequiredLabel(option.label);
                    setRequiredValue(option.value);
                  }}
                  options={requiredOptions}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create Document
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateDocument;

import axiosClient from "..";
const Api = "/api/hrm/settings/company_document_types";
class CompanyDocumentTypesApi {
  static createCompanyDocumentTypesApi(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allCompanyDocumentTypesApi() {
    return axiosClient.get(`${Api}.json`);
  }

  static updateCompanyDocumentTypesApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static getCompanyDocumentTypesApi(id) {
    return axiosClient.get(`${Api}/${id}.json`);
  }
  static deleteCompanyDocumentTypesApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default CompanyDocumentTypesApi;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import LocationsAPI from "api/location/location";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import TransfersAPI from "api/posapi/transfers";
import Button from "Components/Common/Button";

function LocationDetails() {
  let params = useParams();

  const [dataToShow, setDataToShow] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    LocationsAPI.viewLocationsAPI(params)
      .then((response) => {
        const filterData = response?.data?.location.map((item) => {
          const { company_id, ...filterData } = item;
          return filterData;
        });
        setDataToShow(filterData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    TransfersAPI.productInventoriesAPI({ location_id: params.id })
      .then((response) => {
        const filterData = response?.data?.allProducts.map(
          ({
            created_at,
            updated_at,
            location_id,
            category_id,
            company_id,
            product_id,
            ...item
          }) => ({
            id: item.id,
            product_name: item.product_name,
            product_barcode: item.product_barcode,
            product_sku: item.product_sku,
            category_name: item.category_name,
            quantity: item.quantity,
            price: item.price,
          })
        );
        setAllProducts(filterData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-locations");
  };

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Location Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <Table data={dataToShow} loading={loading} />
          <div className="pt-4">
            <div className="w-full text-24 flex text-center">Products</div>
            <Table data={allProducts} />
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default LocationDetails;

import axiosClient from "..";
const Api = "/api/hrm/settings/employee_document_types";
class EmployeeDocumentTypesApi {
  static createEmployeeDocumentTypesApi(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allEmployeeDocumentTypesApi() {
    return axiosClient.get(`${Api}.json`);
  }

  static updateEmployeeDocumentTypesApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static getEmployeeDocumentTypesApi(id) {
    return axiosClient.get(`${Api}/${id}.json`);
  }
  static deleteEmployeeDocumentTypesApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default EmployeeDocumentTypesApi;

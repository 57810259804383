import CustomerApi from "api/customerapi/customer";
import HoliDaysApi from "api/hrmapi/holiDays";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";

function CreateHolidays({ setPopupId, setFetchHolidays }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCreateHoliday = (e) => {
    e.preventDefault();
    if (!selectedCountry) {
      toast.error("Please select a country");
      return;
    }
    setLoading(true);
    const requestData = {
      holiday_calender: {
        country: selectedCountry.value,
        company_id: company_id,
        holidays_attributes:[]
      },
    };
    HoliDaysApi.createHoliDaysApi(requestData)
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setTimeout(() => {
          setPopupId("");
          setFetchHolidays(true);
        }, 2000);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
        toast.error(error.response.data.errors[0]);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setSelectedCountry(null);
    setPopupId("");
  };

  useEffect(() => {
    CustomerApi.AllCountries()
      .then((response) => {
        const formattedCountries = response.data.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(formattedCountries);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  return (
    <div className="w-full max-h-[150px]">
      <div className="mb-2">Add Holiday Calendar</div>
      <form onSubmit={handleCreateHoliday}>
        <div>
          <Select
            options={countries}
            value={selectedCountry}
            styles={customStyles}
            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
          />
        </div>
        <div className="w-full justify-end gap-2 flex mt-2">
          <div className="w-[15%]">
            <button
              className={`w-full ${
                loading ? "btn-primary cursor-not-allowed " : " btn-primary"
              }`}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
          <div className="w-[15%]">
            <button
              className={`w-full ${
                loading ? "btn-primary cursor-not-allowed " : " btn-primary"
              }`}
              type="submit"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateHolidays;

import axiosClient from "..";

class PosDashboard {
  static annualSales(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/annual_sales_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static dailySales(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/daily_sales_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static annualPurchase(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/annual_purchases_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static dailyPurchase(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/daily_purchases_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static annualRefund(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/annual_refunds_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static dailyRefund(currentDate, startDate, endDate) {
    return axiosClient.get(
      `/api/reports/daily_refunds_dashboard.json?start_date=${startDate}&end_date=${endDate}&current_date=${currentDate}`
    );
  }
  static totalProducts() {
    return axiosClient.get(`/api/reports/total_products_dashboard.json`);
  }
  static outOfStockProducts() {
    return axiosClient.get(`/api/reports/out_of_stock_products_dashboard.json`);
  }
  static yearlySales(startDate, endDate) {
    return axiosClient.get(
      `/api/reports/yearly_sales_dashboard.json?start_date=${startDate}&end_date=${endDate}`
    );
  }
  static topSellingProducts(startDate, endDate) {
    return axiosClient.get(
      `/api/reports/yearly_top_selling_products_dashboard.json?start_date=${startDate}&end_date=${endDate}`
    );
  }
}
export default PosDashboard;

// Yearly Sales -> Params (start_date, end_date)
// /api/reports/yearly_sales_dashboard

// Yearly Top Selling Products -> Params (start_date, end_date)
// /api/reports/yearly_top_selling_products_dashboard

import axiosClient from "..";
const API="/api/admin/pos/purchases"
class PurchasesAPI {
  static viewPurchasesAPI(data) {
    return axiosClient.get(`${API}/${data.id}.json`);
  }
  static createProductpurchasesAPI(purchaseData) {
    return axiosClient.post(`${API}.json`,purchaseData);
  }
  static allPurchasesAPI(page,filterPerpage, filter, idPredicate, filterId, namePredicate, filterName, startDate, endDate) {
    return axiosClient.get(`${API}.json?page=${page}&per_page_count=${filterPerpage}/${filter && `&q[id_${idPredicate}]=${filterId}&q[user_username_${namePredicate}]=${filterName}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`}`);
  }
  static searchProductAPI(value) {
    return axiosClient.get(`/api/admin/pos/search_products.json?&q[barcode]=${value}`);
  }
  static allVendor(filterPhoneNumber){
    return axiosClient.get(`/api/admin/vendors.json?${`&q[phone_no_cont]=${filterPhoneNumber}`}`)}
  
}
export default PurchasesAPI;

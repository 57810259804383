import axiosClient from "..";

class CashierApi {
    static viewTotal(defaultLoc,date, sessionId) {
        return axiosClient.get(`/api/reports/view_total_day_report.json?location_id=${defaultLoc}&date=${date}&session_id=${sessionId}`);
    }
    static viewInvoice(defaultLoc,buttonCheck,date, sessionId) {
        return axiosClient.get(`/api/reports/view_all_orders_day_report.json?location_id=${defaultLoc}&status=${buttonCheck}&date=${date}&session_id=${sessionId}`);
    }
    static PreviousPurchaseHistory(id) {
        return axiosClient.get(`/api/reports/view_purchase_history_report.json?customer_id=${id}`);
    }
    static PreviousPurchaseDetials(id) {
        return axiosClient.get(`/api/reports/view_purchase_details_report.json?invoice_id=${id}`);
    }
    static refundSale(defaultLoc){
    return axiosClient.get(`/api/reports/view_orders_day_report.json?location_id=${defaultLoc}`);
    }
    static allInvoices(defaultLoc) {
        return axiosClient.get(`/api/reports/view_orders_day_report.json?location_id=${defaultLoc}`);
    }
  
}
export default CashierApi;

import axiosClient from "..";
const Api ="/api/users.json"
class UserApi {
  static sendEmailUser(data) {
    return axiosClient.post(`${Api}`, data);
  }
  static createUser(data) {
    return axiosClient.put(`${Api}`, data);
  }
  static resendInvitationAPI(itemId) {
    return axiosClient.put(`/api/users/resend_invitation/${itemId}.json`);
  }
  static acceptInvitationAPI(data) {
    return axiosClient.post(`/api/users/accept_invitation.json`,data);
  }
  static allUsers(page,filterPerpage, filter,  idPredicate, filterId, filterEmail, emailPredicate ,usernamePredicate, filterUsername,userRolePredicate,filterUserRole, startDate, endDate) {
    return axiosClient.get(`${Api}?page=${page}&per_page_count=${filterPerpage}/${filter && `&q[id_${idPredicate}]=${filterId}&q[email_${emailPredicate}]=${filterEmail}&q[username_${usernamePredicate}]=${filterUsername}&q[role_name_${userRolePredicate}]=${filterUserRole}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`}`);
  }
  static getRole() {
    return axiosClient.get("/api/admin/roles.json");
  }
  static viewUser(data) {
    return axiosClient.get(`/api/admin/users/${data.id}.json`);
  }
}
export default UserApi;

import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import CustomerApi from "api/customerapi/customer";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import EmployeesApi from "api/hrmapi/employee";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CreateEmployeeDataManagement() {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [religionValue, setReligionValue] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationalities, setNationalities] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [residence, setResidence] = useState("");
  const [countries, setCountries] = useState([]);
  const [birthDate, setBirthDate] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [professionTitle, setProfessionTitle] = useState("");
  const [hiringDate, SetHiringDate] = useState("");
  const [probationEnd, setProbationEnd] = useState("");
  const [workNo, setWorkNo] = useState("");
  const [workTime, setWorkTime] = useState("");
  const [inviteEmployee, setInviteEmployee] = useState(false);
  const navigate = useNavigate();
  const handleNavgetAll = (name) => {
    if (name === "add another") {
      setProfileImage(null);
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("");
      setReligion("");
      setReligionValue("");
      setNationality("");
      setCountryName("");
      setResidence("");
      setBirthDate(null);
      setMobileNo("");
      setWorkEmail("");
      setEmployeeId("");
      setProfessionTitle("");
      setInviteEmployee(false);
      setWorkNo("");
      setProbationEnd("");
      SetHiringDate("");
      setLoading(false);
    } else {
      setTimeout(() => {
        navigate(`/all-employee-data`);
        setLoading(false);
      }, 2000);
    }
  };
  const handleProfileFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setProfileImage(selectedFile);
    }
  };

  useEffect(() => {
    CustomerApi.AllCountries()
      .then((res) => {
        const filteredCountries = res.data.filter(
          (country) =>
            country.country_name.trim() !== "" &&
            country.country_name.trim() !== ""
        );
        const filterednNationalities = res.data.filter(
          (country) =>
            country.nationality.trim() !== "" &&
            country.country_name.trim() !== ""
        );
        setNationalities(filterednNationalities);
        setCountries(filteredCountries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const nationalitiesOptions = nationalities.map((country) => ({
    value: country.nationality,
    label: country.nationality,
  }));
  const countryOptions = countries.map((country) => ({
    value: country.country_name,
    label: country.country_name,
  }));
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer not to say", label: "Prefer not to say" },
  ];
  const religionOptions = [
    { value: "christian", label: "Christian" },
    { value: "islam", label: "Islam" },
    { value: "hindu", label: "Hindu" },
    { value: "buddhism", label: "Buddhism" },
    { value: "sikhism", label: "Sikhism" },
    { value: "non_religious", label: "Nonreligious" },
  ];

  const handleCreateEmployee = (e) => {
    const { name } = e.target;
    e.preventDefault();
    if (!firstName || !lastName || !workEmail) {
      toast.error("Please fill in all fields.");
      return;
    }
    setLoading(true);
    const employeeData = {
      title: professionTitle,
      work_email: workEmail,
      employee_id: employeeId,
      joining_date: hiringDate,
      probation_end: probationEnd,
      invited: inviteEmployee,
      report_manager_id: 2,
      legal_country_of_residence: residence,
      work_no: workNo,
      work_email: workEmail,
      company_id: parseInt(company_id),
    };
    EmployeesApi.createEmployees({ employees: employeeData })
      .then((response) => {
        const employee_id = response?.data.data.id;
        toast.success(`Employee created successfully`);
        const employeeInfo = new FormData();
        if (profileImage) {
          employeeInfo.set("employee_info[profile_photo]", profileImage);
        }
        employeeInfo.set("employee_info[company_id]", parseInt(company_id));
        employeeInfo.set("employee_info[first_name]", firstName);
        employeeInfo.set("employee_info[last_name]", lastName);
        employeeInfo.set("employee_info[middle_name]", middleName);
        employeeInfo.set("employee_info[gender]", gender);
        employeeInfo.set("employee_info[religion]", religionValue);
        employeeInfo.set("employee_info[nationality]", nationality);
        employeeInfo.set("employee_info[dob]", birthDate);
        EmployeesApi.createEmployeesInfo(employee_id, employeeInfo)
          .then(() => {
            handleNavgetAll(name);
          })
          .catch((error) => {
            console.error("Error creating employee info:", error);
            toast.error("An error occurred while creating employee info");
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error creating employee:", error);
        const errorMessage =
          error.response?.data.message || "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };
  const handleCancel = () => {
    navigate(`/all-employee-data`);
  };

  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Employee</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[80vh]">
          <form onSubmit={handleCreateEmployee}>
            <div className="w-full flex p-4">
              <div className="items-center text-center flex">
                {profileImage ? (
                  <span className="ml-2">
                    <img
                      src={URL.createObjectURL(profileImage)}
                      alt="Avatar"
                      className="w-40 h-40 rounded-full"
                    />
                  </span>
                ) : (
                  <label htmlFor="browsecsv" className="cursor-pointer">
                    <img
                      src="./icons/avatar.jpg"
                      alt="Avatar"
                      className="w-40 h-40 rounded-full"
                    />
                  </label>
                )}
              </div>
              <input
                type="file"
                accept=".png,.jpg"
                id="browsecsv"
                onChange={handleProfileFileChange}
                className="hidden"
              />
              <div className="ml-5 justify-center items-center flex text-24">
                Create new Employee Profile
              </div>
            </div>
            <div className="w-full border  border-gray-300 p-5">
              <div className="text-22">Personal</div>
              <div className="border-b p-3 mb-3 w-full"></div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Preferred Name</div>
                <input
                  required
                  className="p-2 w-full"
                  type="text"
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">First Name</div>
                <input
                  required
                  className="p-2 w-full"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Last Name</div>
                <input
                  required
                  className="p-2 w-full"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Date of Birth</div>
                <input
                  required
                  showIcon
                  type="date"
                  className="p-2 w-full "
                  dateFormat="dd/MM/yyyy"
                  onKeyDown={(e) => e.preventDefault()}
                  disabledKeyboardNavigation
                  selected={birthDate}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Nationality</div>
                <Select
                  value={{ value: nationality, label: nationality }}
                  onChange={(selectedOption) =>
                    setNationality(selectedOption.value)
                  }
                  options={nationalitiesOptions}
                  className="w-full p-2"
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Gender</div>
                <Select
                  value={{ value: gender, label: gender }}
                  required
                  onChange={(obj) => setGender(obj.label)}
                  options={genderOptions}
                  className="w-full p-2"
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Religion</div>
                <Select
                  value={{ value: religion, label: religion }}
                  onChange={(obj) => {
                    setReligionValue(obj.value);
                    setReligion(obj.label);
                  }}
                  options={religionOptions}
                  className="w-full p-2"
                />
              </div>
              <div className="text-22 mt-2">Contact</div>
              <div className="border-b p-3 mb-3 w-full"></div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Mobile No</div>
                <input
                  type="number"
                  className="p-2 w-full"
                  min="0"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Work No</div>
                <input
                  type="number"
                  min="0"
                  className="p-2 w-full"
                  value={workNo}
                  onChange={(e) => setWorkNo(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Work Email</div>
                <input
                  type="email"
                  className="p-2 w-full"
                  value={workEmail}
                  onChange={(e) => setWorkEmail(e.target.value)}
                />
              </div>
              <div className="text-22 mt-2">Work</div>
              <div className="border-b p-3 mb-3 w-full"></div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Title</div>
                <input
                  required
                  className="p-2 w-full"
                  type="text"
                  value={professionTitle}
                  onChange={(e) => setProfessionTitle(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Employee ID</div>
                <input
                  required
                  className="p-2 w-full"
                  min="0"
                  type="number"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className="mb-2">Hiring Date</div>
                <input
                  required
                  className="p-2 w-full"
                  placeholderText="Select Date"
                  dateFormat="dd/MM/yyyy"
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  disabledKeyboardNavigation
                  selected={hiringDate}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  onChange={(e) => SetHiringDate(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className="mb-2">Probation End Date</div>
                <input
                  placeholderText="DD/MM/YYYY"
                  className="p-2 w-full"
                  dateFormat="DD/MM/YYYY"
                  onKeyDown={(e) => e.preventDefault()}
                  disabledKeyboardNavigation
                  showMonthDropdown
                  type="date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  selected={probationEnd}
                  onChange={(e) => setProbationEnd(e.target.value)}
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className="mb-2">Work Week</div>
                <Select className="w-full p-2" />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className=" mb-2">Legal Country of Residence</div>
                <Select
                  value={{ value: residence, label: residence }}
                  onChange={(selectedOption) =>
                    setResidence(selectedOption.value)
                  }
                  options={countryOptions}
                  className="w-full p-2"
                />
              </div>
              <div className="border w-[70%] border-gray-300 p-2 mb-2">
                <div className="mb-2">Work Time</div>
                <Select className="w-full p-2" />
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  value={inviteEmployee}
                  onChange={(e) => setInviteEmployee(!inviteEmployee)}
                />
                <div className="ml-2">
                  Send invitation to employee upon creation (requires work
                  email)
                </div>
              </div>

              <div className=" w-1/2 flex justify-between  p-4">
                <div className="w-[30%]">
                  <button
                    className="w-full border p-3.5 text-blue"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-[30%]">
                  <button
                    className="w-full btn-primary"
                    onClick={handleCreateEmployee}
                    type="submit"
                    name="create"
                  >
                    Create
                  </button>
                </div>
                <div className="w-[38%]">
                  <button
                    className="w-full btn-primary"
                    name="add another"
                    onClick={handleCreateEmployee}
                    type="button"
                  >
                    Create and add another
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateEmployeeDataManagement;

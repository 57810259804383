import axiosClient from "..";
const Api = "/api/hrm/employees/departments";
class DepartmentApi {
  static createDepartment(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allDepartments() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewDepartmentApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateDepartmentApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteDepartments(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default DepartmentApi;

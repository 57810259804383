import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import Button from "Components/Common/Button";
import AccountsApi from "api/inventorymanagement/accounts";
import LocationsAPI from "api/location/location";
import { toast } from "react-toastify";

function UpdateAccount() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [accountdata, setAccountdata] = useState({
    locations: [],
    selectedBranch: "",
    code: "",
    name: "",
  });

  useEffect(() => {
    AccountsApi.viewAccount(params.id)
      .then((response) => {
        setLoading(false);
        setAccountdata((prevState) => ({
          ...prevState,
          code: response?.data?.account_code?.code,
          name: response?.data?.account_code?.name,
        }));
        setAddress(response?.data?.account_code?.location);
      })
      .catch((error) => {
        setLoading(false);
      });

    LocationsAPI.allLocationsAPI()
      .then((response) => {
        const matchedLocation = response?.data?.locations.find(
          (location) => location.address === address
        );
        setAccountdata((prevState) => ({
          ...prevState,
          locations: response?.data?.locations,
          selectedBranch: matchedLocation ? matchedLocation.id : "",
        }));
      })
      .catch((error) => {
        if (error) {
          toast.error("Error fetching locations");
        }
      });
  }, [params.id, address]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-accounts");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    AccountsApi.updateAccount(params.id, {
      account_code: {
        location_id: accountdata.selectedBranch,
        name: accountdata.name,
        code: parseInt(accountdata.code),
      },
    })
      .then((response) => {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/all-accounts");
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Account Update
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="border-t-2 border-blue bg-white rounded-md min-h-[50vh]">
          <form onSubmit={handleSubmit}>
            <div className="w-full flex mt-4">
              <div className="w-[20%] mx-4">
                <label htmlFor="branchName">Branch Name</label>
                <select
                  className="form-control mt-2"
                  name="selectedBranch"
                  value={accountdata.selectedBranch}
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Select Branch</option>
                  {accountdata.locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.address}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[20%] mx-4">
                <label htmlFor="accountsCode">Code</label>
                <input
                  type="number"
                  className="form-control mt-2 hideNumberArrow"
                  name="code"
                  placeholder="Enter Code"
                  value={accountdata.code}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-[20%] mx-4">
                <label htmlFor="accountsName">Name</label>
                <input
                  type="text"
                  className="form-control mt-2 hideNumberArrow"
                  name="name"
                  placeholder="Enter Name"
                  value={accountdata.name}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-[20%] my-5">
                <Button type="submit">Update Account</Button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default UpdateAccount;

import React from "react";
import useOverview from "../Hooks/useOverview";
import ShowComponent from "Components/ShowComponent";

const OverviewTabRenderer = ({ date, identifier }) => {
  const { currentDate, startDate, endDate } = date;
  const { data, loading, message } = useOverview(identifier, {
    currentDate,
    startDate,
    endDate,
  });

  const icons = {
    annual_sales: "/icons/sales",
    daily_sales: "/icons/sales",
    annual_purchases: "/icons/purchaseIcon",
    daily_purchases: "/icons/purchaseIcon",
    annual_refunds: "/icons/allDiscount",
    daily_refunds: "/icons/allDiscount",
    outOfStockProducts: "/icons/products",
    totalProducts: "/icons/products",
  };

  const colors = {
    annual_refunds: "text-[#ff4560]",
    daily_refunds: "text-[#ff4560]",
    outOfStockProducts: "text-[#ff4560]",
  };
  const identifiers = {
    outOfStockProducts: "Out Of Stock Products",
    totalProducts: "Total Products",
  };
  return (
    <div className="flex flex-start items-center gap-3 w-full my-2">
      <ShowComponent condition={loading}>
        <div className="w-[40px] h-[40px] border-t-1 border-[#0090fb] rounded-full animate-spin"></div>
      </ShowComponent>
      <ShowComponent condition={!loading}>
        <div className="w-10 h-10 flex justify-center items-center rounded-full border">
          <img
            className="filter invert w-5"
            src={`${icons[data?.identifier]}.svg`}
            alt={data?.identifier}
          />
        </div>
        <div>
          <span>
            {identifiers[data?.identifier] ||
              data?.identifier
                ?.split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
          </span>
          <p
            className={`mt-2 text-18 font-semibold ${
              colors[data?.identifier] || "text-[#0090fb]"
            }`}
          >
            {data?.count}
          </p>
        </div>
      </ShowComponent>
    </div>
  );
};

export default OverviewTabRenderer;

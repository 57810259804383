import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AssetApi from "api/hrmapi/assests";
import { useNavigate } from "react-router-dom";
function CreateAssestsType({
  contentOnly = false,
  setPopupId,
  setFetchAssests,
}) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-asset-type`);
    }, 2000);
  };
  const handleCreateAssets = (e) => {
    e.preventDefault();
    setLoading(true);
    AssetApi.createAssetType({
      asset_type: {
        name: name,
        company_id: company_id,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        handleNavgetAll();
        toast.success(`${Message}`);
        if (contentOnly) {
          setPopupId("");
          setFetchAssests(true);
        }
        setName("");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Assets Type</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreateAssets}>
            <div className="w-full flex p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[14%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateAssestsType;

import React, { useState } from "react";
import WorkWeekApi from "api/hrmapi/workweek";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function CreateWorkWeek({ setPopupId, setFetchWorkWeek }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [workWeekName, setWorkWeekName] = useState("");
  const [workDays, setWorkDays] = useState([
    { day: "sunday", selected: false, fullDay: true },
    { day: "monday", selected: false, fullDay: true },
    { day: "tuesday", selected: false, fullDay: true },
    { day: "wednesday", selected: false, fullDay: true },
    { day: "thursday", selected: false, fullDay: true },
    { day: "friday", selected: false, fullDay: true },
    { day: "saturday", selected: false, fullDay: true },
  ]);

  const handleDayToggle = (index) => {
    const updatedDays = [...workDays];
    updatedDays[index].selected = !updatedDays[index].selected;
    setWorkDays(updatedDays);
  };

  const handleToggleChange = (index) => {
    const updatedDays = [...workDays];
    updatedDays[index].fullDay = !updatedDays[index].fullDay;
    setWorkDays(updatedDays);
  };
  const handleCancel = () => {
    setWorkWeekName("");
    setWorkDays("");
    setPopupId("");
  };
  const handleCreateWorkWeek = () => {
    const formattedWorkDays = workDays.map((day) => ({
      day: day.day,
      day_type: day.selected ? (day.fullDay ? "Full Day" : "Half Day") : null,
    }));

    const data = {
      name: workWeekName,
      company_id: company_id,
      work_days_attributes: formattedWorkDays,
    };
    WorkWeekApi.creatWorkWeekApi(data)
      .then((response) => {
        toast.success("Work week created successfully");
        handleCancel();
        setFetchWorkWeek(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full">
      <div className="text-22 p-2">Add Workweek</div>
      <div className="p-2">Workweek Name</div>
      <div>
        <input
          type="text"
          className="border border-gray-300 px-3 py-2 w-full mb-4"
          value={workWeekName}
          onChange={(e) => setWorkWeekName(e.target.value)}
        />
      </div>
      <div className="p-2">Select working days and duration</div>
      {workDays.map((day, index) => (
        <div key={index} className="p-2 w-full flex">
          <div className="p-2 w-[16%] justify-between flex border boderradius-5">
            <div className="w-[40%]">
              <input
                type="checkbox"
                className="w-full rounded-full"
                checked={day.selected}
                onChange={() => handleDayToggle(index)}
              />
            </div>
            <div className="w-[48%]">
              {day.day.substring(0, 3).toUpperCase()}
            </div>
          </div>
          {day.selected && (
            <div className="flex w-full justify-center items-center">
              <div>Half day</div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={day.fullDay}
                  onChange={() => handleToggleChange(index)}
                />
                <div
                  className={`relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 ${
                    day.fullDay
                      ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                      : ""
                  } after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${
                    day.fullDay ? "peer-checked:bg-blue-600" : ""
                  }`}
                ></div>
              </label>

              <div>Full day</div>
            </div>
          )}
        </div>
      ))}
      <div className="w-full justify-end flex ">
        <div className="w-[35%] flex justify-between">
          <div className="w-[48%]">
            <button className="btn-primary w-full " onClick={handleCancel}>
              Cancle
            </button>
          </div>
          <div className="w-[48%]">
            <button
              onClick={handleCreateWorkWeek}
              className="btn-primary w-full"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateWorkWeek;

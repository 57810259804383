import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import CustomerApi from "api/customerapi/customer";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DepartmentApi from "api/hrmapi/department";
import PositionApi from "api/hrmapi/positionapi";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import CreateDepartment from "../Departments/CreateDepartment";
import CreatePosition from "../Positions/CreatePosition";
import CreateContract from "../Contract/CreateContract";
import EmployeesApi from "api/hrmapi/employee";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ContractApi from "api/hrmapi/contract";
import BranchesApi from "api/hrmapi/branches";
import FormatDate from "Components/Common/FormatDate";
import DocumentApi from "api/hrmapi/document";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AllContract from "../Contract/AllContract";
import AssetApi from "api/hrmapi/assests";
function EmployeeDataMagement() {
  const [activeComponent, setActiveComponent] = useState("PersonalInfo");
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [file, setFile] = useState(null);
  const [profileChange, setProfileChange] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [religionValue, setReligionValue] = useState("");
  const [nationality, setNationality] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [languages, setLanguages] = useState("");
  const [telephoneNo, setTelephoneNo] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [emergencyNo, setEmergencyNo] = useState("");
  const [emailPersonal, setEmailPersonal] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");

  // ........................... EmploymentInfo......................................
  const [departmentId, setDepartmentId] = useState("");
  const [allDepartments, setAllDepartments] = useState([]);
  const [contractId, setContractId] = useState("");
  const [allContracts, setAllContaract] = useState([]);
  const [fetchDepartments, setFetchDepartments] = useState(false);
  const [branchesId, setBranchesId] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const [fetchBranches, setFetchBranches] = useState(false);
  const [fetchContaract, setFetchContaract] = useState(false);
  const [fetchPosition, setFetchPostion] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [positions, setAllPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [professionTitle, setProfessionTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [employmentTypeId, setEmploymentTypeId] = useState("");
  const [workingFromType, setWorkingFromType] = useState("");
  const [workingFromId, setWorkingFromId] = useState("");
  const [selectedLineManager, setSelectedLineManager] = useState("");
  const [emailOffice, setEmailOffice] = useState("");
  const [grade, setGrade] = useState("");
  const [staffCode, setStaffCode] = useState("");
  const [projects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [functions, setFunctions] = useState("");
  const [serviceLines, setServiceLines] = useState([]);
  const [selectedServiceLine, setSelectedServiceLine] = useState("");
  const [supportServices, setSupportServices] = useState([]);
  const [selectedSupportService, setSelectedSupportService] = useState("");
  const [workLocations, setWorkLocations] = useState([]);
  const [selectedWorkLocation, setSelectedWorkLocation] = useState("");
  const [payCardNo, setPayCardNo] = useState("");
  const [payrollCompanies, setPayrollCompanies] = useState([]);
  const [selectedPayrollCompany, setSelectedPayrollCompany] = useState("");
  const [sponsorshipId, setSponsorshipId] = useState("");
  const [sponsorshipType, setSponsorshipType] = useState("");
  const [workPermitId, setWorkPermitId] = useState("");
  const [workPermitType, setWorkPermitType] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [terminationDate, setTerminationDate] = useState("");
  const [probationEnd, setProbationEnd] = useState("");
  const sponseoption = [
    { value: "", label: "Please Select" },
    { value: "0", label: "Same Company" },
    { value: "1", label: "Other Company" },
    { value: "2", label: "Father" },
    { value: "3", label: "Husband" },
    { value: "4", label: "Under Process" },
  ];
  const workFromOptions = [
    { value: "", label: "Please Select" },
    { value: "0", label: "Home" },
    { value: "1", label: "Office" },
    { value: "3", label: "Other" },
  ];
  const employmentTypeOption = [
    { value: "", label: "Please Select" },
    { value: "0", label: "Contract" },
    { value: "1", label: "Full Time" },
    { value: "2", label: "Internship" },
    { value: "3", label: "Part Time" },
    { value: "4", label: "Temporary" },
    { value: "5", label: "Seasonal" },
    { value: "6", label: "Freelance" },
  ];
  const navigate = useNavigate();
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-employee-data`);
    }, 2000);
  };

  // ........................... DocumentInfo......................................

  const [allDocument, setAllDocument] = useState([]);
  const [documentrows, setDocumentRows] = useState([
    {
      id: "",
      docName: "",
      DocNo: "",
      docIssueDate: null,
      docExpiryDate: null,
      possession: "",
      attachment: "",
    },
  ]);
  useEffect(() => {
    DocumentApi.allDocument()
      .then((res) => {
        const formattedDocument = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllDocument(formattedDocument);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const addDocRow = () => {
    setDocumentRows([
      ...documentrows,
      {
        id: "",
        docName: "",
        DocNo: "",
        docIssueDate: null,
        docExpiryDate: null,
        possession: "",
        attachment: "",
      },
    ]);
  };

  const handleDocInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...documentrows];
    updatedRows[index][name] = value;
    setDocumentRows(updatedRows);
  };
  const handleDocNameInputChange = (selectedOption, index) => {
    const updatedRows = [...documentrows];
    updatedRows[index].docName = selectedOption ? selectedOption.value : "";
    setDocumentRows(updatedRows);
  };

  const handleDocDateChange = (date, field, index) => {
    const updatedRows = [...documentrows];
    updatedRows[index][field] = date;
    setDocumentRows(updatedRows);
  };

  const handleFileDocChange = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedRows = [...documentrows];
      updatedRows[index].attachment = selectedFile;
      setDocumentRows(updatedRows);
    }
  };

  const handleModal = (e) => {
    const { name } = e.target;
    console.log("name", name);
    setPopupId(name);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  const [docInfoLenght, setDocInfoLenght] = useState("");
  const handleDocSaveRow = (row) => {
    const exists = docInfoLenght.some((item) => item.id === row.id);
    if (!exists) {
      const employeeCreateDocuments = new FormData();
      filteredDocRows.forEach((row, index) => {
        employeeCreateDocuments.set(
          `employee_documents[documents][${index}][document_type_id]`,
          row.docName
        );
        employeeCreateDocuments.set(
          `employee_documents[documents][${index}][issue_date]`,
          row.docIssueDate ? FormatDate(row.docIssueDate) : ""
        );
        employeeCreateDocuments.set(
          `employee_documents[documents][${index}][expiry_date]`,
          row.docExpiryDate ? FormatDate(row.docExpiryDate) : ""
        );
        employeeCreateDocuments.set(
          `employee_documents[documents][${index}][document_no]`,
          row.DocNo
        );

        if (row.attachment) {
          employeeCreateDocuments.set(
            `employee_documents[documents][${index}][file]`,
            row.attachment
          );
        }
      });
      EmployeesApi.createEmployeeDocuments(params.id, employeeCreateDocuments)
        .then((response) => {
          toast.success(response.data.message);
          handleNavgetAll();
          console.log("createEmployeeDocuments:", response);
        })
        .catch((error) => {
          console.error("createEmployeeDocuments:", error);
        });
    } else {
      const employeeDocuments = new FormData();

      employeeDocuments.set(
        `employee_documents[document_type_id]`,
        row.docName
      );
      employeeDocuments.set(
        `employee_documents[issue_date]`,
        row.docIssueDate ? FormatDate(row.docIssueDate) : ""
      );
      employeeDocuments.set(
        `employee_documents[expiry_date]`,
        row.docExpiryDate ? FormatDate(row.docExpiryDate) : ""
      );
      employeeDocuments.set(`employee_documents[document_no]`, row.DocNo);

      if (row.attachment) {
        employeeDocuments.set(`employee_documents[file]`, row.attachment);
      }

      EmployeesApi.updateDocumentInfo(row.id, params.id, employeeDocuments)
        .then((response) => {
          toast.success(response.data.message);
          handleNavgetAll();
          console.log("fdsfsf");
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";

          toast.error(`Error: ${errorMessage}`);
          console.error(":", error);
        });
    }
  };

  // ................................ employe assets..............................

  const [allEmployeeAssets, setAllEmployeeAssets] = useState([]);
  useEffect(() => {
    AssetApi.allCompanyAsset()
      .then((res) => {
        const formatted = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllEmployeeAssets(formatted);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  const [employeeAssetsRows, setEmployeeAssetsRows] = useState([
    {
      name: "",
      assignDate: "",
      returnDate: null,
      notes: "",
    },
  ]);
  const handleEmployeeAssetsInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...employeeAssetsRows];
    updatedRows[index][name] = value;
    setEmployeeAssetsRows(updatedRows);
  };
  const handleEmployeeAssetsNameInputChange = (selectedOption, index) => {
    const updatedRows = [...employeeAssetsRows];
    updatedRows[index].name = selectedOption ? selectedOption.value : "";
    setEmployeeAssetsRows(updatedRows);
  };

  const handleEmployeeAssetsDateChange = (date, field, index) => {
    const updatedRows = [...employeeAssetsRows];
    updatedRows[index][field] = date;
    setEmployeeAssetsRows(updatedRows);
  };

  const addEmployeeAssetsRow = () => {
    setEmployeeAssetsRows((prevRows) => [
      ...prevRows,
      {
        name: "",
        assignDate: "",
        returnDate: null,
        notes: "",
      },
    ]);
  };
  const [employeeAssetsLenght, setEmployeeAssetsLenght] = useState("");
  const handleUpdateEmployeeAssetsRow = (row) => {
    const assetsExist = employeeAssetsLenght.some((item) => item.id === row.id);

    const employeeAssets = {
      employee_assets: {
        assets: [
          {
            asset_id: row.name,
            assign_date: row.assignDate ? FormatDate(row.assignDate) : "",
            return_date: row.returnDate ? FormatDate(row.returnDate) : "",
            notes: row.notes || "",
            employee_id: params.id,
          },
        ],
      },
    };

    if (!assetsExist) {
      EmployeesApi.createEmployeeAssets(employeeAssets)
        .then((response) => {
          const Message = response
            ? response.data.message
            : "An error occurred";
          toast.success(`${Message}`);
          console.log("Employee assets created:", response);
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";

          toast.error(`Error: ${errorMessage}`);
          console.error("Error creating employee assets:", error);
        });
    } else {
      const id = allEmployeeAssets.find(
        (item) => item.label === row.name
      )?.value;
      EmployeesApi.updateEmployeeAssets(row.id, params.id, {
        employee_asset: {
          asset_id: id,
          assign_date: row.assignDate ? FormatDate(row.assignDate) : "",
          return_date: row.returnDate ? FormatDate(row.returnDate) : "",
          notes: row.notes || "",
        },
      })
        .then((response) => {
          const Message = response
            ? response.data.message
            : "An error occurred";
          toast.success(`${Message}`);
          console.log("Employee assets updated:", response);
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";

          toast.error(`Error: ${errorMessage}`);
          console.error("Error updating employee assets:", error);
        });
    }
  };
  // ........................... FamilyInfo......................................
  const [familyInfoLenght, setFamilyInfoLenght] = useState("");
  const [familyInforows, setFamilyInfoRows] = useState([
    {
      id: "",
      name: "",
      relation: "",
      dateOfBirth: null,
      currentLoction: "",
      idNo: "",
      idExpiryDate: null,
      sponsoredBy: "",
    },
  ]);

  const handleFamInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...familyInforows];
    updatedRows[index][name] = value;
    setFamilyInfoRows(updatedRows);
  };
  const handleFamDateChange = (date, field, index) => {
    const updatedRows = [...familyInforows];
    updatedRows[index][field] = date;
    setFamilyInfoRows(updatedRows);
  };

  const addFamRow = () => {
    setFamilyInfoRows([
      ...familyInforows,
      {
        id: "",
        Name: "",
        relation: "",
        dateOfBirth: null,
        currentLoction: "",
        idNo: "",
        idExpiryDate: null,
        sponsoredBy: "",
      },
    ]);
  };
  const filteredFamRows = familyInforows.filter((row) => {
    const requiredFields = ["name"];
    return requiredFields.every((field) => row[field]);
  });
  const filteredDocRows = documentrows.filter((row) => {
    const requiredFields = ["docName"];
    return requiredFields.every((field) => row[field]);
  });
  const handleFamSaveRow = (row) => {
    const exists = familyInfoLenght.some((item) => item.id === row.id);
    if (!exists) {
      const employeeFamilyInfoAttributes = filteredFamRows.map((row) => ({
        name: row.name,
        relationship_to_employee: row.relation,
        dob: row.dateOfBirth ? FormatDate(row.dateOfBirth) : "",
        current_location: row.currentLoction,
        id_no: row.idNo,
        id_expiry_date: row.idExpiryDate ? FormatDate(row.idExpiryDate) : "",
        sponsored_by: row.sponsoredBy,
        employee_id: params.id,
      }));

      const dataFamily = {
        employee_family_info: {
          attributes: employeeFamilyInfoAttributes,
        },
      };
      EmployeesApi.createFamilyInfos(dataFamily)
        .then((response) => {
          toast.success(response.data.message);
          handleNavgetAll();
          console.log("Family info created:", response);
        })
        .catch((error) => {
          console.error("Error creating family info:", error);
        });
    } else {
      EmployeesApi.updateFamilyInfo(row.id, params.id, {
        employee_family_info: {
          name: row.name,
          relationship_to_employee: row.relation,
          dob: row.dateOfBirth ? FormatDate(row.dateOfBirth) : "",
          current_location: row.currentLoction,
          id_no: row.idNo,
          id_expiry_date: row.idExpiryDate ? FormatDate(row.idExpiryDate) : "",
          sponsored_by: row.sponsoredBy,
        },
      })
        .then((response) => {
          toast.success(response.data.message);
          handleNavgetAll();
          // console.log(object);
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";

          toast.error(`Error: ${errorMessage}`);
          console.error(":", error);
        });
    }
  };
  // ..........................................................................

  // ........................... informationTicket......................................
  const [informationTicket, setInformationTicket] = useState("");
  const [informationTicketValue, setInformationTicketValue] = useState("");
  const [originAirport, setOriginAirport] = useState("");
  const [destinationAirport, setDestinationAirport] = useState("");
  const [entitledEveryLable, setEntitledEveryLable] = useState("");
  const [entitledEveryValue, setEntitledEveryValue] = useState(null);
  const [airClassLable, setAirClassLable] = useState("");
  const [airClassValue, setAirClassValue] = useState(null);
  const [onTripLable, setOnTripLable] = useState("");
  const [onTripValue, setOnTripValue] = useState(null);
  const [adult, setAdult] = useState("");
  const [children, setChildren] = useState("");
  const [traveld, setTraveld] = useState("");
  const handleUpdateTravelInfo = () => {
    if (
      informationTicketValue === "true" &&
      (!originAirport || !destinationAirport)
    ) {
      toast.error("Please enter the required data");
      return;
    }
    EmployeesApi.updateTravelInfo(params.id, {
      employee_travel_info: {
        ticket_entitled: informationTicketValue,
        ongoing_airpot: originAirport,
        destination_airpot: destinationAirport,
        entitled_every: parseInt(entitledEveryValue),
        air_class: parseInt(airClassValue),
        ticket_type: parseInt(onTripValue),
        adult: adult,
        children: children,
      },
    })
      .then((response) => {
        toast.success(response.data.message);
        handleNavgetAll();
        console.log("object");
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        console.log("object");
      });
  };

  const TicketOption = [
    { value: "", label: "Please Select" },
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  let params = useParams();
  useEffect(() => {
    EmployeesApi.getDocumentInfo(params.id)
      .then((res) => {
        const employeeDocuments = res?.data.map((item) => {
          return {
            id: item.id,
            docName: item.document_type_id,
            docIssueDate: item.issue_date ? new Date(item.issue_date) : null,
            docExpiryDate: item.expiry_date ? new Date(item.expiry_date) : null,
            DocNo: item.document_no,
            attachment: item.attachment,
          };
        });

        setDocumentRows(employeeDocuments);
        setDocInfoLenght(employeeDocuments);
      })
      .catch((error) => {
        console.log("eroror");
      });
  }, []);
  useEffect(() => {
    EmployeesApi.viewEmployeesApi(params)
      .then((res) => {
        const employmentInfo = res?.data.employee.employement_info;
        setProfessionTitle(employmentInfo?.professional_title);
        setJoiningDate(employmentInfo?.joining_date);
        setTerminationDate(employmentInfo?.termination_date);
        setProbationEnd(employmentInfo?.probaion_end);
        setEmailOffice(employmentInfo?.company);
        setDepartmentId(employmentInfo?.departments[0]?.id || "");
        setSelectedPosition(employmentInfo?.position_id);
        setContractId(employmentInfo?.contract_type_id);
        setBranchesId(employmentInfo?.branch_id);
        setReligionValue(res?.data?.employee?.employee_info.religion);
        setReligion(
          religionOptions.find(
            (option) =>
              option.value === res?.data.employee.employee_info.religion
          )?.label || ""
        );
        setFile(res?.data.employee.employee_info.profile_photo);
        setProfileChange(res?.data.employee.employee_info.profile_photo);
        setFirstName(res?.data.employee.employee_info.first_name);
        setLastName(res?.data.employee.employee_info.last_name);
        setMiddleName(res?.data.employee.employee_info.middle_name);
        setGender(res?.data.employee.employee_info.gender);

        setNationality(res?.data.employee.employee_info.nationality);
        setBirthDate(
          res?.data.employee.employee_info.dob
            ? new Date(res.data.employee.employee_info.dob)
            : null
        );
        setMaritalStatus(res?.data.employee.employee_info.marital_status);
        setLanguages(res?.data.employee.employee_info.spoken_langauges);
        setTelephoneNo(res?.data.employee.employee_info.telephone_no);
        setMobileNo(res?.data.employee.employee_info.mobile_no);
        setEmergencyNo(res?.data.employee.employee_info.emergency_no);
        setCountry(res?.data.employee.employee_info.country);
        setPermanentAddress(res?.data.employee.employee_info.permanent_address);
        setCurrentAddress(res?.data.employee.employee_info.current_address);
        const infamilyInfo = res?.data.employee.employee_family_infos.map(
          (item) => {
            return {
              id: item.id,
              name: item.name,
              relation: item.relationship_to_employee,
              dateOfBirth: item.dob ? new Date(item.dob) : null,
              currentLoction: item.current_location,
              idNo: item.id_no,
              idExpiryDate: item.id_expiry_date
                ? new Date(item.id_expiry_date)
                : null,
              sponsoredBy: item.sponsored_by,
            };
          }
        );
        const employeeAssets = res?.data.employee.employee_assets.map(
          (item) => {
            return {
              id: item.id,
              name: item.name,
              notes: item.notes,
              assignDate: item.assign_date ? new Date(item.assign_date) : null,
              returnDate: item.return_date ? new Date(item.return_date) : null,
            };
          }
        );
        setEmployeeAssetsRows(employeeAssets);
        setEmployeeAssetsLenght(employeeAssets);
        // const employeeDocuments = res?.data.employee.employee_documents.map(
        //   (item) => {
        //     return {
        //       id: item.id,
        //       docName: item.document_type,
        //       docIssueDate: item.issue_date ? new Date(item.issue_date) : null,
        //       docExpiryDate: item.expiry_date
        //         ? new Date(item.expiry_date)
        //         : null,
        //       DocNo: item.document_no,
        //       attachment: item.attachment,
        //     };
        //   }
        // );

        // setDocumentRows(employeeDocuments);
        setFamilyInfoRows(infamilyInfo);
        setFamilyInfoLenght(infamilyInfo);
        setTraveld(res?.data.employee.travel_info.id);
        const ticketEntitled = res.data.employee.travel_info.ticket_entitled;
        setInformationTicketValue(ticketEntitled.toString());
        setInformationTicket(
          TicketOption.find(
            (option) => option.value === ticketEntitled.toString()
          )?.label || ""
        );
        setOriginAirport(res?.data.employee.travel_info.ongoing_airpot);
        setDestinationAirport(
          res?.data.employee.travel_info.destination_airpot
        );
        setEntitledEveryLable(res?.data.employee.travel_info.entitled_every);
        setAirClassLable(res?.data.employee.travel_info.air_class);
        setOnTripLable(res?.data.employee.travel_info.ticket_type);
        setAdult(res?.data.employee.travel_info.adult);
        setChildren(res?.data.employee.travel_info.children);
        const valueOption = employmentTypeOption.find(
          (option) => option.label === employmentInfo.employement_type
        );
        setEmploymentTypeId(valueOption?.value || "");
        setEmploymentType(valueOption?.label || "");
        setSponsorshipType(employmentInfo.sponsorship || "");
        const add = sponseoption.find(
          (option) => option.label === employmentInfo.sponsorship
        );
        setSponsorshipId(add.value || "");
        const optin = workPermitOptions.find(
          (option) => option.value === employmentInfo.work_permit.toString()
        );
        setWorkPermitType(optin.label || "");
        setWorkPermitId(optin.value || "");
        setWorkingFromType(employmentInfo.working_from || "");
        const option = workFromOptions.find(
          (option) => option.label === employmentInfo.working_from
        );
        setWorkingFromId(option.value || "");
      })
      .catch((error) => {
        console.log(error);
      });
    CustomerApi.AllCountries()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    DepartmentApi.allDepartments()
      .then((res) => {
        const formattedDepartments = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllDepartments(formattedDepartments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchDepartments]);
  useEffect(() => {
    PositionApi.allPositions()
      .then((res) => {
        const formattedPosition = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllPositions(formattedPosition);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchPosition]);
  useEffect(() => {
    ContractApi.allContract()
      .then((res) => {
        const formattedContract = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllContaract(formattedContract);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchContaract]);
  useEffect(() => {
    BranchesApi.allBranches()
      .then((res) => {
        const formattedBranches = res.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        setAllBranches(formattedBranches);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchBranches]);

  const options = countries.map((country) => ({
    value: country.nationality,
    label: country.nationality,
  }));
  const optionCountry = countries.map((country) => ({
    value: country.country_name,
    label: country.country_name,
  }));
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer not to say", label: "Prefer not to say" },
  ];

  const religionOptions = [
    { value: "christian", label: "Christian" },
    { value: "islam", label: "Islam" },
    { value: "hindu", label: "Hindu" },
    { value: "buddhism", label: "Buddhism" },
    { value: "sikhism", label: "Sikhism" },
    { value: "non_religious", label: "Nonreligious" },
  ];
  const maritalStatusOptions = [
    { value: "Single", label: "Single" },
    { value: "Married", label: "Married" },
    { value: "Divorced", label: "Divorced" },
    { value: "Widowed", label: "Widowed" },
  ];
  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "arabic", label: "Arabic" },
    { value: "croatia", label: "Croatia" },
    { value: "espanol", label: "Espanol" },
    { value: "urdu", label: "Urdu" },
    { value: "thai", label: "Thai" },
    { value: "french", label: "French" },
    { value: "Italian", label: "Italian" },
    { value: "Georgian", label: "Georgian" },
  ];
  const functionOptions = [
    { value: "", label: "Please Select" },
    { value: "Audit", label: "Audit" },
    { value: "Advisory", label: "Advisory" },
    { value: "Translation", label: "Translation" },
  ];

  const workPermitOptions = [
    { value: "", label: "Please Select" },
    { value: "false", label: "No" },
    { value: "true", label: "Yes" },
  ];

  const possessionOptions = [
    { value: "with HR", label: "with HR" },
    { value: "with Employee", label: "with Employee" },
  ];
  const [personalInfoAlert, setPersonalInfoAlert] = useState(false);
  const [EmploymentInfoAlert, setEmploymentInfoAlert] = useState(false);
  const [DocumentInfoAlert, setDocumentInfoAlert] = useState(false);
  useEffect(() => {
    if (firstName && lastName && gender && birthDate) {
      setPersonalInfoAlert(false);
    }
  }, [firstName, lastName, gender, birthDate]);
  useEffect(() => {
    if (departmentId && selectedPosition && joiningDate && contractId) {
      setEmploymentInfoAlert(false);
    }
  }, [departmentId, selectedPosition, joiningDate, contractId]);
  let documentNamealert = documentrows.some((row) => row.docName);
  useEffect(() => {
    if (documentNamealert) {
      setDocumentInfoAlert(false);
      return;
    }
  }, [documentNamealert]);
  const handleEmploymentTypeChange = (selectedOption) => {
    setEmploymentType(selectedOption.label);
    setEmploymentTypeId(selectedOption.value);
  };
  const handleSponsorshipChange = (selectedOption) => {
    setSponsorshipType(selectedOption.label);
    setSponsorshipId(selectedOption.value);
  };
  const handleWorkingFromChange = (selectedOption) => {
    setWorkingFromType(selectedOption.label);
    setWorkingFromId(selectedOption.value);
  };
  const handleWorkPermitChange = (selectedOption) => {
    setWorkPermitType(selectedOption.label);
    setWorkPermitId(selectedOption.value);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  const handlePersonal = (e) => {
    if (!firstName || !lastName || !gender || !birthDate) {
      setPersonalInfoAlert(true);
      return;
    } else {
      setActiveComponent("EmploymentInfo");
    }
  };
  const handleEmployment = (e) => {
    if (!departmentId || !selectedPosition || !joiningDate || !contractId) {
      setEmploymentInfoAlert(true);
      return;
    } else {
      setActiveComponent("DocumentInfo");
    }
  };
  const handleDocument = (e) => {
    if (!documentNamealert) {
      setDocumentInfoAlert(true);
      return;
    } else {
      setActiveComponent("FamilyInfo");
    }
  };
  const handlePersonalUpdate = () => {
    if (!firstName) {
      toast.error("Please enter first name");
      return;
    }
    if (!lastName) {
      toast.error("Please enter last name");
      return;
    }
    if (!gender) {
      toast.error("Please select Gender");
      return;
    }
    if (!religionValue) {
      toast.error("Please select Religion");
      return;
    }
    if (!birthDate) {
      toast.error("Please select date of birth");
      return;
    }
    const employeeInfo = new FormData();
    {
      file !== profileChange &&
        employeeInfo.set("employee_info[profile_photo]", file);
    }
    employeeInfo.set("employee_info[company_id]", parseInt(company_id));

    employeeInfo.set("employee_info[first_name]", firstName);
    employeeInfo.set("employee_info[last_name]", lastName);
    employeeInfo.set("employee_info[middle_name]", middleName);
    employeeInfo.set("employee_info[gender]", gender);
    employeeInfo.set("employee_info[religion]", religionValue);
    employeeInfo.set("employee_info[nationality]", nationality);
    employeeInfo.set("employee_info[dob]", birthDate);
    employeeInfo.set("employee_info[marital_status]", maritalStatus);
    employeeInfo.set("employee_info[spoken_langauges]", languages);
    employeeInfo.set("employee_info[telephone_no]", telephoneNo);
    employeeInfo.set("employee_info[mobile_no]", mobileNo);
    employeeInfo.set("employee_info[emergency_no]", emergencyNo);
    employeeInfo.set("employee_info[country]", country);
    employeeInfo.set("employee_info[personal_email]", emailPersonal);
    employeeInfo.set("employee_info[permanent_address]", permanentAddress);
    employeeInfo.set("employee_info[current_address]", currentAddress);
    EmployeesApi.updatePersonalInfo(params.id, employeeInfo)
      .then((response) => {
        toast.success(response.data.message);
        handleNavgetAll();
        console.log("Family info created:", response);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        console.error("Error creating family info:", error);
      });
  };
  const handleUpdateEmployementInfo = () => {
    EmployeesApi.updateEmployemantInfo(params.id, {
      employees: {
        position_id: selectedPosition,
        branch_id: branchesId,
        contract_type_id: parseInt(contractId),
        professional_title: professionTitle,
        official_email: emailOffice,
        employeement_type: parseInt(employmentTypeId),
        sponsorship: parseInt(sponsorshipId),
        work_permit: workPermitId,
        joining_date: joiningDate ? FormatDate(joiningDate) : "",
        termination_date: terminationDate ? FormatDate(terminationDate) : "",
        probation_end: probationEnd ? FormatDate(probationEnd) : "",
        working_from: parseInt(workingFromId),
        company_id: parseInt(company_id),
      },
      department: {
        ids: [departmentId],
      },
    })
      .then((response) => {
        toast.success(response.data.message);
        handleNavgetAll();
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        console.log("snkds");
      });
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "department"}>
          <Popup setPopup={() => setPopupId("")} className="w-[70%]">
            <CreateDepartment
              contentOnly={true}
              setPopupId={setPopupId}
              setFetchDepartments={setFetchDepartments}
            />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "contractType"}>
          <Popup setPopup={() => setPopupId("")} className="w-[70%]">
            <CreateContract
              contentOnly={true}
              setPopupId={setPopupId}
              setFetchContaract={setFetchContaract}
            />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "position"}>
          <Popup setPopup={() => setPopupId("")} className="w-1/2">
            <CreatePosition
              contentOnly={true}
              setPopupId={setPopupId}
              setFetchPostion={setFetchPostion}
            />
          </Popup>
        </ShowComponent>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Update Employee</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[80vh]">
          {/* .................. TOP BUTTON ..................................... */}
          <div className=" w-full flex justify-around pt-5 border-b ">
            <div
              className={
                (personalInfoAlert && "border-b-2 border-red text-red") ||
                (activeComponent === "PersonalInfo" &&
                  "border-b-2 border-blue text-blue")
              }
            >
              <button
                className="pb-3"
                type="button"
                onClick={() => setActiveComponent("PersonalInfo")}
              >
                Personal Info
              </button>
            </div>
            <div
              className={
                (EmploymentInfoAlert && "border-b-2 border-red text-red") ||
                (activeComponent === "EmploymentInfo" &&
                  "border-b-2 border-blue text-blue")
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("EmploymentInfo")}
              >
                Employment Info
              </button>
            </div>
            <div
              className={
                (DocumentInfoAlert && "border-b-2 border-red text-red") ||
                (activeComponent === "DocumentInfo" &&
                  "border-b-2 border-blue text-blue")
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("DocumentInfo")}
              >
                Document Info
              </button>
            </div>
            <div
              className={
                activeComponent === "FamilyInfo" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("FamilyInfo")}
              >
                Family Info
              </button>
            </div>

            <div
              className={
                activeComponent === "employeeAssets" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("employeeAssets")}
              >
                Employee Assets
              </button>
            </div>
            <div
              className={
                activeComponent === "TravelInfo" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("TravelInfo")}
              >
                Travel Info
              </button>
            </div>
          </div>
          <form>
            {activeComponent === "PersonalInfo" && (
              <>
                <div className="w-full flex p-4">
                  <div className=" w-[15%]">
                    <label
                      htmlFor="browsecsv"
                      className="h-12 p-2 w-full btn-primary cursor-pointer items-center text-center flex"
                    >
                      <div className="w-full flex justify-around">
                        {file ? (
                          <>
                            <span className=" text-white">Change Photo</span>
                          </>
                        ) : (
                          <>
                            <img src="./icons/picture.png" alt="img" />
                            <span className="ml-2 text-white">
                              Upload Photo
                            </span>
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                  <div className="items-center text-center flex ml-4">
                    {file !== profileChange ? (
                      <span className="ml-2 text-12">
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Avatar"
                          className="w-20 h-20 rounded-full"
                        />
                      </span>
                    ) : (
                      <img
                        src={profileChange}
                        alt="Avatar"
                        className="w-20 h-20 rounded-full "
                      />
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  accept=".png,.jpg"
                  id="browsecsv"
                  onChange={handleFileChange}
                  className="hidden"
                />
                <div className="w-full flex justify-around flex-wrap ">
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>First Name</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <input
                        required
                        className="form-control"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Middle Name</div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Last Name</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <input
                        required
                        className="form-control"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Gender</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: gender, label: gender }}
                        required
                        onChange={(obj) => setGender(obj.label)}
                        options={genderOptions}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Religion</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: religionValue, label: religion }}
                        onChange={(obj) => {
                          setReligionValue(obj.value);
                          setReligion(obj.label);
                        }}
                        options={religionOptions}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Nationality</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: nationality, label: nationality }}
                        onChange={(selectedOption) =>
                          setNationality(selectedOption.value)
                        }
                        options={options}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Date Of Birth</div>
                      <div className="text-red">*</div>
                    </div>
                    <DatePicker
                      required
                      className="form-control datePickerWidth"
                      dateFormat="dd/MM/yyyy"
                      onKeyDown={(e) => e.preventDefault()}
                      disabledKeyboardNavigation
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      selected={birthDate ? new Date(birthDate) : null}
                      onChange={(date) => setBirthDate(date)}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Marital Status</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: maritalStatus, label: maritalStatus }}
                        required
                        onChange={(obj) => setMaritalStatus(obj.label)}
                        options={maritalStatusOptions}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Spoken Languages</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: languages, label: languages }}
                        required
                        onChange={(obj) => setLanguages(obj.label)}
                        options={languageOptions}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="border-b p-2"></div>
                <div className="p-4 text-base font-semibold font-montserrat tracking-wide">
                  Employment History
                </div>
                <div className="w-full flex justify-around flex-wrap">
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Telephone No</div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="number"
                        value={telephoneNo}
                        onChange={(e) => setTelephoneNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Mobile No</div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Emergency No</div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="number"
                        value={emergencyNo}
                        onChange={(e) => setEmergencyNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Country</div>
                    </div>
                    <div>
                      <Select
                        value={{ value: country, label: country }}
                        onChange={(selectedOption) =>
                          setCountry(selectedOption.value)
                        }
                        options={optionCountry}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    {/* <div className="flex w-full pt-4 pb-4">
                      <div>Personal Email </div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="email"
                        value={emailPersonal}
                        onChange={(e) => setEmailPersonal(e.target.value)}
                      />
                    </div> */}
                  </div>
                  <div className="w-[30%]"></div>
                  <div className="w-[46%]">
                    <div>
                      <div className="pt-5 pb-5">Permanent Address</div>
                      <div>
                        <textarea
                          className="form-control min-h-36 h-auto p-2"
                          value={permanentAddress}
                          rows={5}
                          onChange={(e) => setPermanentAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-[46%]">
                    <div>
                      <div className="pt-5 pb-5">Current Address</div>
                      <div>
                        <textarea
                          className="form-control min-h-36 h-auto p-2"
                          value={currentAddress}
                          rows={5}
                          onChange={(e) => setCurrentAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" w-full flex justify-end">
                    <div className="w-[15%] p-4">
                      <button
                        className="w-full btn-primary"
                        onClick={handlePersonalUpdate}
                        // onClick={() => setActiveComponent("EmploymentInfo")}
                        type="button"
                      >
                        Save
                      </button>
                    </div>
                    <div className="w-[16%] p-4">
                      <button
                        className="w-full btn-primary"
                        onClick={handlePersonal}
                        name="EmploymentInfo"
                        // onClick={() => setActiveComponent("EmploymentInfo")}
                        type="button"
                      >
                        Move to next tab
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeComponent === "EmploymentInfo" && (
              <>
                <div className="w-full flex justify-around flex-wrap pt-5 ">
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Department</div>
                      <div className="text-red">*</div>
                    </div>
                    <div className="w-full flex">
                      <div className="w-[80%]">
                        <Select
                          required
                          options={allDepartments}
                          value={
                            allDepartments.find(
                              (dept) =>
                                dept.value === departmentId ||
                                dept.label === departmentId
                            ) || ""
                          }
                          onChange={(obj) =>
                            setDepartmentId(obj ? obj.value : "")
                          }
                          styles={customStyles}
                        />
                      </div>
                      <button
                        className="w-[20%] justify-center flex text-center items-center border mb-2.5 h-[2.75rem] rounded-md border-solid border-gray-300  text-gray-700"
                        name="department"
                        type="button"
                        onClick={handleModal}
                      >
                        <button
                          className=" w-[24px] h-[24px] pb-1 text-white  justify-center flex text-center items-center rounded-full bg-blue"
                          name="department"
                          type="button"
                          onClick={handleModal}
                        >
                          +
                        </button>
                      </button>
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Position</div>
                      <div className="text-red">*</div>
                    </div>
                    <div className="w-full flex">
                      {console.log("selectedPosition", selectedPosition)}
                      <Select
                        value={
                          positions.find(
                            (obj) => obj.value === selectedPosition
                          ) || ""
                        }
                        onChange={(selectedOption) => {
                          setSelectedPosition(selectedOption.value);
                        }}
                        styles={customStyles}
                        options={positions}
                        className="w-[80%]"
                        required
                      />
                      <button
                        className="w-[20%] justify-center flex text-center items-center border mb-2.5 h-[2.75rem] rounded-md border-solid border-gray-300  text-gray-700"
                        name="position"
                        type="button"
                        onClick={handleModal}
                      >
                        <button
                          className="w-[24px] h-[24px] pb-1  text-white  justify-center flex text-center items-center rounded-full bg-blue"
                          name="position"
                          type="button"
                          onClick={handleModal}
                        >
                          +
                        </button>
                      </button>
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Profession Title</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <input
                        required
                        className="form-control"
                        type="text"
                        value={professionTitle}
                        onChange={(e) => setProfessionTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Employment Type</div>
                      <div className="text-red">*</div>
                    </div>
                    <Select
                      value={{ value: employmentTypeId, label: employmentType }}
                      onChange={handleEmploymentTypeChange}
                      options={employmentTypeOption}
                      styles={customStyles}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Office Email</div>
                      <div className="text-red">*</div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="email"
                        value={emailOffice}
                        onChange={(e) => setEmailOffice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Working From</div>
                    </div>
                    <Select
                      value={{ value: workingFromId, label: workingFromType }}
                      onChange={handleWorkingFromChange}
                      options={workFromOptions}
                      styles={customStyles}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Sponsorship</div>
                    </div>
                    <Select
                      value={{ value: sponsorshipId, label: sponsorshipType }}
                      onChange={handleSponsorshipChange}
                      options={sponseoption}
                      styles={customStyles}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Work Permit</div>
                    </div>
                    <Select
                      value={{
                        value: workPermitId,
                        label: workPermitType,
                      }}
                      onChange={handleWorkPermitChange}
                      options={workPermitOptions}
                      styles={customStyles}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Joining Date</div>
                      <div className="text-red">*</div>
                    </div>
                    <DatePicker
                      required
                      className="form-control datePickerWidth"
                      placeholderText="Select Date"
                      dateFormat="dd/MM/yyyy"
                      onKeyDown={(e) => e.preventDefault()}
                      disabledKeyboardNavigation
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      selected={joiningDate ? new Date(joiningDate) : null}
                      onChange={(date) => setJoiningDate(date)}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Termination Date</div>
                    </div>
                    <DatePicker
                      className="form-control datePickerWidth"
                      placeholderText="Select Date"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      onKeyDown={(e) => e.preventDefault()}
                      disabledKeyboardNavigation
                      selected={
                        terminationDate ? new Date(terminationDate) : null
                      }
                      onChange={(date) => setTerminationDate(date)}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Probation End</div>
                    </div>
                    <DatePicker
                      placeholderText="Select Date"
                      className="form-control datePickerWidth"
                      dateFormat="dd/MM/yyyy"
                      onKeyDown={(e) => e.preventDefault()}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      disabledKeyboardNavigation
                      selected={probationEnd ? new Date(probationEnd) : null}
                      onChange={(date) => setProbationEnd(date)}
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Contract Type</div>
                      <div className="text-red">*</div>
                    </div>
                    <div className="w-full flex ">
                      <Select
                        value={
                          allContracts.find(
                            (obj) => obj.value === contractId
                          ) || ""
                        }
                        onChange={(obj) => setContractId(obj ? obj.value : "")}
                        options={allContracts}
                        className="w-[80%]"
                        styles={customStyles}
                      />
                      <button
                        className="w-[20%] justify-center flex text-center items-center border mb-2.5 h-[2.75rem] rounded-md border-solid border-gray-300  text-gray-700"
                        name="contractType"
                        type="button"
                        onClick={handleModal}
                      >
                        <button
                          className="w-[24px] h-[24px] pb-1 text-white  justify-center flex text-center items-center rounded-full bg-blue"
                          name="contractType"
                          type="button"
                          onClick={handleModal}
                        >
                          +
                        </button>
                      </button>
                    </div>
                  </div>

                  <div className="w-[30%]">
                    <div className="flex w-full pt-4 pb-4">
                      <div>Branch </div>
                      <div className="text-red">*</div>
                    </div>
                    <Select
                      value={
                        allBranches.find((obj) => obj.value === branchesId) ||
                        ""
                      }
                      onChange={(obj) => setBranchesId(obj ? obj.value : "")}
                      options={allBranches}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className=" w-full flex justify-between  p-4">
                  <div className="w-[16%]">
                    <button
                      className="w-full btn-primary"
                      onClick={() => setActiveComponent("PersonalInfo")}
                      type="button"
                    >
                      Move to Back Tab
                    </button>
                  </div>

                  <div className="w-[35%] flex justify-between">
                    <div className="w-[35%]">
                      <button
                        className="w-full btn-primary"
                        onClick={handleUpdateEmployementInfo}
                        // onClick={() => setActiveComponent("EmploymentInfo")}
                        type="button"
                      >
                        Save
                      </button>
                    </div>
                    <div className="w-[43%]">
                      <button
                        className="w-full btn-primary"
                        onClick={handleEmployment}
                        // onClick={() => setActiveComponent("DocumentInfo")}
                        type="button"
                      >
                        Move to next tab
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeComponent === "DocumentInfo" && (
              <>
                <div className="w-full flex justify-between pt-8 pl-8 pr-8 pb-4">
                  <div className="w-1/7 text-center">Doc Name</div>
                  <div className="w-1/7 text-center">Doc No</div>
                  <div className="w-1/7 text-center">Issue Date</div>
                  <div className="w-1/7 text-center">Expiry Date</div>
                  {/* <div className="w-1/7 text-center">Possession</div> */}
                  <div className="w-1/7 text-center">Attachment</div>
                  <div className="w-1/7 text-center">Action</div>
                </div>
                <div className="">
                  {documentrows.map((row, index) => (
                    <div
                      key={index}
                      className="w-full flex justify-between p-4"
                    >
                      <div className="w-[11%]">
                        <Select
                          options={allDocument}
                          value={
                            allDocument.find(
                              (obj) =>
                                obj.value === row.docName ||
                                obj.label === row.docName
                            ) || ""
                          }
                          styles={customStyles}
                          name="DocName"
                          onChange={(selectedOption) =>
                            handleDocNameInputChange(selectedOption, index)
                          }
                        />
                      </div>

                      <div className="w-[14%]">
                        <input
                          type="text"
                          className="form-control"
                          name="DocNo"
                          value={row.DocNo}
                          onChange={(e) => handleDocInputChange(e, index)}
                        />
                      </div>
                      <div className="w-[14%]">
                        <DatePicker
                          selected={
                            row.docIssueDate ? new Date(row.docIssueDate) : null
                          }
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          onChange={(date) =>
                            handleDocDateChange(date, "docIssueDate", index)
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                      <div className="w-[14%]">
                        <DatePicker
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          selected={
                            row.docExpiryDate
                              ? new Date(row.docExpiryDate)
                              : null
                          }
                          onChange={(date) =>
                            handleDocDateChange(date, "docExpiryDate", index)
                          }
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                      {/* <div className="w-[14%]">
                        <Select
                          value={{
                            value: row.possession,
                            label: row.possession,
                          }}
                          styles={customStyles}
                          onChange={(selectedOption) =>
                            handleDocInputChange(
                              {
                                target: {
                                  name: "possession",
                                  value: selectedOption
                                    ? selectedOption.value
                                    : "",
                                },
                              },
                              index
                            )
                          }
                          options={possessionOptions}
                        />
                      </div> */}
                      <div className="w-[15%]">
                        <label
                          htmlFor={`browsecsv_${index}`}
                          className="h-11 p-2 w-full btn-primary cursor-pointer items-center text-center flex"
                        >
                          <div className="flex justify-around">
                            {row.attachment ? (
                              <span className="">
                                {typeof row.attachment === "string" &&
                                row.attachment.startsWith(
                                  "https://res.cloudinary.com"
                                ) ? (
                                  <img
                                    src={row.attachment}
                                    alt="Attachment"
                                    className="h-4 pr-2"
                                  />
                                ) : (
                                  row.attachment.name || row.attachment
                                )}
                              </span>
                            ) : (
                              <>
                                <img
                                  src="./icons/upload.png"
                                  alt="Upload Icon"
                                  className="h-4 pr-2"
                                />
                                <span className="text-center text-white">
                                  Upload File
                                </span>
                              </>
                            )}
                          </div>
                        </label>

                        <input
                          type="file"
                          accept=".doc,.docx,.xml,.pdf, .xls,.xlsx,pptx, .ppt, ppsx,.pps"
                          id={`browsecsv_${index}`}
                          onChange={(e) => handleFileDocChange(e, index)}
                          className="hidden"
                        />
                      </div>

                      <div className="w-[10%] flex items-center text-center justify-center ">
                        <div className=" border p-2.5 rounded-3xl ">
                          <button
                            type="button"
                            className="text-green cursor-pointer"
                            onClick={() => handleDocSaveRow(row)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-start w-[14%] p-4">
                    <button
                      className="btn-primary w-full"
                      type="button"
                      onClick={addDocRow}
                    >
                      Add Row
                    </button>
                  </div>
                  <div className="w-full p-4 flex justify-between">
                    <div className="w-[16%]">
                      <button
                        className="w-full btn-primary"
                        onClick={() => setActiveComponent("EmploymentInfo")}
                        type="button"
                      >
                        Move to Back Tab
                      </button>
                    </div>
                    <div className="w-[14%]">
                      <button
                        className="w-full btn-primary"
                        onClick={handleDocument}
                        // onClick={() => setActiveComponent("FamilyInfo")}
                        type="button"
                      >
                        Move to next tab
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeComponent === "FamilyInfo" && (
              <>
                <div className="w-full flex justify-between pt-8 pl-2 pr-2 pb-4">
                  <div className="w-[12%] text-center">Name</div>
                  <div className="w-[12%] text-center flex flex-wrap">
                    Relationship To Employee
                  </div>
                  <div className="w-[12%] text-center">Date of Birth</div>
                  <div className="w-[12%] text-center">Current Location</div>
                  <div className="w-[13%] text-center">ID No</div>
                  <div className="w-[12%] text-end">ID Expiry Date</div>
                  <div className="w-[12%] text-end">Sponsored By</div>
                  <div className="w-[10%] text-center">Action</div>
                </div>
                <div className="">
                  {familyInforows.map((row, index) => (
                    <div
                      key={index}
                      className="w-full flex justify-between p-2"
                    >
                      <div className="w-[12.5%]">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={row.name}
                          onChange={(e) => handleFamInputChange(e, index)}
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <input
                          value={row.relation}
                          name="relation"
                          type="text"
                          className="form-control"
                          onChange={(e) => handleFamInputChange(e, index)}
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <DatePicker
                          selected={
                            row.dateOfBirth ? new Date(row.dateOfBirth) : null
                          }
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          onChange={(date) =>
                            handleFamDateChange(date, "dateOfBirth", index)
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <Select
                          value={{
                            value: row.currentLoction,
                            label: row.currentLoction,
                          }}
                          styles={customStyles}
                          onChange={(selectedOption) =>
                            handleFamInputChange(
                              {
                                target: {
                                  name: "currentLoction",
                                  value: selectedOption
                                    ? selectedOption.value
                                    : "",
                                },
                              },
                              index
                            )
                          }
                          options={optionCountry}
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <input
                          type="number"
                          className="form-control"
                          name="idNo"
                          value={row.idNo}
                          onChange={(e) => handleFamInputChange(e, index)}
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <DatePicker
                          selected={
                            row.idExpiryDate ? new Date(row.idExpiryDate) : null
                          }
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          onChange={(date) =>
                            handleFamDateChange(date, "idExpiryDate", index)
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                      <div className="w-[12.5%]">
                        <input
                          type="text"
                          className="form-control"
                          name="sponsoredBy"
                          value={row.sponsoredBy}
                          onChange={(e) => handleFamInputChange(e, index)}
                        />
                      </div>

                      <div className="w-[7%] flex items-end text-center justify-end mb-3">
                        <div className="border p-2.5 rounded-3xl">
                          <button
                            type="button"
                            className="text-green cursor-pointer"
                            onClick={() => handleFamSaveRow(row)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-start w-[10%] p-2">
                    <button
                      className="btn-primary w-full"
                      type="button"
                      onClick={addFamRow}
                    >
                      Add Row
                    </button>
                  </div>
                  <div className="w-full   bg-white p-2 flex justify-between">
                    <div className="w-[16%]">
                      <button
                        className="w-full btn-primary"
                        onClick={() => setActiveComponent("DocumentInfo")}
                        type="button"
                      >
                        Move to Back Tab
                      </button>
                    </div>
                    <div className="w-[14%]">
                      <button
                        className="w-full btn-primary"
                        onClick={() => setActiveComponent("TravelInfo")}
                        type="button"
                      >
                        Move to next tab
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeComponent === "employeeAssets" && (
              <>
                <div className="w-full flex justify-between gap-2 pt-8 pl-4 pr-4 pb-4">
                  <div className="w-[23%] text-center flex">Assets Name</div>
                  <div className="w-[23%] text-center flex">Assign Date</div>
                  <div className="w-[23%] text-center flex ">Return Date</div>
                  <div className="w-[23%] text-center flex ">Notes</div>
                  <div className="w-[8%] text-center ">Action</div>
                </div>
                <div className="">
                  {employeeAssetsRows.map((row, index) => (
                    <div
                      key={index}
                      className="w-full flex justify-between gap-2 p-4"
                    >
                      <div className="w-[23%]">
                        <Select
                          options={allEmployeeAssets}
                          value={
                            allEmployeeAssets.find(
                              (obj) =>
                                obj.value === row.name || obj.label === row.name
                            ) || null
                          }
                          styles={customStyles}
                          onChange={(selectedOption) =>
                            handleEmployeeAssetsNameInputChange(
                              selectedOption,
                              index
                            )
                          }
                        />
                      </div>

                      <div className="w-[23%]">
                        <DatePicker
                          selected={row.assignDate}
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          onChange={(date) =>
                            handleEmployeeAssetsDateChange(
                              date,
                              "assignDate",
                              index
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control w-full"
                        />
                      </div>
                      <div className="w-[23%]">
                        <DatePicker
                          onKeyDown={(e) => e.preventDefault()}
                          disabledKeyboardNavigation
                          selected={row.returnDate}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          onChange={(date) =>
                            handleEmployeeAssetsDateChange(
                              date,
                              "returnDate",
                              index
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                          className="form-control  w-full"
                        />
                      </div>
                      <div className="w-[23%] text-center flex">
                        <input
                          type="text"
                          className="form-control"
                          name="notes"
                          value={row.notes}
                          onChange={(e) =>
                            handleEmployeeAssetsInputChange(e, index)
                          }
                        />
                      </div>

                      <div className="w-[8%] flex items-center text-center justify-center ">
                        <div className=" border p-2.5 rounded-3xl ">
                          <button
                            type="button"
                            className="text-green cursor-pointer"
                            onClick={() => handleUpdateEmployeeAssetsRow(row)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-start w-[15%] pt-4 pl-4 ">
                    <button
                      className="btn-primary w-full"
                      type="button"
                      onClick={addEmployeeAssetsRow}
                    >
                      Add Row
                    </button>
                  </div>
                  <div className="w-full p-4 flex justify-between">
                    <div className="w-[14%]">
                      <button
                        className="w-full btn-primary"
                        onClick={() => setActiveComponent("FamilyInfo")}
                        type="button"
                      >
                        Move to Back Tab
                      </button>
                    </div>
                    <div className="w-[14%]">
                      <button
                        className="w-full btn-primary"
                        onClick={() => setActiveComponent("PayrollInfo")}
                        type="button"
                      >
                        Move to Next Tab
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeComponent === "TravelInfo" && (
              <>
                <div className="w-full p-5">
                  <div className="p-4">Information Ticket Entitled?</div>
                  <div className="w-1/3 p-4">
                    <Select
                      value={{
                        value: informationTicketValue,
                        label: informationTicket,
                      }}
                      onChange={(obj) => {
                        setInformationTicket(obj.label);
                        setInformationTicketValue(obj.value);
                      }}
                      options={TicketOption}
                      menuPosition="fixed"
                      styles={customStyles}
                    />
                  </div>
                  {informationTicketValue === "true" && (
                    <>
                      <div className="flex w-full  pl-4 pr-4 pb-4">
                        <div className="w-[30%] mr-4">
                          <div className="flex w-full pt-4 pb-4">
                            <div>Origin Airport</div>
                            <div className="text-red">*</div>
                          </div>
                          <div>
                            <input
                              className="form-control"
                              required
                              type="text"
                              value={originAirport}
                              onChange={(e) => setOriginAirport(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="w-[30%]">
                          <div className="flex w-full pt-4 pb-4">
                            <div>Destination Airport</div>
                            <div className="text-red">*</div>
                          </div>
                          <div>
                            <input
                              required
                              className="form-control"
                              type="text"
                              value={destinationAirport}
                              onChange={(e) =>
                                setDestinationAirport(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-b "></div>
                      <div className="p-4 text-16 bold">
                        No of ticket for family
                      </div>
                      <div className="w-full flex justify-between pt-4 pl-4 pr-4 ">
                        <div className="w-[19%] text-start">Entitled Every</div>
                        <div className="w-[19%] text-start flex flex-wrap">
                          Air Class
                        </div>
                        <div className="w-[19%] text-start">
                          One Way/Round Trip
                        </div>
                        <div className="w-[19%] text-start">Adult</div>
                        <div className="w-[19%] text-start">Children</div>
                      </div>
                      <div className="w-full flex justify-between pl-4  pr-4 pt-4">
                        <Select
                          value={{
                            value: entitledEveryValue,
                            label: entitledEveryLable,
                          }}
                          className="w-[19%]"
                          onChange={(obj) => {
                            setEntitledEveryLable(obj.label);
                            setEntitledEveryValue(obj.value);
                          }}
                          options={[
                            { value: "0", label: "Yearly" },
                            { value: "1", label: "Monthly" },
                            { value: "2", label: "Quaterly" },
                            { value: "3", label: "Every Two Years" },
                          ]}
                          menuPosition="fixed"
                          styles={customStyles}
                        />
                        <Select
                          value={{ value: airClassValue, label: airClassLable }}
                          className="w-[19%]"
                          onChange={(obj) => {
                            setAirClassLable(obj.label);
                            setAirClassValue(obj.value);
                          }}
                          options={[
                            { value: "0", label: "First Class" },
                            { value: "1", label: "Business Class" },
                            { value: "2", label: "Economy Class" },
                          ]}
                          menuPosition="fixed"
                          styles={customStyles}
                        />
                        <Select
                          value={{ value: onTripValue, label: onTripLable }}
                          onChange={(obj) => {
                            setOnTripLable(obj.label);
                            setOnTripValue(obj.value);
                          }}
                          className="w-[19%]"
                          options={[
                            { value: "0", label: "One Way" },
                            { value: "1", label: "Round Trip" },
                          ]}
                          menuPosition="fixed"
                          styles={customStyles}
                        />
                        <div className="w-[19%]">
                          <input
                            type="number"
                            min={0}
                            className="form-control"
                            value={adult}
                            onChange={(e) => setAdult(e.target.value)}
                          />
                        </div>
                        <div className="w-[19%]">
                          <input
                            type="number"
                            min={0}
                            className="form-control"
                            value={children}
                            onChange={(e) => setChildren(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-full flex justify-between">
                  <div className="w-[16%] p-4">
                    <button
                      className="w-full btn-primary"
                      onClick={() => setActiveComponent("FamilyInfo")}
                      type="button"
                    >
                      Move to Back Tab
                    </button>
                  </div>
                  <div className="w-[14%] p-4">
                    <button
                      className="w-full btn-primary"
                      onClick={handleUpdateTravelInfo}
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                  {/* <div className="w-[20%] p-4">
                    <button
                      className={`w-full ${
                        loading
                          ? "btn-primary cursor-not-allowed "
                          : " btn-primary"
                      }`}
                      type="submit"
                      disabled={loading}
                    >
                      Create Employee
                    </button>
                  </div> */}
                </div>
              </>
            )}
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default EmployeeDataMagement;

import React from "react";
import Button from "Components/Common/Button";

function DiscountItemList({ items, RemoveItem, type }) {
  return (
    <div className="overflow-y-scroll max-h-400 mt-11 w-full">
      <div className="grid grid-cols-2 mx-2 mb-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="w-full flex flex-row justify-between items-center p-1 border border-gray-300 bg-white"
          >
            <span className="flex w-[80%] items-center text-xs">
              {type === "category" ? item.category_name : item.product_name}
            </span>
            <Button
              onClick={() => RemoveItem(item)}
              className="bg-red w-[10%] h-[10px] py-3 flex items-center justify-center rounded-full"
              type="button"
            >
              x
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DiscountItemList;
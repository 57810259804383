import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import TableDetails from "Components/Common/TableDetails";
import BranchesApi from "api/hrmapi/branches";
function BranchesDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    BranchesApi.viewBranchesApi(params)
      .then((response) => {
        setDataToShow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Branch Details #{params.id}</div>
        </div>
        <TableDetails data={dataToShow} loading={loading} />
      </PagesWrapper>
    </AppWrapper>
  );
}

export default BranchesDetails;

import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PositionApi from "api/hrmapi/positionapi";
import DepartmentApi from "api/hrmapi/department";

function Position() {
  const navigate = useNavigate();
  let params = useParams();
  const [UpdatePosition, setUpdatePosition] = useState({
    id: "",
    name: "",
    department: "",
    description: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAllDepartment = () => {
    setTimeout(() => {
      navigate(`/all-position`);
    }, 2000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdatePosition({
      ...UpdatePosition,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(true);
  const [departments, setAllDepartment] = useState([]);
  useEffect(() => {
    DepartmentApi.allDepartments()
      .then((res) => {
        setAllDepartment(res.data);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
    setLoading(true);
    PositionApi.viewPositionApi(params)
      .then((response) => {
        setUpdatePosition({
          id: response.data.id,
          name: response.data.name,
          department: response.data.department_id,
          description: response.data.description,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handlePositionUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    PositionApi.updatePositionApi(params.id, {
      position: {
        name: UpdatePosition.name,
        department_id: UpdatePosition.department,
        description: UpdatePosition.description,
        company_id: UpdatePosition.company_id,
      },
    })
      .then((response) => {
        handleNavgetAllDepartment();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Position</div>
        </div>

        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handlePositionUpdate}>
            <div className="w-full flex justify-around flex-wrap pl-4 pr-4">
              <div className="w-[25%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={UpdatePosition.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[25%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Department</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    value={{
                      value: UpdatePosition.department,
                      label: departments.find(
                        (dep) => dep.id === UpdatePosition.department
                      )?.name,
                    }}
                    onChange={(selectedOption) =>
                      inputOnChange({
                        target: {
                          name: "department",
                          value: selectedOption.value,
                        },
                      })
                    }
                    styles={customStyles}
                    options={departments.map((department) => ({
                      value: department.id,
                      label: department.name,
                    }))}
                    placeholder="Please select"
                  />
                </div>
              </div>
              <div className="w-[40%]">
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-4 h-auto p-2"
                    value={UpdatePosition.description}
                    required
                    rows={2}
                    name="description"
                    onChange={inputOnChange}
                  />
                </div>
              </div>
              <div className="flex w-full justify-end">
                <button
                  className={
                    loading
                      ? "w-[14%] btn-primary cursor-not-allowed"
                      : "w-[14%]  btn-primary"
                  }
                  type="submit"
                  disabled={loading}
                >
                  Update Position
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Position;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import PurchasesAPI from "api/posapi/purchases";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";

function PurchaseDetail() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    PurchasesAPI.viewPurchasesAPI(params)
      .then((response) => {
        const modifiedPurchaseItems = (response?.data?.purchase_items).map(item => ({
          product_name: item.product_name,
          category: item.category,
          unit_cost: item.unit_cost,
          unit_price: item.unit_price,
          quantity: item.quantity,
          total: item.price, 
        }));
        setData(modifiedPurchaseItems);
        const responseData = response?.data?.purchase;
        const modifiedData = {
          created_by: responseData.purchase_created_by,
          Vendor: responseData.vendor, 
          grand_total: responseData.total_price,
          purchase_date: responseData.purchase_date,
        };
        setDataToShow([modifiedData]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-purchase");
  };

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Purchase Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="mt-[3.5rem]">
            <div className="mb-[1.5rem] text-xl">Purchase </div>
            <Table data={dataToShow} loading={loading} />
            {data.length > 0 && (
              <>
                <div className="mt-[3.5rem] mb-[1.5rem] text-xl">
                  Purchase Items
                </div>
                <Table data={data} loading={loading} />
              </>
            )}
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default PurchaseDetail;

import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";
import DocumentApi from "api/hrmapi/document";

function Document() {
  const navigate = useNavigate();
  let params = useParams();
  const [UpdateDocument, setUpdateDocument] = useState({
    id: "",
    name: "",
    required: "",
    issueDate: "",
    expiryDate: "",
    documentNo: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-documents`);
    }, 2000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateDocument({
      ...UpdateDocument,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    DocumentApi.viewDocumentApi(params)
      .then((response) => {
        setUpdateDocument({
          id: response.data.id,
          name: response.data.name,
          required: response.data.required,
          issueDate: response.data.has_issue_date,
          expiryDate: response.data.has_expiry_date,
          documentNo: response.data.has_document_no,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handleDocumentUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    DocumentApi.updateDocumentApi(params.id, {
      document: {
        name: UpdateDocument.name,
        has_issue_date: UpdateDocument.issueDate,
        has_expiry_date: UpdateDocument.expiryDate,
        has_document_no: UpdateDocument.documentNo,
        required: UpdateDocument.required,
        company_id: UpdateDocument.company_id,
      },
    })
      .then((response) => {
        handleNavgetAll();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };

  const handleRequiredChange = (value) => {
    setUpdateDocument({
      ...UpdateDocument,
      required: value,
    });
  };

  const handleDocumentNoChange = (value) => {
    setUpdateDocument({
      ...UpdateDocument,
      documentNo: value,
    });
  };
  const handleExpiryDateChange = (value) => {
    setUpdateDocument({
      ...UpdateDocument,
      expiryDate: value,
    });
  };
  const handleIssueDateChange = (value) => {
    setUpdateDocument({
      ...UpdateDocument,
      issueDate: value,
    });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Document</div>
        </div>

        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleDocumentUpdate}>
            <div className="w-full flex flex-wrap gap-5 p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={UpdateDocument.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Issue Date</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateDocument.issueDate,
                    label: UpdateDocument.issueDate ? "YES" : "NO",
                  }}
                  required
                  onChange={(obj) => handleIssueDateChange(obj.value)}
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Expiry Date</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateDocument.expiryDate,
                    label: UpdateDocument.expiryDate ? "YES" : "NO",
                  }}
                  required
                  onChange={(obj) => handleExpiryDateChange(obj.value)}
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Document No</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateDocument.documentNo,
                    label: UpdateDocument.documentNo ? "YES" : "NO",
                  }}
                  required
                  onChange={(obj) => handleDocumentNoChange(obj.value)}
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Required</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateDocument.required,
                    label: UpdateDocument.required ? "YES" : "NO",
                  }}
                  required
                  onChange={(obj) => handleRequiredChange(obj.value)}
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="flex w-full bottom-0 pr-4 justify-end">
                <button
                  className={
                    loading
                      ? "w-[14%] btn-primary cursor-not-allowed"
                      : "w-[14%] btn-primary"
                  }
                  type="submit"
                  disabled={loading}
                >
                  Update Document
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Document;

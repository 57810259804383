import Button from "Components/Common/Button";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Pagination from "Components/Common/Pagination";
import ShowComponent from "Components/ShowComponent";
import ProductsAPI from "api/posapi/product";
import React, { useState, useEffect } from "react";

function TrackProductModal() {
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const [pageNumber, setPageNumber] = useState(1);

  const trackProduct = () => {
    setLoading(true);
    ProductsAPI.trackProduct(searchQuery, pageNumber)
      .then((res) => {
        setLoading(false);
        setProductDetails(res.data.product_inventories);
        setPaginationValue(res.data.page_meta);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    if (searchQuery) {
      trackProduct();
    }
  }, [pageNumber]);

  return (
    <PagesWrapper>
      <div>
        <h1 className="font-bold">Track Product Quantity</h1>
      </div>

      <div className="container overflow-auto mx-auto">
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search by name, barcode, or SKU"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border-gray-300 form-control mb-4 w-1/2"
          />
          <Button className="ml-3" onClick={trackProduct}>
            Track
          </Button>
        </div>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Location Id</th>
              <th className="py-2 px-4 border-b">Location Name</th>
              <th className="py-2 px-4 border-b">QTY</th>
              <th className="py-2 px-4 border-b">Product Name</th>
            </tr>
          </thead>
          <tbody>
            {!productDetails.length ? (
              <tr>
                <td colSpan="4" className="py-2 px-4 text-center">
                  Search product to track quantity
                </td>
              </tr>
            ) : loading ? (
              <td className="flex justify-center items-center w-full">
                <div className="h-[20px] w-[20px] rounded-full border-b-2 border-b-[#287dfd] animate-spin my-1"></div>
              </td>
            ) : (
              productDetails.map((product, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{product.location.id}</td>
                  <td className="py-2 px-4 border-b">
                    {product.location.address}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {product.inventory.quantity}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {product.inventory.product_name}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <ShowComponent condition={productDetails.length}>
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={paginationValue.total_pages}
          totalCount={paginationValue.totalCount}
          startValue={paginationValue.startValue}
          endValue={paginationValue.endValue}
        />
      </ShowComponent>
    </PagesWrapper>
  );
}

export default TrackProductModal;

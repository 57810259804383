import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popUp: "",
  tableData: [],
  filteredProducts: [],
  selectedCategory: [],
  selectedCategoryOption: [],
  saleCommissionData: {
    Percentage: "",
    comissionType: "",
    inputValue:"",
    valueType: "",
  },
};

const saleCommission = createSlice({
  name: "saleCommission",
  initialState,
  reducers: {
    addItemToTable: (state, action) => {
      state.tableData = [...action.payload];
    },
    updateItemValueType: (state, action) => {
      const { product_id, valueType } = action.payload;
      state.tableData = state.tableData.map((product) =>
        product.product_id === product_id
          ? { ...product, commission: { ...product.commission, valueType } }
          : product
      );
    },
    updateItemPercentage: (state, action) => {
      const { product_id, Percentage } = action.payload;
      state.tableData = state.tableData.map((product) =>
        product.product_id === product_id
          ? { ...product, commission: { ...product.commission, Percentage } }
          : product
      );
    },

    filterItem: (state, action) => {
      state.filteredProducts = [...action.payload];
    },
    removeItemFromTable: (state, action) => {
      state.tableData = state.tableData.filter(
        (product) => product.product_id !== action.payload.product_id
      );
    },

    clearTable: (state) => {
      state.tableData = [];
      state.filteredProducts = [];
      state.selectedCategory = [];
      state.selectedCategoryOption = [];
      state.saleCommissionData = {
        Percentage: "",
        inputValue:"",
        comissionType: "",
        valueType: "",
      };
    },
    setSaleCommissionData: (state, action) => {
      state.saleCommissionData = action.payload;
    },

    selectedCategoryOptionName: (state, action) => {
      state.selectedCategoryOption = action.payload;
    },
    popUpId: (state, action) => {
      state.popUp = action.payload;
    },
  },
});

export const {
  addItemToTable,
  clearTable,
  popUpId,
  updateItemPercentage,
  updateItemValueType,
  filterItem,
  selectedCategoryOptionName,
  setSaleCommissionData,
  removeItemFromTable,
} = saleCommission.actions;

export default saleCommission.reducer;

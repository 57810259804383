import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import BranchesApi from "api/hrmapi/branches";
import CustomerApi from "api/customerapi/customer";

function Branches() {
  const navigate = useNavigate();
  let params = useParams();
  const [countries, setCountries] = useState([]);
  const [UpdateBranch, setUpdateBranch] = useState({
    id: "",
    name: "",
    location: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-branches`);
    }, 2000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateBranch({
      ...UpdateBranch,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    CustomerApi.AllCountries()
      .then((res) => {
        const options = res.data.map((country) => ({
          label: country.country_name,
          value: country.country_name,
        }));
        setCountries(options);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(true);
    BranchesApi.viewBranchesApi(params)
      .then((response) => {
        setUpdateBranch({
          id: response.data.id,
          name: response.data.name,
          location: response.data.location,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handleBranchUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    BranchesApi.updateBranchesApi(params.id, {
      branch: {
        name: UpdateBranch.name,
        location: UpdateBranch.location,
        company_id: UpdateBranch.company_id,
      },
    })
      .then((response) => {
        handleNavgetAll();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Branch</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleBranchUpdate}>
            <div className="w-full flex gap-5 flex-wrap pl-4 pr-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={UpdateBranch.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Location</div>
                  <div className="text-red">*</div>
                </div>

                <Select
                  className="custom-select"
                  value={{
                    label: UpdateBranch.location,
                    value: UpdateBranch.location,
                  }}
                  options={countries}
                  onChange={(obj) =>
                    inputOnChange({
                      target: { name: "location", value: obj.value },
                    })
                  }
                  styles={customStyles}
                />
              </div>
              <div className="flex w-full  pr-4 justify-end">
                <button
                  className={
                    loading
                      ? "w-[14%] btn-primary cursor-not-allowed"
                      : "w-[14%] btn-primary"
                  }
                  type="submit"
                  disabled={loading}
                >
                  Update Branch
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Branches;

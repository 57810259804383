import axiosClient from "..";

class AuthAPI {
  static signIn(data) {
    return axiosClient.post("/users/sign_in.json", data);
  }
  static signOut() {
    return axiosClient.delete("/users/sign_out.json");
  }
  static resetPassword(data) {
    return axiosClient.post("/api/auth/passwords/reset.json", data);
  }
  static forgetPasswordEmail(data) {
    return axiosClient.post("/api/auth/passwords/forgot.json", data);
  }
  static verfiyAccess(accessCode) {
    return axiosClient.post(`/api/admin/verify-access/${accessCode}.json`);
  }
}
export default AuthAPI;

import PosDashboard from "api/dashboardApi/posDashboard";
import React, { useState, useEffect } from "react";

const useOverview = (identifier, params = {}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("h");

  useEffect(() => {
    setLoading(true);
    PosDashboard[identifier](
      params.currentDate,
      params.startDate,
      params.endDate
    )
      .then((res) => {
        setData({
          identifier: res.data.identifier || identifier,
          count: res.data.identifier ? res.data.amount : res.data,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return { data, loading, message };
};

export default useOverview;

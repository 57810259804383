import React from "react";

const Button = ({
  children,
  onClick,
  loading,
  icon,
  className,
  style,
  name,
  disabled = false,
}) => {
  return (
    <button
      style={style}
      className={`${
        disabled ? "btn-disabled" : "btn-primary"
      } ${className} ${loading ? "disabled btn-progress" : ""}`}
      onClick={onClick}
      name={name}
      disabled={disabled}
    >
      {children}
      {icon}
    </button>
  );
};

export default Button;

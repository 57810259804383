import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Button from "Components/Common/Button";
import invoiceAPi from "api/invoiceapi/invoiceAPi";
import { toast } from "react-toastify";
import { SaleIdEmail, customerData, ReturnSaleId } from "store/Sale";

function InvoiceShareEmail({ setPopupId, contentOnly = false }) {
  const email = useSelector((state) => state.sales?.customer?.customerEmail);
  const saleId = useSelector((state) => state?.sales?.saleIdEmail);
  const Id = useSelector((state) => state?.sales?.returnSaleId);
  const [userEmail, setUserEmail] = useState(email);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const saleID = saleId || Id;
    invoiceAPi
      .invoiceShareAPI({
        search_params: {
          email: userEmail,
          sale_id: saleID
        },
      })
      .then((response) => {
        if (response) {
          setLoading(false);
          dispatch(SaleIdEmail(null));
          dispatch(ReturnSaleId(null));
          dispatch(
            customerData({
              customerId: "",
              customerName: "",
              customerPhone: "",
              customerAddress: "",
            })
          );
          setPopupId("");
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to send invoice");
        setLoading(false);
      });
  };

  return (
    <AppWrapper contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-24 w-full text-center">
          Invoice Share via Email
        </div>
        <form onSubmit={handleSendEmail}>
          <div className="w-full px-4">
            <div className="mt-3 font-semibold">Email</div>
            <input
              className="form-control mt-2"
              type="email"
              name="userEmail"
              placeholder="Enter Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>
          <div className="text-center mb-10">
            <Button type="submit" disabled={loading}>
              {loading ? 'Sending...' : 'Send Email'}
            </Button>
          </div>
        </form>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default InvoiceShareEmail;

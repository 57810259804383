import React from "react";
import OverviewTabRenderer from "./OverviewTabRenderer";

const OverviewTab = ({ tab, date, identifiers }) => {
  return (
    <div className="p-6 bg-white rounded-2xl mb-6 md:col-span-6 xl:col-span-3 col-span-12">
      <p className="text-16 font-semibold">{tab}</p>
      <div className="flex flex-wrap gap-x-1 gap-y-4 mt-4">
        {identifiers.map((identifier, index) => (
          <OverviewTabRenderer
            key={index}
            date={date}
            identifier={identifier}
          />
        ))}
      </div>
    </div>
  );
};

export default OverviewTab;

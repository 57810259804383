import React from "react";

const ProductTable = ({ tableData, columns, className }) => {
  return (
    <table className={className}>
      <thead className="border-b-2 border-black">
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              className={`${
                column.key === "product_name" || column.key === "product"
                  ? "text-start justify-start"
                  : "text-center"
              } p-1 font-bold`}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row) => (
          <tr key={row.id}>
            {columns.map((column) => (
              <td
                className={` p-1 ${
                  column.key === "product_name" || column.key === "product"
                    ? "text-start justify-start font-bold"
                    : "text-center"
                }`}
                key={column.key}
              >
                {row[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductTable;

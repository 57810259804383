import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import React from "react";
function Recruitment() {
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Recruitment and Onboarding</div>
        </div>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="w-[49%] bg-white rounded-2xl">
            <div>
              <div>Job Postings</div>
              <div>02 Job Postings</div>
            </div>
            <div>Create New Job</div>
            <div>All Job Postings</div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Recruitment;

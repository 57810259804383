import React, { useState, useEffect } from "react";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import { useParams, useNavigate } from "react-router-dom";
import LocationsAPI from "api/location/location";
import UserApi from "api/adminapi/user";
import Button from "Components/Common/Button";

function LocPermissionDetail() {
  const [locDetailData, setLocDetailData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    setLoading(true);
    LocationsAPI.userLocationDetail(id)
      .then((response) => {
        const addresses = response?.data?.locations?.map(
          (location) => location.address
        );
        setLocDetailData(addresses);
      })
      .catch((error) => {
        console.log(error);
      });

    UserApi.viewUser(params)
      .then((response) => {
        setUserData(response?.data?.user);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/location-permission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Location Permission Details
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-1/2 mr-3 bg-white rounded-3xl min-h-[70vh]">
            <div className="p-4 m-4">
              <table className="w-full">
                <tbody className="flex flex-col">
                  {userData && (
                    <tr className="flex justify-between py-2">
                      <th>Username/Email :</th>
                      <td>
                        {userData.username ? userData.username : userData.email}
                      </td>
                    </tr>
                  )}
                  {userData?.location_address && (
                    <tr className="flex justify-between py-2">
                      <th>Default Location:</th>
                      <td>{userData?.location_address}</td>
                    </tr>
                  )}
                  {userData?.company_name && (
                    <tr className="flex justify-between py-2">
                      <th>Company Name:</th>
                      <td>{userData?.company_name}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-1/2 ml-3 bg-white rounded-3xl h-[70vh] overflow-y-auto overflow-x-hidden">
            <div className="flex p-4 m-4">
              <div className="text-[20px] font-semibold">
                Permited Locations
              </div>
            </div>
            <div className="w-full m-3 p-3">
              {locDetailData.map((location, index) => (
                <tr key={index}>
                  <div className="flex items-center pb-4 px-4">
                    <div className="w-2 h-2 bg-black rounded-full mr-2"></div>
                    {location}
                  </div>
                </tr>
              ))}
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default LocPermissionDetail;

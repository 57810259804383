import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { useNavigate } from "react-router-dom";
function EmployeMagement() {
  const navigate = useNavigate();
  const handleNavgetAllAssetType = () => {
    navigate(`/all-asset-type`);
  };
  const handleNavgetAssetType = () => {
    navigate(`/create-asset-type`);
  };
  const handleNavgetAllCompanyAssets = () => {
    navigate(`/all-company-assets`);
  };
  const handleNavgetCompanyAssets = () => {
    navigate(`/create-company-assets`);
  };
  const handleNavgetAllEmployeeData = () => {
    navigate(`/all-employee-data`);
  };
  const handleNavgetAllBranch = () => {
    navigate(`/all-branches`);
  };
  const handleNavgetBranch = () => {
    navigate(`/create-branch`);
  };
  const handleNavgetAllDocument = () => {
    navigate(`/all-documents`);
  };
  const handleNavgetDocument = () => {
    navigate(`/create-document`);
  };
  const handleNavgetAllContracts = () => {
    navigate(`/all-contracts`);
  };
  const handleNavgetPosition = () => {
    navigate(`/create-position`);
  };
  const handleNavgetAllPosition = () => {
    navigate(`/all-position`);
  };
  const handleNavgetDeparment = () => {
    navigate(`/create-department`);
  };
  const handleNavgetAllDeparment = () => {
    navigate(`/all-departments`);
  };
  const handleNavgetContracts = () => {
    navigate(`/create-contract`);
  };
  const handleNavgetEmployeeDataManagement = () => {
    navigate(`/create-employee-data-management`);
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24 mb-5 font-semibold">
            Employee Date Management
          </div>
        </div>
        <div className="w-full flex flex-wrap gap-5">
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Employee</div>
                <div className="text-blue font-semibold">Total Employee</div>
              </div>
              <div className="bg-blue rounded-xl bg-opacity-20 p-2">
                <img
                  src="./icons/employee.png"
                  alt="employee"
                  className="text-blue"
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetEmployeeDataManagement}
              >
                Create New Employee
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllEmployeeData}
              >
                All Employees
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Position</div>
                <div className="text-blue font-semibold">Positions</div>
              </div>
              <div className="bg-body rounded-xl  p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-lg leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetPosition}
              >
                Create Position
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-lg leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllPosition}
              >
                All Positions
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Department</div>
                <div className="text-blue font-semibold">Departments</div>
              </div>
              <div className="bg-green rounded-xl bg-opacity-20 p-2">
                <img src="./icons/employee.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetDeparment}
              >
                Create Department
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllDeparment}
              >
                All Departments
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Branches</div>
                <div className="text-blue font-semibold">Branches</div>
              </div>
              <div className="bg-orange rounded-xl bg-opacity-20 p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetBranch}
              >
                Create Branch
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllBranch}
              >
                All Branches
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Document</div>
                <div className="text-blue font-semibold">Documents</div>
              </div>
              <div className="bg-grey-mode rounded-xl p-2">
                <img src="./icons/employee.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetDocument}
              >
                Create Document
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllDocument}
              >
                All Documents
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Company Assets</div>
                <div className="text-blue font-semibold">Company Assets</div>
              </div>
              <div className=" bg-red rounded-xl bg-opacity-20 p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetCompanyAssets}
              >
                Create Company Assets
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllCompanyAssets}
              >
                All Company Assets
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Assets</div>
                <div className="text-blue font-semibold">Assets</div>
              </div>
              <div className="bg-primary-blue rounded-xl bg-opacity-30 p-2">
                <img src="./icons/employee.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAssetType}
              >
                Create Assets
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllAssetType}
              >
                All Assets
              </div>
            </div>
          </div>
          <div className="w-[49%] bg-white rounded-3xl p-5">
            <div className="w-full flex justify-between mb-10">
              <div>
                <div className="text-24 leading-4 mb-3">Contracts</div>
                <div className="text-blue font-semibold">Contracts</div>
              </div>
              <div className="bg-body rounded-xl p-2">
                <img src="./icons/position.png" alt="employee" />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetContracts}
              >
                Create Contract
              </div>
              <div
                className="w-228 h-58 top-339 left-347 rounded-7 justify-center flex items-center font-montserrat font-semibold text-base leading-none tracking-tighter text-gray-700 border border-primary-blue bg-primary-blue bg-opacity-7"
                onClick={handleNavgetAllContracts}
              >
                All Contracts
              </div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default EmployeMagement;

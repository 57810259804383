import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import CashierApi from "api/cashier/cashier";
import { useSelector } from "react-redux";
import FormatDate from "Components/Common/FormatDate";
import GeneralInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import OrderApi from "api/posapi/order";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";

function ReturnSale() {
  const [dataToShow, setDataToShow] = useState([]);
  const [filterInvoices, setFilterInvoices] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [buttonCheck,setButtonCheck] = useState("completed")
  const defaultLoc = useSelector((state) => state.auth.currentUser.default_loc);
  
  const fetchInvoice = () => {
  CashierApi.allInvoices(defaultLoc)
      .then((response) => {
        const invoiceData = response.data.map((invoice) => ({
          invoice_id: invoice.id,
          invoice_no: invoice.invoice_no,
          created_at: invoice.created_at,
          discount: invoice.discount,
          total_price: invoice.sub_total,
          phone_no: invoice.phone_no,
          tax: invoice.tax,
          is_tax_free: invoice.is_tax_free,
          refund_invoice:invoice.refund_invoice,
          status: invoice.status,
          value:invoice.value,
          net_ammount:invoice.total_price
        }));
        setDataToShow(invoiceData);
        setFilterInvoices(invoiceData)
        
      })
      .catch((error) => {
        console.log("error", error);
      });
    
}

  const InvoiceCount = new Set(dataToShow.map((invoice) => invoice.invoice_no)).size;
  const handleSearchInvoice = e => {
    let localInvoices = dataToShow
    if (!invoiceNumber) {
      setFilterInvoices(dataToShow)
    } else {
      setFilterInvoices(localInvoices.filter(({ invoice_no }) => invoice_no === invoiceNumber.trim()))
    }
    console.log(dataToShow)
  }
  const handleSetInvoice = e => {
    const { value } = e.target
    setInvoiceNumber(value)
    if (!value) {
      setFilterInvoices(dataToShow)
    }
  }
  const handlebuttonCheck =(e) => {
    const { value } = e.target;
    console.log("New buttonCheck value:", value);
    setButtonCheck(value); 
  };
  const RefundInvoice = (invoice, id) => {
    OrderApi.refund(invoice)
      .then((res) => {
        const newData = dataToShow.filter((item) => item.id !== id);
        setDataToShow(newData);
        setFilterInvoices(newData);
        toast.success("Invoice Refunded");
        fetchInvoice()
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchInvoice()
  }, [buttonCheck]);
  return (
    <PagesWrapper>
        <div className="flex justify-between items-center">
          <GeneralInput placeholder="Invoice No" onChange={handleSetInvoice} value={invoiceNumber} />
          <Button onClick={handleSearchInvoice}>Search</Button>
        </div>
      <div className="container max-w-full overflow-auto mx-auto mt-8">
        <table className="min-w-full bg-white border border-gray-300 mb-5">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4">Invoice No</th>
              <th className="py-2 px-4">Invoice Date</th>
              <th className="py-2 px-4">Total Amount</th>
              <th className="py-2 px-4">Discount Amount</th>
              <th className="py-2 px-4">VAT Amount</th>
              <th className="py-2 px-4">Net Amount</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Customer Mobile#</th>
              <th className="py-2 px-4">VAT Free</th>
              </tr>
          </thead>
          <tbody>
            {
              filterInvoices.length ?
                filterInvoices.map((invoice) => (
                  <tr key={invoice.invoice_no} className="text-center border-b">
                    <td className="py-2 px-4">{invoice.invoice_no}</td>
                    <td className="py-2 px-4">
                      {FormatDate(invoice.created_at)}
                    </td>
                    <td className="py-2 px-4">{invoice.total_price}</td>
                    <td className="py-2 px-4">{invoice.discount}</td>
                    <td className="py-2 px-4">{invoice.tax}</td>
                    <td className="py-2 px-4">{invoice.net_ammount}</td>
                    <td className="py-2 px-4">{invoice.status}</td>
                    <td className="py-2 px-4">{invoice.phone_no}</td>
                    <td className="py-2 px-4">
                      {invoice.is_tax_free?.toString()}
                    </td>
                      <td className="py-2 px-4">
                        <button className="text-red" onClick={() => RefundInvoice(invoice.invoice_no,invoice.id)}>Refund</button>
                      </td>
                </tr>
                )) : <tr>
                  <td colSpan={5} className="py-2 px-4 border-b">
                    No Data Available
                  </td>
                </tr>
            }
          </tbody>
        </table>
        <div>
          <h2 className="font-bold mb-3">Total Invoice : {InvoiceCount}</h2>
        </div>
      </div>
    </PagesWrapper>
  );
}

export default ReturnSale;

import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ContractApi from "api/hrmapi/contract";
import { useNavigate } from "react-router-dom";
function CreateContract({
  contentOnly = false,
  setPopupId,
  setFetchContaract,
  onContractCreate,
}) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [trackAttendanceLabel, setTrackAttendanceLabel] = useState("");
  const [trackAttendanceValue, setTrackAttendanceValue] = useState(null);
  const [temporaryContractLabel, setTemporaryContractLabel] = useState("");
  const [temporaryContractValue, setTemporaryContractValue] = useState(null);
  const navigate = useNavigate();
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-contracts`);
    }, 2000);
  };
  const handleCreateContract = (e) => {
    e.preventDefault();
    if (!trackAttendanceLabel) {
      toast.error("Please Select Track Attendance");
      return;
    }
    if (!temporaryContractLabel) {
      toast.error("Please Select Temporary Contract");
      return;
    }

    setLoading(true);
    ContractApi.createContract({
      contract: {
        name: name,
        salary_payment_based_on: parseInt(selectedOption.value),
        track_attendance: trackAttendanceValue,
        temporary_contract: temporaryContractValue,
        company_id: company_id,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        if (!contentOnly) {
          handleNavgetAll();
        }
        if (contentOnly) {
          setFetchContaract(true);
          setPopupId("");
          onContractCreate(response.data.data.id)
        }
        setTrackAttendanceLabel("");
        setTrackAttendanceValue(null);
        setTemporaryContractLabel("");
        setTemporaryContractValue(null);
        setName("");
        setSelectedOption(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  const trackAttendanceOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const temporaryContractOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Contract</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreateContract}>
            <div className="w-full flex flex-wrap gap-5 p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div> Salary Payment Based On</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={selectedOption}
                  required
                  onChange={handleChange}
                  options={[
                    { value: "0", label: "Based on Attendance" },
                    { value: "1", label: "Fixed Salary + Allowance" },
                    { value: "2", label: "Based on Contract" },
                  ]}
                  styles={customStyles}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Track Attendance</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: trackAttendanceValue,
                    label: trackAttendanceLabel,
                  }}
                  required
                  onChange={(option) => {
                    setTrackAttendanceLabel(option.label);
                    setTrackAttendanceValue(option.value);
                  }}
                  options={trackAttendanceOptions}
                  styles={customStyles}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Temporary Contract</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: temporaryContractValue,
                    label: temporaryContractLabel,
                  }}
                  required
                  onChange={(option) => {
                    setTemporaryContractLabel(option.label);
                    setTemporaryContractValue(option.value);
                  }}
                  options={temporaryContractOptions}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create Contract
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateContract;

import React, { useState, useEffect } from "react";
import WorkWeekApi from "api/hrmapi/workweek";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function UpdateWorkWeek({ upDateId, setPopupId, setFetchWorkWeek }) {
  const [loading, setLoading] = useState(true);
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [workWeekData, setWorkWeekData] = useState({
    name: "",
    workDays: [
      {id:"", day: "sunday", selected: false, fullDay: true },
      {id:"", day: "monday", selected: false, fullDay: true },
      {id:"", day: "tuesday", selected: false, fullDay: true },
      {id:"", day: "wednesday", selected: false, fullDay: true },
      {id:"", day: "thursday", selected: false, fullDay: true },
      {id:"", day: "friday", selected: false, fullDay: true },
      {id:"", day: "saturday", selected: false, fullDay: true },
    ],
  });

  useEffect(() => {
    WorkWeekApi.viewWorkWeekApi(upDateId)
      .then((res) => {
        const { name, work_days } = res.data.work_week;
        const updatedWorkDays = workWeekData.workDays.map((day) => {
          const foundDay = work_days.find((d) => d.day === day.day);
          if (foundDay) {
            return {
              ...day,
              id: foundDay.id,
              selected: foundDay.day_type === "Full Day" || foundDay.day_type === "Half Day",
              fullDay: foundDay.day_type === "Full Day" || foundDay.day_type === "Half Day",
            };
          }
          return day;
        });
        setWorkWeekData({ name, workDays: updatedWorkDays });
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, [upDateId]);

  const handleDayToggle = (index) => {
    const updatedDays = [...workWeekData.workDays];
    updatedDays[index].selected = !updatedDays[index].selected;
    setWorkWeekData({ ...workWeekData, workDays: updatedDays });
  };

  const handleToggleChange = (index) => {
    const updatedDays = [...workWeekData.workDays];
    updatedDays[index].fullDay = !updatedDays[index].fullDay;
    setWorkWeekData({ ...workWeekData, workDays: updatedDays });
  };

  const handleCancel = () => {
    setPopupId("");
  };

  const handleUpdateWorkWeek = () => {
    const formattedWorkDays = workWeekData.workDays.map((day) => ({
        id: day.id,
      day: day.day,
      day_type: day.selected ? (day.fullDay ? "Full Day" : "Half Day") : null,
    }));

    const data = {
      name: workWeekData.name,
      company_id: company_id,
      work_days_attributes: formattedWorkDays,
    };
    WorkWeekApi.updateWorkWeekApi(upDateId, data)
      .then((response) => {
        toast.success("Work week updated successfully");
        setTimeout(() => {
            setFetchWorkWeek(true);
            setPopupId("");
       }, 2000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full">
      <div className="text-22 p-2">Update Workweek</div>
      <div className="p-2">Workweek Name</div>
      <div>
        <input
          type="text"
          className="border border-gray-300 px-3 py-2 w-full mb-4"
          value={workWeekData.name}
          onChange={(e) =>
            setWorkWeekData({ ...workWeekData, name: e.target.value })
          }
        />
      </div>
      <div className="p-2">Select working days and duration</div>
      {workWeekData.workDays.map((day, index) => (
        <div key={index} className="p-2 w-full flex">
          <div className="p-2 w-[16%] justify-between flex border boderradius-5">
            <div className="w-[40%]">
              <input
                type="checkbox"
                className="w-full rounded-full"
                checked={day.selected}
                onChange={() => handleDayToggle(index)}
              />
            </div>
            <div className="w-[48%]">
              {day.day.substring(0, 3).toUpperCase()}
            </div>
          </div>
          {day.selected && (
            <div className="flex w-full justify-center items-center">
              <div>Full day</div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={day.fullDay}
                  onChange={() => handleToggleChange(index)}
                />
                <div
                  className={`relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 ${
                    day.fullDay
                      ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                      : ""
                  } after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${
                    day.fullDay ? "peer-checked:bg-blue-600" : ""
                  }`}
                ></div>
              </label>
              <div>Half day</div>
            </div>
          )}
        </div>
      ))}
      <div className="w-full justify-end flex ">
        <div className="w-[35%] flex justify-between">
          <div className="w-[48%]">
            <button className="btn-primary w-full " onClick={handleCancel}>
              Cancel
            </button>
          </div>
          <div className="w-[48%]">
            <button
              onClick={handleUpdateWorkWeek}
              className="btn-primary w-full"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateWorkWeek;

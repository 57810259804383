import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ContractApi from "api/hrmapi/contract";

function Contract() {
  const navigate = useNavigate();
  let params = useParams();
  const [UpdateContract, setUpdateContract] = useState({
    id: "",
    name: "",
    salaryPaymentBasedOn: "",
    trackAttendance: "",
    temporaryContract: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-contracts`);
    }, 2000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateContract({
      ...UpdateContract,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    ContractApi.viewContractApi(params)
      .then((response) => {
        setUpdateContract({
          id: response.data.id,
          name: response.data.name,
          salaryPaymentBasedOn: response.data.salary_payment_based_on,
          trackAttendance: response.data.track_attendance,
          temporaryContract: response.data.temporary_contract,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handleDepartmentUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    ContractApi.updateContractApi(params.id, {
      contract: {
        name: UpdateContract.name,
        salary_payment_based_on: UpdateContract.salaryPaymentBasedOn,
        track_attendance: UpdateContract.trackAttendance,
        temporary_contract: UpdateContract.temporaryContract,
        company_id: UpdateContract.company_id,
      },
    })
      .then((response) => {
        handleNavgetAll();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const handleTrackAttendanceChange = (value) => {
    setUpdateContract({
      ...UpdateContract,
      trackAttendance: value,
    });
  };

  const handleTemporaryContractChange = (value) => {
    setUpdateContract({
      ...UpdateContract,
      temporaryContract: value,
    });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Contract</div>
        </div>

        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleDepartmentUpdate}>
            <div className="w-full flex flex-wrap gap-5 p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={UpdateContract.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div> Salary Payment Based On</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateContract.salaryPaymentBasedOn,
                    label: UpdateContract.salaryPaymentBasedOn,
                  }}
                  required
                  onChange={(selectedOption) =>
                    setUpdateContract({
                      ...UpdateContract,
                      salaryPaymentBasedOn: selectedOption.label,
                    })
                  }
                  styles={customStyles}
                  name="salaryPaymentBasedOn"
                  options={[
                    { value: "0", label: "Based on Attendance" },
                    { value: "1", label: "Fixed Salary + Allowance" },
                    { value: "2", label: "Based on Contract" },
                  ]}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Track Attendance</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateContract.trackAttendance,
                    label: UpdateContract.trackAttendance ? "YES" : "NO",
                  }}
                  required
                  onChange={(selectedOption) =>
                    handleTrackAttendanceChange(selectedOption.value)
                  }
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Temporary Contract</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={{
                    value: UpdateContract.temporaryContract,
                    label: UpdateContract.temporaryContract ? "YES" : "NO",
                  }}
                  required
                  onChange={(selectedOption) =>
                    handleTemporaryContractChange(selectedOption.value)
                  }
                  styles={customStyles}
                  options={[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ]}
                />
              </div>
              <div className="flex w-full bottom-0 pr-4 justify-end">
                <button
                  className={`w-[14%] btn-primary ${
                    loading ? "cursor-not-allowed" : ""
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Update Contract
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Contract;

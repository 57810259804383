import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Popup from "Components/Common/Pages/Popup";
import OrderApi from "api/posapi/order";
import { toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import CreateCustomer from "Components/Accounts/CreateCustomer";
import Button from "Components/Common/Button";
import Invoice from "./Invoice";
import ConfirmationSale from "Components/Pos/CreateSale/ConfirmationSale";
import PosSidebar from "Components/Common/PosSidebar";
import DiscountAPI from "api/posapi/discount";
import numeral from "configurations/default_money";
import SaleItemsTable from "./SaleItemsTable";
import AppWrapper from "Components/Routes/AppWrapper";
import SlickCarouselWithPagination from "./PaginateSlick";
import {
  addItemToCart,
  clearCart,
  CouponCart,
  saleId,
  removeItemFromCart,
  updateItemQuantity,
  updateTotals,
} from "store/Sale";
import { useDispatch, useSelector } from "react-redux";
function CreateSale() {
  const dispatch = useDispatch();
  const tableData = useSelector((obj) => obj.sales.cart);
  const { subtotal, totalItems, totalTax, totalDiscount, totalPrice } =
    useSelector((state) => state.sales);
  const saleid = useSelector((state) => state.sales?.saleid);
  const [show, setShow] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [status, setStatus] = useState("completed");
  const [cash, setCash] = useState("cash");
  const [showpopup, setShowPopUp] = useState(false);
  const [recipedata, setRecipeData] = useState({});
  const [saleitems, setSaleItems] = useState([]);
  const [itemtax, setItemTax] = useState("");
  const [customerid, setCustomerId] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [barcodealert, setBarcodeAlert] = useState("");
  const [error_message, setErrorMessage] = useState("");
  const [barcode_error, setBarCodeError] = useState(false);
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [showSearchedCustomers, setShowSearchedCustomers] = useState(false);
  const [cashAmount, setCashAmount] = useState(null);
  const [cardAmount, setCardAmount] = useState(null);
  const [returnamount, setReturnAmount] = useState(null);
  const [allproducts, setAllProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryid, setCategoryId] = useState(null);
  const [quantityalert, setQuantityAlert] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(null);
  const [popupId, setPopupId] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [maxValueLimit, setMaxValueLimit] = useState(0);
  const [currentSaleId, setCurrentSaleId] = useState(0);
  const [discounttypes, setDiscountTypes] = useState([]);
  const [discounttype, setDiscountType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isCancelAccessCode, setIsCancelAccessCode] = useState(false);
  const [customerMode, setCustomerMode] = useState("");

  const handleErrorMessage = (error) => {
    const errorMessage = error.response?.data.message
      ? error.response.data.message
      : "An error occurred";

    toast.error(`Error: ${errorMessage}`);
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`);
  };
  const handleListItemClick = (phoneNo, customerId) => {
    setCustomerId(customerId);
    setFilterPhoneNumber(phoneNo);
    setShowSearchedCustomers(false);
  };
  const handleInputfilterChange = (event) => {
    setFilterPhoneNumber(event.target.value);
  };
  const fetchProducts = () => {
    setBarcode("");
    OrderApi.productsAvailable(queryParams)
      .then((response) => {
        const selectedProduct = response.data[0];
        if (selectedProduct) {
          handleProductSelect(selectedProduct);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setBarcode("");
        setDataLoading(false);
      });
  };
  const queryParams = {};
  if (barcode && barcode.length === 13) {
    queryParams["q[barcode]"] = barcode;
  }
  if (categoryid) {
    queryParams["q[category_id]"] = categoryid;
  }
  if (barcode.length === 13) {
    fetchProducts();
  }
  const handleBarcodeKeyDown = (event) => {
    if (event.key === "Enter") {
      queryParams["q[sku]"] = barcode;
      fetchProducts();
    }
  };
  const AllCategoryClicked = () => {
    setCategoryId(null);
    setSelectedCategoryIds(null);
    OrderApi.productsAvailable()
      .then((response) => {
        setAllProducts(response.data);
        setDataLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setDataLoading(false);
      });
  };
  if (categoryid) {
    OrderApi.productsAvailable(queryParams)
      .then((response) => {
        setAllProducts(response.data);
        setDataLoading(false);
        setCategoryId(null);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setDataLoading(false);
      });
  }

  useEffect(() => {
    OrderApi.prevSalesAPI()
      .then((response) => {
        const updatedProduct = response.data.sale_items;
        const total = {
          subtotal: response.data.calculations.subtotal,
          totalItems: response.data.calculations.total_items,
          totalDiscount: response.data.calculations.total_discount,
          totalTax: response.data.calculations.total_tax,
          totalPrice: response.data.calculations.total_price,
        };
        dispatch(saleId(response.data.sale_id));
        dispatch(updateTotals(total));
        dispatch(CouponCart(updatedProduct));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    DiscountAPI.categorysAvailable()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.log(error);
      });
    AllCategoryClicked();
  }, []);
  useEffect(() => {
    OrderApi.AllCustomer(filterPhoneNumber)
      .then((response) => {
        setSearchedCustomers(response?.data.customers);
        setDataLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setDataLoading(false);
      });
  }, [filterPhoneNumber]);

  const handleModal = (e) => {
    const Name = e.currentTarget.getAttribute("name");
    const { name } = e.target;
    if (name === "finishSale") {
      if (tableData.length > 0) {
        setShowPopUp(true);
        setPopupId(name);
      } else {
        toast.error(`Error: Please add product to cart`);
      }
    } else {
      setShowPopUp(true);
      setPopupId(name || Name);
    }
  };

  const handleBarcodeInputChange = (event) => {
    const value = event.target.value;
    setBarcode(value);
    const remainingCharacters = 13 - parseInt(value.length);

    if (value.length === 0) {
      setBarcodeAlert("");
      setBarCodeError(false);
      setErrorMessage("");
    } else if (value.length !== 13) {
      setBarcodeAlert(
        remainingCharacters >= 1
          ? `Remaining characters: ${remainingCharacters}`
          : ""
      );
      setBarCodeError(true);
      setErrorMessage("Number of characters should be equal to 13!");
    } else {
      setBarcodeAlert("");
      setBarCodeError(false);
      setErrorMessage("");
    }
  };
  const handleCategorySelect = (obj) => {
    const CategoryId = categories.find(
      (category) => category.category_id === obj.category_id
    );
    setCategoryId(CategoryId.category_id);
    setSelectedCategoryIds(CategoryId.category_id);
  };
  const handleProductSelect = (selectedProduct) => {
    if (!saleid) {
      OrderApi.initiateOrderAPI()
        .then((response) => {
          dispatch(saleId(response.data.sale.id));
          OrderApi.addItemAPI({
            cart: {
              product_id: selectedProduct.product_id,
              sale_id: saleid,
              quantity: 1,
            },
          })
            .then((response) => {
              setDataLoading(false);
              const product = {
                product_id: response.data.sale_item.product_id,
                name: selectedProduct.product_name,
                total_tax_amount: response.data.sale_item.total_tax_amount,
                discount: response.data.sale_item.discount,
                qty: response.data.sale_item.quantity,
                input_field_value: 1,
                quantity: selectedProduct.quantity,
                price: response.data.sale_item.price,
                amount: response.data.sale_item.amount,
              };
              const calculation = {
                subtotal: response.data.calculations.subtotal,
                totalItems: response.data.calculations.total_items,
                totalDiscount: response.data.calculations.total_discount,
                totalTax: response.data.calculations.total_tax,
                totalPrice: response.data.calculations.total_price,
              };
              dispatch(addItemToCart(product));
              dispatch(updateTotals(calculation));
              setDataLoading(false);
            })
            .catch((error) => {
              handleErrorMessage(error);
              setDataLoading(false);
            });
        })
        .catch((error) => {
          handleErrorMessage(error);
        });
    }
    const existingProduct = tableData.find(
      (product) => product.product_id === selectedProduct.product_id
    );
    if (existingProduct) {
      if (existingProduct.qty < selectedProduct.quantity) {
        handleQtyChange(existingProduct, existingProduct.qty + 1);
      } else {
        setQuantityAlert(true);
        setTimeout(() => {
          setQuantityAlert(false);
        }, 2000);
      }
    } else {
      setDataLoading(true);
      OrderApi.addItemAPI({
        cart: {
          product_id: selectedProduct.product_id,
          sale_id: saleid,
          quantity: 1,
        },
      })
        .then((response) => {
          setCurrentSaleId(response.data.sale_item.sale_id);
          setDataLoading(false);
          const product = {
            product_id: response.data.sale_item.product_id,
            name: selectedProduct.product_name,
            total_tax_amount: response.data.sale_item.total_tax_amount,
            discount: response.data.sale_item.discount,
            qty: response.data.sale_item.quantity,
            input_field_value: 1,
            quantity: selectedProduct.quantity,
            price: response.data.sale_item.price,
            amount: response.data.sale_item.amount,
          };
          const calculation = {
            subtotal: response.data.calculations.subtotal,
            totalItems: response.data.calculations.total_items,
            totalDiscount: response.data.calculations.total_discount,
            totalTax: response.data.calculations.total_tax,
            totalPrice: response.data.calculations.total_price,
          };
          dispatch(addItemToCart(product));
          dispatch(updateTotals(calculation));
          setDataLoading(false);
        })
        .catch((error) => {
          handleErrorMessage(error);
          setDataLoading(false);
        });
    }
  };
  // ==================== ADD UPDATE REMOVE TABLE DATA ======================
  const removeProductFromTable = (product_id) => {
    OrderApi.removeItemAPI({
      cart: {
        product_id: product_id.product_id,
        sale_id: saleid,
      },
    })
      .then((response) => {
        const total = {
          subtotal: response.data.calculations.subtotal,
          totalItems: response.data.calculations.total_items,
          totalDiscount: response.data.calculations.total_discount,
          totalTax: response.data.calculations.total_tax,
          totalPrice: response.data.calculations.total_price,
        };
        dispatch(updateTotals(total));
        dispatch(removeItemFromCart(product_id));
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.log(error);
      });
  };
  // ========================================================================
  const handleOnBlurQuantity = (existingProduct, value) => {
    if (!value) {
      OrderApi.updateItemAPI({
        cart: {
          product_id: existingProduct.product_id,
          sale_id: saleid,
          quantity: existingProduct.qty,
        },
      })
        .then((response) => {
          const updatedProduct = {
            product_id: response.data.sale_item.product_id,
            name: existingProduct.name,
            total_tax_amount: response.data.sale_item.total_tax_amount,
            discount: response.data.sale_item.discount,
            qty: response.data.sale_item.quantity,
            input_field_value: response.data.sale_item.quantity,
            quantity: existingProduct.quantity,
            price: response.data.sale_item.price,
            amount: response.data.sale_item.amount,
          };
          const total = {
            subtotal: response.data.calculations.subtotal,
            totalItems: response.data.calculations.total_items,
            totalDiscount: response.data.calculations.total_discount,
            totalTax: response.data.calculations.total_tax,
            totalPrice: response.data.calculations.total_price,
          };
          dispatch(updateTotals(total));
          dispatch(updateItemQuantity(updatedProduct));
        })
        .catch((error) => {
          handleErrorMessage(error);
          console.log(error);
        });
    }
  };
  const handleQtyChange = (existingProduct, value) => {
    if (!value) {
      dispatch(
        updateItemQuantity({
          ...existingProduct,
          product_id: existingProduct.product_id,
          input_field_value: "",
        })
      );
    } else {
      let quantity = value;
      if (quantity >= 1 && quantity <= existingProduct.quantity) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: parseInt(value),
          },
        })
          .then((response) => {
            setDataLoading(false);
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              name: existingProduct.name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            handleErrorMessage(error);
            setDataLoading(false);
            console.log(error);
          });
      } else if (quantity < 1) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: 1,
          },
        })
          .then((response) => {
            setDataLoading(false);
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              name: existingProduct.name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            handleErrorMessage(error);
            setDataLoading(false);
            console.log(error);
          });
      } else if (quantity > existingProduct.quantity) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: existingProduct.quantity,
          },
        })
          .then((response) => {
            setDataLoading(false);
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              name: existingProduct.name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            setDataLoading(false);
            handleErrorMessage(error);
            console.log(error);
          });
        setQuantityAlert(true);
        setTimeout(() => {
          setQuantityAlert(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    const cashValue = numeral(cashAmount).format("0[.]00");
    const cardValue = numeral(cardAmount).format("0[.]00");

    if (cashValue > 0 || cardValue > 0) {
      const totalAmount = numeral(cashValue).add(cardValue).value();
      const returnAmt = numeral(totalAmount)
        .subtract(totalPrice)
        .format("0[.]00");
      setReturnAmount(returnAmt);
    } else if (cashValue == 0 && cardValue == 0) {
      setReturnAmount(0);
    }
  }, [cashAmount, cardAmount, totalPrice]);

  const handleCreateOrder = (e) => {
    const card_amount = numeral(cardAmount).format("0[.]00") || 0;
    const cash_amount = numeral(cashAmount).format("0[.]00") || 0;
    const total_price = numeral(totalPrice).format("0[.]00") || 0;
    const return_amount = numeral(returnamount).format("0[.]00") || 0;
    e.preventDefault();
    if (
      numeral(card_amount).add(cash_amount).value() <
      numeral(total_price).value()
    ) {
      toast.error(`Error: Received amount less than total bill`);
      return;
    }
    const OrderItems = tableData.map((item) => ({
      product_id: item.product_id,
      quantity: item.qty,
    }));
    const OrderData = {
      cart: {
        status: status,
        customer_id: customerid,
        sale_id: saleid,
        payment_method: cash,
        return_amount: numeral(return_amount).value() || 0,
        cash_amount: numeral(parseFloat(cash_amount) <= 0 ? 0 : parseFloat(cash_amount) - parseFloat(return_amount)).value(),
        card_amount: numeral(parseFloat(card_amount) > parseFloat(total_price) ? parseFloat(total_price) : parseFloat(card_amount)).value(),
        sale_items: OrderItems,
      },
    };
    OrderApi.createOrder(OrderData)
      .then((response) => {
        setDiscountType("");
        setPopupId("");
        handleSuccessMessage(response);
        setRecipeData(response?.data?.sale);
        setPaymentMethod(response.data.sale_received_amounts);
        setCashAmount("");
        setCardAmount("");
        setSaleItems(response.data.sale_items);
        setItemTax(response.data.taxes_applied_on_sale);
        setShow(true);
        setPopupId("invoice");
        dispatch(clearCart());
        AllCategoryClicked();
        setShowPopUp(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  };
  const handleResetClick = () => {
    OrderApi.clearCartAPI({
      cart: {
        sale_id: saleid,
      },
    })
      .then((res) => {
        dispatch(clearCart());
        OrderApi.initiateOrderAPI()
          .then((response) => {
            dispatch(saleId(response.data.sale.id));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCustomerCreated = (customerId, name) => {
    setCustomerId(customerId);
    setPopupId("")
  };
  const handleCouponApply = (e) => {
    e.preventDefault();
    if (!coupon) {
      toast.error(`Error: Plaese Enter Coupon!`);
      return;
    }
    if (tableData.length === 0) {
      toast.error(`Error: Please add product to cart!`);
      return;
    }
    OrderApi.applyCoupon({
      coupon_code: coupon,
      sale_id: saleid,
    })
      .then((response) => {
        const updatedProduct =
          response.data.applicable_discount_coupon.sale_items;
        const total = {
          subtotal:
            response.data.applicable_discount_coupon.calculations.subtotal,
          totalItems:
            response.data.applicable_discount_coupon.calculations.total_items,
          totalDiscount:
            response.data.applicable_discount_coupon.calculations
              .total_discount,
          totalTax:
            response.data.applicable_discount_coupon.calculations.total_tax,
          totalPrice:
            response.data.applicable_discount_coupon.calculations.total_price,
        };
        dispatch(updateTotals(total));
        dispatch(CouponCart(updatedProduct));
        const message = response
          ? response.data.applicable_discount_coupon.message
          : "An error occurred";

        toast.success(`Success:${message}`);
        setCoupon("");
      })
      .catch((error) => {
        console.log(error);
        handleErrorMessage(error);
      });
  };

  const handleAccessCode = (e) => {
    e.preventDefault();
    if(!saleid){
      toast.error(`Please Add Product`);
      return
    }
    if (maxValue >= 0.1 && maxValue <= maxValueLimit) {
      DiscountAPI.applyAccessCode({
        sale_id: saleid,
        access_code: accessCode,
        value: maxValue,
      })
        .then((response) => {
          const Message =
            response?.data?.applicable_discount_access_code?.message;
          toast.success(`${Message}`);
          setAccessCode("");
          setMaxValue("");
          setMaxValueLimit("");
          setPopupId("")
          setDiscountType("")
          const updatedProductWithQuantity = response?.data.applicable_discount_access_code.sale_items.map(
            (product) => {

              return {
                product_id: product.product_id,
                product_name: product.name,
                total_tax_amount: product.total_tax_amount,
                discount: product.discount,
                qty: product.qty,
                input_field_value: product.input_field_value,
                quantity: product.quantity,
                price: product.price,
                amount: product.amount,
                ch_discount: product.ch_discount,
              };
            }
          );
          const total = {
            subtotal:
              response.data.applicable_discount_access_code.calculations
                .subtotal,
            totalItems:
              response.data.applicable_discount_access_code.calculations
                .total_items,
            totalDiscount:
              response.data.applicable_discount_access_code.calculations
                .total_discount,
            totalTax:
              response.data.applicable_discount_access_code.calculations
                .total_tax,
            totalPrice:
              response.data.applicable_discount_access_code.calculations
                .total_price,
            couponDiscount:
              response.data.applicable_discount_access_code.coupon_discount,
          };
          dispatch(updateTotals(total));
          dispatch(CouponCart(updatedProductWithQuantity));
          // setPopupId("");
        })
        .catch((error) => {
          if (error.code === "ERR_BAD_REQUEST") {
            toast.error(`Error: Something went wrong!`);
          }
        });
    } else {
      toast.error(`Error: Plaese Enter Correct Max Value!`);
      return;
    }
  };

  const handleVerifyAccessCode = (e) => {
    e.preventDefault();
    if (accessCode) {
      setIsCancelAccessCode(false)
      DiscountAPI.isApplicable(accessCode)
        .then((response) => {
          const errorMessage = response?.data?.message;
          const maxLimit = parseFloat(response?.data?.max_limit);
          setMaxValueLimit(maxLimit);
          if (errorMessage === "not ok") {
            toast.error(`Error: Invalid Access Code!`);
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  };

  const handleAccessCodeCancel = () => {
    setIsCancelAccessCode(true)
    setPopupId("");
    // setAccessCode("");
  }

  const handleMaxValue = (e) => {
    setMaxValue(parseFloat(e.target.value));
  };

  useEffect(() => {
    DiscountAPI.DiscountTypeAPI()
      .then((res) => {
        const filteredDiscountTypes = res.data.discount_types.filter(
          (type) => type === "coupon_sale" || type === "access_code"
        );
        setDiscountTypes(filteredDiscountTypes);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleDiscountTypeChange = (e) => {
    let value = e.target.value;
    setDiscountType(value);
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "customer"}>
          <Popup setPopup={() => setPopupId("")} className="w-[60%]">
            <CreateCustomer
              onCustomerCreated={handleCustomerCreated}
              contentOnly={true}
              setCustomerMode={setCustomerMode}
              setPopupId={setPopupId}
            />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "invoice"}>
          <Popup setPopup={() => setPopupId("")} className="w-[30%]">
            <Invoice
              recipedata={recipedata}
              saleitems={saleitems}
              paymentMethod={paymentMethod}
              itemTax={itemtax}
              setPopupId={setPopupId}
              isRedux={true}
            />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "finishSale"}>
          <Popup setPopup={() => setPopupId("")} className="w-[50%]">
            <ConfirmationSale
              showpopup={showpopup}
              setShowPopUp={setShowPopUp}
              totalbill={totalPrice}
              setCash={setCash}
              setStatus={setStatus}
              tableData={tableData}
              cash={cash}
              cashAmount={cashAmount}
              cardAmount={cardAmount}
              setCardAmount={setCardAmount}
              setCashAmount={setCashAmount}
              returnAmount={returnamount}
              setReturnAmount={setReturnAmount}
              status={status}
              handleCreateOrder={handleCreateOrder}
            />
            <div className="modal-footer mt-2">
              <Button
                type="Button"
                className="bg-danger"
                onClick={() => setPopupId("")}
              >
                Cancel
              </Button>
            </div>
          </Popup>
        </ShowComponent>
        <ShowComponent condition={quantityalert}>
          <Popup
            showpopup={quantityalert}
            setShowPopUp={setQuantityAlert}
            customClassName="min-w-[800px] border-slate-400 z-10"
          >
            <div className="text-red-600	 text-center">Sorry! Low Stock.</div>
          </Popup>
        </ShowComponent>
        <div className="text-24 font-semibold mb-3">
          Create Sales
        </div>
        <div className="flex justify-between">
          <div className="lg:w-[61%] md:w-[61%] sm:w-1/2 w-full bg-white rounded-3xl">
            <div className="p-3">
              <div className="w-full flex justify-start items-start">
                <div className="xl:w-1/2 lg:w-1/2 md:w-full sm:w-1/2 w-1/2 mb-3">
                  <div className="relative">
                    <div className="flex mr-2">
                    <input
                      type="number"
                      className="input-style form-control rounded-r-none rounded-l-lg hideNumberArrow w-[83%]"
                      placeholder="Search by Phone No"
                      value={filterPhoneNumber}
                      onChange={handleInputfilterChange}
                      onFocus={() => setShowSearchedCustomers(true)}
                    />
                      <div className="bg-blue w-[17%] flex items-center justify-center rounded-r-lg mb-2 cursor-pointer"
                        name="customer"
                        onClick={handleModal}>
                        <img
                          className="h-[15px] cursor-pointer"
                          name="customer"
                          src="/icons/createCustomers.svg"
                          onClick={handleModal}
                        />
                      </div>
                    </div>
                    {filterPhoneNumber.length > 0 && showSearchedCustomers && (
                      <ul className="m-0 flex flex-col space-x-4 bg-white border absolute w-full z-1">
                        {searchedCustomers.map((customer) => (
                          <li
                            className="px-2 py-1 inline-block"
                            key={customer.id}
                            onClick={() =>
                              handleListItemClick(
                                customer.phone_no,
                                customer.id
                              )
                            }
                          >
                            {customer.phone_no}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-1/2 w-1/2 mb-3">
                  <input
                    type="text"
                    min={0}
                    className="input-style form-control"
                    placeholder="Search by barcode or sku"
                    value={barcode}
                    onKeyDown={handleBarcodeKeyDown}
                    onChange={handleBarcodeInputChange}
                  />
                </div>
              </div>
              <div className="min-h-[400px] max-h-[400px] overflow-auto">
                <SaleItemsTable
                  className="m-0 sales-page-table-design"
                  tableData={tableData}
                  dataLoading={dataLoading}
                  removeProductFromTable={removeProductFromTable}
                  handleProductSelect={handleProductSelect}
                  handleQtyChange={handleQtyChange}
                  handleOnBlurQuantity={handleOnBlurQuantity}
                />
              </div>
            </div>
            <div className="flex">
              <div className="sm:w-1/2 bg-white rounded-3xl p-3">
                <div className="relative">
                  <Button
                    onClick={() => setPopupId("discountsApply")}
                    type="button"
                    className="bg-warning mt-3 p-2 py-3 w-24"
                  >
                    Discounts
                  </Button>
                </div>
              </div>

              <div className="sm:w-1/2 bg-white rounded-3xl p-3">
                <div className="invoice-detail-name flex justify-between py-2">
                  <span className="font-bold">Subtotal: </span>
                  <span> {numeral(subtotal).format("0[.]00")} </span>
                </div>
                <div className="invoice-detail-name flex justify-between py-2">
                  <span className="font-bold">Total Items: </span>
                  <span> {numeral(totalItems).format("0[.]")} </span>
                </div>
                <div className="invoice-detail-name flex justify-between py-2">
                  <span className="font-bold"> Total VAT: </span>
                  <span> {numeral(totalTax).format("0[.]00")} </span>
                </div>
                <div className="invoice-detail-name flex justify-between py-2">
                  <span className="font-bold">Total Discount: </span>
                  <span> {numeral(totalDiscount).format("0[.]00")} </span>
                </div>
                <hr className="mt-2 mb-2" />
                <div className="invoice-detail-name flex justify-between py-2">
                  <span className="font-bold">Total: </span>
                  <span> {numeral(totalPrice).format("0[.]00")} </span>
                </div>

                <div className="mt-4 flex justify-end gap-4">
                  <Button
                    className="btn-primary bg-success px-2"
                    name="finishSale"
                    onClick={handleModal}
                  >
                    Pay Now
                  </Button>
                  <Button
                    className="btn-primary bg-danger px-2"
                    onClick={handleResetClick}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>

            <div className="mt-4"></div>
          </div>
          <div className="lg:w-[38%] md:w-[38%] w-1/3 sm:w-1/3 bg-white rounded-3xl p-3">
            <div className="sytlecreatesalegap mb-2 sales-category-slick-slider">
              <SlickCarouselWithPagination>
                <Button
                  onClick={AllCategoryClicked}
                  className={`${
                    selectedCategoryIds == null
                      ? "bg-blue mb-2  mr-2 text-white"
                      : "mb-2 bg-white mr-2 text-dark"
                  }`}
                >
                  All
                </Button>
                {categories.map((obj) => (
                  <Button
                    key={obj.category_id}
                    className={`${
                      selectedCategoryIds === obj.category_id
                        ? "bg-blue mb-2  mr-2 text-white"
                        : "mb-2 bg-white mr-2 text-dark"
                    }`}
                    style={{ borderRadius: "9px" }}
                    onClick={() => handleCategorySelect(obj)}
                  >
                    {obj.category_name}
                  </Button>
                ))}
              </SlickCarouselWithPagination>
            </div>
            <div
              className="sytlecreatesalegap grid grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-6 "
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              {allproducts.map((obj) => (
                <div
                  className="sytlecreatesale sytlecreatesalegap bg-white rounded-md p-2 text-center"
                  key={obj.product_id}
                  disabled={dataLoading ? true : false}
                  onClick={() => handleProductSelect(obj)}
                >
                  <p className="sytlecreatesaleunset text-left leading-4">
                    {obj.product_name}
                  </p>
                  <img
                    style={{
                      maxHeight: "60px",
                      margin: "10px auto",
                    }}
                    src={obj.get_avatar}
                    alt={" "}
                    // alt={obj.product_name}
                  />
                  <p className="sytlecreatesaleunset bg-blue text-white rounded-xl leading-5 text-12">
                    {obj.price}
                  </p>
                </div>
              ))}
            </div>
            {barcodealert && <p>{barcodealert}</p>}
            {barcode_error && <p>{error_message}</p>}
          </div>
        </div>
        <ShowComponent condition={popupId === "discountsApply"}>
        <Popup setPopup={() => setPopupId("")} className="w-[470px]">
            <>
              <div className="w-full">
                <label htmlFor="Discounttype" className="text-[14px] font-bold">
                  Discount Type
                </label>
                <select
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={discounttype}
                  onChange={(e) => handleDiscountTypeChange(e)}
                >
                  <option value="">Select Discount Type</option>
                  {discounttypes.map((type, index) => (
                    <option key={index} value={type}>
                      {capitalizeFirstLetter(type)}
                    </option>
                  ))}
                </select>
              </div>
              {(discounttype === "access_code") && (
                <div className="flex mt-4">
                  {!maxValueLimit &&(
                  <>
                  <div className="w-[60%] bg-white rounded-3xl p-3">
                    <label className="text-[14px] font-bold">Access Code</label>
                    <input
                      className="form-control rounded mt-2 mb-4 hideNumberArrow"
                      name="Access Code"
                      type="number"
                      value={accessCode}
                      placeholder="Enter Access Code"
                      onChange={(e) => {
                        setAccessCode(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="flex justify-end w-[40%]">
                      <Button
                      onClick={handleVerifyAccessCode}
                      type="button"
                      className="bg-orange mb-5 mt-9 ml-3 py-3"
                      >
                        Verify Access Code
                    </Button>
                  </div>
                  </>
                  )}
                  {(maxValueLimit > 0) &&(
                  <div className="w-full bg-white rounded-3xl py-3">
                    <label className="text-[14px] font-bold">Max Value(Between 0.1 to {maxValueLimit})</label>
                    <input
                      className="form-control rounded mt-2 mb-2 hideNumberArrow"
                      name="Max Value"
                      type="number"
                      value={maxValue}
                      placeholder="Enter Value"
                      onChange={handleMaxValue}
                      required
                    />
                    <div className="flex justify-end">
                      <Button
                        onClick={handleAccessCode}
                        type="button"
                        className="bg-warning mt-3 p-2 py-3"
                      >
                        Apply
                      </Button>
                      <Button
                        type="Button"
                        className="bg-danger mt-3 ml-4 p-2 py-3"
                        onClick={handleAccessCodeCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  )}
                </div>
              )}
              {discounttype === "coupon_sale" && (
                <div className="flex mt-4">
                  <div className="sm:w-1/2 bg-white rounded-3xl p-3">
                    <label className="text-[14px] font-bold">Coupon Code</label>
                    <input
                      className="form-control rounded mt-2 mb-2 hideNumberArrow"
                      name="Discount Title"
                      value={coupon}
                      placeholder="Enter Coupon Code"
                      onChange={(e) => {
                        setCoupon(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="sm:w-1/2 bg-white rounded-3xl p-3 mt-2 mb-2">
                    <div className="flex justify-end">
                      <Button
                        onClick={handleCouponApply}
                        type="button"
                        className="bg-warning mt-8 p-2 py-3"
                      >
                        Apply
                      </Button>
                      <Button
                        type="Button"
                        className="bg-danger ml-4 mt-8 p-2 py-3"
                        onClick={() => setPopupId("")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Popup>
        </ShowComponent>
      </PagesWrapper>
    </AppWrapper>
  );
}
export default CreateSale;
import React from "react";
import { useNavigate } from "react-router-dom";

function SessionExpired() {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div class="flex justify-center items-center min-h-screen">
      <div class="text-center">
        <h1 class="text-4xl sm:text-5xl lg:text-6xl font-bold mb-10">
          Session Expired
        </h1>
        <div class="text-base sm:text-lg lg:text-xl text-gray-700 mb-8">
          Your Session Has Expired Please{" "}
          <span>
            <a href="#" onClick={handleLogin} class="text-blue underline">
              Login
            </a>
          </span>{" "}
          Again.
        </div>
      </div>
    </div>
  );
}

export default SessionExpired;

import axiosClient from "..";
const Api = "/api/hrm/employees/documents";
class DocumentApi {
  static createDocument(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allDocument() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewDocumentApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateDocumentApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteDocument(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default DocumentApi;

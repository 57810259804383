import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import DepartmentApi from "api/hrmapi/department";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CreateDepartment({
  contentOnly = false,
  setPopupId,
  setFetchDepartments,
  onDepartmentCreated,
}) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const navigate = useNavigate();
  const handleNavgetAllDepartment = () => {
    setTimeout(() => {
      navigate(`/all-departments`);
    }, 2000);
  };

  const [department, setDepartment] = useState({
    name: "",
    department_head_id: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDepartment({
      ...department,
      [name]: value,
    });
  };

  const handleCreateDepartment = (e) => {
    e.preventDefault();
    setLoading(true);

    DepartmentApi.createDepartment({
      departments: {
        ...department,
        company_id: company_id,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        if (!contentOnly) {
          handleNavgetAllDepartment();
        }
        toast.success(`${Message}`);
        if (contentOnly) {
          setFetchDepartments(true);
          setPopupId("");
          // onDepartmentCreated(response.data.data.id);
        }
        setDepartment({
          name: "",
          department_head_id: "",
          description: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Department</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreateDepartment}>
            <div className="w-full flex justify-around flex-wrap pl-4 pr-4">
              <div className="w-[20%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={department.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="w-[20%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Department Head</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    value={{
                      value: department.department_head_id,
                      label: department.department_head_id,
                    }}
                    onChange={(obj) =>
                      setDepartment((prev) => ({
                        ...prev,
                        department_head_id: obj.label,
                      }))
                    }
                    styles={customStyles}
                    options={[]}
                    placeholder="Please select"
                  />
                </div>
              </div>
              <div className="w-[40%]">
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-4 h-auto p-2"
                    value={department.description}
                    required
                    rows={2}
                    name="description"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create Department
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateDepartment;

import axiosClient from "..";
const Api ="/api/hrm/employees/positions"
class PositionApi {
  static createPosition(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allPositions() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewPositionApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updatePositionApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deletePosition(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default PositionApi;

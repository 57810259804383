import GeneralTable from "Components/Common/GeneralTable";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import ShowComponent from "Components/ShowComponent";
import DiscountAPI from "api/posapi/discount";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormatDate from "Components/Common/FormatDate";
import Button from "Components/Common/Button";
import Loader from "../../../assets/svg/Loader.svg";

const DiscountTypes = () => {
  const [selectedDiscount, setSelectedDiscount] = useState({ item_list: [] });
  const [location, setLocation] = useState("");
  const params = useParams();
  const { discount_type, id } = params;
  const selectedCategory = discount_type;
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const availableDiscountTypes = {
    product: (id) =>
      DiscountAPI.discountProducts(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    category: (id) =>
      DiscountAPI.discountCategory(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    coupon_sale: (id) =>
      DiscountAPI.discountCoupenSale(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    coupon_product: (id) =>
      DiscountAPI.discountCoupenProduct(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    offer_get_y_item: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => {
          setSelectedDiscount(res?.data?.offer);
          setOffers(res?.data?.offer?.discount_offer);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    offer_get_y_amount: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => {
          setSelectedDiscount(res?.data?.offer);
          setOffers(res?.data?.offer?.discount_offer?.gifts);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    offer_get_y_category: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => {
          setSelectedDiscount(res?.data?.offer);
          setOffers(res?.data?.offer?.discount_offer?.gifts);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    coupon_category: (id) =>
      DiscountAPI.discountCoupenCategory(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
    // access_code: (id) => DiscountAPI.discountCoupenCategory(id).then(res => setSelectedDiscount(res.data)).catch(res => console.log(res.data)),
    access_code: (id) =>
      DiscountAPI.accessCodeDiscountAPI(id)
        .then((res) => {
          setSelectedDiscount(res.data);
          setLocation(res.data);
        })
        .catch((res) => console.log(res.data)),
  };
  useEffect(() => {
    availableDiscountTypes[discount_type](id)
      .then((res) => {
        setSelectedDiscount(res.data);
        setLocation(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return "";
    }
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const CouponSale = location?.discount_meta?.discount_type;
  const isCouponSale = CouponSale && CouponSale === "coupon_sale";
  const AccessCode = location?.discount_access_code?.discount_type;
  const isAccessCode = AccessCode && AccessCode === "access_code";

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/discounts");
  };

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <img src={Loader} className="m-auto" alt="" />
          </div>
        ) : (
          <div>
            <div className="flex justify-between my-4">
              <div className="flex items-center text-24 font-semibold">
                Discount Details
              </div>
              <div className="w-[8%]">
                <Button
                  className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="w-full min-h-[50vh] mt-5">
              <div className="w-full flex justify-between">
                <div className="w-[40%] bg-white rounded-3xl">
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Title:</div>
                    <div>
                      {location?.discount_meta?.title ||
                        location?.discount_access_code?.title ||
                        selectedDiscount.title}
                    </div>
                  </div>
                  {(location?.discount_meta?.coupon_code ||
                    location?.discount_access_code?.access_code) && (
                    <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                      <div className="font-bold">
                        {location?.discount_meta?.coupon_code
                          ? "Coupon Code:"
                          : "Access Code:"}
                      </div>
                      <div>
                        {location?.discount_meta?.coupon_code ||
                          location?.discount_access_code?.access_code}
                      </div>
                    </div>
                  )}
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Type:</div>
                    <div>
                      {capitalizeFirstLetter(
                        location?.discount_meta?.discount_type ||
                          location?.discount_access_code?.discount_type ||
                          selectedDiscount.discount_type
                      )}
                    </div>
                  </div>
                  {selectedDiscount.discount_type != "offer_get_y_item" && (
                    <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                      <div className="font-bold">
                        {selectedDiscount.min_quantity
                          ? "Min Quantity: "
                          : selectedDiscount.min_sale_amount
                          ? "Min Sale Amount: "
                          : "Value: "}
                      </div>
                      <div>
                        {location?.discount_meta?.value ||
                          location?.discount_access_code?.max_limit ||
                          selectedDiscount.min_quantity ||
                          selectedDiscount.min_sale_amount}
                      </div>
                    </div>
                  )}
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Status:</div>
                    <div>
                      {location?.discount_meta?.status === true ||
                      location?.discount_access_code?.status === true ||
                      selectedDiscount.status
                        ? "Active"
                        : "Inactive"}
                    </div>
                  </div>
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Start Date:</div>
                    <div>
                      {FormatDate(
                        location?.discount_meta?.start_date ||
                          location?.discount_access_code?.start_date ||
                          selectedDiscount.start_date
                      )}
                    </div>
                  </div>
                  <div className="p-4 mb-3 border-b border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">End Date:</div>
                    <div>
                      {FormatDate(
                        location?.discount_meta?.end_date ||
                          location?.discount_access_code?.end_date ||
                          selectedDiscount.end_date
                      )}
                    </div>
                  </div>
                </div>
                {/* {!isCouponSale && !isAccessCode && ( */}
                  <div className="w-[60%] bg-white rounded-3xl ml-5">
                    <ShowComponent
                      condition={selectedDiscount?.item_list?.length}
                    >
                      {selectedCategory === "coupon_category" ||
                      selectedCategory === "category" ? (
                        <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                          <div className="w-full p-3 flex justify-center">
                            <table className="w-[80%] bg-white border-2 border-b-0 border-gray-300">
                              <thead className="w-full">
                                <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                  <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                    Id
                                  </th>
                                  <th className="py-2 px-4 w-[80%] flex justify-center">
                                    Category
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedDiscount?.item_list?.map((item) => (
                                  <tr
                                    key={item.item_id}
                                    className="w-full justify-between flex border-b-2 border-gray-300"
                                  >
                                    <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                      {item.item_id}
                                    </td>
                                    <td className="py-2 px-4 w-[80%] flex justify-center">
                                      {item.item_name}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                          <div className="p-3 w-full flex justify-center">
                            <table className="min-w-full bg-white border-2 border-b-0 border-gray-300 rounded-lg">
                              <thead className="w-full">
                                <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                  <th className="py-2 px-4 w-[30%] border-r-2 border-gray-300 flex justify-center">
                                    Barcode
                                  </th>
                                  <th className="py-2 px-4 w-[35%] border-r-2 border-gray-300 flex justify-center">
                                    Item Name
                                  </th>
                                  <th className="py-2 px-4 w-[35%] flex justify-center">
                                    Item Category
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedDiscount?.item_list?.map((item) => (
                                  <tr
                                    key={item.item_id}
                                    className="w-full justify-between flex border-b-2 border-gray-300"
                                  >
                                    <td className="py-2 pl-2 w-[30%] border-r-2 border-gray-300">
                                      {item.barcode}
                                    </td>
                                    <td className="py-2 pl-2 w-[35%] border-r-2 border-gray-300">
                                      {item.item_name}
                                    </td>
                                    <td className="py-2 pl-2 w-[35%]">
                                      {item.item_category}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </ShowComponent>
                    {(selectedCategory === "offer_get_y_category" ||
                      selectedCategory === "offer_get_y_amount") && (
                      <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                        <div className="w-full p-3 flex justify-center">
                          <table className="w-[80%] bg-white border-2 border-b-0 border-gray-300">
                            <thead className="w-full">
                              <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                  Id
                                </th>
                                <th className="py-2 px-4 w-[80%] flex justify-center">
                                  {selectedCategory === "offer_get_y_category"
                                    ? "Category Name"
                                    : "Product Name"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {offers.map((item) => (
                                <tr
                                  key={item.id}
                                  className="w-full justify-between flex border-b-2 border-gray-300"
                                >
                                  <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                    {item.id}
                                  </td>
                                  <td className="py-2 px-4 w-[80%] flex justify-center">
                                    {item.name}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {selectedDiscount?.discount_type == "offer_get_y_item" && (
                      <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                        <div className="w-full flex p-3">
                          <div className="w-1/2 mr-3">
                            <h1 className="font-bold mb-2">Products</h1>
                            <table className="w-full border-2 border-b-0 border-gray-300">
                              <thead className="w-full">
                                <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                  <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                    Id
                                  </th>
                                  <th className="py-2 px-4 w-[80%] flex justify-center">
                                    Product Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {offers?.products.map((item) => (
                                  <tr
                                    key={item.id}
                                    className="w-full justify-between flex border-b-2 border-gray-300"
                                  >
                                    <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                      {item.id}
                                    </td>
                                    <td className="py-2 px-4 w-[80%] flex justify-center">
                                      {item.name}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="w-1/2 ml-3">
                            <h1 className="font-bold mb-2 ml-2">Gifts</h1>
                            <table className="w-full border-2 border-b-0 border-gray-300">
                              <thead className="w-full">
                                <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                  <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                    Id
                                  </th>
                                  <th className="py-2 px-4 w-[80%] flex justify-center">
                                    Product Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {offers?.gifts.map((item) => (
                                  <tr
                                    key={item.id}
                                    className="w-full justify-between flex border-b-2 border-gray-300"
                                  >
                                    <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                      {item.id}
                                    </td>
                                    <td className="py-2 px-4 w-[80%] flex justify-center">
                                      {item.name}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                      <div className="w-full flex p-3">
                        <div className="w-full mr-3">
                          <h1 className="font-bold mb-2">Active Locations</h1>
                          <table className="w-full border-2 border-b-0 border-gray-300">
                            <thead className="w-full">
                              <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                  Location Id
                                </th>
                                <th className="py-2 px-4 w-[80%] flex justify-center">
                                  Location Name
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedDiscount.locations.map(
                                ({ id, address }) => <tr className="w-full justify-between flex border-b-2 border-gray-300">
                                    <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                      {id}
                                    </td>
                                    <td className="py-2 px-4 w-[80%] flex justify-center">
                                      {address}
                                    </td>
                                  </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* )} */}
              </div>
            </div>
          </div>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
};

export default DiscountTypes;

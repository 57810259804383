import axiosClient from "..";
const Api = "/api/admin/pos/sales"
const CalculationApi = "/api/admin/pos/cart-updates"
let urlBase = `/api/admin/pos/products/available_for_sale.json`;
class OrderApi {
  static createOrder(data) {
    return axiosClient.post(`/api/admin/pos/cart-updates/finalize-sale.json`, data)
  }
  static discountAppliedOnSales(data) {
    return axiosClient.get(`/api/admin/pos/discount_applied_on_sales.json`,{ params: data })
  }
  static discountRemovedOnSales(data) {
    const params = { sale_id: data.sale_id };
    return axiosClient.delete(`/api/admin/pos/discount_applied_on_sales/${data.id}.json`,{params })
  }
  static allOrderAPI(page, filterPerpage, filter, idPredicate, filterId, startDate, endDate) {
    return axiosClient.get(`${Api}.json?page=${page}&per_page_count=${filterPerpage}/${filter && `&q[id_${idPredicate}]=${filterId}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`}`);
  }
  static viewOrderAPI(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static initiateOrderAPI() {
    return axiosClient.get(`${CalculationApi}/initiate-sale.json`);
  }
  static addItemAPI(data) {
    return axiosClient.post(`${CalculationApi}/add-item.json`, data);
  }
  static updateItemAPI(data) {
    return axiosClient.patch(`${CalculationApi}/update-item.json`, data);
  }
  static removeItemAPI(data) {
    return axiosClient.delete(`${CalculationApi}/remove-item.json`, { params: data });
  }
  static clearCartAPI(data) {
    return axiosClient.delete(`${CalculationApi}/cancel-sale.json`, { params: data });
  }
  static prevSalesAPI() {
    return axiosClient.get(`${CalculationApi}/prev-sales.json`);
  }
  static refund(invoice) {
    return axiosClient.get(`/api/admin/pos/sales/refund?invoice_no=${invoice}`)
  }
  static invoiceSearch(invoice) {
    return axiosClient.get(`${Api}.json?${'&q[invoice_no_eq]='}${invoice}`)
  }
  static amountTransfer(saleId, transferMethod,access_code) {
    const url = `${Api}/${saleId}/sale_received_amounts/amount_transfer.json`;
    const params = { transfer_method: transferMethod,access_code };
  
    return axiosClient.get(url, { params });
  }
  static applyCoupon(data) {
    return axiosClient.get(`/api/admin/pos/discount_coupons/apply.json`, {
      params: data
    });
  }
  static productsAvailable(queryParams) {
    let url = urlBase;
    if (queryParams) {
      url += "?&" + new URLSearchParams(queryParams)
    }
    return axiosClient.get(url);
  }
  static AllCustomer(filterPhoneNumber,id) {
    return axiosClient.get(`/api/customers.json?${'&q[phone_no_eq]='}${filterPhoneNumber}${'&q[customer_id_eq]='}${id}`);
  }
  static CloseCashier() {
    return axiosClient.post("/api/admin/pos/cashier/close.json")
  }
  static ReOpenCashier(data) {
    return axiosClient.post("/api/admin/pos/cashier/reopen.json",data)
  }
  static allSessions() {
    return axiosClient.get("/api/admin/pos/cashier.json")
  }
  static ViewClosingDayReport(defaultLoc,date,sessionId) {
    return axiosClient.get(`api/reports/view_closing_day_report.json?location_id=${defaultLoc}&date=${date}&session_id=${sessionId}`)
  }
}
export default OrderApi
import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AssetApi from "api/hrmapi/assests";
import DatePicker from "react-datepicker";
import Select from "react-select";
function CompanyAssets() {
  const navigate = useNavigate();
  let params = useParams();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [status, setStatus] = useState("");
  const [updateCompanyAssets, setUpdateCompanyAssets] = useState({
    id: "",
    name: "",
    serialNumber: "",
    purchaseCost: "",
    warrantyExpiryDate: "",
    assetTypeId: "",
    purchaseDate: "",
    description: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-company-assets`);
    }, 1000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateCompanyAssets({
      ...updateCompanyAssets,
      [name]: value,
    });
  };

  const handleDateChange = (date, name) => {
    setUpdateCompanyAssets({
      ...updateCompanyAssets,
      [name]: date,
    });
  };

  const [loading, setLoading] = useState(true);
  const [allAssetType, setAllAssetType] = useState([]);
  useEffect(() => {
    AssetApi.allAssetType()
      .then((res) => {
        setAllAssetType(res.data);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
    setLoading(true);
    AssetApi.viewCompanyAssetApi(params)
      .then((response) => {
        setUpdateCompanyAssets({
          id: response.data.id,
          name: response.data.name,
          serialNumber: response.data.serial_number,
          purchaseCost: response.data.purchase_cost,
          warrantyExpiryDate: response.data.warranty_expiry_date,
          assetTypeId: response.data.asset_type_id,
          purchaseDate: response.data.purchase_date,
          description: response.data.description,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        const valueOption = statusOption.find(
          (option) => option.label === response.data.status
        );
        setStatus(response.data?.status);
        setStatusId(valueOption?.value);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handleCompanyAssetsUpdate = (e) => {
    e.preventDefault();
    if (!statusId) {
      toast.error("Please select a status.");
      return;
    }
    setLoading(true);
    AssetApi.updateCompanyAssetApi(params.id, {
      asset: {
        name: updateCompanyAssets.name,
        company_id: updateCompanyAssets.company_id,
        asset_type_id: updateCompanyAssets.assetTypeId,
        description: updateCompanyAssets.description,
        purchase_date: updateCompanyAssets.purchaseDate,
        status: parseInt(statusId),
        warranty_expiry_date: updateCompanyAssets.warrantyExpiryDate,
        serial_number: updateCompanyAssets.serialNumber,
        purchase_cost: updateCompanyAssets.purchaseCost,
      },
    })
      .then((response) => {
        handleNavgetAll();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const statusOnChange = (selectedOption) => {
    setStatus(selectedOption.label);
    setStatusId(selectedOption.value);
  };
  const statusOption = [
    { value: "", label: "Please Select" },
    { value: "0", label: "Available" },
    { value: "1", label: "Assigned" },
    { value: "2", label: "In Use" },
    { value: "3", label: "In Maintenance" },
    { value: "4", label: "Disposed" },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Company Assets</div>
        </div>

        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCompanyAssetsUpdate}>
            <div className="w-full flex gap-5 flex-wrap pl-4 pr-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={updateCompanyAssets.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Purchase Cost</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="purchaseCost"
                    value={updateCompanyAssets.purchaseCost}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Serial Number</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="serialNumber"
                    value={updateCompanyAssets.serialNumber}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Status</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    value={{
                      value: statusId,
                      label: status,
                    }}
                    onChange={statusOnChange}
                    name="status"
                    options={statusOption}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Warranty Expiry Date</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <DatePicker
                    required
                    className="form-control datePickerWidth"
                    dateFormat="dd/MM/yyyy"
                    name="warrantyExpiryDate"
                    onKeyDown={(e) => e.preventDefault()}
                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    selected={
                      updateCompanyAssets.warrantyExpiryDate
                        ? new Date(updateCompanyAssets.warrantyExpiryDate)
                        : null
                    }
                    onChange={(date) =>
                      handleDateChange(date, "warrantyExpiryDate")
                    }
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Asset Type</div>
                </div>
                <div>
                  <Select
                    value={{
                      value: updateCompanyAssets.assetTypeId,
                      label: allAssetType.find(
                        (dep) => dep.id === updateCompanyAssets.assetTypeId
                      )?.name,
                    }}
                    onChange={(obj) =>
                      inputOnChange({
                        target: {
                          name: "assetTypeId",
                          value: obj.value,
                        },
                      })
                    }
                    options={allAssetType.map((obj) => ({
                      value: obj.id,
                      label: obj.name,
                    }))}
                    placeholder="Please select"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Purchase Date</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <DatePicker
                    required
                    className="form-control datePickerWidth"
                    dateFormat="dd/MM/yyyy"
                    name="purchaseDate"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    onKeyDown={(e) => e.preventDefault()}
                    disabledKeyboardNavigation
                    selected={
                      updateCompanyAssets.purchaseDate
                        ? new Date(updateCompanyAssets.purchaseDate)
                        : null
                    }
                    onChange={(date) => handleDateChange(date, "purchaseDate")}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-4 h-auto p-2"
                    value={updateCompanyAssets.description}
                    rows={3}
                    name="description"
                    onChange={inputOnChange}
                  />
                </div>
              </div>
              <div className="flex w-full  pr-4 pb-4 justify-end">
                <button
                  className={
                    loading
                      ? "w-[20%] btn-primary cursor-not-allowed"
                      : "w-[20%] btn-primary"
                  }
                  type="submit"
                  disabled={loading}
                >
                  Update Company Assets
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CompanyAssets;

import axiosClient from "..";
const Api = "/api/hrm/employees/branches";
class BranchesApi {
  static createBranches(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allBranches() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewBranchesApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateBranchesApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteBranches(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default BranchesApi;

import React from "react";
import Button from "Components/Common/Button";

function TaxFree({passportInputs, handleTaxfree, closeTaxFree, passportChangeHandler}) {
  return (
    <>
      <div>
        {passportInputs.map((input) =>
          input.type === "button" ? (
            <Button
              className={`w-full ${input.className}`}
              onClick={input.onClick}
            >
              {input.title}
            </Button>
          ) : input.type === "date" ? (
            <div className="flex items-center">
              <span className="font-bold w-[40%]">{input.title}</span>
              <input
                type="date"
                className={`form-control ${input.className}`}
                name={input.name}
                value={input.value}
                onChange={passportChangeHandler}
                disabled={input.disabled}
              />
            </div>
          ) : (
            <div className="flex items-center">
              <span className="font-bold w-[40%]">{input.title}</span>
              <input
                type={input.type}
                className={`form-control ${input.className}`}
                name={input.name}
                value={input.value}
                placeholder={input.placeholder}
                required={input.required}
                disabled={input.disabled}
                onChange={passportChangeHandler}
              />
            </div>
          )
        )}
      </div>
      <div className="modal-footer flex justify-end mt-4">
        <Button type="Button" className="bg-blue" onClick={handleTaxfree}>
          Apply
        </Button>
        <Button
          type="Button"
          className="bg-red ml-4"
          onClick={closeTaxFree}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default TaxFree;

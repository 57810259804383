import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import CircularAPi from "api/posapi/circulars";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import Button from "Components/Common/Button";
import Table from "Components/Common/GeneralTable";
import CircularViews from "./CircularModal";
import Loader from "../../../assets/svg/Loader.svg";

const Circular = ({ contentOnly = false, uploadAble = true }) => {
  const defaultLoc = useSelector((state) => state.auth.currentUser.default_loc);
  const [circulars, setCirculars] = useState([]);
  const [preview, setPreview] = useState("");
  const [selectedCircular, setSelectedCircular] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [popupId, setPopupId] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setPopupId("circular");
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      setPopupId("");
      return;
    }
  };
  const fetchCirculars = () => {
    CircularAPi.fetch_circulars(defaultLoc)
      .then((res) => {
        setCirculars(res.data.views);
        console.log(res.data.views);
        setFetching(false);
      })
      .catch((err) => setFetching(false));
  };
  const handleSubmit = (e) => {
    setUploading(true);
    let formData = new FormData();
    formData.set("location[file]", selectedFile);
    CircularAPi.upload_circulard(defaultLoc, formData)
      .then((res) => {
        setPopupId("");
        setUploading(false);
        toast.success("Uploaded Successfully");
        fetchCirculars();
      })
      .catch((err) => {
        setUploading(false);
        toast.error("An Error Occcured");
      });
  };
  const handleViewCircular = (e, file_url) => {
    const { name } = e.target;
    setPopupId(name);
    setSelectedCircular(file_url);
    console.log(file_url);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    fetchCirculars();
  }, []);
  return (
    <AppWrapper sideBar={<PosSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "viewCircular"}>
          <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
            <CircularViews url={selectedCircular} />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "circular"}>
          <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
            <div className="h-[80vh] relative">
              <iframe
                className="w-full min-h-full"
                src={preview}
                title="W3Schools Free Online Web Tutorials"
              ></iframe>
              <div className="absolute bottom-0 flex justify-end items-center gap-1 w-full bg-white">
                <Button className="my-1" name="search" onClick={handleSubmit}>
                  <ShowComponent condition={!uploading}>Upload</ShowComponent>
                  <ShowComponent condition={uploading}>
                    Uploadind ...
                  </ShowComponent>
                </Button>
              </div>
            </div>
          </Popup>
        </ShowComponent>
        <div className="w-full flex my-8 justify-between">
          <div className="py-4">
            <h1 className="text-24 font-semibold w-full px-4 text-center">Circulars</h1>
          </div>
          <ShowComponent condition={uploadAble}>
            <div>
              <label className="btn-primary px-3" htmlFor="circular">
                Upload File
              </label>
              <input
                className="hidden"
                type="file"
                name="file"
                id="circular"
                onChange={changeHandler}
                accept="application/pdf, application/vnd.ms-excel"
              />
            </div>
          </ShowComponent>
        </div>
        {fetching ? (
          <div className={`text-center p-3 ${fetching ? "loader" : ""}`}>
            <img src={Loader} className="m-auto" alt="" />
          </div>
        ) : (
          <div>
            {circulars.length > 0 ? (
              <table className="w-[80%] my-10 bg-white rounded-2xl mx-20 ">
                <thead className="items-center text-center justify-center">
                  <tr>
                    <th class="pb-6 pt-10 w-[90px] text-center text-blue">
                      Id
                    </th>
                    <th class="pb-6 pt-10 w-[90px] text-center text-blue">
                      Name
                    </th>
                    <th class="pb-6 pt-10 w-[90px] text-center text-blue">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="items-center text-center justify-center">
                  {circulars.reverse().map(({ id, description, file_url }) => (
                    <tr
                      className="border-b border-b-[#D9D9D9]"
                      key={id}
                    >
                      <td className="pt-5 w-[90px] text-center">{id}</td>
                      <td className="pt-5 w-[90px] text-center">
                        {description}
                      </td>
                      <td className="p-2 w-[90px] text-center">
                        <Button
                          className="w-[100px]"
                          name="viewCircular"
                          onClick={(e) => handleViewCircular(e, file_url)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="p-4 w-full text-center">No data available.</div>
            )}
          </div>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
};

export default Circular;

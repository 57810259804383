import axiosClient from "..";
const Api = "/api/hrm/employees/contracts";
class ContractApi {
  static createContract(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allContract() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewContractApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateContractApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteContract(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default ContractApi;

import OrderApi from "api/posapi/order";
import React, { useState } from "react";
import Invoice from "./Invoice";
import { ReturnSaleId } from "store/Sale";
import { useDispatch } from "react-redux";

function PrintBill({ setPopupId }) {
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [data, setData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [saleStatus, setSaleStatus] = useState("");
  const [recipedata, setRecipedata] = useState("");
  const [itemTax, setItemTax] = useState("");
  const [refundedInvoiceNo, setRefundedInvoiceNo] = useState("");
  const dispatch = useDispatch();
  const SearchInvocie = () => {
    if (!invoiceNo || tableData.length > 0) {
      return;
    }
    OrderApi.invoiceSearch(invoiceNo)
      .then((res) => {
        dispatch(ReturnSaleId(res?.data?.sale[0]?.id));
        setTableData(res?.data.sale[0].sale_items);
        setRecipedata(res?.data?.sale[0]);
        let taxes_applied_on_sales =
          res?.data.sales?.[0].taxes_applied_on_sales;
        setItemTax(taxes_applied_on_sales);
        const Data = res?.data.sales.map((data) => ({
          sale_status: data.sale_status,
          complete_refunded_invoice_no: data?.complete_refunded_invoice_no,
        }));
        const status = Data.length > 0 ? Data[0].sale_status : null;
        setSaleStatus(status);
        const refInvoice =
          Data.length > 0 ? Data[0].complete_refunded_invoice_no : null;
        setRefundedInvoiceNo(refInvoice);
        setInvoiceNo("");
       
        setData(res?.data.sales);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full">
      {tableData.length === 0 ? (
        <>
          <div className="mb-2">Search Invoice</div>
          <div className="w-full flex">
            <div className="w-[70%] mr-2 ">
              <input
                className="form-control"
                type="text"
                placeholder="Enter Invoice No"
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
              />
            </div>
            <div className="w-[30%]">
              <div className="flex w-full justify-between">
                <div className="w-full">
                  <button
                    className=" btn-primary w-full"
                    onClick={SearchInvocie}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Invoice
          recipedata={recipedata}
          saleitems={tableData}
          paymentMethod={recipedata?.sale_received_amounts}
          itemTax={itemTax}
          setPopupId={setPopupId}
          isRedux={false}
        />
      )}
    </div>
  );
}

export default PrintBill;

import React, { useState } from "react";
import { setVerified } from "store/Auth";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GernalInput from "../Common/GeneralInput";
import Button from "../Common/Button";
import UserApi from "api/adminapi/user";
import AuthWrapper from "Components/Common/Pages/AuthWrapper";
import { toast } from "react-toastify";

const RegisterUser = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    password: "",
    confirmpassword: "",
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationToken = queryParams.get("invitation_token");
  const role_id = queryParams.get("role_id");
  const handleCreateUser = (e) => {
    e.preventDefault();
    setLoading(true);
    UserApi.acceptInvitationAPI({
      user: {
        password: formData.password,
        password_confirmation: formData.confirmpassword,
        invitation_token: invitationToken,
        username: formData.name,
        company_name: formData.company,
      },
    })
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);

        dispatch(
          setVerified({
            verified: true,
          })
        );
        Navigate("/login");
      })
      .catch((error) => {
        toast.error(
          error.response ? error.response.data.message : "An error occurred"
        );
        setLoading(false);
      });
  };
  const inputFields = [
    {
      title: "User Name",
      value: formData.name,
      onChange: (e) => setFormData({ ...formData, name: e.target.value }),
      placeholder: "",
      type: "text",
      className: "mb-9",
      name: "name",
      required: true,
      paraClassName: "font-bold mb-4",
    },
    {
      title: "Password",
      value: formData.password,
      onChange: (e) => setFormData({ ...formData, password: e.target.value }),
      placeholder: "",
      type: "password",
      className: "mb-9",
      name: "password",
      required: true,
      paraClassName: "font-bold mb-4",
    },
    {
      title: "Password Confirmation",
      value: formData.confirmpassword,
      onChange: (e) =>
        setFormData({ ...formData, confirmpassword: e.target.value }),
      placeholder: "",
      type: "password",
      className: "mb-9",
      name: "confirmpassword",
      required: true,
      paraClassName: "font-bold mb-4",
    },
  ];

  return (
    <AuthWrapper>
      <h1 className="mb-12 text-36 font-bold text-dark dark:text-grey-mode text-center md:text-left">
        Register&nbsp;
        <p className="mt-6 inline-block md:block">your Account</p>
      </h1>
      <form onSubmit={handleCreateUser}>
        {role_id == 2 && (
          <GernalInput
            title="Company Name"
            value={formData.company}
            onChange={(e) =>
              setFormData({
                ...formData,
                company: e.target.value,
              })
            }
            placeholder=""
            type="text"
            name="company"
            required={true}
            className="mb-9"
            paraClassName='font-bold mb-4'
          />
        )}
        {inputFields.map((input, index) => (
          <GernalInput
            key={index}
            title={input.title}
            value={input.value}
            onChange={input.onChange}
            placeholder={input.placeholder}
            type={input.type}
            className={input.className}
            name={input.name}
            required={input.required}
            paraClassName={input.paraClassName}
          />
        ))}
        <Button loading={loading} type="submit" className="w-full">
          Register
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default RegisterUser;

import axiosClient from "..";
const Api = "/api/configurations/sale_commisions"
const productComissionApi = "/api/configurations/sale_product_commisions"
class ConfigurationAPI {
    static CreateCommission(data) {
        return axiosClient.post(`${Api}.json`, data);
    }
    static updateCommission(id, data) {
        return axiosClient.patch(`${Api}/${id}.json`, data);
    }
    static updateProductCommission(id, data) {
        return axiosClient.patch(`${productComissionApi}/${id}.json`, data);
    }
    static DeleteInvoiceCommission(id) {
        return axiosClient.delete(`${Api}/${id}.json`);
    }
    static DeleteProductCommission(id) {
        return axiosClient.delete(`${productComissionApi}/${id}.json`);
    }
    static viewCommission(data) {
        return axiosClient.get(`${Api}/${data.id}.json`);
    }
    static viewProductCommission(data) {
        return axiosClient.get(`${productComissionApi}/${data.id}.json`);
    }
    static allCommission() {
        return axiosClient.get(`${Api}.json`)
    }
    static allProductComissionApi(page,perPage) {
        return axiosClient.get(`${productComissionApi}.json?page=${page}&per_page_count=${perPage}`)
    }
    static productComission(data) {
        return axiosClient.post("/api/configurations/sale_product_commisions/bulk-add.json", data)
    }
    static createCard(data) {
        return axiosClient.post(`/api/configurations/cards.json`,data)
    }
    static cardUpdate(id,data) {
        return axiosClient.put(`/api/configurations/cards/${id}.json`,data)
    }
    static showAllCards() {
        return axiosClient.get(`/api/configurations/cards.json`)
    }
    static cardDetail(id) {
        return axiosClient.get(`/api/configurations/cards/${id}.json`)
    }
    static cardDelete(id) {
        return axiosClient.delete(`/api/configurations/cards/${id}.json`)
    }
}
export default ConfigurationAPI;
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import React from "react";
import OverView from "./OverView";
import Statistics from "./Statistics";
import FormatDate from "Components/Common/FormatDate";

const index = () => {
  let currentDate = new Date();
  let endDate = currentDate;
  let startDate = new Date(currentDate);
  startDate.setFullYear(currentDate.getFullYear() - 1);

  return (
    <PagesWrapper>
      <h3 className="pages-title"><span className="relative z-1">POS Dashboard</span></h3>
      <div className="pb-2"></div>
      <OverView
        date={{
          currentDate: FormatDate(currentDate),
          startDate: FormatDate(startDate),
          endDate: FormatDate(endDate),
        }}
      />
      <Statistics
        date={{
          startDate: FormatDate(startDate),
          endDate: FormatDate(endDate),
        }}
      />
    </PagesWrapper>
  );
};

export default index;

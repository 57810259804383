import axiosClient from "..";
const Api = "/api/hrm/company/asset_types";
const ApiAssets = "/api/hrm/company/assets";
class AssetApi {
  static createAssetType(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allAssetType() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewAssetTypeApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateAssetTypeApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteAssetType(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
  static createCompanyAsset(data) {
    return axiosClient.post(`${ApiAssets}.json`, data);
  }
  static allCompanyAsset() {
    return axiosClient.get(`${ApiAssets}.json`);
  }
  static viewCompanyAssetApi(data) {
    return axiosClient.get(`${ApiAssets}/${data.id}.json`);
  }
  static updateCompanyAssetApi(id, data) {
    return axiosClient.put(`${ApiAssets}/${id}.json`, data);
  }
  static deleteCompanyAsset(id) {
    return axiosClient.delete(`${ApiAssets}/${id}.json`);
  }
}
export default AssetApi;

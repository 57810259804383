import axiosClient from "..";
const Api ="/api/admin/vendors"
class VendorAPI {
  static allVendorAPI(page,filterPerpage,filter,idPredicate,filterId,namePredicate,filterName,phoneNumberPredicate,filterPhoneNumber,companyPredicate,filterCompany) {
    return axiosClient.get(`${Api}.json?page=${page}&per_page_count=${filterPerpage} /${filter && `&q[id_${idPredicate}]=${filterId}&q[name_${namePredicate}]=${filterName}&q[phone_no_${phoneNumberPredicate}]=${filterPhoneNumber}&q[company_name_${companyPredicate}]=${filterCompany}`}`);
  }
  static viewVendorAPI(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static createVendorAPI(data) {
    return axiosClient.post(`${Api}.json`,data);
  }
  

}
export default VendorAPI;
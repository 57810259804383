import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AssetApi from "api/hrmapi/assests";
import FormatDate from "Components/Common/FormatDate";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
function CreateCompanyAssets({
  contentOnly = false,
  setPopupId,
  setFetchAssests,
}) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-company-assets`);
    }, 2000);
  };
  const [name, setName] = useState("");
  const [purchaseCost, setPurchaseCost] = useState("");
  const [description, setDescription] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [status, setStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [warrantyExpiryDate, setWarrantyExpiryDate] = useState(null);
  const [assetId, setAssetId] = useState("");
  const [allAssets, setAllAssets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null)
  const handleCompanyAssets = (e) => {
    e.preventDefault();
    if (!selectedStatus) {
      toast.error("Please select a status.");
      return;
    }
    setLoading(true);
    AssetApi.createCompanyAsset({
      asset: {
        name: name,
        company_id: company_id,
        asset_type_id: parseInt(assetId),
        description: description,
        purchase_date: purchaseDate ? FormatDate(purchaseDate) : "",
        status: parseInt(statusId),
        warranty_expiry_date: warrantyExpiryDate
          ? FormatDate(warrantyExpiryDate)
          : "",
        serial_number: serialNumber,
        purchase_cost: purchaseCost,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        handleNavgetAll();
        toast.success(`${Message}`);
        if (contentOnly) {
          setPopupId("");
          setFetchAssests(true);
        }
        setName("");
        setPurchaseCost("");
        setDescription("");
        setSerialNumber("");
        setStatus("");
        setStatusId("");
        setWarrantyExpiryDate("");
        setAssetId("");
        setPurchaseDate("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };
  useEffect(() => {
    AssetApi.allAssetType()
      .then((res) => {
        const formattedAssetType = res.data.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));
        setAllAssets(formattedAssetType);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const handleStatusChange = (obj) => {
    setStatus(obj.label);
    setStatusId(obj.value);
    setSelectedStatus(obj.value)
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Company Assets</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCompanyAssets}>
            <div className="w-full flex flex-wrap justify-between p-4">
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Purchase Cost</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="number"
                    value={purchaseCost}
                    onChange={(e) => setPurchaseCost(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Serial Number</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Status</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    value={{ value: statusId || "", label: status }}
                    required
                    onChange={handleStatusChange}
                    options={[
                      { value: "", label: "Please Select" },
                      { value: "0", label: "Available" },
                      { value: "1", label: "Assigned" },
                      { value: "2", label: "In Use" },
                      { value: "3", label: "In Maintenance" },
                      { value: "4", label: "Disposed" },
                    ]}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[30%] ">
                <div className="flex w-full pt-5 pb-5">
                  <div>Asset Type</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    required
                    options={allAssets}
                    value={allAssets.find((obj) => obj.value === assetId) || ""}
                    onChange={(obj) => setAssetId(obj ? obj.value : "")}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Warranty Expiry Date</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <DatePicker
                    required
                    className="form-control datePickerWidth"
                    dateFormat="dd/MM/yyyy"
                    selected={warrantyExpiryDate}
                    onKeyDown={(e) => e.preventDefault()}
                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    onChange={(date) => setWarrantyExpiryDate(date)}
                  />
                </div>
              </div>
              <div className="w-[30%] ">
                <div className="flex w-full pt-5 pb-5 ">
                  <div>Purchase Date</div>
                  <div className="text-red">*</div>
                </div>
                <div className="w-full">
                  <DatePicker
                    required
                    className="form-control w-[90%] datePickerWidth"
                    dateFormat="dd/MM/yyyy"
                    selected={purchaseDate}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    onKeyDown={(e) => e.preventDefault()}
                    disabledKeyboardNavigation
                    onChange={(date) => setPurchaseDate(date)}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-4 h-auto p-2"
                    value={description}
                    rows={3}
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[30%]"></div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateCompanyAssets;

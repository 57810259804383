import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PosSidebar from "Components/Common/PosSidebar";
import ProductsAPI from "api/posapi/product";
import CategoriesAPI from "api/posapi/categories";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "Components/Common/Button";

function UpdateProduct() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [formData, setFormData] = useState({
    productName: "",
    selectedCategory: null,
    sku: "",
    barcode: "",
    barcode_error: false,
  });

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/products");
  };

  const error_messages = {
    barcode_error: "Number of characters should be equal to 13!",
  };
  const { productName, selectedCategory, sku, barcode, barcode_error } =
    formData;

  useEffect(() => {
    setLoading(true);
    ProductsAPI.viewProductAPI({ id })
      .then((response) => {
        const initialCategory = response.data.list.category;
        setFormData((prevFormData) => ({
          ...prevFormData,
          productName: response.data.list.name,
          sku: response.data.list.sku,
          barcode: response.data.list.barcode,
          selectedCategory: initialCategory,
        }));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    CategoriesAPI.allCategoriesAPI()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleCategoryChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedCategory: event.target.value,
    }));
  };

  const handleUpdate = (e, action) => {
    e.preventDefault();
    if (action === "updateProduct") {
      setLoading(true);
      const updatedProductData = {
        name: productName,
        category_id: parseInt(selectedCategory),
        sku: sku,
        barcode: barcode,
      };
      ProductsAPI.updateProductAPI(id, updatedProductData)
        .then((res) => {
          const Message = res ? res.data.message : "An error occurred";
          toast.success(`Success:${Message}`);
          setTimeout(() => {
            handleBack();
          }, 1500);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";
          toast.error(`Error: ${errorMessage}`);
          toast.error(error.response.data.error);
        });
    } else if (action === "updateImage") {
      setLoadingImage(true);
      const formData = new FormData();
      formData.set("product[avatar]", image);
      ProductsAPI.uploadProductImgAPI(id, formData)
        .then((res) => {
          const Message = res ? res.data.message : "An error occurred";
          toast.success(`Success:${Message}`);
          setLoadingImage(false);
        })
        .catch((error) => {
          setLoadingImage(false);
          toast.error(error?.response.data.errors);
          const errorMessage = error.response?.data.message
            ? error.response.data.message
            : "An error occurred";
          toast.error(`Error: ${errorMessage}`);
        });
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    const remainingCharacters = 13 - value.length;
    setFormData({
      ...formData,
      barcode: value,
      barcode_error: value.length !== 13,
      error:
        remainingCharacters >= 1
          ? `Remaining characters: ${remainingCharacters}`
          : "",
    });
  };
  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Update Product
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="flex border-t-2 border-blue bg-white rounded-md justify-center py-4 px-2">
            <form>
              <div className="grid grid-cols-4">
                <div className="mr-3">
                  <div className="mb-2">Product Name</div>
                  <input
                    type="text"
                    className="form-control"
                    value={productName}
                    onChange={(e) =>
                      setFormData({ ...formData, productName: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mr-3">
                  <div className="mb-2">Category</div>
                  <select
                    className="form-control"
                    required
                    onChange={handleCategoryChange}
                  >
                    <option value={formData.selectedCategory}>
                      {formData.selectedCategory}
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mr-3">
                  <div className="mb-2">SKU</div>
                  <input
                    title="SKU"
                    value={sku}
                    onChange={(e) =>
                      setFormData({ ...formData, sku: e.target.value })
                    }
                    required
                    name="sku"
                    className="form-control"
                  />
                </div>
                <div className="mr-3">
                  <div className="mb-2">Barcode</div>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={barcode}
                    onChange={handleInputChange}
                  />
                  {barcode_error && (
                    <p className="text-danger">
                      {error_messages.barcode_error}
                    </p>
                  )}
                </div>
                <div className="mr-3 mt-3">
                  <div className="mb-2">Product Image</div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="w-full flex justify-end">
                <div className="w-[15%]">
                  <button
                    type="submit"
                    className=" btn-primary text-center w-[90%]"
                    disabled={loading}
                    onClick={(e) => handleUpdate(e, "updateImage")}
                  >
                    {loadingImage ? "Uploading Image..." : "Upload Image"}
                  </button>
                </div>
                <div className="w-[15%]">
                  <button
                    type="submit"
                    className=" btn-primary text-center w-[90%]"
                    disabled={loading}
                    onClick={(e) => handleUpdate(e, "updateProduct")}
                  >
                    {loading ? "Updating..." : "Update Product"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default UpdateProduct;

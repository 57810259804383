import React, { useState, useEffect } from "react";
import PosSidebar from "Components/Common/PosSidebar";
import Button from "Components/Common/Button";
import TaxAPI from "api/posapi/tax";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";

function CreateTax({ contentOnly = false, setPopupId, fetchTaxes }) {
  const [tax, setTax] = useState("");
  const [taxes, setTaxes] = useState([]);
  const [percentage, setPercentage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tax === "") {
      toast.error("Please select a tax");
      return;
    }
    TaxAPI.createTaxAPI({
      tax_configuration: {
        tax_type_id: tax,
        percentage: percentage,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      fetchTaxes()
      setPopupId("")
      toast.error(res.data.error);
    })
    .catch((error) => {
      toast.error(error.data.error);
    })
  };

  const onChangeInput = (e) => {
    let value = e.target.value;
    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      value = value.slice(0, dotIndex + 3);
    }
    if (value >= 0 && value <= 100) {
      setPercentage(value);
    } else {
      toast.error(`Error: Please enter a correct value between 0 and 100!`, {autoClose: 1500});
    }
  };

  useEffect(() => {
    TaxAPI.getTaxtypeAPI()
      .then((res) => {
        setTaxes(res.data.tax_types);
      })
      .catch((error) => {
        console.error("Error fetching tax:", error);
      });
  }, []);
  const onTaxTypeChange = (e) => {
    setTax(e.target.value);
  };

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />} contentOnly={contentOnly}>
        <PagesWrapper>
          <div className="py-4">
            <h1 className="text-24 mb-2 w-full text-center"> Create VAT</h1>
          </div>
          <div className="w-full justify-center px-4">
            <form onSubmit={handleSubmit}>
                <div className="w-full mr-4">
                  <label>Percentage</label>
                  <input
                    className="form-control mt-2 hideNumberArrow"
                    value={percentage}
                    onChange={onChangeInput}
                    placeholder="Percentage"
                    type="number"
                    required
                  />
                </div>
                <div className="w-full mt-4">
                  <label>VAT Type</label>
                  <select
                    className="form-control mt-2"
                    value={tax}
                    onChange={onTaxTypeChange}
                    required
                  >
                    <option value="">Select VAT</option>
                    {taxes.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              <div className="text-center mt-4">
                <Button className="w-[40%] btn btn-primary" type="submit">
                  Create VAT
                </Button>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CreateTax;

import React from "react";
import Sidebar from "./Sidebar";

function PosSidebar() {
  const menuItemsPOS = [
    {
      key: "maindashboard",
      text: "Main Dashboard",
      src: "/icons/dashboard.svg",
      path: "/",
      allowedRoles: [2, 3],
    },
    {
      key: "dashboardpos",
      text: "Dashboard",
      src: "/icons/dashboard.svg",
      path: "/pos-dashboard",
      allowedRoles: [2, 3],
    },
    {
      key: "dashboard",
      text: "Dashboard",
      src: "/icons/dashboard.svg",
      path: "/",
      allowedRoles: [1, 4],
    },
    {
      key: "products",
      text: "Products",
      src: "/icons/products.svg",
      path: "/products",
      allowedRoles: [2, 3],
    },
    {
      key: "purchase",
      text: "Purchase",
      src: "/icons/purchaseIcon.svg",
      path: "/all-purchase",
      allowedRoles: [2, 3],
    },
    {
      key: "discount",
      text: "Discounts",
      src: "/icons/discount.svg",
      dropdown: true,
      subMenuItems: [
        {
          key: "createDiscount",
          text: "Create Discount",
          path: "/create-discount",
          src: "/icons/createDiscount.svg",
        },
        {
          key: "allDiscounts",
          text: "All Discounts",
          path: "/discounts",
          src: "/icons/allDiscount.svg",
        },
      ],
      allowedRoles: [2, 3],
    },
    {
      key: "sales",
      text: "Sales",
      dropdown: true,
      src: "/icons/sales.svg",
      subMenuItems: [
        {
          key: "createSales",
          text: "Create Sales",
          path: "/sale-pages",
          src: "/icons/createSales.svg",
        },
        {
          key: "allSales",
          text: "All Sales",
          path: "/sales",
          src: "/icons/allSales.svg",
        },
      ],
      allowedRoles: [2, 3],
    },
    {
      key: "customer",
      text: "Customers",
      src:"/icons/allCustomers.svg",
      path: "/pos-customers",
      allowedRoles: [2,3],
    },
    {
      key: "Vendor",
      text: "Vendors",
      src: "/icons/supplier.svg",
      path: "/vendors",
      allowedRoles: [2, 3],
    },
    {
      key: "Taxe",
      text: "VAT",
      src: "/icons/tax.svg",
      path: "/all-taxes",
      allowedRoles: [2, 3],
    },
    {
      key: "Salemancommission",
      text: "Saleman Commission",
      path: "/allpos-salecommission",
      src: "/icons/salesman.svg",
      allowedRoles: [2, 3],
    },
    {
      key: "Cashiersales",
      text: "Sales",
      dropdown: true,
      subMenuItems: [
        { key: "createSales", text: "Create Sales", path: "/sale-pages" },
        { key: "allSales", text: "All Sales", path: "/sales" },
      ],
      allowedRoles: [4],
    },
    {
      key: "cashircommission",
      text: "Commission",
      path: "/allpos-salecommission",
      allowedRoles: [4],
    },
    {
      key: "circulars",
      text: "Circulars",
      path: "/circulars",
      src: "/icons/dashboard.svg",
      allowedRoles: [2],
    },
  ];

  return (
    <>
      <Sidebar menuItems={menuItemsPOS} />
    </>
  );
}
export default PosSidebar;

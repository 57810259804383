import axiosClient from "..";
const Api = "/api/hrm/employees/employees";
class EmployeesApi {
  static createEmployees(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static invitationsEmployees() {
    return axiosClient.get(`/api/hrm/employees/invitations.json`);
  }
  static resendInviteEmployees(data) {
    return axiosClient.post(`/api/hrm/employees/resend_invite.json`, data);
  }
  static createEmployeesInfo(employee_id,data) {
    return axiosClient.post(`/api/hrm/employees/employee_infos?employee_id=${employee_id}`, data);
  }
  static updatePersonalInfo(employee_id,data) {
    return axiosClient.put(`/api/hrm/employees/employee_infos/${employee_id}`, data);
  }
  static updateEmployemantInfo(employee_id,data) {
    return axiosClient.put(`${Api}/${employee_id}`, data);
  }
  static updateFamilyInfo(id,employee_id,data) {
    return axiosClient.put(`/api/hrm/employees/employee_family_infos/${id}?employee_id=${employee_id}`, data);
  }
  static updateEmployeeAssets(id,employee_id,data) {
    return axiosClient.put(`/api/hrm/employees/employee_assets/${id}?employee_id=${employee_id}`, data);
  }
  static updateTravelInfo(employee_id,data) {
    return axiosClient.put(`/api/hrm/employees/employee_travel_infos/${employee_id}`, data);
  }
  static updateDocumentInfo(id, employee_id, data) {
    return axiosClient.put(
      `/api/hrm/employees/employee_documents/${id}?employee_id=${employee_id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
    );
  }
  
  static getDocumentInfo(employee_id) {
    return axiosClient.get(`/api/hrm/employees/employee_documents?employee_id=${employee_id}`);
  }
  static createFamilyInfos(data) {
    return axiosClient.post(`/api/hrm/employees/employee_family_infos.json`, data);
  }
  static createTravelInfos(employee_id,data) {
    return axiosClient.post(`/api/hrm/employees/employee_travel_infos.json?employee_id=${employee_id}`, data);
  }
  static createEmployeeDocuments(employee_id,data) {
    return axiosClient.post(`/api/hrm/employees/employee_documents?employee_id=${employee_id}`, data);
  }
  static createEmployeeAssets(data) {
    return axiosClient.post(`/api/hrm/employees/employee_assets`, data);
  }
  static allEmployeesApi() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewEmployeesApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateEmployeesApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteEmployees(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default EmployeesApi;

import axiosClient from "..";
const Api ="/api/configurations/taxes"
class TaxAPI {
  static allTaxAPI() {
    return axiosClient.get(`${Api}.json`)
  }
  static showTaxAPI(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateTaxAPI(id) {
    return axiosClient.patch(`${Api}/${id}.json`);
  }
  static DeleteTax(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
  static createTaxAPI(data) {
    return axiosClient.post("/api/configurations/taxes.json",data);
  }
  static getTaxtypeAPI() {
    return axiosClient.get("/api/configurations/tax_types.json",);
  }
}
export default TaxAPI;

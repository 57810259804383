import axiosClient from "..";
const Api = "/api/hrm/settings/work_weeks";
class WorkWeekApi {
  static creatWorkWeekApi(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allWorkWeekApi() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewWorkWeekApi(id) {
    return axiosClient.get(`${Api}/${id}.json`);
  }
  static updateWorkWeekApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteWorkWeekApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default WorkWeekApi;

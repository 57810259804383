import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import PosDashboard from "api/dashboardApi/posDashboard";

const Statistics = ({ date }) => {
  const { startDate, endDate } = date;
  const [items, setItems] = useState({
    labels: [],
  });
  const [topSelling, setTopSelling] = useState({
    labels: [],
    legend: {
      position: "bottom",
    },
  });

  const [series, setSeries] = useState([]);
  const [salesStats, setSalesStats] = useState([
    {
      data: [],
    },
  ]);

  useEffect(() => {
    PosDashboard.yearlySales(startDate, endDate)
      .then((res) => {
        let group_column = res.data.map((item) => item.group_column);
        let sales = res.data.map((item) => item.sales);
        setItems({ labels: group_column });
        setSalesStats([{ data: sales }]);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    PosDashboard.topSellingProducts(startDate, endDate)
      .then((res) => {
        let names = res.data.map((item) => item.name);
        let annual_quantities = res.data.map((item) =>
          parseFloat(item.annual_quantities)
        );

        setTopSelling({
          labels: names,
          legend: {
            position: "bottom",
          },
        });
        setSeries(annual_quantities);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="grid grid-cols-12 gap-x-6">
      <div className="p-6 bg-white rounded-2xl mb-6 md:col-span-7 col-span-12">
        <p className="text-16 font-semibold">Sales Statistics</p>
        <Chart options={items} series={salesStats} type="line" className="w-full" />
      </div>
      <div className="p-6 bg-white rounded-2xl mb-6 md:col-span-5 col-span-12">
        <p className="text-16 font-semibold">Top Selling Items</p>
        <Chart options={topSelling} series={series} type="donut" className="w-full" />
      </div>
    </div>
  );
};

export default Statistics;

import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateDeduction({ contentOnly = false, setPopupId }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [referenceNo, setReferenceNo] = useState("");
  const [payrollID, setPayrollID] = useState("");
  const [currency, setCurrency] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [deductionType, setDeductionType] = useState("");
  const [deductionId, setDeductionId] = useState("");
  const [allDeductionType, setAllDeductionType] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  const handleCreateVariablePay = (e) => {
    e.preventDefault();
    setLoading(true);
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-center text-center items-center pt-4">
          <div className="text-24">Deduction</div>
        </div>
        <div className="bg-white rounded-3xl w-full  min-h-[60vh]">
          <form onSubmit={handleCreateVariablePay}>
            <div className="w-full flex flex-wrap gap-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Reference No</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={referenceNo}
                    onChange={(e) => setReferenceNo(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Type of Deduction</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <Select
                    value={{ value: deductionId, label: deductionType }}
                    required
                    onChange={(obj) => {
                      setDeductionType(obj.label);
                      setDeductionId(obj.value);
                    }}
                    options={allDeductionType}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Payroll ID</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
               
                    className="form-control"
                    type="text"
                    name="Amount"
                    value={payrollID}
                    onChange={(e) => setPayrollID(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Amount</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Currency</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <Select
                    value={{ value: currency, label: currency }}
                   
                    menuPosition="fixed"
                    onChange={(obj) => setCurrency(obj.label)}
                    options={allCurrency}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Notes/Reason</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className="w-[20%] p-4">
                <button class="w-[104px] h-[45px] top-[948px] left-[779px] rounded-[10px] border-[1.5px] border-blue py-[14px] px-[28px] gap-[15px] bg-blue-500 text-blue">
                  Cancel
                </button>
              </div>
              <div className={`${contentOnly ? "w-[20%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateDeduction;

import axiosClient from "..";
const Api ="/api/admin/pos/categories"
class CategoriesAPI {
  static allCategoriesAPI() {
    return axiosClient.get(`${Api}.json`);
  }
  static getCategorieAPI(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static filterCategorieAPI(filter,categoryPredicate,filtercategoryname,startDate,endDate) {
    return axiosClient.get(`${Api}.json?/${filter && `&q[name_${categoryPredicate}]=${filtercategoryname}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`}`);
  }
  static createCategoriesAPI(data) {
    return axiosClient.post(`${Api}.json`,data);
  }

}
export default CategoriesAPI;

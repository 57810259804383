import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  subtotal: 0,
  totalItems: 0,
  totalTax: 0,
  totalDiscount: 0,
  couponDiscount: 0,
  totalPrice: 0,
  saleid:null,
  saleIdEmail: null,
  sessionId:null,
  isOpen: true,
  isClose: false,
  returnSaleId: null,
  customer:{
    customerId:"",
    customerName:"",
    customerPhone:"",
    customerAddress:"",
    customerEmail:""
  }
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      state.cart = [...state.cart, action.payload];
    },
    CouponCart: (state, action) => {
      state.cart = action.payload;
    },
    updateTotals: (state, action) => {
      const {
        subtotal,
        totalItems,
        totalTax,
        totalDiscount,
        totalPrice,
        couponDiscount,
      } = action.payload;
      state.subtotal = subtotal;
      state.totalItems = totalItems;
      state.totalTax = totalTax;
      state.totalDiscount = totalDiscount;
      state.totalPrice = totalPrice;
      state.couponDiscount = couponDiscount;
    },
    removeItemFromCart: (state, action) => {
      state.cart = state.cart.filter(
        (product) => product.product_id !== action.payload.product_id
      );
    },
    updateItemQuantity: (state, action) => {
        const { product_id, qty,total_tax_amount,discount,amount,input_field_value } = action.payload;
        state.cart = state.cart.map((product) =>
          product.product_id === product_id
            ? { ...product, qty: qty, input_field_value:input_field_value, total_tax_amount:total_tax_amount,discount:discount, amount:amount }
            : product
        );
      },
      saleId:(state,action)=>{
      state.saleid= action.payload
      },
      SaleIdEmail:(state,action)=>{
        state.saleIdEmail= action.payload
        },
      SessionId:(state,action)=>{
        state.sessionId= action.payload
        },
      IsOpen:(state,action)=>{
        state.isOpen= action.payload
        },
      IsClose:(state,action)=>{
        state.isClose= action.payload
        },
        ReturnSaleId:(state,action)=>{
          state.returnSaleId= action.payload
          },
      customerData:(state,action)=>{
      state.customer.customerId= action.payload.customerId
      state.customer.customerName= action.payload.customerName
      state.customer.customerPhone= action.payload.customerPhone
      state.customer.customerAddress= action.payload.customerAddress
      state.customer.customerEmail= action.payload.customerEmail
      },
    clearCart: (state) => {
      state.cart = [];
      state.subtotal = 0;
      state.totalItems = 0;
      state.totalTax = 0;
      state.totalDiscount = 0;
      state.totalPrice = 0;
      state.couponDiscount = 0;
      state.saleid = null;
      state.customer.customerId = "";
      state.customer.customerName = "";
      state.customer.customerPhone = "";
      state.customer.customerAddress = "";
    },
  },
});

export const { 
    addItemToCart, 
    clearCart, 
    saleId,
    SaleIdEmail,
    SessionId,
    IsOpen,
    IsClose,
    ReturnSaleId,
    customerData,
    updateTotals,
    removeItemFromCart,
    updateItemQuantity,
    incrementItemOfCart,
    decrementItemOfCart, 
    CouponCart,
} = salesSlice.actions;

export default salesSlice.reducer;

import axiosClient from "..";
const API = "/api/admin/pos/discounts";
const accessCodeAPI = "/api/admin/pos/discount_access_codes";
class DiscountAPI {
  static allDiscountAPI(apiURL) {
    return axiosClient.get(`${API}.json?${apiURL}`);
  }
  static DiscountTypeAPI() {
    return axiosClient.get(`${API}_types.json`);
  }
  static getDiscountAPI(data) {
    return axiosClient.get(`${API}/${data.id}.json`);
  }
  static getOffersDiscountAPI() {
    return axiosClient.get(`/api/admin/pos/discount_offers.json`);
  }
  static offersDiscountApplyAPI(params) {
    return axiosClient.get(`/api/admin/pos/discount_offers/apply.json`, {
      params,
    });
  }
  static DeleteDiscount(id) {
    return axiosClient.delete(`${API}/${id}.json`);
  }
  static toggleDiscount(id, status) {
    const params = { active: status };
    return axiosClient.get(`${API}/${id}/toggle.json`, { params });
  }
  static accessCodeDiscountAPI(id) {
    return axiosClient.get(`${accessCodeAPI}/${id}.json`);
  }
  static allCouposAPI() {
    return axiosClient.get(`/api/admin/pos/discount_coupons.json`);
  }
  static createDiscountAPI(data) {
    return axiosClient.post(`${API}.json`, data);
  }
  static productsAvailable() {
    return axiosClient.get(`/api/admin/pos/products/available_for_sale.json`);
  }
  static categorysAvailable() {
    return axiosClient.get(`/api/admin/pos/categories/available_for_sale.json`);
  }
  static discountProducts(id) {
    return axiosClient.get(`/api/admin/pos/discount_products/${id}.json`);
  }
  static discountCategory(id) {
    return axiosClient.get(`/api/admin/pos/discount_categories/${id}.json`);
  }
  static discountCoupenSale(id) {
    return axiosClient.get(`/api/admin/pos/discount_coupons/sale/${id}.json`);
  }
  static discountCoupenProduct(id) {
    return axiosClient.get(
      `/api/admin/pos/discount_coupons/product/${id}.json`
    );
  }
  static discountCoupenCategory(id) {
    return axiosClient.get(
      `/api/admin/pos/discount_coupons/category/${id}.json`
    );
  }
  static discountOffers(id) {
    return axiosClient.get(`/api/admin/pos/discount_offers/${id}.json`);
  }
  static isApplicable(accessCode) {
    return axiosClient.get(
      `/api/admin/pos/discount_access_codes/is_applicable?access_code=${accessCode}`
    );
  }
  static applyAccessCode(data) {
    return axiosClient.patch(
      `/api/admin/pos/discount_access_codes/apply.json`,
      data
    );
  }
}
export default DiscountAPI;

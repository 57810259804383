import React from 'react'
const CircularViews = ({ url }) => {
    return (
        <div className='min-h-[80vh]'>
            <iframe className='w-full min-h-[80vh]' src={url} title="Circular">
            </iframe>
        </div>
    )
}

export default CircularViews
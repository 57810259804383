import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Button from "Components/Common/Button";
import LocationsAPI from "api/location/location";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const UpdateLocation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleNavgetAllLocation = () => {
    setTimeout(() => {
      navigate(`/all-locations`);
    }, 2000);
  };
  const handleErrorMessage = (error) => {
    const errorMessage = error.response?.data.message
      ? error.response.data.message
      : "An error occurred";

    toast.error(`Error: ${errorMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const [UpdateLocation, setUpdateLocation] = useState({
    type: "",
    address: "",
    phone: "",
    email: "",
    zip: "",
    city: "",
    currency: "",
    country: "",
  });

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateLocation({
      ...UpdateLocation,
      [name]: value,
    });
  };

  useEffect(() => {
    LocationsAPI.viewLocationsAPI({ id })
      .then((response) => {
        setUpdateLocation({
          type: response.data.location[0].loc_type,
          address: response.data.location[0].address,
          phone: response.data.location[0].phone,
          zip: response.data.location[0].zip,
          email: response.data.location[0].email,
          currency: response.data.location[0].currency,
          city: response.data.location[0].city,
          country: response.data.location[0].country,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleLocationUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    LocationsAPI.updateLocation(id, {
      location: {
        type: UpdateLocation.type,
        address: UpdateLocation.address,
        phone: UpdateLocation.phone,
        email: UpdateLocation.email,
        zip: UpdateLocation.zip,
        country: UpdateLocation.country,
        city: UpdateLocation.city,
        currency: UpdateLocation.currency,
      },
    })
      .then((response) => {
        handleNavgetAllLocation();
        handleSuccessMessage(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleErrorMessage(error);
      });
  };

  const handleBack = () => {
    navigate("/all-locations");
  };

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Update Location
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="w-full bg-white rounded-md">
            <form onSubmit={handleLocationUpdate}>
              <div className="flex flex-wrap mx-2">
                <div className="w-1/3 p-4">
                  <div className="mb-2">Type</div>
                  <select
                    className="form-control"
                    name="type"
                    value={UpdateLocation.type}
                    // onChange={inputOnChange}
                    required
                  >
                    <option value="shop">Shop </option>
                    <option value="warehouse">WareHouse </option>
                    <option value="factory">Factory </option>
                  </select>
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Address</div>
                  <input
                    className="form-control"
                    name="address"
                    value={UpdateLocation.address}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Phone No:</div>
                  <input
                    className="form-control hideNumberArrow"
                    name="phone"
                    type="number"
                    value={UpdateLocation.phone}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Zip Code:</div>
                  <input
                    className="form-control"
                    name="zip"
                    value={UpdateLocation.zip}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Country</div>
                  <input
                    className="form-control"
                    name="country"
                    type="text"
                    value={UpdateLocation.country}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">City</div>
                  <input
                    className="form-control"
                    name="city"
                    type="text"
                    value={UpdateLocation.city}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Currency:</div>
                  <input
                    className="form-control"
                    name="currency"
                    type="text"
                    value={UpdateLocation.currency}
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Email:</div>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={UpdateLocation.email}
                    placeholder="Email"
                    onChange={inputOnChange}
                    required
                  />
                </div>
                <div className="flex w-full justify-end pb-5 pr-3">
                  <button
                    className={
                      loading
                        ? "w-[14%] btn btn-primary btn-progress"
                        : "w-[14%] btn btn-primary"
                    }
                    type="submit"
                  >
                    Update Location
                  </button>
                </div>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
};
export default UpdateLocation;

import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Offices from "./Offices";
import Logo from "./Logo";
import CreateWorkWeek from "./CreateWorkWeek";
import AllWorkWeek from "./AllWorkWeek";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import AllCompanyDocumentTypes from "./AllCompanyDocumentTypes";
import CreateCompanyDocumentsTypes from "./CreateCompanyDocumentsTypes";
import AllOffices from "./AllOffices";
import CreateEmployeeDocType from "./CreateEmployeeDocType";
import AllEmployeeDocType from "./AllEmployeeDocType";
import CreateHolidays from "./CreateHolidays";
import AllHoliDays from "./AllHoliDays";

function CompanySettings() {
  const [activeTab, setActiveTab] = useState(null);
  const toggleTab = (tabName) => {
    setActiveTab(tabName === activeTab ? null : tabName);
  };
  const [popupId, setPopupId] = useState("");
  const [fetchWorkWeek, setFetchWorkWeek] = useState(false);
  const [fetchOffice, setFetchOffice] = useState(false);
  const [fetchHolidays, setFetchHolidays] = useState(false);
  const [fetchCompanyDocumentTypes, setFetchCompanyDocumentTypes] =
    useState(false);
  const [fetchEmployeeDocumentTypes, setFetchEmployeeDocumentTypes] =
    useState(false);
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Company setting</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "companyLogo" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("companyLogo")}
              >
                <div>
                  <div className="text-22 mb-2">Company Logo</div>
                  <div>Upload your company logo</div>
                </div>
                <img
                  src={
                    activeTab === "companyLogo"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "companyLogo" && (
                <div className="text-22 mb-2 mt-5">
                  <Logo />
                </div>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "airTicketPolicies" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("airTicketPolicies")}
              >
                <div className="text-22 mb-2">Air Ticket Policies</div>
                <img
                  src={
                    activeTab === "airTicketPolicies"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "airTicketPolicies" && (
                <div className="text-22 mb-2 mt-5">
                  Air Ticket Policies Details
                </div>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "offices" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("offices")}
              >
                <div className="text-22 mb-2">Offices</div>
                <img
                  src={
                    activeTab === "offices"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "offices" && (
                <>
                  <AllOffices
                    fetchOffice={fetchOffice}
                    setFetchOffices={setFetchOffice}
                  />
                  <div className="w-full ">
                    <button
                      className="mb-2 mt-5 btn-primary w-[10%]"
                      onClick={() => setPopupId("addOffice")}
                    >
                      Add New
                    </button>
                  </div>

                  <ShowComponent condition={popupId === "addOffice"}>
                    <Popup setPopup={() => setPopupId("")} className="w-[50%]">
                      <Offices setPopupId={setPopupId} />
                    </Popup>
                  </ShowComponent>
                </>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "departments" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("departments")}
              >
                <div className="text-22 mb-2">Departments</div>
                <img
                  src={
                    activeTab === "departments"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "departments" && (
                <div className="text-22 mb-2 mt-5">Departments Details</div>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "employeeDocumentsTypes" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("employeeDocumentsTypes")}
              >
                <div className="text-22 mb-2">Employee Documents Types</div>
                <img
                  src={
                    activeTab === "employeeDocumentsTypes"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "employeeDocumentsTypes" && (
                <>
                  <AllEmployeeDocType
                    fetchEmployeeDocumentTypes={fetchEmployeeDocumentTypes}
                    setFetchEmployeeDocumentTypes={
                      setFetchEmployeeDocumentTypes
                    }
                  />
                  <div className="w-full ">
                    <button
                      className="mb-2 mt-5 btn-primary w-[10%]"
                      onClick={() => setPopupId("addEmployeeDocuments")}
                    >
                      Add New
                    </button>
                  </div>
                  <ShowComponent condition={popupId === "addEmployeeDocuments"}>
                    <Popup setPopup={() => setPopupId("")} className="w-[50%]">
                      <CreateEmployeeDocType
                        setPopupId={setPopupId}
                        fetch={setFetchEmployeeDocumentTypes}
                      />
                    </Popup>
                  </ShowComponent>
                </>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "companyDocumentsTypes" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("companyDocumentsTypes")}
              >
                <div className="text-22 mb-2">Company Documents Types</div>
                <img
                  src={
                    activeTab === "companyDocumentsTypes"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>
              {activeTab === "companyDocumentsTypes" && (
                <>
                  <AllCompanyDocumentTypes
                    fetchCompanyDocumentTypes={fetchCompanyDocumentTypes}
                    setFetchCompanyDocumentTypes={setFetchCompanyDocumentTypes}
                  />
                  <div className="w-full ">
                    <button
                      className="mb-2 mt-5 btn-primary w-[10%]"
                      onClick={() => setPopupId("companyDocuments")}
                    >
                      Add New
                    </button>
                  </div>
                  <ShowComponent condition={popupId === "companyDocuments"}>
                    <Popup setPopup={() => setPopupId("")} className="w-[50%]">
                      <CreateCompanyDocumentsTypes
                        setFetchCompanyDocumentTypes={
                          setFetchCompanyDocumentTypes
                        }
                        setPopupId={setPopupId}
                      />
                    </Popup>
                  </ShowComponent>
                </>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "Workweek" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("Workweek")}
              >
                <div>
                  <div className="text-22 mb-2"> Workweek</div>
                  <div className="lh-1">
                    Set workweeks for your employees. Non work days are not
                    counted as days of leaves in the calculation of working days
                    for leaves policies.
                  </div>
                </div>

                <img
                  src={
                    activeTab === "Workweek"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>

              {activeTab === "Workweek" && (
                <>
                  <AllWorkWeek
                    fetchWorkWeek={fetchWorkWeek}
                    setFetchWorkWeek={setFetchWorkWeek}
                  />

                  <div className="w-full ">
                    <button
                      className="mb-2 mt-5 btn-primary w-[10%]"
                      onClick={() => setPopupId("addWorkweek")}
                    >
                      Add New
                    </button>
                  </div>
                  <ShowComponent condition={popupId === "addWorkweek"}>
                    <Popup setPopup={() => setPopupId("")} className="w-[50%]">
                      <CreateWorkWeek
                        setPopupId={setPopupId}
                        setFetchWorkWeek={setFetchWorkWeek}
                      />
                    </Popup>
                  </ShowComponent>
                </>
              )}
            </div>
          </div>
          <div className="p-5">
            <div
              className={`w-full border border-gray-300 p-5 cursor-pointer ${
                activeTab === "holiDays" ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="flex justify-between items-center"
                onClick={() => toggleTab("holiDays")}
              >
                <div>
                  <div className="text-22 mb-2"> Holiday Calendars</div>
                  <div className="lh-1">
                    Set public holidays for your employees. Improves the
                    accuracy of leaves requests.
                  </div>
                </div>

                <img
                  src={
                    activeTab === "holiDays"
                      ? "/icons/upicon.jpg"
                      : "/icons/downicon.jpg"
                  }
                  alt=""
                  className="w-10 h-10"
                />
              </div>

              {activeTab === "holiDays" && (
                <>
                  <AllHoliDays
                   fetchHolidays={fetchHolidays}
                   setPopupId={setPopupId}
                   popupId={popupId}
                    setFetchHolidays={setFetchHolidays}
                  />

                  <div className="w-full ">
                    <button
                      className="mb-2 mt-5 btn-primary w-[10%]"
                      onClick={() => setPopupId("addHoliDays")}
                    >
                      Add New
                    </button>
                  </div>
                  <ShowComponent condition={popupId === "addHoliDays"}>
                    <Popup setPopup={() => setPopupId("")} className="w-[50%] ">
                      <CreateHolidays setPopupId={setPopupId} popupId setFetchHolidays={setFetchHolidays} />
                    </Popup>
                  </ShowComponent>
                </>
              )}
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CompanySettings;

import React, { useEffect, useState } from "react";
import Button from "Components/Common/Button";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TransfersAPI from "api/posapi/transfers";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import LocationsAPI from "api/location/location";
function CreateTransfer({ contentOnly = false }) {
  const userId = useSelector((state) => state.auth.currentUser.id);
  const default_loc = useSelector(
    (state) => state.auth?.currentUser.default_loc
  );
  const access_code = useSelector(
    (state) => state.auth?.currentUser.access_code
  );
  const role_id = useSelector(
    (state) => state.auth?.currentUser.role_id
  )
  const CASHIER_ROLE = 4;

  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fromLocation, setFromLocation] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation] = useState("");
  const [tableData, setTableData] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [date, setDate] = useState(new Date());
  const initialData = [];
  const [categoryNames, setCategoryNamesArray] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleTransferHistory = () => {
    setTimeout(() => {
      navigate(`/transfer-history`);
    }, 2000);
  };
  const handleErrorMessage = (error) => {
    const errorMessage = error.response?.data.message
      ? error.response.data.message
      : "An error occurred";

    toast.error(`Error: ${errorMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };
  const handleSendToBackend = (e) => {
    e.preventDefault();
    if (!selectedFromLocation) {
      toast.error("Please Select from Location", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (tableData.length === 0) {
      toast.error("Please add product", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    const productsDetail = tableData.map((item) => ({
      id: item.product_id,
      quantity: item.qty,
    }));
    const transferData = {
      transfer: {
        source_loc: parseInt(selectedFromLocation),
        dest_loc: parseInt(default_loc),
        products_detail: productsDetail,
        date: date,
        access_code:access_code
      },
    };
    TransfersAPI.createTransfersAPI(transferData)
      .then((response) => {
        handleSuccessMessage(response);
        setTableData(initialData);
        setSelectedCategory([]);
        if(role_id !== CASHIER_ROLE) handleTransferHistory();
        setSelectedFromLocation(false);
        setLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.error("Error sending purchase data:", error);
        setLoading(false);
      });
  };
  const handleReset = () => {
    setTableData(initialData);
    setSelectedCategory([]);
    setSelectedFromLocation(false);
  };
  useEffect(() => {
    setLoading(true);
    LocationsAPI.userLocationDetail(userId)
      .then((response) => {
        setFromLocation(response?.data?.locations);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleSelectAll = () => {
    if (selectAll) {
      const updatedProducts = filteredProducts.map((product) => ({
        ...product,
        qty: 1,
      }));
      setTableData(updatedProducts);
      setSelectAll(false);
    } else {
      setTableData([]);
      setSelectAll(true);
    }
  };
  const handleQtyChange = (product, value) => {
    let updatedProduct = {};
    if (!value) {
      updatedProduct = {
        ...product,
        qty: null,
      };
    } else {
      updatedProduct = {
        ...product,
        qty: value,
      };
    }

    setTableData((prevTableData) =>
      prevTableData.map((item) =>
        item.product_id === updatedProduct.product_id ? updatedProduct : item
      )
    );
  };
  const handleOnBlur = (product, value) => {
    let updatedProduct = {};
    if (!value) {
      updatedProduct = {
        ...product,
        qty: 1,
      };
    } else {
      let qty = value;
      if (qty >= 1 && qty <= product.quantity) {
        updatedProduct = {
          ...product,
          qty: parseInt(value),
        };
      } else if (qty < 1) {
        updatedProduct = {
          ...product,
          qty: 1,
        };
      } else if (qty > product.quantity) {
        updatedProduct = {
          ...product,
          qty: product.quantity,
        };
      }
    }
    setTableData((prevTableData) =>
      prevTableData.map((item) =>
        item.product_id === updatedProduct.product_id ? updatedProduct : item
      )
    );
  };
  useEffect(() => {
    TransfersAPI.productInventoriesAPI({ location_id: selectedFromLocation })
      .then((response) => {
        setCategoryNamesArray(response.data.allProducts);
        setProducts(response.data.allProducts);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [selectedFromLocation]);
  const uniqueCategories = Array.from(
    new Set(categoryNames.map((object) => object.category_name))
  );

  const addProductToTable = (product) => {
    const productExistsInTable = tableData.some(
      (item) => item.product_id === product.product_id
    );

    if (!productExistsInTable) {
      const updatedProduct = {
        product_id: product.product_id,
        product_name: product.product_name || product.label,
        qty: 1,
        quantity: product.quantity,
      };

      setTableData((prevSelected) => [...prevSelected, updatedProduct]);
    } else {
      toast.error("Product is already in the table");
    }
  };

  useEffect(() => {
    if (selectedCategory.length > 0) {
      const filteredData = products.filter((item) =>
        selectedCategory.includes(item.category_name)
      );
      setFilteredProducts(filteredData);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedCategory]);

  const onChangeSelectFromLocation = (locationId) => {
    if (selectedCategory.length > 0 && selectedFromLocation) {
      toast.error(`Error: Cannot Change location  `);
    } else {
      setSelectedFromLocation(locationId);
    }
  };

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
        <PagesWrapper>
          <div className="text-24 mb-4">
            <h1>Create Transfer</h1>
          </div>
          <div className="lg:w-[61%] md:w-[61%] sm:w-1/2 w-full">
            <div className="flex w-full mt-4 justify-between">
              <div className="w-full">
                <div className="mb-4 w-full ">TRANSFER REQUEST (BRANCH)</div>
                {fromLocation.map((object, index) => (
                  <div
                    className={`${
                      selectedFromLocation === object.id
                        ? "border p-2 w-1/2 btn-primary"
                        : "border p-2 w-1/2"
                    }`}
                    key={index}
                    onClick={() => onChangeSelectFromLocation(object.id)}
                  >
                    {object.address}
                  </div>
                ))}
              </div>
              <div className="form-group w-[40%] mb-2">
                <div className="mb-4">Date </div>
                <DatePicker
                  className="form-control"
                  placeholderText="Select Date"
                  selected={date}
                  onChange={(date) => setDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="flex w-full mt-4 justify-between">
              <div className="form-group w-[45%] py-2">
                <div className="mb-4">Category</div>
                <Select
                  required
                  isMulti={true}
                  value={selectedCategory.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedCategory(selectedValues);
                  }}
                  options={uniqueCategories.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  isClearable={false}
                />
              </div>
              <div className="form-group w-[45%] py-2">
                <div className="mb-4">Search Product</div>
                <Select
                  options={filteredProducts.map((obj) => ({
                    value: obj.product_name,
                    label: obj.product_name,
                    product_id: obj.product_id,
                    quantity: obj.quantity,
                  }))}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      addProductToTable(selectedOption);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="lg:w-[61%] md:w-[61%] sm:w-1/2 w-full">
              <div
                className="bg-white rounded-3xl"
                style={{ minHeight: "40vh" }}
              >
                <div className="">
                  <div className="flex justify-between p-4">
                    <div className="text-24  text-center">Products</div>
                    {filteredProducts.length > 0 && (
                      <div className="">
                        <Button
                          style={{ width: "120px" }}
                          onClick={handleSelectAll}
                        >
                          Select All
                        </Button>
                      </div>
                    )}
                  </div>
                  {filteredProducts.length > 0 ? (
                    <div
                      className="justify-between p-2 grid grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-6"
                      style={{ maxHeight: "40vh", overflowY: "auto" }}
                    >
                      {filteredProducts.map((obj) => (
                        <div
                          className="sytlecreatesale sytlecreatesalegap w-full justify-between flex border-solid border-2 border-gray-400 rounded-md p-2 text-center"
                          key={obj.id}
                        >
                          <div className="w-[70%] text-center">
                            {obj.product_name}
                          </div>
                          <div className="w-[30%] text-center">
                            <input
                              type="checkbox"
                              className="w-full rounded-md text-center"
                              checked={tableData.some(
                                (selectedProduct) =>
                                  selectedProduct.product_id === obj.product_id
                              )}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                if (isChecked) {
                                  addProductToTable(obj);
                                } else {
                                  setTableData((prevSelected) =>
                                    prevSelected.filter(
                                      (selectedProduct) =>
                                        selectedProduct.product_id !==
                                        obj.product_id
                                    )
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="w-full mt-9 justify-center text-center">
                      No Selected Category
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="lg:w-[38%] md:w-[38%] w-1/3 sm:w-1/3 bg-white rounded-3xl p-3"
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <div className="text-[#287DFD] flex justify-center p-2">
                <div className="w-[40%] flex justify-center">
                  <span>Product</span>
                </div>
                <div className="w-[30%] flex justify-center">
                  <span>Requested Qty</span>
                </div>
                <div className="w-[30%] flex justify-center">
                  <span>Available Qty</span>
                </div>
              </div>
              {tableData.length > 0 ? (
                <>
                  {tableData.map((product) => {
                    return (
                      <div
                        className="flex justify-between p-2"
                        key={product.product_id}
                      >
                        <div className="w-[40%] flex justify-start">
                          {product.product_name}
                        </div>
                        <div className="w-[20%] flex justify-center">
                          <div className="w-full relative flex justify-center items-center">
                            <button
                              className="border-0 cursor-pointer bg-[#287DFD] rounded-full outline-none absolute left-0 h-[19px] w-[19px] text-white"
                              onClick={(e) =>
                                handleQtyChange(product, product.qty - 1)
                              }
                              disabled={product.qty == 1}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="hideNumberArrow text-center h-[19px] border border-[#D9D9D9] rounded-full w-full"
                              value={product.qty}
                              onChange={(e) =>
                                handleQtyChange(product, e.target.value)
                              }
                              onBlur={(e) =>
                                handleOnBlur(product, e.target.value)
                              }
                            />
                            <button
                              className="border-0 cursor-pointer bg-[#287DFD] rounded-full absolute right-0 outline-none h-[19px] w-[19px] text-white"
                              onClick={(e) =>
                                handleQtyChange(product, product.qty + 1)
                              }
                              disabled={product.qty >= product.quantity}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="w-[20%] flex justify-start">
                          {product.quantity}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="w-full  flex  mt-10 justify-center text-center">
                  No Selected Product
                </div>
              )}
            </div>
          </div>
          {filteredProducts.length > 0 && (
            <div className="flex w-full mt-4 mb-10 justify-end gap-x-1">
              <div className="p-2 w-[10%]">
                <button
                  onClick={handleReset}
                  className=" btn-primary w-full"
                  type="button"
                >
                  Reset
                </button>
              </div>
              <div className="p-2 w-[10%]">
                <button
                  onClick={handleSendToBackend}
                  className={
                    loading
                      ? " btn-primary btn-progress w-full"
                      : "w-full  btn-primary"
                  }
                  type="submit"
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CreateTransfer;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import PositionApi from "api/hrmapi/positionapi";
import DepartmentApi from "api/hrmapi/department";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function CreatePosition({ contentOnly = false, setPopupId, setFetchPostion,onPositionCreated }) {
  const navigate = useNavigate();
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [positionName, setPositionName] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [allDepartments, setAllDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numberOfPostions, setNumberOfPostions] = useState(null);
  const [hourlyRate, setHourlyRate] = useState("");
  const [grade, setGrade] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [department, setDepartment] = useState("");
  const [description, setDescription] = useState("");
  const handleNavgetAllPosition = () => {
    setTimeout(() => {
      navigate(`/all-position`);
    }, 2000);
  };
  const handleCreatePosition = (e) => {
    e.preventDefault();
    setLoading(true);
    PositionApi.createPosition({
      position: {
        name: positionName,
        department_id: departmentId,
        company_id: company_id,
        description: description,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        if (!contentOnly) {
          handleNavgetAllPosition();
        }
        setDepartmentId("");
        setPositionName("");
        setDescription("");
        setLoading(false);
        if (contentOnly) {
          setFetchPostion(true);  
          onPositionCreated(response.data.data.id)
          setPopupId("");       
        }
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    DepartmentApi.allDepartments()
      .then((res) => {
        const formattedDepartments = res.data.map((dept) => ({
          value: dept.id,
          label: dept.name,
        }));
        setAllDepartments(formattedDepartments);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Position</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreatePosition}>
            <div className="w-full flex justify-around flex-wrap ">
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    value={positionName}
                    onChange={(e) => setPositionName(e.target.value)}
                  />
                </div>
              </div>

              {/* <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Hourly Rate</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    value={hourlyRate}
                    onChange={(e) => setHourlyRate(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Grade</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <select
                    value={grade}
                    required
                    onChange={(e) => setGrade(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Please select</option>
                    <option value="Grade A-">Grade A-</option>
                    <option value="Grade B-">Grade B-</option>
                    <option value="Grade C-">Grade C-</option>
                    <option value="Grade D-">Grade D-</option>
                    <option value="Grade E-Grate E">Grade E-Grate E</option>
                    <option value="Grade F-">Grade F-</option>
                    <option value="Reseller-">Reseller-</option>
                    <option value="S1-">S1-</option>
                    <option value="Grade Staff-Deskripsi">
                      Grade Staff-Deskripsi
                    </option>
                  </select>
                </div>
              </div>
              <div className="w-[30%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Employment Type</div>
                </div>
                <div>
                  <select
                    required
                    value={employmentType}
                    onChange={(e) => setEmploymentType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Please select</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Outsourced Full Time">
                      Outsourced Full Time
                    </option>
                    <option value="Outsourced Part Time">
                      Outsourced Part Time
                    </option>
                  </select>
                </div>
              </div> */}
              <div className="w-[30%] ">
                <div className="flex w-full pt-5 pb-5">
                  <div>Department</div>
                </div>
                <div>
                  <Select
                    required
                    options={allDepartments}
                    value={
                      allDepartments.find(
                        (dept) => dept.value === departmentId
                      ) || ""
                    }
                    onChange={(obj) => setDepartmentId(obj ? obj.value : "")}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[30%]"></div>
            </div>
            <div className="p-4 w-[96.5%]">
              <div>
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-36 h-auto p-2"
                    value={description}
                    rows={5}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className=" w-full flex justify-end">
              <div className={`${contentOnly ? "w-[30%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create Position
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreatePosition;

import React, { useEffect } from "react";
import OverviewTab from "./Components/OverviewTab";

const OverView = ({ date }) => {
  return (
    <div className="grid grid-cols-12 gap-x-6">
      <OverviewTab
        tab="Sales Overview"
        date={date}
        identifiers={["annualSales", "dailySales"]}
      />
      <OverviewTab
        tab="Purchase Overview"
        date={date}
        identifiers={["annualPurchase", "dailyPurchase"]}
      />
      <OverviewTab
        tab="Refund Overview"
        date={date}
        identifiers={["annualRefund", "dailyRefund"]}
      />
      <OverviewTab
        tab="Products Overview"
        date={date}
        identifiers={["totalProducts", "outOfStockProducts"]}
      />
    </div>
  );
};

export default OverView;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomerApi from "api/customerapi/customer";
import Popup from "Components/Common/Pages/Popup";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowComponent from "Components/ShowComponent";
const CreateCustomer = ({
  setPopupId,
  onCustomerCreated,
  contentOnly = false,
  setCustomerMode,
}) => {
  const [showpopup, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [birthdayDate, setBirthdayDate] = useState(null);
  const [customerNationality, setCustomerNationality] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [customerCountry, setCustomerCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nameError, setNameError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [birthdateError, setBirthError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [ageBracketError, setAgeBracketError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedNationalityOption, setSelectedNationalityOption] =
    useState(null);

  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState({
    customerName: "",
    customerPhoneNumber: "",
    customerType: 0,
    customerEmail: "",
    customerAddress: "",
    customerGender: "",
    customerAge: "",
    customerAgeGroup: "",
  });

  const findSelectedCountryIndex = () => {
    return customerCountry.findIndex((country) => country === selectedCountry);
  };
  const validateName = (name) => {
    const regex = /^[A-Za-z\s]+$/; // Regular expression allowing only alphabets and spaces
    return regex.test(name);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateAddress = (address) => {
    const regex = /^[A-Za-z0-9\s]+$/;
    return regex.test(address);
  };
  const validateDate = (date) => {
    // Regular expression for date format dd-MM-yyyy
    const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;
    return regex.test(date);
  };
  const validateAgeBracket = (ageGroup) => {
    const regex = /^[0-9-]+$/;
    return regex.test(ageGroup);
  };
  const validatePhoneNumber = (number) => {
    const regex = /^\d+$/;
    return regex.test(number);
  };

  useEffect(() => {
    CustomerApi.AllCountries().then((response) => {
      if (response.data && response.data.length > 0) {
        const nationalities = response.data.map((item) => item.nationality);
        setCustomerNationality((prevNationalities) =>
          prevNationalities.concat(nationalities)
        );

        const countriesNames = response.data.map((item) => item.country_name);
        setCustomerCountry((prev) => prev.concat(countriesNames));

        const countriesCode = response.data.map((item) => item.country_code);
        setCountryCodes((prev) => prev.concat(countriesCode));

        const selectedIndex = findSelectedCountryIndex();
        if (selectedIndex !== -1) {
          setSelectedCode(countryCodes[selectedIndex]);
        }
      }
    });
  }, [selectedCountry]);

  if (window.location.pathname === "create-customer") {
    navigate("/all-customer");
  } else if (window.location.pathname === "create-sale") {
    navigate("create-sale");
  }

  const handleFormattedBirthDate = (date) => {
    if (date !== null) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${day}-${month}-${year}`;
    }
    return "";
  };

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    const isValidName = validateName(value);

    setCustomerData({
      ...customerData,
      [name]: value,
    });

    setNameError(!isValidName);
  };

  const inputOnChangeAddress = (e) => {
    const value = e.target.value;
    const isValidAddress = validateAddress(value);

    setCustomerData({
      ...customerData,
      customerAddress: value,
    });
    setAddressError(!isValidAddress);
  };
  const inputOnChangePhone = (e) => {
    const value = e.target.value;
    const isValidPhone = validatePhoneNumber(value);

    setCustomerData({
      ...customerData,
      customerPhoneNumber: value,
    });
    setPhoneNumberError(!isValidPhone);
  };

  const inputOnChangeEmail = (e) => {
    const value = e.target.value;
    const isValidEmail = validateEmail(value);

    setCustomerData({
      ...customerData,
      customerEmail: value,
    });
    setEmailError(!isValidEmail);
  };

  const handleFormattedBirthday = (date) => {
    if (date !== null) {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${day}-${month}`;
    }
  };

  const handleCreateCustomer = (e) => {
    const formattedBirthDate = handleFormattedBirthDate(birthDate);
    const formattedBirthday = handleFormattedBirthday(birthDate);
    e.preventDefault();
    setLoading(true);
    if (customerData.customerPhoneNumber) {
      CustomerApi.createCustomer({
        customer: {
          name: customerData.customerName,
          phone_no: phoneNumber,
          customer_type: customerData.customerType,
          country: selectedCountry,
          address: customerData.customerAddress,
          email: customerData.customerEmail,
          nationality: selectedNationality,
          gender: customerData.customerGender,
          age_bracket: customerData.customerAge,
          age_group: customerData.customerAgeGroup,
          birthday: formattedBirthday,
          birthdate: formattedBirthDate,
        },
      })
        .then((res) => {
          let phoneNo = phoneNumber;
          onCustomerCreated(res?.data.customer_id, phoneNo);
          if (window.location.pathname === "/create-customer") {
            navigate("/all-customer");
          } else if (window.location.pathname === "/create-sale") {
            navigate("/create-sale");
          }
          setLoading(false);
          const Message = res ? res.data.message : "An error occurred";
          if (Message === "customer created successfully") {
            toast.success(`Success:${Message}`);
            setCustomerMode("search");
            setPopupId("")
          }
          const errorMessage = res?.data.errors;
          if (errorMessage) {
            setCustomerMode("newCustomer");
            toast.error(`${errorMessage}`);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setCustomerMode("newCustomer");
        });
    }
  };

  useEffect(() => {
    setPhoneNumber(`${selectedCode}${customerData.customerPhoneNumber}`);
  }, [selectedCode, customerData.customerPhoneNumber]);

  const calculateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  
  const handleCountryChange = (selectedOption) => {
    setSelectedCountryOption(selectedOption);
    if (selectedOption && selectedOption.value !== null) {
      setSelectedCountry(selectedOption.value); // Update the selected country state
      const selectedIndex = customerCountry.findIndex(
        (country) => country === selectedOption.value
      );
      if (selectedIndex !== -1) {
        setSelectedCode(countryCodes[selectedIndex]);
      }
    }
  };

  const handleNationalityChange = (selectedOption) => {
    setSelectedNationalityOption(selectedOption);
    if (selectedOption && selectedOption.value !== null) {
      setSelectedNationality(selectedOption.value); // Update the selected nationality state
    }
  };

  const determineAgeGroup = (age) => {
    if (age >= 18 && age <= 24) {
      return "18-24";
    } else if (age > 25 && age <= 34) {
      return "25-34";
    } else if (age > 35 && age <= 44) {
      return "35-44";
    } else if (age > 45 && age <= 54) {
      return "45-54";
    } else if (age > 55 && age <= 64) {
      return "55-64";
    } else if (age > 65 && age <= 74) {
      return "65-74";
    } else if (age >= 75) {
      return "75 +";
    } else {
      return "smaller than 18";
    }
  };
  const countryOptions = customerCountry.map((country, index) => ({
    value: country,
    label: country,
  }));
  const nationalityOptions = customerNationality.map((nationality, index) => ({
    value: nationality,
    label: nationality,
  }));

  const handleAgeInputChange = (event) => {
    const userInput = event.target.value.trim();
    const isValidAgeBracket = validateAgeBracket(userInput);

    setCustomerData({
      ...customerData,
      customerAge: userInput,
    });

    const selectedOption = document.getElementById("ageSelect").value;

    if (selectedOption === "between") {
      if (userInput.length === 3 && userInput[2] !== "-") {
        setCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput.substring(0, 2) + "-" + userInput.substring(2),
        }));
      } else if (userInput.length === 2 && userInput[1] === "-") {
        setCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput.substring(0, 1),
        }));
      } else {
        setCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput,
        }));
      }
    } else {
      setCustomerData((prevData) => ({
        ...prevData,
        customerAge: userInput,
      }));
    }
    setAgeBracketError(!isValidAgeBracket);
  };
  const handleCancel = () => {
    setPopupId("");
    setPhoneNumber("");
    setSelectedCountryOption(null);
    setSelectedNationality("");
    setSelectedNationalityOption(null);
    setSelectedCountry("");
    setSelectedCode("");
    setBirthDate(null);
    setBirthdayDate(null);
    setCustomerData({
      customerName: "",
      customerPhoneNumber: "",
      customerType: "",
      customerEmail: "",
      customerAddress: "",
      customerGender: "",
      customerAge: "",
      customerAgeGroup: "",
    });
    setShowPopUp(false);
  };

  useEffect(() => {
    let ageGroup;
    if (birthDate !== null) {
      setBirthdayDate(handleFormattedBirthday(birthDate));
      const age = calculateAge(birthDate);
      ageGroup = determineAgeGroup(age);
    }
    setCustomerData((prevData) => ({
      ...prevData,
      customerAgeGroup: ageGroup,
    }));
  }, [birthDate]);

  const handleGenderSelection = (e) => {
    setCustomerData((prevData) => ({
      ...prevData,
      customerGender: e.target.value,
    }));
  };

  return (
    <>
      <AppWrapper sideBar={<AccountsSidebar />} contentOnly={contentOnly}>
        <div className="py-2">
          <h1 className="text-24 w-full text-center"> Create Customer</h1>
        </div>
        <ShowComponent condition={showpopup}>
          <Popup
            showpopup={showpopup}
            setShowPopUp={setShowPopUp}
            customClassName="w-[444px] z-10"
          >
            <div className="text-center justify-center w-full">
              <div className="mb-2">
                Are you sure you want to cancel? Your changes will be discarded.
              </div>
              <div className="flex w-full justify-center">
                <div className="w-[33%] mr-2">
                  <button
                    className="btn-primary w-full"
                    onClick={() => setShowPopUp(!showpopup)}
                  >
                    No
                  </button>
                </div>
                <div className="w-[33%]">
                  <button className="btn-primary w-full" onClick={handleCancel}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        </ShowComponent>
        <form onSubmit={handleCreateCustomer}>
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={3000}
          />
          <div className=" justify-between flex w-full ">
            <div htmlFor="customerName" className="mt-3 font-semibold">
              Name:
            </div>
            <div className="w-[87%]">
              <input
                className="form-control"
                type="text"
                name="customerName"
                value={customerData.customerName}
                onChange={inputOnChange}
                required
              />
            </div>
          </div>
          <div className="flex">
            <div className="row w-[80%]">
              <div className="flex w-full justify-between">
                <div htmlFor="" className="flex items-center mb-3 font-semibold">
                  Country:
                </div>
                <div className="w-[80%]">
                  <Select
                    classNames={{ control: () => "form-control w-[90%]" }}
                    required
                    value={selectedCountryOption}
                    onChange={handleCountryChange}
                    options={countryOptions}
                    placeholder="Select Country"
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div htmlFor="" className="flex items-center mb-2 font-semibold">
                  Phone No:
                </div>
                <div className="flex w-[80%] items-center">
                  <select
                    className="w-[20%] form-control border-gray-300 rounded-r-none rounded-l-lg"
                    name="selectedCode"
                    value={selectedCode}
                    required
                    onChange={(e) => {
                      setSelectedCode(e.target.value);
                    }}
                    disabled
                  >
                    <option value=""></option>
                    {countryCodes.map((countriesCode, index) => (
                      <option key={index} value={countriesCode}>
                        {countriesCode}
                      </option>
                    ))}
                  </select>
                  <input
                    className="w-[70%] form-control border-l-0 border-gray-300 rounded-r-lg rounded-l-none"
                    type="number"
                    value={customerData.customerPhoneNumber}
                    onChange={inputOnChangePhone}
                    name="customerPhoneNumber"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="flex items-center font-semibold mb-3">Email: </div>
                <div className="w-[80%]">
                  <input
                    className="w-[90%] form-control"
                    type="email"
                    name="customerEmail"
                    value={customerData.customerEmail}
                    onChange={inputOnChangeEmail}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div htmlFor="" className="flex items-center mb-2 font-semibold">
                  Nationality:
                </div>
                <div className="w-[80%]">
                  <Select
                    classNames={{
                      control: () => "form-control w-[90%] border-gray-300",
                    }}
                    value={selectedNationalityOption}
                    onChange={handleNationalityChange}
                    options={nationalityOptions}
                    placeholder="Select Nationality"
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="w-[60%]">
                <div className="flex justify-between w-full my-3">
                  <div htmlFor="" className="flex items-center mb-2 font-semibold">
                    Gender:
                  </div>
                  <div className="w-1/10 flex items-center">
                    <input
                      className="mr-2 h-5 w-5"
                      type="radio"
                      id="maleGender"
                      name="customerGender"
                      value="male"
                      checked={customerData.customerGender === "male"}
                      onChange={handleGenderSelection}
                    />
                    <label htmlFor="maleGender" className="font-semibold mr-5 mt-1">
                      Male
                    </label>
                    <input
                      className="mr-2 h-5 w-5"
                      type="radio"
                      id="femaleGender"
                      name="customerGender"
                      value="female"
                      checked={customerData.customerGender === "female"}
                      onChange={handleGenderSelection}
                    />
                    <label htmlFor="femaleGender" className="font-semibold mt-1">
                      Female
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex justify-between w-full">
                <div htmlFor="" className="flex items-center mb-2 font-semibold">
                  Birthday:
                </div>
                <div className="w-[80%]">
                  <input
                    className="w-[90%] form-control"
                    placeholderText="dd-MM"
                    dateFormat="dd-MM"
                    type="text"
                    value={birthdayDate}
                    readOnly
                    onChange={() => {
                      setBirthdayDate(handleFormattedBirthday(birthDate));
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div htmlFor="" className="flex items-center mb-2 font-semibold">
                  Birth Date:
                </div>
                <div className="w-[80%] pr-10">
                  <DatePicker
                    className="form-control"
                    placeholderText="dd-MM-yyyy"
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    selected={birthDate}
                    yearDropdownItemNumber={80}
                    onChange={(date) => {
                      if (validateDate(handleFormattedBirthDate(date))) {
                        setBirthError(false);
                      } else {
                        setBirthError(true);
                      }
                      setBirthDate(date);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center font-semibold mb-2">Address: </div>
              <textarea
                className="rounded-lg block border border-gray-300 p-2.5 w-full"
                type="text"
                value={customerData.customerAddress}
                name="customerAddress"
                onChange={inputOnChangeAddress}
                rows={10}
              ></textarea>
              <div className="w-full">
                <label htmlFor="" className=" font-semibold ">
                  Age Bracket:
                </label>
                <div className="flex mt-2">
                  <select
                    className="border border-gray-300 rounded-r-none rounded-l-lg w-[49%] form-control"
                    id="ageSelect"
                  >
                    <option value="less">Less Than</option>
                    <option value="between">Between</option>
                    <option value="greater">Greater Than</option>
                  </select>
                  <input
                    className="form-control w-[51%] border-gray-300 rounded-r-lg rounded-l-none"
                    id="customerAgeInput"
                    type="text"
                    name="customerAge"
                    value={customerData.customerAge}
                    onChange={(event) => handleAgeInputChange(event)}
                  />
                </div>
              </div>
              {ageBracketError && (
                <span className="text-red text-sm">
                  Please enter a valid Age Bracket.
                </span>
              )}
              <div className="w-full">
                <div className=" font-semibold">Age Group:</div>
                <input
                  className="form-control mt-2"
                  type="text"
                  value={customerData.customerAgeGroup}
                  readOnly
                />
              </div>
            </div>
          </div>
          {birthdateError && (
            <span className="text-red text-sm">
              Please enter a valid birth date.
            </span>
          )}
          <div className=" w-full flex justify-end">
            <div className="w-[25%]">
              <button
                className={`w-full ${
                  loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                }`}
                type="submit"
              >
                Create Customer
              </button>
            </div>
          </div>
        </form>
      </AppWrapper>
    </>
  );
};
export default CreateCustomer;
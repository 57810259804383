import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductsAPI from "api/posapi/product";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import { useNavigate } from "react-router-dom";
import FormatDate from "Components/Common/FormatDate";
import CreateProduct from "./CreateProduct";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";

function ProductDetail() {
  const [addProductPopUp, setAddProductPopUp] = useState(false);
  let params = useParams();
  const [loading, setLoading] = useState(true);
  const [dataToShow, setDataToShow] = useState({});

  useEffect(() => {
    setLoading(true);
    ProductsAPI.viewProductAPI(params)
      .then((response) => {
        setDataToShow(response.data.list);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [params]); // Added params to the dependency array for re-fetching data when params change

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/products");
  };

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <ShowComponent condition={addProductPopUp}>
          <Popup
            showpopup={addProductPopUp}
            setShowPopUp={setAddProductPopUp}
            customClassName="w-[444px]"
          >
            <CreateProduct />
          </Popup>
        </ShowComponent>
        <div className="flex justify-between w-full mt-10">
          <div className="flex text-2xl font-bold items-center">
            Product Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="w-full min-h-[70vh] mt-4 bg-white rounded-3xl p-3">
          <div className="text-xl mb-2 w-1/2">
            <h1>
              <b>Product # {params.id}</b>
            </h1>
          </div>
          <div className="w-full flex justify-between mt-10 ">
            <div className="w-[45%]">
              <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                <div className="">
                  <b>Product Name:</b>
                </div>
                <div>{dataToShow.name}</div>
              </div>
              <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                <div className="">
                  <b>Category Name:</b>
                </div>
                <div>{dataToShow.category}</div>
              </div>
              <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                <div className="">
                  <b>Sku:</b>
                </div>
                <div>{dataToShow.sku}</div>
              </div>
              <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                <div className="">
                  <b>Created At:</b>
                </div>
                <div>{FormatDate(dataToShow?.created_at)}</div>
              </div>
            </div>
            <div className="w-[45%] mt-[18px]">
              <div className=" justify-center flex">
                <b className="mr-1">Barcode:</b>
                {dataToShow.barcode}
              </div>
              <div className="mt-4 justify-center flex rounded-3xl bg-slate-200">
                {loading ? (
                  <div className="h-74">
                    <img src={Loader} className="m-auto" alt="" />
                  </div>
                ) : (
                  <>
                    {dataToShow.avatar_url ? (
                      <img
                        src={dataToShow.avatar_url}
                        alt="Image"
                        className="w-[80%] h-74"
                      />
                    ) : (
                      <img
                        src="/icons/no-image.png"
                        alt="No Image Found"
                        className="w-full h-74"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default ProductDetail;

import axiosClient from "..";

class PosReportApi {
    static highestSalingProduct(startDate, endDate){
        return axiosClient.get(`/api/reports/highest_selling_product_report.json?start_date=${startDate}&end_date=${endDate}`);
    }
    static ProductWiseSale(startDate, endDate){
        return axiosClient.get(`/api/reports/productwise_sale_report.json?start_date=${startDate}&end_date=${endDate}`);
    }
    static salesmanSaleReport(startDate, endDate){
        return axiosClient.get(`/api/reports/salesman_sale_report.json?start_date=${startDate}&end_date=${endDate}`);
    }
    static oldCustomerReport(startDate, endDate){
        return axiosClient.get(`/api/reports/old_customer_report.json?start_date=${startDate}&end_date=${endDate}`);
    }

    static newCustomerReport(startDate, endDate){
        return axiosClient.get(`/api/reports/new_customer_report.json?start_date=${startDate}&end_date=${endDate}`);
    }
}
export default PosReportApi;
import CompanySettingApi from "api/hrmapi/companysetting";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Logo() {
  const [dataToShow, setDataToShow] = useState({});
  const [loading, setLoading] = useState(true);
  const [logoChanged, setLogoChanged] = useState(false);
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const fileInputRef = useRef(null);

  useEffect(() => {
    CompanySettingApi.allLogoApi()
      .then((res) => {
        setDataToShow(res.data.company);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, [logoChanged]);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.set("company[logo]", file);

    CompanySettingApi.updateLogoApi(company_id, formData)
      .then((res) => {
        toast.success(res.data.message);
        setLogoChanged(true);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "An error occurred"
        );
      });
  };

  const handleRemove = () => {
    CompanySettingApi.deleteLogoApi(company_id)
      .then((res) => {
        setDataToShow({ ...dataToShow, logo: null });
        toast.success(res.data.message);
        setLogoChanged(true);
      })
      .catch((error) => {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "An error occurred"
        );
      });
  };
  return (
    <div className="flex justify-between items-center">
      <div className="flex">
        <div className="mr-2">{dataToShow.name}</div>
        <div>{dataToShow.timezone}</div>
      </div>
      {dataToShow.logo ? (
        <div className="justify-center">
          <div className="mb-4">
            <img src={dataToShow.logo} alt="" className="h-10 w-10" />
          </div>
          <div className="w-full">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="logoChangeInput"
              ref={fileInputRef}
              onChange={handleUpload}
              className="hidden"
            />
            <label
              htmlFor="logoChangeInput"
              className="w-full text-blue cursor-pointer"
            >
              Change
            </label>
          </div>
          <div className="mt-4">
            <button
              onClick={handleRemove}
              className=" w-full cursor-pointer text-blue"
            >
              Remove
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="w-[19%]">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="logoUploadInput"
              ref={fileInputRef}
              onChange={handleUpload}
              className="hidden"
            />
            <label
              htmlFor="logoUploadInput"
              className="bg-blue hover:bg-blue-700 text-white py-2 px-4 rounded w-full cursor-pointer"
            >
              Choose Logo
            </label>
          </div>
        </>
      )}
    </div>
  );
}

export default Logo;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CustomerApi from "api/customerapi/customer";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import TableDetails from "Components/Common/TableDetails";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";
import PosSidebar from "Components/Common/PosSidebar";

function CustomerDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isAccounts = location.pathname.startsWith("/accounts/customer/details");

  useEffect(() => {
    setLoading(true);
    CustomerApi.viewCustomer(params)
      .then((response) => {
        const { customer_type, ...FilterData } = response?.data?.customer;
        setDataToShow(FilterData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    if (isAccounts) {
      navigate("/accounts-customers");
    } else {
      navigate("/pos-customers");
    }
  };

  return (
    <>
      <AppWrapper sideBar={isAccounts ? <AccountsSidebar /> : <PosSidebar />}>
        <PagesWrapper>
        <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Customer Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="w-1/2">
          <TableDetails data={dataToShow} loading={loading} header={false} />
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CustomerDetails;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
import AuthWrapper from "Components/Common/Pages/AuthWrapper";
import AuthAPI from "api/auth/auth";
import AlertMessage from "Components/Common/alertMessage";
import ShowComponent from "Components/ShowComponent";
import GeneralInput from "Components/Common/GeneralInput";
import { toast } from "react-toastify";

function ResendPassWord() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthAPI.forgetPasswordEmail({
      user: {
        email: email,
      },
    })
      .then((response) => {
        toast.success(response.data.message);
        setEmail("")
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response.data.message : "An error occurred");
      });
  };
  const handleNavigate = () => {
    navigate("/login");
  };

  return (
    <AuthWrapper>
      <h1 className="mb-12 text-36 font-bold text-dark dark:text-grey-mode text-center md:text-left">
        Forget PassWord?
        <p className="mt-6 text-16 font-bold">No worries, we'll send you Email</p>
      </h1>
      <form onSubmit={handleSendEmail}>
        <GeneralInput
          title="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder=""
          type="email"
          className="mb-9"
          name="email"
          required={true}
          paraClassName="font-bold mb-4"
        />
        <div className="text-right">
          <button
            className="mb-9 underline text-blue border-0 cursor-pointer block-inline font-bold"
            onClick={handleNavigate}
            type="button"
          >
            Back to login?
          </button>
        </div>

        <Button loading={loading} type="submit" className="w-full">
          Reset password
        </Button>
</form>
    </AuthWrapper>
  );
}

export default ResendPassWord;
import React, { useState } from "react";
import GernalInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import PosSidebar from "Components/Common/PosSidebar";
import VendorAPI from "api/posapi/vendor";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast, ToastContainer } from "react-toastify";

function CreateVendor({ ClassName, vendorCreated, contentOnly = false, setPopupId,fetchVendors }) {
  const [vendor, setVendor] = useState("");
  const [vendorPhoneNo, setVendorPhoneNo] = useState("");
  const [vendorCompany, setVendorCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSendToBackend = (e) => {
    e.preventDefault();
    setLoading(true);
    VendorAPI.createVendorAPI({
      vendor: {
        name: vendor,
        phone_no: vendorPhoneNo,
        company_name: vendorCompany,
      },
    })
      .then((response) => {
        if(response?.data?.message==="vendor has been created"){
        toast.success(response?.data?.message);
          setPopupId("")
          fetchVendors()
        }
        toast.error(response?.data?.error);
        setVendorPhoneNo("");
        setVendorCompany("");
        setVendor("");
        vendorCreated(response.data.vendor.id);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
        console.error("Error sending purchase data:", error);
        setLoading(false);
      });
  };

  const additionalInputFields = [
    {
      title: "Vendor Name",
      value: vendor,
      type: "text",
      onChange: (e) => setVendor(e.target.value),
      placeholder: "Vendor",
      required: true,
    },
    {
      title: "Vendor Company Name",
      value: vendorCompany,
      onChange: (e) => setVendorCompany(e.target.value),
      placeholder: "Vendor Company",
      type: "text",
      required: true,
    },
    {
      title: "Vendor Phone No",
      type: "number",
      value: vendorPhoneNo,
      onChange: (e) => setVendorPhoneNo(e.target.value),
      placeholder: "Phone No",
      required: true,
    },
  ];

  return (
    <AppWrapper sideBar={<PosSidebar />} contentOnly={contentOnly}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
      <div className="py-4">
        <h1 className="text-24 mb-2 w-full px-4 text-center"> Create Vendor</h1>
      </div>
      <div className="w-full justify-center px-4">
        <form onSubmit={handleSendToBackend}>
          <div>
            {additionalInputFields.map((obj, index) => (
              <GernalInput
              className="mb-5"
                key={index}
                title={obj.title}
                value={obj.value}
                onChange={obj.onChange}
                placeholder={obj.placeholder}
                isSelect={obj.isSelect}
                type={obj.type}
                required={obj.required}
                paraClassName= "font-bold mb-4"
              />
            ))}
          </div>
          <div className="text-center mt-4 pb-4">
            <Button
              className={
                loading ? "btn-primary btn-progress" : "btn btn-primary"
              }
              type="submit"
            >
              Create Vendor
            </Button>
          </div>
        </form>
      </div>
    </AppWrapper>
  );
}

export default CreateVendor;

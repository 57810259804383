import React, { useState, useEffect, useRef } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import DiscountAPI from "api/posapi/discount";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import DiscountItemList from "./DiscountItemList";
import Button from "Components/Common/Button";
import DiscountSelectableList from "./DiscountSelectableList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import FormatDate from "Components/Common/FormatDate";
import LocationsAPI from "api/location/location";
import Select from "react-select";

function CreateDiscount() {
  const [title, setTitle] = useState("");
  const [coupon, setCoupon] = useState("");
  const [discountValue, setDiscountValue] = useState("");

  const [discounttypes, setDiscountTypes] = useState([]);
  const [fetchingDiscountTypes, setFetchingDiscountTypes] = useState(true);

  const [discounttype, setDiscountType] = useState("");
  const [discountmethods, setDiscountMethods] = useState([]);
  const [discountmethod, setDiscountMethod] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showpopup, setShowPopUp] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selecteCategory, setSelecteCategory] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [giftedProducts, setGiftedProducts] = useState([]);
  const [minSaleAmount, setMinSaleAmount] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [inputChanged, setInputChanged] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [locations, setLocations] = useState([]);
  const [fetchingLocations, setFetchingLocations] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const selectInputRef = useRef();

  const handleMaxValueChange = (e) => {
    let value = e.target.value;
    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      value = value.slice(0, dotIndex + 3);
    }
    if (value >= 0 && value <= 100) {
      setMaxValue(value);
    } else {
      toast.error(`Error: Please enter a correct value between 0 and 100!`);
    }
  };

  const handleDiscountTypeChange = (e) => {
    let value = e.target.value;
    if (
      selectedCategories.length > 0 ||
      selectedProducts.length > 0 ||
      giftedProducts.length > 0
    ) {
      setShowConfirmationPopup(true);
      setInputChanged(value);
    } else {
      setDiscountType(value);
    }
  };

  const handleConfirmation = (confirmed, discountType) => {
    setShowConfirmationPopup(false);

    if (confirmed) {
      setDiscountType(inputChanged);
      setSelectedCategories([]);
      setSelectedProducts([]);
      setGiftedProducts([]);
    }
  };

  const handleAddCategory = (category) => {
    const isCategoryAlreadySelected = selectedCategories.find(
      (selectedCategory) =>
        selectedCategory.category_id === category.category_id
    );

    if (isCategoryAlreadySelected) {
      toast.warning("Category is already added.");
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
    if (selectedProducts.length > 0) {
      toast.warning("Product is already added.");
      setSelectedProducts([]);
      setGiftedProducts([]);
    }
  };

  const handleRemoveCategory = (item) => {
    const updatedCategories = selectedCategories.filter((obj) => obj !== item);
    setSelectedCategories(updatedCategories);
  };

  const handleAddProduct = (item) => {
    const isProductAlreadySelected = selectedProducts.find(
      (selectedProduct) => selectedProduct.product_id === item.product_id
    );

    if (isProductAlreadySelected) {
      toast.warning("Product is already added.");
    } else {
      setSelectedProducts([...selectedProducts, item]);
    }
    if (selectedCategories.length > 0) {
      toast.warning("Category is already added.");
      setSelectedCategories([]);
    }
  };
  const handleGiftProduct = (item) => {
    const isProductAlreadySelected = giftedProducts.find(
      (selectedProduct) => selectedProduct.product_id === item.product_id
    );

    if (isProductAlreadySelected) {
      toast.warning("Product is already added.");
    } else {
      setGiftedProducts([...giftedProducts, item]);
    }
    if (selectedCategories.length > 0) {
      toast.warning("Category is already added.");
      setSelectedCategories([]);
    }
  };
  const handleRemoveProduct = (item) => {
    const updatedProducts = selectedProducts.filter((obj) => obj !== item);
    setSelectedProducts(updatedProducts);
  };
  const handleRemoveGiftProduct = (item) => {
    const updatedProducts = giftedProducts.filter((obj) => obj !== item);
    setGiftedProducts(updatedProducts);
  };
  useEffect(() => {
    setFetchingLocations(true);
    setFetchingDiscountTypes(true);
    DiscountAPI.DiscountTypeAPI()
      .then((res) => {
        // const filteredDiscountTypes = (res?.data?.discount_types).filter(type =>
        //   type === "category" ||
        //   type === "product" ||
        //   type === "coupon_sale" ||
        //   type === "coupon_product" ||
        //   type === "coupon_category" ||
        //   type === "access_code"
        // );
        setDiscountTypes(res?.data?.discount_types);
        setDiscountMethods(res.data.discount_methods);
        setFetchingDiscountTypes(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setFetchingDiscountTypes(false);
      });
    DiscountAPI.productsAvailable()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    DiscountAPI.categorysAvailable()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setFetchingLocations(false);
        setLocations(response.data.locations);
      })
      .catch((error) => {
        setFetchingLocations(false);
      });
  }, []);

  const showModal = () => {
    setShowPopUp(!showpopup);
  };
  const clearSelectedItem = () => {
    setShowPopUp(!showpopup);
    setSelectedCategories([]);
    setSelectedProducts([]);
    setGiftedProducts([]);
  };
  const formattedStartDate = startDate ? FormatDate(startDate) : "";
  const formattedEndDate = endDate ? FormatDate(endDate) : "";

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const selectedProductIds = selectedProducts.map(
      (product) => product.product_id
    );
    const selectedGiftedProductIds = giftedProducts.map(
      (product) => product.product_id
    );
    const selectedCategoryIds = selectedCategories.map(
      (category) => category.category_id
    );

    const locationsToCreate = selectedLocation.map(({ value }) => value);
    const discoutnTypes = {
      category: {
        title: title,
        value: discountValue,
        discount_method: discountmethod,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_type: discounttype,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        discountable_ids:
          selectedProductIds.length > 0
            ? selectedProductIds
            : selectedCategoryIds,
        location_ids: locationsToCreate,
      },
      product: {
        title: title,
        value: discountValue,
        discount_method: discountmethod,
        discount_type: discounttype,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        discountable_ids:
          selectedProductIds.length > 0
            ? selectedProductIds
            : selectedCategoryIds,
        location_ids: locationsToCreate,
      },
      coupon_sale: {
        title: title,
        value: discountValue,
        discount_method: discountmethod,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_type: discounttype,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        coupon_code: coupon,
        location_ids: locationsToCreate,
      },
      coupon_product: {
        title: title,
        value: discountValue,
        discount_method: discountmethod,
        discount_type: discounttype,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        coupon_code: coupon,
        discountable_ids:
          selectedProductIds.length > 0
            ? selectedProductIds
            : selectedCategoryIds,
        location_ids: locationsToCreate,
      },
      coupon_category: {
        title: title,
        value: discountValue,
        discount_method: discountmethod,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_type: discounttype,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        coupon_code: coupon,
        discountable_ids:
          selectedProductIds.length > 0
            ? selectedProductIds
            : selectedCategoryIds,
        location_ids: locationsToCreate,
      },
      access_code: {
        title: title,
        discount_method: discountmethod,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_type: discounttype,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        access_code: accessCode,
        max_limit: parseFloat(maxValue),
        location_ids: locationsToCreate,
      },
      offer_get_y_item: {
        title: title,
        value: discountValue || 0,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_method: discountmethod || "percentage",
        discount_type: discounttype || "percentage",
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        discountable_ids: selectedProductIds,
        giftable_items: selectedGiftedProductIds,
        location_ids: locationsToCreate,
      },
      offer_get_y_amount: {
        title: title,
        min_sale_amount: minSaleAmount,
        value: discountValue || 0,
        category_id: selecteCategory || 0,
        min_quantity: minQuantity || 0,
        discount_method: discountmethod || "percentage",
        discount_type: discounttype || "percentage",
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        giftable_items: selectedGiftedProductIds,
        location_ids: locationsToCreate,
      },
      offer_get_y_category: {
        title: title,
        value: discountValue || 0,
        discount_method: discountmethod || "percentage",
        discount_type: discounttype || "percentage",
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        category_id: selecteCategory,
        min_quantity: minQuantity,
        giftable_items: selectedGiftedProductIds,
        location_ids: locationsToCreate,
      },
    };
    const categoryLength = selectedCategoryIds.length;
    const productIdLength = selectedProductIds.length;
    if (categoryLength === 0 && productIdLength === 0) {
      if (categoryLength === 0 && discounttype === "category") {
        toast.error("Please Add Category");
      } else if (productIdLength === 0 && discounttype === "product") {
        toast.error("Please Add Product");
      } else if (productIdLength === 0 && discounttype === "coupon_product") {
        toast.error("Please Add Product");
      } else if (categoryLength === 0 && discounttype === "coupon_category") {
        toast.error("Please Add Category");
      } else if (discounttype === "access_code") {
        DiscountAPI.createDiscountAPI({ discount: discoutnTypes[discounttype] })
          .then((res) => {
            toast.success(res.data.message);
            setTitle("");
            setDiscountMethod("");
            setMinSaleAmount("");
            setDiscountType("");
            setStartDate("");
            setEndDate("");
            setAccessCode("");
            setMaxValue("");
            setCoupon("");
            setDiscountValue("");
            setSelectedCategories([]);
            setSelectedProducts([]);
            selectInputRef.current.clearValue();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } else if (discounttype === "coupon_sale") {
        DiscountAPI.createDiscountAPI({
          discount: discoutnTypes[discounttype],
        })
          .then((res) => {
            toast.success(res.data.message);
            setTitle("");
            setDiscountMethod("");
            setDiscountType("");
            setStartDate("");
            setEndDate("");
            setAccessCode("");
            setMaxValue("");
            setCoupon("");
            setDiscountValue("");
            setSelectedCategories([]);
            setSelectedProducts([]);
            setGiftedProducts([]);
            selectInputRef.current.clearValue();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } else if (discounttype === "offer_get_y_amount") {
        if (!selectedGiftedProductIds.length) {
          toast.error("Please Add Product");
          return;
        }
        DiscountAPI.createDiscountAPI({
          discount: discoutnTypes[discounttype],
        })
          .then((res) => {
            toast.success(res.data.message);
            setTitle("");
            setDiscountMethod("");
            setDiscountType("");
            setStartDate("");
            setMinSaleAmount("");
            setEndDate("");
            setAccessCode("");
            setMaxValue("");
            setCoupon("");
            setDiscountValue("");
            setSelectedCategories([]);
            setSelectedProducts([]);
            setGiftedProducts([]);
            selectInputRef.current.clearValue();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } else if (discounttype === "offer_get_y_item") {
        if (!productIdLength) {
          toast.error("Please Add Product");
          return;
        } else if (!giftedProducts.length) {
          toast.error("Please Add Gifted Product");
          return;
        }
      } else if (discounttype === "offer_get_y_category") {
        if (!selecteCategory) {
          toast.error("Please select Category");
          return;
        } else if (!giftedProducts.length) {
          toast.error("Please Add Gifted Product");
          return;
        }
        DiscountAPI.createDiscountAPI({
          discount: discoutnTypes[discounttype],
        })
          .then((res) => {
            toast.success(res.data.message);
            setTitle("");
            setDiscountMethod("");
            setDiscountType("");
            setStartDate("");
            setEndDate("");
            setAccessCode("");
            setMaxValue("");
            setMinQuantity("");
            setCoupon("");
            setDiscountValue("");
            setSelecteCategory("");
            setSelectedCategories([]);
            setSelectedProducts([]);
            setGiftedProducts([]);
            setMinSaleAmount("");
            selectInputRef.current.clearValue();
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      }
      return;
    } else if (discounttype === "offer_get_y_item") {
      if (!productIdLength) {
        toast.error("Please Add Product");
        return;
      } else if (!giftedProducts.length) {
        toast.error("Please Add Gifted Product");
        return;
      }
      DiscountAPI.createDiscountAPI({
        discount: discoutnTypes[discounttype],
      })
        .then((res) => {
          toast.success(res.data.message);
          setTitle("");
          setDiscountMethod("");
          setDiscountType("");
          setStartDate("");
          setEndDate("");
          setAccessCode("");
          setMaxValue("");
          setCoupon("");
          setDiscountValue("");
          setSelectedCategories([]);
          setSelectedProducts([]);
          setGiftedProducts([]);
          setMinSaleAmount("");
          selectInputRef.current.clearValue();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } else {
      DiscountAPI.createDiscountAPI({
        discount: discoutnTypes[discounttype],
      })
        .then((res) => {
          toast.success(res.data.message);
          setTitle("");
          setDiscountMethod("");
          setDiscountType("");
          setStartDate("");
          setEndDate("");
          setAccessCode("");
          setMaxValue("");
          setCoupon("");
          setDiscountValue("");
          setSelectedCategories([]);
          setSelectedProducts([]);
          setGiftedProducts([]);
          setMinSaleAmount("");
          selectInputRef.current.clearValue();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleDisValChange = (e) => {
    let value = e.target.value;
    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      value = value.slice(0, dotIndex + 3);
    }
    if (value >= 0 && value <= 100) {
      setDiscountValue(value);
    } else {
      toast.error(`Error: Please enter a correct value between 0 and 100!`);
    }
  };
  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <div className="py-4">
            <h1 className="text-24 font-semibold mb-2 w-full px-4">
              Create Discount
            </h1>
          </div>
          <div className="border-t-2 border-blue bg-white rounded-md">
            <form onSubmit={handleFormSubmit}>
              <div className="flex ">
                <div className="flex flex-wrap mt-5 mx-5">
                  <div className="form-group mr-4 w-[230px]">
                    <label htmlFor="inputPassword4">Discount Type</label>
                    <ShowComponent condition={fetchingDiscountTypes}>
                      <div className="mt-2">
                        <img src="/assets/img/spinner.svg" alt="loading" />
                      </div>
                    </ShowComponent>
                    <ShowComponent condition={!fetchingDiscountTypes}>
                      <select
                        required
                        className="form-control mt-2"
                        value={discounttype}
                        onChange={(e) => handleDiscountTypeChange(e)}
                      >
                        <option value="">Select Discount Type</option>
                        {discounttypes.map((type, index) => (
                          <option key={index} value={type}>
                            {capitalizeFirstLetter(type)}
                          </option>
                        ))}
                      </select>
                    </ShowComponent>
                  </div>
                  <div className="form-group mr-4 w-[230px]">
                    <label htmlFor="inputEmail4">Discount Title</label>
                    <input
                      className="form-control mt-2"
                      name="Discount Title"
                      placeholder="Enter Discount Title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      required
                    />
                  </div>
                  {discounttype !== "access_code" &&
                    discounttype !== "offer_get_y_amount" &&
                    discounttype !== "offer_get_y_category" &&
                    discounttype !== "offer_get_y_item" && (
                      <div className="form-group mr-4 w-[230px]">
                        <label htmlFor="inputEmail4">Discount Value</label>
                        <input
                          className="form-control hideNumberArrow mt-2"
                          name="Discount Value"
                          placeholder="Enter Discount Value"
                          type="number"
                          value={discountValue}
                          onChange={handleDisValChange}
                          required
                        />
                      </div>
                    )}
                  {discounttype === "offer_get_y_amount" && (
                    <div className="form-group mr-4 w-[230px]">
                      <label htmlFor="inputEmail4">Minimum Sale Amount</label>
                      <input
                        className="form-control hideNumberArrow mt-2"
                        name="Discount Value"
                        type="number"
                        value={minSaleAmount}
                        onChange={(e) => setMinSaleAmount(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  {discounttype === "offer_get_y_category" && (
                    <div className="form-group mr-4 w-[230px]">
                      <label htmlFor="inputEmail4">Minimum Quantity</label>
                      <input
                        className="form-control hideNumberArrow mt-2"
                        name="Discount Value"
                        type="number"
                        value={minQuantity}
                        onChange={(e) => setMinQuantity(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  {discounttype !== "offer_get_y_item" &&
                    discounttype !== "offer_get_y_amount" &&
                    discounttype !== "offer_get_y_category" && (
                      <div className="form-group mr-4 w-[230px]">
                        <label htmlFor="inputPassword4">Discount Method</label>
                        <select
                          required
                          className="form-control mt-2"
                          value={discountmethod}
                          onChange={(e) => {
                            setDiscountMethod(e.target.value);
                          }}
                        >
                          <option value="">Select Discount Method</option>
                          {discountmethods.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  {(discounttype === "coupon_sale" ||
                    discounttype === "coupon_product" ||
                    discounttype === "coupon_category") && (
                    <div className="form-group mr-4 w-[230px]">
                      <label htmlFor="inputEmail4">Coupon Code</label>
                      <input
                        className="form-control mt-2"
                        name="Discount Title"
                        value={coupon}
                        onChange={(e) => {
                          setCoupon(e.target.value);
                        }}
                        required
                      />
                    </div>
                  )}
                  {discounttype === "access_code" && (
                    <div className="form-group mr-4 w-[230px]">
                      <label htmlFor="inputAccesscode">Access Code</label>
                      <input
                        className="form-control hideNumberArrow mt-2"
                        name="Access Code"
                        type="number"
                        value={accessCode}
                        placeholder="Access Code"
                        onChange={(e) => {
                          setAccessCode(e.target.value);
                        }}
                        required
                      />
                    </div>
                  )}
                  {discounttype === "access_code" && (
                    <div className="form-group mr-4 w-[230px]">
                      <label htmlFor="inputMaxValue">Max Value</label>
                      <input
                        className="form-control hideNumberArrow mt-2"
                        type="number"
                        name="Max Value"
                        value={maxValue}
                        placeholder="Max Value"
                        onChange={handleMaxValueChange}
                        required
                      />
                    </div>
                  )}
                  <div className="mr-4 w-[230px]">
                    <div>
                      <label>Start Date</label>
                    </div>
                    <DatePicker
                      required
                      className="form-control mt-2 w-[230px]"
                      placeholderText="Select Date"
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      minDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="mr-4 w-[230px] overflow-auto">
                    <div>
                      <label>End Date</label>
                    </div>
                    <DatePicker
                      required
                      className="form-control mt-2 w-[230px]"
                      placeholderText="Select Date"
                      dateFormat="MM/dd/yyyy"
                      selected={endDate}
                      minDate={new Date()}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  <div className="form-group mr-4 w-[230px]">
                    <label>Location</label>
                    <ShowComponent condition={fetchingLocations}>
                      <div className="mt-2">
                        <img src="/assets/img/spinner.svg" alt="loading" />
                      </div>
                    </ShowComponent>
                    <ShowComponent condition={!fetchingLocations}>
                      <Select
                        classNames={{
                          control: () => "form-control mt-2 overflow-auto",
                        }}
                        required
                        ref={selectInputRef}
                        value={selectedLocation.map(({ value, label }) => ({
                          value,
                          label,
                        }))}
                        onChange={(selectedOptions) => {
                          setSelectedLocation(selectedOptions);
                        }}
                        options={locations.map((value) => ({
                          value: value.id,
                          label: value.address,
                        }))}
                        isSearchable={true}
                        isMulti={true}
                      />
                    </ShowComponent>
                  </div>
                </div>
              </div>
              {/* {selectedProducts.length > 0 && !showpopup && (
                  <DiscountItemList
                    items={selectedProducts}
                    RemoveItem={handleRemoveProduct}
                    type="product"
                  />
                )} */}
              <div className="text-end mt-5 ml-5 mr-3 pb-3">
                <Button className="w-[15%] btn btn-primary" type="submit">
                  Create Discount
                </Button>
              </div>
            </form>
            <ShowComponent condition={showConfirmationPopup}>
              <Popup
                showpopup={showConfirmationPopup}
                setShowPopUp={() => handleConfirmation(false)}
                ClassName="modal-md"
              >
                <div className="my-2">
                  <p className="mb-2">
                    Are you sure you want to change the discount type?
                  </p>
                  <div className="justify-end">
                    <Button
                      type="button"
                      className="btn btn-primary mr-5"
                      onClick={() => handleConfirmation(true)}
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleConfirmation(false)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Popup>
            </ShowComponent>
            <ShowComponent condition={showpopup}>
              <Popup
                showpopup={showpopup}
                setShowPopUp={setShowPopUp}
                ClassName="modal-lg"
              >
                <div className="modal-footer">
                  <Button
                    type="button"
                    className="btn btn-secondary"
                    onClick={showModal}
                  >
                    OK
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-secondary"
                    onClick={clearSelectedItem}
                  >
                    Cancel
                  </Button>
                </div>
              </Popup>
            </ShowComponent>
          </div>
          {(discounttype === "product" ||
            discounttype === "category" ||
            discounttype === "coupon_product" ||
            discounttype === "coupon_category" ||
            discounttype === "offer_get_y_item" ||
            discounttype === "offer_get_y_amount" ||
            discounttype === "offer_get_y_category") && (
            <>
              <div className="bg-white mt-3 flex">
                {(discounttype === "product" ||
                  discounttype === "coupon_product" ||
                  discounttype === "offer_get_y_item") && (
                  <>
                    <DiscountSelectableList
                      items={products}
                      AddItem={handleAddProduct}
                      RemoveItem={handleRemoveProduct}
                      type="product"
                    />
                    <div className="pb-4 pt-5 ml-2 bg-white w-[50%]">
                      <label className="text-[#34395e] font-bold text-base">
                        Selected Products:
                      </label>
                      <DiscountItemList
                        items={selectedProducts}
                        RemoveItem={handleRemoveProduct}
                        type="product"
                      />
                    </div>
                  </>
                )}
                {(discounttype === "category" ||
                  discounttype === "coupon_category") && (
                  <>
                    <DiscountSelectableList
                      items={categories}
                      AddItem={handleAddCategory}
                      RemoveItem={handleRemoveCategory}
                      type="category"
                    />
                    <div className="py-4 ml-2 bg-white w-[50%]">
                      <label className="text-[#34395e] font-bold text-lg">
                        Selected Categories:
                      </label>
                      <DiscountItemList
                        items={selectedCategories}
                        RemoveItem={handleRemoveCategory}
                        type="category"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="py-4 ml-2 bg-white flex">
                {(discounttype === "offer_get_y_item" ||
                  discounttype === "offer_get_y_amount") && (
                  <>
                    <DiscountSelectableList
                      items={products}
                      AddItem={handleGiftProduct}
                      type="product"
                    />
                    <div className="p-4 w-[50%]">
                      <label className="text-[#34395e] font-bold text-base">
                        Selected Gifted Products:
                      </label>
                      <DiscountItemList
                        items={giftedProducts}
                        RemoveItem={handleRemoveGiftProduct}
                        type="product"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="bg-white p-2 w-full justify-between flex">
                {discounttype === "offer_get_y_category" && (
                  <div className="form-group ">
                    <label htmlFor="inputPassword4">Category</label>
                    <select
                      required
                      className="form-control"
                      value={selecteCategory}
                      onChange={(e) => setSelecteCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {categories.map((obj, index) => (
                        <option key={index} value={obj.category_id}>
                          {obj.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="bg-white p-2 w-full justify-between flex">
                {discounttype === "offer_get_y_category" && (
                  <>
                    <DiscountSelectableList
                      items={products}
                      AddItem={handleGiftProduct}
                      type="product"
                    />
                    <div className="py-4 ml-2 bg-white">
                      <label className="text-[#34395e] font-bold text-lg ml-2">
                        Selected Gifted Products:
                      </label>
                      <DiscountItemList
                        items={giftedProducts}
                        RemoveItem={handleRemoveGiftProduct}
                        type="product"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CreateDiscount;

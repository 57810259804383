import axiosClient from "..";
const Api = "/api/admin/locations";
class officesApi {
  static createOffice(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allOffices() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewOffice(id) {
    return axiosClient.get(`${Api}/${id}.json`);
  }
  static updateOffice(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteOffice(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default officesApi;

import axiosClient from "..";
const Api ="/api/suppliers"
class SupplierApi {
    static createSupplier(data){
        return axiosClient.post(`${Api}.json`, data);
    }
    static updateSupplier(id,data){
        return axiosClient.put(`${Api}/${id}.json`,data);
    }
    static viewSupplier(data){
        return axiosClient.get(`${Api}/${data.id}.json`);
    }
    static DeleteSupplier(id){
        return axiosClient.delete(`${Api}/${id}.json`);
    }
    static AllSupplier(page,filterPerpage,filter,idPredicate,filterId,namePredicate,filterName,phoneNumberPredicate,filterPhoneNumber){
        return axiosClient.get(`${Api}.json?page=${page}&per_page_count=${filterPerpage} ${filter && `&q[id_${idPredicate}]=${filterId}&q[name_${namePredicate}]=${filterName}&q[phone_no_${phoneNumberPredicate}]=${filterPhoneNumber}`}`)
    }
}
export default SupplierApi;

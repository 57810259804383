import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../Pos/Dashboard";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "./AppWrapper";

function PosDashboard() {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const role = currentUser?.role_id;
  return (
    <AppWrapper sideBar={<PosSidebar />}>
        <Routes>
          <Route
            path="/"
            element={
              role === 2 || role === 3 ? (
                <Dashboard />
              ) : (
                currentUser == null && <Navigate to="/login" />
              )
            }
          />
        </Routes>
    </AppWrapper>
  );
}
export default PosDashboard;

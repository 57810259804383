import React, { useState } from 'react';
import HoliDaysApi from 'api/hrmapi/holiDays';
import FormatDate from '../../../Common/FormatDate';
import { useSelector } from 'react-redux';


function CreateHoliDayCalandar({ setPopupId, selectedCountry, setFetchHolidays }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, startDate, endDate } = formData;

    const newHoliday = {
      holiday_calender: {
        // country: selectedCountry,
        // company_id: company_id,
        holidays_attributes: [
          {
            name,
            start_date:FormatDate(startDate),
            end_date: FormatDate(endDate),
            year: new Date(startDate).getFullYear().toString(),
          },
        ],
      },
    };

    HoliDaysApi.createHoliDaysCandaraApi(selectedCountry.id,newHoliday)
      .then((response) => {
        console.log('Holiday added successfully:', response);
       
        setFormData({
          name: '',
          startDate: '',
          endDate: '',
        });
      })
      .catch((error) => {
        console.error('Error adding holiday:', error);
      });
  };

  return (
    <div className="w-full">
      <div className='mb-2 text-22'>Add Holidays</div>
      <form onSubmit={handleSubmit}>
        <div className='mb-2'>Name</div>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full form-control"
          required
        />
        <div className='mb-2'>Start Date</div>
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleChange}
          className="w-full form-control"
          required
        />
        <div className='mb-2'>End Date</div>
        <input
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleChange}
          className="w-full form-control"
          required
        />
        <button type="submit" className="btn-primary mt-4">
          Add Holiday
        </button>
      </form>
    </div>
  );
}

export default CreateHoliDayCalandar;

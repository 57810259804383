import FormatDate from "Components/Common/FormatDate";
import DiscountAPI from "api/posapi/discount";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CouponCart, updateTotals } from "store/Sale";
import OrderApi from "api/posapi/order";
import Loader from "../../../assets/svg/Loader.svg";

function DiscountOffers({ setPopupId }) {
  const dispatch = useDispatch();
  const { totalPrice } = useSelector((state) => state.sales);
  const [tableData, setTableData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [discountType, setDiscountType] = useState("");
  const [selectedGifts, setSelectedGifts] = useState([]);
  const [allproduct, setAllProduct] = useState([]);
  const [qty, setQty] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [minSaleAmount, setMinSaleAmount] = useState(null);
  const saleid = useSelector((state) => state.sales?.saleid);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    OrderApi.productsAvailable()
      .then((response) => {
        setAllProduct(response.data);
      })
      .catch((error) => {
        console.log("");
      });
  }, [qty]);
  useEffect(() => {
    setLoading(true);
    DiscountAPI.getOffersDiscountAPI()
      .then((res) => {
        setTableData(res.data.offers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTitleClick = (
    id,
    title,
    discount_type,
    gifts,
    products,
    minSaleAmount
  ) => {
    setDiscountType(discount_type);
    setSelectedTitle(selectedTitle === id ? null : id);
    setSelectedGifts(gifts || []);
    setSelectedProducts(products || []);
    setMinSaleAmount(minSaleAmount);
  };

  const handleApplyDiscount = (offerId) => {
    const params = {
      discount_id: offerId,
      sale_id: saleid,
    };
    if (
      discountType === "offer_get_y_amount" &&
      parseFloat(minSaleAmount) > parseFloat(totalPrice)
    ) {
      toast.error(`Total Bill Amount is lessthen offer Amount `);
      return;
    }
    DiscountAPI.offersDiscountApplyAPI(params)
      .then((response) => {
        const quantityMap = {};
        allproduct.forEach((product) => {
          const { product_id, quantity } = product;
          quantityMap[product_id] = quantity;
        });
       
        const saleItems = response.data.sale_items.map((saleItem) => {
          const { product_id } = saleItem;
          const totalQuantity = quantityMap[product_id] || 0;
          return {
            product_id: saleItem.product_id,
            product_name: saleItem.product_name,
            total_tax_amount: saleItem.total_tax_amount,
            discount: saleItem.discount,
            qty: saleItem.quantity,
            input_field_value: saleItem.quantity,
            quantity: totalQuantity,
            price: saleItem.price,
            amount: saleItem.amount,
            ch_discount: saleItem.ch_discount,
          };
        });
        dispatch(CouponCart(saleItems));
        setQty(true);
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setSelectedTitle(null);
        setSelectedGifts([]);
        setSelectedProducts([]);
        setMinSaleAmount(null);
        setPopupId("");
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        setPopupId("");
        console.error("Error applying discount:", error);
      });
  };

  return (
    <div className="w-full">
      <div className="mb-2">Offers</div>
      {loading ? (
        <div
          className={`flex justify-center items-center w-full p-3 ${
            loading && "loader"
          }`}
        >
          <img src={Loader} className="m-auto" alt="" />
        </div>
      ) : (
        <>
          {tableData.length > 0 ? (
            <table className="min-w-full divide-y border divide-gray-200 overflow-y-scroll max-h-96">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Expiry Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData.map((offer, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      handleTitleClick(
                        offer.id,
                        offer.title,
                        offer.discount_type,
                        offer.andar_wala_packet?.gifts,
                        offer.andar_wala_packet?.products,
                        offer.andar_wala_packet?.min_sale_amount
                      )
                    }
                    className={`${
                      selectedTitle === offer.id
                        ? "bg-blue"
                        : "hover:bg-gray-100"
                    } cursor-pointer`}
                  >
                    <td className="px-3 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{offer.title}</div>
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {FormatDate(offer.start_date)}
                      </div>
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {FormatDate(offer.end_date)}
                      </div>
                    </td>
                    <td
                      className="px-3 py-2 whitespace-nowrap cursor-pointer"
                      onClick={() => handleApplyDiscount(offer.id)}
                    >
                      <div className="text-sm text-gray-900">Apply</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="p-4 w-full text-center">No Offer Available</div>
          )}
        </>
      )}
      {selectedTitle && (
        <>
          {selectedProducts.length > 0 && (
            <div className="mt-5">
              <div>Products </div>
              <div className="bordered mt-3">
                <table className="min-w-full divide-y border divide-gray-200 overflow-y-scroll max-h-96">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedProducts.map((product, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2 w-1/2 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {product.id}
                          </div>
                        </td>
                        <td className="px-3 py-2 w-1/2 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {product.name}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {minSaleAmount && (
            <div className="mt-5">
              <div>Minimum Sale Amount</div>
              <div className="bordered mt-3">
                <div className="text-sm text-gray-900 px-3 py-2">
                  {minSaleAmount}
                </div>
              </div>
            </div>
          )}
          {selectedGifts.length > 0 && (
            <div className="mt-5">
              <div>Gifted Items</div>
              <div className="bordered mt-3">
                <table className="min-w-full divide-y border divide-gray-200 overflow-y-scroll max-h-96">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedGifts.map((gift, index) => (
                      <tr key={index}>
                        <td className="px-3 py-2 w-1/2 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{gift.id}</div>
                        </td>
                        <td className="px-3 py-2 w-1/2 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {gift.name}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default DiscountOffers;

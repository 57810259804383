import React from "react";
import Sidebar from "./Sidebar";

function AccountsSidebar() {
  const menuItemsAccounts = [
    {
      key: "maindashboard",
      text: "Main Dashboard",
      src: "/icons/dashboard.svg",
      path: "/",
      allowedRoles: [2,3],
    },
    {
      key: "dashboardaccounts",
      text: "Dashboard",
      src: "/icons/dashboard.svg",
      path: "/accounts-dashboard",
      allowedRoles: [2,3],
    },
    {
      key: "customer",
      text: "Customers",
      src:"/icons/allCustomers.svg",
      path: "/accounts-customers",
      allowedRoles: [2,3],
    },
    {
      key: "supplier",
      text: "Suppliers",
      src:"/icons/supplier.svg",
      path: "/all-supplier",
      allowedRoles: [2,3],
    },
  ];
  return (
    <>
      <Sidebar menuItems={menuItemsAccounts} />
    </>
  );
}
export default AccountsSidebar;
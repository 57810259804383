import React, { useEffect, useState } from "react";
import CategoriesAPI from "api/posapi/categories";
import ProductsAPI from "api/posapi/product";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import GernalInput from "Components/Common/GeneralInput";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import TaxAPI from "api/posapi/tax";
import { toast } from "react-toastify";
function CreateProduct({setPopupId,fetchProducts}) {
  const [formData, setFormData] = useState({
    productName: "",
    selectedCategory: "Select Category",
    createCategory: "",
    sku: "",
    barcode: "",
    barcode_error: false,
  });
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [productTax, setProductTax] = useState([]);
  const [selectedTax, setSelectedTax] = useState("");
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };
  const error_messages = {
    barcode_error: "Number of characters should be equal to 13!",
  };
  const {
    productName,
    selectedCategory,
    createCategory,
    sku,
    barcode,
    barcode_error,
  } = formData;

  const CreateCategories = (e) => {
    e.preventDefault();
    setLoader(true);
    if(!createCategory){
      toast.error(`Category Name can't be blank`)
      return;
    }
    CategoriesAPI.createCategoriesAPI({
      category: {
        name: createCategory,
      },
    })
      .then((res) => {
        setFormData({
          ...formData,
          createCategory: "",
          selectedCategory: res.data.category.id,
        });
        setCategories([...categories, res.data.category]);
        const Message = res ? res.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error creating category:", error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        setLoader(false);
      });
  };
  useEffect(() => {
    CategoriesAPI.allCategoriesAPI()
      .then((res) => {
        if (res && res.data && res.data.categories) {
          setCategories(res.data.categories);
        } else {
          console.error("Invalid response format:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });

    TaxAPI.allTaxAPI()
      .then((response) => {
        setProductTax(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleCategoryChange = (event) => {
    setFormData({ ...formData, selectedCategory: event.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (barcode.length !== 13) {
      setFormData({ ...formData, error: "Barcode length must be 13 digits." });
      setTimeout(() => {
        setFormData({ ...formData, error: "" });
      }, 3000);
      return;
    }
    setLoading(true);
    ProductsAPI.createProductAPI({
      product: {
        name: productName,
        category_id: parseInt(selectedCategory),
        sku: sku,
        barcode: barcode,
        tax_id: parseInt(selectedTax),
      },
    })
      .then((res) => {
        setPopupId("")
        fetchProducts();
        const id = res?.data.product_id;
        const imageFormData = new FormData();
        imageFormData.set("product[avatar]", image);
        ProductsAPI.uploadProductImgAPI(id, imageFormData)
          .then((response) => {
            setImage(null);
            const fileInput = document.getElementById("browsecsv");
            fileInput.value = "";
            imageFormData(null);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            setLoading(false);
          });
        setFormData({
          ...formData,
          productName: "",
          barcode: "",
          sku: "",
          selectedCategory: "",
        });
        setSelectedTax("");
        setLoading(false);

        const Message = res?.data?.msg
        toast.success(`Success:${Message}`);
      })
      .catch((error) => {
        toast.error(`Error: ${error?.response?.data?.error}`);
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const remainingCharacters = 13 - value.length;
    setFormData({
      ...formData,
      barcode: value,
      barcode_error: value.length !== 13,
      error:
        remainingCharacters >= 1
          ? `Remaining characters: ${remainingCharacters}`
          : "",
    });
  };
  const categoriesOptions = [
    { value: "", label: "Select Category" },
    ...categories.map((category) => ({
      value: category.id,
      label: category.name,
    })),
  ];
  const additionalInputFields = [
    {
      title: "Product Title",
      value: productName,
      onChange: (e) =>
        setFormData({ ...formData, productName: e.target.value }),
      placeholder: "Product Title",
      name: "productName",
      className: "form-group",
      required: true,
    },
    {
      title: "SKU",
      placeholder: "Sku",
      value: sku,
      onChange: (e) => setFormData({ ...formData, sku: e.target.value }),
      name: "sku",
      className: "form-group",
      required: true,
    },
    {
      title: "Barcode",
      value: barcode,
      onChange: handleInputChange,
      type: "number",
      placeholder: "Barcode",
      name: "barcode",
      className: "form-group",
      required: true,
    },
  ];

  return (
    <PagesWrapper>
      <div className="p-4">
        <div className="text-24 mb-2 w-full text-center">
          <h1>Create Product</h1>
        </div>
          <form onSubmit={handleFormSubmit}>
            <div className="row w-full">
              <p className="my-4 w-full text-center text-red">Note: Please create or select a Category</p>
              <div className="mb-2 w-full">
                <div className="mb-2"> Category</div>
                <input
                  placeholder="Enter Category Name"
                  className="form-control"
                  value={createCategory}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      createCategory: e.target.value,
                    })
                  }
                />
              </div>
              <div className="text-center w-full">
                <button
                  className={
                    loader
                      ? "w-[40%] btn-primary cursor-not-allowed btn-progress"
                      : "w-[40%] btn-primary "
                  }
                  type="button"
                  onClick={CreateCategories}
                >
                  Create Category
                </button>
              </div>

              <div className="w-full">
              <GernalSelectDropDown
                title="Category"
                required={true}
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={categoriesOptions}
                className="form-control"
                name="selectedCategory"
              />
              </div>

              <div className="w-full">
              {additionalInputFields.map((obj, index) => (
                <GernalInput
                  className="form-group "
                  key={index}
                  title={obj.title}
                  value={obj.value}
                  onChange={obj.onChange}
                  placeholder={obj.placeholder}
                  isSelect={obj.isSelect}
                  type={obj.type}
                  required={obj.required}
                />
              ))}
              </div>
              <div className="w-full">
                <div htmlFor="browsecsv" className="mb-2">
                  Product Image
                </div>
                <input
                  type="file"
                  id="browsecsv"
                  accept=".png, .jpg, .jpeg ,webp"
                  onChange={handleImageChange}
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-12">
                <div className="mb-2">VAT</div>
                <select
                  className="form-control"
                  value={selectedTax}
                  onChange={(e) => {
                    setSelectedTax(e.target.value);
                  }}
                >
                  <option value=""> Please select VAT type</option>
                  {productTax.map((object, index) => (
                    <option key={index} value={object.id}>
                      {`${object.name} ${object.percentage}%`}
                    </option>
                  ))}
                </select>
              </div>
              {barcode_error && (
                <p style={{ color: "red" }}>{error_messages.barcode_error}</p>
              )}
            </div>
            <div className="text-center mt-5">
              <button
                type="submit"
                className={
                  loading
                    ? "w-[40%] btn-primary cursor-not-allowed btn-progress"
                    : "w-[40%] btn-primary "
                }
              >
                Create Product
              </button>
            </div>
          </form>
      </div>
    </PagesWrapper>
  );
}
export default CreateProduct;

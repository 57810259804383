import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from "./Auth/index";
import routeReducer from "./Routes/index";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import salesReducer from "./Sale/index"
import TaxfreeReducer from "./Taxfree/index"
import SaleCommission from "./SaleCommission";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  route: routeReducer,
  sales: salesReducer,
  saleCommission:SaleCommission,
  Taxfree: TaxfreeReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
const persistor = persistStore(store);
// const unsubscribe = store.subscribe(() => {});
export { store, persistor };

import axiosClient from "..";
const Api = "/api/customers"
class CustomerApi {
    static createCustomer(data) {
        return axiosClient.post(`${Api}.json`, data);
    }
    static updateCustomer(id, data) {
        return axiosClient.put(`${Api}/${id}.json`, data);
    }
    static viewCustomer(data) {
        return axiosClient.get(`${Api}/${data.id}.json`);
    }
    static DeleteCustomer(id) {
        return axiosClient.delete(`${Api}/${id}.json`);
    }
    static AllCustomer(page, filterPerpage, selectedLocation, filter, idPredicate, filterId, namePredicate, filterName, phoneNumberPredicate, filterPhoneNumber, customerTypePredicate, filterCustomerType) {
        return axiosClient.get(`${Api}.json?page=${page}&per_page_count=${filterPerpage}&q[sales_location_id_eq]=${selectedLocation} ${filter && `&q[id_${idPredicate}]=${filterId}&q[name_${namePredicate}]=${filterName}&q[phone_no_${phoneNumberPredicate}]=${filterPhoneNumber}&q[customer_type_eq${customerTypePredicate}]=${filterCustomerType} `}`)
    }
    static getPassportDetails(mrz) {
        return axiosClient.post('/api/admin/pos/cart-updates/validate-passport', mrz)
    }
    static AllCountries(){
        return axiosClient.get("/api/configurations/countries.json");
    }
}
export default CustomerApi;

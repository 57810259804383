import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import TableDetails from "Components/Common/TableDetails";
import SupplierApi from "api/supplierapi/supplier";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";

function SupplierDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    SupplierApi.viewSupplier(params)
      .then((response) => {
        setDataToShow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-supplier");
  };

  return (
    <>
      <AppWrapper sideBar={<AccountsSidebar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Supplier Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="w-1/2">
            <TableDetails data={dataToShow} loading={loading} header={false} />
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default SupplierDetails;

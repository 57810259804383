import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import TransfersAPI from "api/posapi/transfers";
import FormatDate from "Components/Common/FormatDate";
import { useNavigate } from "react-router-dom";
function Transfers() {
  let params = useParams();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [status, setStatus] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const [date, setDate] = useState("");
  const [feedback, setFeedBack] = useState("");
  const handleTransferHistory = () => {
    setTimeout(() => {
      navigate(`/transfer-history`);
    }, 2000);
  };
  const handleErrorMessage = (error) => {
    const errorMessage = error.response?.data.message
      ? error.response.data.message
      : "An error occurred";

    toast.error(`Error: ${errorMessage}`);
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`);
  };
  const handleSendToBackend = (e) => {
    e.preventDefault();
    if (status === prevStatus) {
      toast.error(
        `Error: Please change status!. Transfer not allowed for current status.`
      );
      return;
    }
    if (status === "transit") {
      const productsDetail = tableData.map((item) => ({
        id: item.product_id,
        quantity: item.approved_qty,
      }));

      const transfer = {
        transfer: {
          id: params.id,
          feedback: feedback,
          products_detail: productsDetail,
        },
      };

      TransfersAPI.approveTransferAPI(transfer)
        .then((response) => {
          handleSuccessMessage(response);
          handleTransferHistory();
          setLoading(false);
        })
        .catch((error) => {
          handleErrorMessage(error);
          console.error("Error sending purchase data:", error);
          setLoading(false);
        });
    }
    if (status === "completed") {
      const productsDetail = tableData.map((item) => ({
        id: item.product_id,
        quantity: item.approved_qty,
      }));

      const transfer = {
        transfer: {
          id: params.id,
          feedback: feedback,
          products_detail: productsDetail,
        },
      };
      TransfersAPI.receiveTransferAPI(transfer)
        .then((response) => {
          handleSuccessMessage(response);
          handleTransferHistory();
          setLoading(false);
        })
        .catch((error) => {
          handleErrorMessage(error);
          console.error("Error sending purchase data:", error);
          setLoading(false);
        });
    }
    if (status === "rejected") {
      handleRejected();
    }
  };
  const handleRejected = () => {
    TransfersAPI.rejectTransferAPI(params)
      .then((response) => {
        handleSuccessMessage(response);
        handleTransferHistory();
        setLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.error("Error sending purchase data:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    TransfersAPI.viewTransfersAPI(params)
      .then((response) => {
        setTableData(response.data.inventories);
        setDate(response.data.inventories[0].updated_at);
        setFromLocation(response.data.metadata.source_loc);
        setToLocation(response.data.metadata.dest_loc);
        setCreatedBy(response.data.metadata.created_by);
        setStatus(response.data.metadata.status);
        setPrevStatus(response.data.metadata.status);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleQtyChange = (product, value, quantityType) => {
    let updatedProduct = {};

    if (!value) {
      updatedProduct = {
        ...product,
        [`${quantityType}_qty`]: null,
      };
    } else {
      updatedProduct = {
        ...product,
        [`${quantityType}_qty`]: parseInt(value),
      };
    }

    setTableData((prevTableData) =>
      prevTableData.map((item) =>
        item.id === updatedProduct.id ? updatedProduct : item
      )
    );
  };

  const handleOnBlur = (product, value, quantityType) => {
    let updatedProduct = {};

    if (!value) {
      updatedProduct = {
        ...product,
        [`${quantityType}_qty`]: 1,
      };
    }

    setTableData((prevTableData) =>
      prevTableData.map((item) =>
        item.id === updatedProduct.id ? updatedProduct : item
      )
    );
  };

  const handleBack = () => {
    navigate("/transfer-history");
  };

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Transfer Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div className="w-full">
            <div className="flex w-full mt-4 justify-between mb-4">
              <div className="form-group w-[30%] mb-2">
                <div className="mb-4">From Location </div>
                <input required className="form-control" value={fromLocation} />
              </div>
              <div className="form-group w-[30%] mb-2">
                <div className="mb-4">To Location </div>
                <input className="form-control" value={toLocation} />
              </div>
              <div className="form-group w-[30%] mb-2">
                <div className="mb-4">Created By </div>
                <input className="form-control" value={createdBy} />
              </div>
            </div>
            <div className="flex w-full gap-12">
              <div className="form-group w-[30%] mb-2">
                <div className="mb-4">Date </div>
                <input className="form-control" value={FormatDate(date)} />
              </div>
              <div className="form-group w-[30%] mb-2">
                <div className="mb-4">Status </div>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  required
                >
                  {status === "pending" ? (
                    <>
                      <option value="pending">Pending </option>
                      <option value="transit">Approved </option>
                      <option value="rejected">Rejected </option>
                    </>
                  ) : status === "transit" ? (
                    <>
                      <option value="transit">Approved </option>
                      <option value="completed">Completed </option>
                    </>
                  ) : status === "rejected" ? (
                    <option value="rejected">Rejected </option>
                  ) : status === "completed" ? (
                    <option value="completed">Completed </option>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              <div className=" w-[30%] flex justify-start  mt-4">
                <div className="w-[30%] ">
                  <button
                    className="btn-primary w-full"
                    onClick={handleRejected}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div
              className="lg:w-full bg-white rounded-3xl p-3 mt-6"
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <div className="text-[#287DFD] flex justify-center p-2">
                <div className="w-1/4 flex justify-center">
                  <span>Product</span>
                </div>
                <div className="w-1/4 flex justify-center">
                  <span>Requested Qty</span>
                </div>
                <div className="w-1/4 flex justify-center">
                  <span>Approved Qty</span>
                </div>
                <div className="w-1/4 flex justify-center">
                  <span>Received Qty</span>
                </div>
              </div>
              {tableData.map((product) => {
                return (
                  <div
                    className="flex justify-between p-2"
                    key={product.product_id}
                  >
                    <div className="w-1/4 flex justify-start">
                      {product.product_name}
                    </div>
                    <div className="w-1/4 flex justify-center ">
                      <div className="w-1/3 relative flex justify-center items-center">
                        <button className="border-0 cursor-pointer bg-[#287DFD] rounded-full outline-none absolute left-0 h-[19px] w-[19px] text-white">
                          -
                        </button>
                        <input
                          type="number"
                          className="hideNumberArrow text-center h-[19px] border border-[#D9D9D9] rounded-full w-full"
                          value={product.requested_qty}
                          readOnly
                        />
                        <button className="border-0 cursor-pointer bg-[#287DFD] rounded-full absolute right-0 outline-none h-[19px] w-[19px] text-white">
                          +
                        </button>
                      </div>
                    </div>
                    <div className="w-1/4 flex justify-center ">
                      <div className="w-1/2 relative flex justify-center items-center">
                        <button
                          className="border-0 cursor-pointer bg-[#287DFD] rounded-full outline-none absolute left-0 h-[19px] w-[19px] text-white"
                          onClick={(e) =>
                            handleQtyChange(
                              product,
                              product.approved_qty - 1,
                              "approved"
                            )
                          }
                          disabled={product.approved_qty === 1}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="hideNumberArrow text-center h-[19px] border border-[#D9D9D9] rounded-full w-full"
                          value={product.approved_qty}
                          onChange={(e) =>
                            handleQtyChange(product, e.target.value, "approved")
                          }
                          onBlur={(e) =>
                            handleOnBlur(product, e.target.value, "approved")
                          }
                        />
                        <button
                          className="border-0 cursor-pointer bg-[#287DFD] rounded-full absolute right-0 outline-none h-[19px] w-[19px] text-white"
                          onClick={(e) =>
                            handleQtyChange(
                              product,
                              product.approved_qty + 1,
                              "approved"
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div className="w-1/4 flex justify-center">
                      <div className="w-1/2 relative flex justify-center items-center">
                        <button
                          className="border-0 cursor-pointer bg-[#287DFD] rounded-full outline-none absolute left-0 h-[19px] w-[19px] text-white"
                          // onClick={(e) =>
                          //   handleQtyChange(
                          //     product,
                          //     product.received_qty - 1,
                          //     "received"
                          //   )
                          // }
                          // disabled={product.received_qty === 1}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="hideNumberArrow text-center h-[19px] border border-[#D9D9D9] rounded-full w-full"
                          value={
                            prevStatus === "transit" ? product.approved_qty : ""
                          }
                          readOnly
                          onChange={(e) =>
                            handleQtyChange(product, e.target.value, "received")
                          }
                        />
                        <button className="border-0 cursor-pointer bg-[#287DFD] rounded-full absolute right-0 outline-none h-[19px] w-[19px] text-white">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-full mt-2">
              <label htmlFor="feedback">Feedback</label>
              <div>
                <textarea
                  className="p-2"
                  id="feedback"
                  name="feedback"
                  value={feedback}
                  onChange={(e) => setFeedBack(e.target.value)}
                  rows="4"
                  cols="50"
                />
              </div>
            </div>

            <div className="flex w-full  mb-4 justify-end">
              {prevStatus !== "completed" && prevStatus !== "rejected" && (
                <Button
                  onClick={handleSendToBackend}
                  className="w-[9%]  btn-primary"
                  type="submit"
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default Transfers;

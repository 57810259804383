import React, { useState } from "react";
import OrderApi from "api/posapi/order";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
function CashToVisa({ setPopupId }) {
  const access_code = useSelector(
    (state) => state.auth?.currentUser.access_code
  );
  const defaultLoc = useSelector((state) => state.auth.currentUser.default_loc);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [saleId, setSaleId] = useState(null);
  const [cashAmount, setCashAmount] = useState(null);
  const [cardAmount, setCardAmount] = useState(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      OrderApi.invoiceSearch(invoiceNo)
        .then((res) => {
          setCashAmount(
            res?.data.sale[0].sale_received_amounts.find(
              (element) => element.payment_method == "cash"
            ).amount
          );
          setCardAmount(
            res?.data.sale[0].sale_received_amounts.find(
              (element) => element.payment_method == "card"
            ).amount
          );
          setSaleId(res?.data.sale[0].id);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleTransferAmount = (transferMethod) => {
    OrderApi.amountTransfer(saleId, transferMethod, access_code)
      .then((res) => {
        toast.success(`Success: Transfered `);
        setTimeout(() => {
          setPopupId("");
          setInvoiceNo("");
        }, 2000);
      })
      .catch((error) => {
        console.log("");
      });
  };
  return (
    <div>
      <div className="">Cash To Visa </div>
      <div className="w-full">
        <div className="w-full mb-10">
          <div className=" flex  justify-between">
            <div className="w-[30%] p-3">Branch No:</div>
            <div className="w-[60%]">
              <input
                type="text"
                value={defaultLoc}
                readOnly
                className="form-control"
              />
            </div>
          </div>
          <div className=" flex  justify-between">
            <div className="w-[30%] p-3">Bill No:</div>
            <div className="w-[60%]">
              <input
                type="text"
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
                onKeyDown={handleKeyDown}
                className="form-control"
              />
            </div>
          </div>
          <div className=" flex  justify-between">
            <div className="w-[30%] p-3">Cash Amount:</div>
            <div className="w-[60%]">
              <input
                type="text"
                name="cashAmount"
                value={cashAmount}
                readOnly
                onChange={(e) => setCashAmount(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className=" flex  justify-between">
            <div className="w-[30%] p-3">Card Amount:</div>
            <div className="w-[60%]">
              <input
                type="text"
                name="cardAmount"
                readOnly
                value={cardAmount}
                onChange={(e) => setCardAmount(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="w-full justify-end flex">
            <div className="flex justify-between w-[90%] ">
              <div className="w-[30%]">
                <button
                  className=" bg-white border p-4 w-full"
                  onClick={() => handleTransferAmount("cash_to_visa")}
                >
                  Cash To Visa
                </button>
              </div>
              <div className="w-[30%]">
                <button
                  className=" bg-white border p-4 w-full"
                  onClick={() => handleTransferAmount("visa_to_cash")}
                >
                  Visa To Cash
                </button>
              </div>
              <div className="w-[30%]">
                <button
                  className=" btn-primary w-full "
                  onClick={() => setPopupId("")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashToVisa;

import React from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";

function PageNotFound() {
  return (
    <PagesWrapper>
      <div class="flex justify-center items-center h-screen">
        <div class="text-center">
          <h1 class="text-9xl font-bold mb-10">404</h1>
          <div class="text-2xl text-gray-700 mb-8">
            The page you were looking for could not be found.
          </div>
        </div>
      </div>
    </PagesWrapper>
  );
}

export default PageNotFound;

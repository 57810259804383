import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import Table from "Components/Common/GeneralTable";
import AppWrapper from "Components/Routes/AppWrapper";
import DiscountAPI from "api/posapi/discount";

function AllCoupons() {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    DiscountAPI.allCouposAPI()
      .then((response) => {
        setDataToShow(response.data.discount_coupons);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between">
          <div className="text-22 ">
            <h1>Coupon</h1>
          </div>
        </div>
        <Table data={dataToShow} loading={loading} />
      </PagesWrapper>
    </AppWrapper>
  );
}
export default AllCoupons;

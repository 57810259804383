import React, { useState, useEffect } from "react";
import Button from "Components/Common/Button";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import LocationsAPI from "api/location/location";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";

function CreateLocationPermission({ contentOnly = false, fetchSuppliers, setPopupId, fetchUsers }) {
  const [user, setUser] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Location, setLocation] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAllLocations, setSelectAllLocations] = useState(false);

  const handleSendToBackend = (e) => {
    e.preventDefault();
    setLoading(true);
    const locationsArray =
      selectedLocations.length === Location.length ? [0] : selectedLocations;
    LocationsAPI.updateUserLocation({
      location: {
        user_id: parseInt(selectUser),
        default_loc: parseInt(defaultLocation),
        locations: locationsArray,
      },
    })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(()=>{
          fetchUsers();
          setPopupId("");
        },1500)
        setSelectedLocations([]);
        setSelectAllLocations(false);
        setDefaultLocation("");
        setSelectUser("");
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data.errors);
        console.error("Error sending purchase data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    LocationsAPI.allAdminUser()
      .then((response) => {
        setUser(response.data.user);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setLocation(response.data.locations);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleLocationSelection = (e, locationId) => {
    if (locationId === 0) {
      if (e.target.checked) {
        setSelectedLocations(Location.map((item) => item.id));
      } else {
        setSelectedLocations([]);
      }
    } else {
      const updatedSelectedLocations = [...selectedLocations];
      if (e.target.checked) {
        updatedSelectedLocations.push(locationId);
      } else {
        const index = updatedSelectedLocations.indexOf(locationId);
        if (index !== -1) {
          updatedSelectedLocations.splice(index, 1);
        }
      }
      const allLocationsSelected =
        updatedSelectedLocations.length === Location.length;

      setSelectedLocations(updatedSelectedLocations);
      setSelectAllLocations(allLocationsSelected);
    }
  };

  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
        <PagesWrapper>
          <div className="text-24 w-full text-center mb-8">
            <h1> Update User Default Location</h1>
          </div>
          <div className="w-full">
            <form onSubmit={handleSendToBackend}>
              <div className="flex  flex-wrap -mx-2">
                <div className="w-1/3 p-4">
                  <div className="mb-2">Select Email/Username</div>
                  <select
                    className="form-control"
                    value={selectUser}
                    onChange={(e) => {
                      setSelectUser(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select Email/Username </option>
                    {user.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.email === "" ? option.username : option.email}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Select Default Location</div>
                  <select
                    className="form-control"
                    value={defaultLocation}
                    onChange={(e) => {
                      setDefaultLocation(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select Location </option>
                    {Location.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.address}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full">
                <div className="mb-2 text-24">Permissions:</div>

                <div className="flex w-full">
                  <div className="w-1/2 bg-white rounded-xl h-[230px] overflow-y-auto overflow-x-hidden mr-3 border-2">
                    <div className="flex mb-3 p-3">
                      <div className="text-[20px] font-semibold">Locations</div>
                      <div className="w-full justify-end flex px-2">
                        <input
                          type="checkbox"
                          name="topping"
                          className="mr-4"
                          onChange={(e) => {
                            setSelectAllLocations(e.target.checked);
                            handleLocationSelection(e, 0);
                          }}
                          checked={selectAllLocations}
                        />
                        Select All
                      </div>
                    </div>
                    <table className="m-3 p-3">
                      <tbody className="my-3 py-3">
                        {Location.reduce((rows, item, index) => {
                          if (index % 2 === 0) rows.push([]);
                          rows[rows.length - 1].push(item);
                          return rows;
                        }, []).map((row, rowIndex) => (
                          <tr key={rowIndex} className="w-full justify-center">
                            {row.map((item, itemIndex) => (
                              <React.Fragment key={itemIndex}>
                                <td className="w-[10%] my-2 py-2">
                                  <input
                                    type="checkbox"
                                    className="ml-2"
                                    name="topping"
                                    onChange={(e) =>
                                      handleLocationSelection(e, item.id)
                                    }
                                    checked={selectedLocations.includes(
                                      item.id
                                    )}
                                  />
                                </td>
                                <td className="w-[45%] my-2 py-2 text-center">
                                  {item.address}
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-1/2 bg-white rounded-xl h-[230px] overflow-y-auto overflow-x-hidden ml-3 border-2">
                    <div className="flex mb-3 p-3">
                      <div className="text-[20px] font-semibold">
                        Selected Locations
                      </div>
                    </div>
                    <table className="w-full m-3 p-3">
                      <tbody className="my-3 py-3">
                        {selectedLocations.map((selectedItemId, index) => {
                          const selectedItem = Location.find(
                            (item) => item.id === selectedItemId
                          );
                          if (index % 2 === 0) {
                            return (
                              <tr key={index}>
                                <td className="w-[50%] pb-4">
                                  <div className="flex items-center">
                                    <div className="w-2 h-2 bg-black rounded-full mr-2"></div>
                                    <div className="flex-1">
                                      {selectedItem.address}
                                    </div>
                                  </div>
                                </td>
                                {selectedLocations[index + 1] && (
                                  <td className="w-[50%] pb-4">
                                    <div className="flex items-center">
                                      <div className="w-2 h-2 bg-black rounded-full mr-2"></div>
                                      <div className="flex-1">
                                        {
                                          Location.find(
                                            (item) =>
                                              item.id ===
                                              selectedLocations[index + 1]
                                          ).address
                                        }
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="text-end mt-4">
                <Button
                  className={
                    loading
                      ? "w-[14%] btn btn-primary btn-progress"
                      : "w-[14%] btn btn-primary"
                  }
                  type="submit"
                >
                  Permission
                </Button>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CreateLocationPermission;

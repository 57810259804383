import React, { useEffect, useState } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Table from "Components/Common/GeneralTable";
import EmployeesApi from "api/hrmapi/employee";
import { toast } from "react-toastify";
import HrmSidebar from "Components/Common/HrmSidebar";
import UserApi from "api/adminapi/user";

function InvitationEmployee() {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleInvitations = () => {
    if(dataToShow.length){
    const userIds = dataToShow.map(user => user.id); 
    EmployeesApi.resendInviteEmployees({ resend_ids: { ids: userIds } })
      .then(response => {
        toast.success("Invitations sent successfully.");
        console.log("Invitations sent successfully.");
      })
      .catch(error => {
        toast.error("An error occurred while sending invitations.");
      });
    }
  };
  const handleSendClick = (itemId) => {
    EmployeesApi.resendInviteEmployees({ resend_ids: { ids: itemId } })
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred while sending invitation.");
        setLoading(false);
      });
  };
  useEffect(() => {
    EmployeesApi.invitationsEmployees()
      .then((res) => {
        const newData = res?.data.users.map((item) => ({
            id: item.id,
            email: item.email,
            company_name: item.company_name,
            invitation_sent_at: item.invitation_sent_at,         
            employee: item.employee,
            reinvite_enable: true,
          }));
        setDataToShow(newData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching invitation data:", error);
      });
  }, []);

  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between">
          <div className="text-24 mb-4 ">
            <h1>Invitation Employee</h1>
          </div>
          <div className="w-[34%] flex justify-end mt-2 text-end">
            <button className="btn-primary w-[45%]" onClick={handleInvitations}>
              All Invitation
            </button>
          </div>
        </div>
        <Table
          data={dataToShow}
          loading={loading}
          onSendClick={handleSendClick}
        />
      </PagesWrapper>
    </AppWrapper>
  );
}

export default InvitationEmployee;

import axiosClient from "..";
const Api = "/api/hrm/settings/holiday_calenders";
class HoliDaysApi {
  static createHoliDaysCandaraApi(id,data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static createHoliDaysApi(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allHoliDaysApi() {
    return axiosClient.get(`${Api}.json`);
  }

  static updateHoliDaysApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static getHoliDaysApi(id) {
    return axiosClient.get(`${Api}/${id}.json`);
  }
  static deleteHoliDaysApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default HoliDaysApi;

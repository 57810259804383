import axiosClient from "..";
const Api = "/api/hrm/payroll/allowances";
class AllowanceApi {
  static creatAllowanceApi(data) {
    return axiosClient.post(`${Api}.json`, data);
  }
  static allAllowanceApi() {
    return axiosClient.get(`${Api}.json`);
  }
  static viewAllowanceApi(data) {
    return axiosClient.get(`${Api}/${data.id}.json`);
  }
  static updateAllowanceApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteAllowanceApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default AllowanceApi;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentUser: null,
  token: null,
  verified: null,
  role_id: "",
  user_name:"",
  default_loc:null,
  company_id:null,
  access_code:null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.user_name = action.payload.user_name;
      state.token = action.payload.token;
      state.role_id = action.payload.role_id;
      state.company_id = action.payload.company_id;
      state.default_loc = action.payload.default_loc;
      state.access_code = action.payload.access_code;
      state.isFetching = true;
    },
    clearAllAuthData: (state, action) => {
      state.currentUser = null;
      state.token = null;
      state.role_id = "";
      state.company_id = "";
      state.user_name=""
      state.default_loc=""
      state.access_code=""
    },
    setVerified: (state, action) => {
      state.verified = action.payload.verified;
    },
  },
});
export const { setAuthData, clearAllAuthData, setVerified } = authSlice.actions;
export default authSlice.reducer;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import DiscountAPI from "api/posapi/discount";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";

function DiscountDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    DiscountAPI.getDiscountAPI(params)
      .then((response) => {
        setDataToShow([response.data]);
        setLoading(false);
      })
      .catch((error) =>{
        console.log(error)
        setLoading(false);
      });
  }, []);

  return (
    <>
     <AppWrapper sideBar={<PosSidebar />}>
    <PagesWrapper>
      <div className="section-header">
        <h1>Discount Details</h1>
      </div>
      <div className="text-center card-header">Discount</div>
      <Table data={dataToShow} loading={loading} />
     
    </PagesWrapper>
    </AppWrapper>
    </>
  );
}

export default DiscountDetails;

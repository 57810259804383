import React, { useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function CreateVariablePay({ contentOnly = false, setPopupId }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [variablePayType, setVariablePayType] = useState("");
  const [variablePayTypeId, setVariablePayTypeId] = useState("");
  const [allVariablePayType, setAllVariablePayTypeId] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  const handleCreateVariablePay = (e) => {
    e.preventDefault();
    setLoading(true);
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-center text-center items-center pt-4">
          <div className="text-24">Variable Pay</div>
        </div>
        <div className="p-2">Unpaid</div>
        <div className="border-b"></div>
        <div className="bg-white rounded-3xl w-full  min-h-[60vh]">
          <form onSubmit={handleCreateVariablePay}>
            <div className="w-full flex flex-wrap gap-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Employee Name/ID</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Variable Pay Type</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <Select
                    value={{ value: variablePayTypeId, label: variablePayType }}
                    required
                    onChange={(obj) => {
                      setVariablePayType(obj.label);
                      setVariablePayTypeId(obj.value);
                    }}
                    options={allVariablePayType}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Amount</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="relative w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Start Date</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div className=" justify-between flex relative">
                  <div className="w-full">
                    <DatePicker
                      required
                      showIcon
                      className="datePickerWidth form-control "
                      dateFormat="dd/MM/yyyy"
                      onKeyDown={(e) => e.preventDefault()}
                      disabledKeyboardNavigation
                      selected={startDate}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>End Date</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div className=" justify-between flex relative">
                  <div className="w-full">
                    <DatePicker
                      required
                      showIcon
                      className="datePickerWidth form-control "
                      dateFormat="dd/MM/yyyy"
                      onKeyDown={(e) => e.preventDefault()}
                      disabledKeyboardNavigation
                      selected={endDate}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Currency</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <Select
                    value={{ value: currency, label: currency }}
                    required
                    menuPosition="fixed"
                    onChange={(obj) => setCurrency(obj.label)}
                    options={allCurrency}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Payroll ID</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <Select
                    value={{ value: currency, label: currency }}
                    required
                    menuPosition="fixed"
                    onChange={(obj) => setCurrency(obj.label)}
                    options={allCurrency}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Remarks</div>
                  {/* <div className="text-red">*</div> */}
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full flex   p-3 bg-body">
                <div className="w-1/2 felx justify-end items-end text-end">
                  Total Unpaid
                </div>
                <div className="w-1/2 justify-around flex items-center">
                  <div>AED</div>
                  <div>0.00</div>
                </div>
              </div>
              <div className="p-5">Processed</div>
              <div className="border-b"></div>
              <div class="overflow-x-auto w-full">
                <table class="table-auto min-w-full divide-y divide-gray-200 rounded-3xl">
                  <thead class="bg-gray-50">
                    <tr>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Pay Items
                      </th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Paid On
                      </th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Paid Via
                      </th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Remarks
                      </th>
                      <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap">Commission</td>
                      <td class="px-6 py-4 whitespace-nowrap">12 Dec, 2020</td>
                      <td class="px-6 py-4 whitespace-nowrap">Cheque</td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        Commission Paid
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">2000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full flex   p-3 bg-body">
                <div className="w-1/2 felx justify-end items-end text-end">
                  Total Unpaid
                </div>
                <div className="w-1/2 justify-around flex items-center">
                  <div>AED</div>
                  <div>0.00</div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className="w-[20%] p-4">
                <button class="w-[104px] h-[45px] top-[948px] left-[779px] rounded-[10px] border-[1.5px] border-blue py-[14px] px-[28px] gap-[15px] bg-blue-500 text-blue">
                  Cancel
                </button>
              </div>
              <div className={`${contentOnly ? "w-[20%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="w-full flex   p-3 bg-body">
              <div className="w-1/2 felx justify-end items-end text-end">
                Total Variable Pay
              </div>
              <div className="w-1/2 justify-around flex items-center">
                <div>AED</div>
                <div>0.00</div>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateVariablePay;

import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import DepartmentApi from "api/hrmapi/department";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
function Department() {
  const navigate = useNavigate();
  let params = useParams();
  const [UpdateDepartment, setUpdateDepartment] = useState({
    id: "",
    name: "",
    departmentHeadId: "",
    description: "",
    company_id: "",
    created_at: "",
    updated_at: "",
  });
  const handleNavgetAllDepartment = () => {
    setTimeout(() => {
      navigate(`/all-departments`);
    }, 2000);
  };
  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateDepartment({
      ...UpdateDepartment,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    DepartmentApi.viewDepartmentApi(params)
      .then((response) => {
        setUpdateDepartment({
          id: response.data.id,
          name: response.data.name,
          departmentHeadId: response.data.department_head_id,
          description: response.data.description,
          company_id: response.data.company_id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  const handleDepartmentUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    DepartmentApi.updateDepartmentApi(params.id, {
      department: {
        name: UpdateDepartment.name,
        department_head_id: UpdateDepartment.departmentHeadId,
        description: UpdateDepartment.description,
        company_id: UpdateDepartment.company_id,
      },
    })
      .then((response) => {
        handleNavgetAllDepartment();
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Department</div>
        </div>

        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleDepartmentUpdate}>
            <div className="w-full flex justify-around flex-wrap pl-4 pr-4">
              <div className="w-[25%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    className="form-control"
                    name="name"
                    value={UpdateDepartment.name}
                    onChange={inputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="w-[25%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Department Head</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <Select
                    value={{
                      value: UpdateDepartment.departmentHeadId,
                      label: UpdateDepartment.departmentHeadId,
                    }}
                    onChange={(obj) =>
                      inputOnChange({
                        target: {
                          name: "department_head_id",
                          value: obj.label,
                        },
                      })
                    }
                    options={[]}
                    placeholder="Please select"
                  />
                </div>
              </div>
              <div className="w-[40%]">
                <div className="pt-5 pb-5">Description</div>
                <div>
                  <textarea
                    className="form-control min-h-4 h-auto p-2"
                    value={UpdateDepartment.description}
                    required
                    rows={2}
                    name="description"
                    onChange={inputOnChange}
                  />
                </div>
              </div>
              <div className="flex w-full justify-end">
                <button
                  className={
                    loading
                      ? "w-[14%] btn-primary cursor-not-allowed"
                      : "w-[14%]  btn-primary"
                  }
                  type="submit"
                  disabled={loading}
                >
                  Update Department
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default Department;

import React, { useState } from "react";
import GernalSelectDropDown from "./GeneralSelectDropDown";
import Button from "./Button";
import ActiveInactiveButton from "./ActiveInactiveButton";
import Table from "./GeneralTable";
import SelectDate from "./DatePicker";
import Pagination from "./Pagination";
import FilterElement from "./FilterElement";

function CompleteTable({
  selectPerpage,
  handleStartDateChange,
  actions,
  filterElements,
  handleEndDateChange,
  totalPages,
  filters,
  filter,
  setFilter,
  dataToShow,
  loading,
  pageNumber,
  setPageNumber,
  totalCount,
  startValue,
  endValue,
  showFilters
}) {
 
  return (
    <>
      <div className="card">
        {showFilters && (
          <div className="card-body">
            <div className="flex flex-wrap gap-4 py-4">
              {filterElements.map((filterElement, index) => (
                <FilterElement
                  key={index}
                  label={filterElement.label}
                  predicate={filterElement.predicate}
                  filterValue={filterElement.filterValue}
                  changePredicate={filterElement.changePredicate}
                  changeFilter={filterElement.changeFilter}
                  options={filterElement.options}
                />
              ))}
              <SelectDate
                label="Start Date"
                selectedDate={filters.startDate}
                onDateChange={(date) =>
                  handleStartDateChange(date, "startDate")
                }
              />
              <SelectDate
                label="End Date"
                selectedDate={filters.endDate}
                onDateChange={(date) => handleEndDateChange(date, "endDate")}
              />
              {selectPerpage.map((Item, index) => (
                <GernalSelectDropDown
                  key={index}
                  title={Item.title}
                  value={Item.value}
                  onChange={Item.onChange}
                  options={Item.options}
                />
              ))}
              <ActiveInactiveButton filter={filter} setFilter={setFilter} />
            </div>
          </div>
        )}
        <Table data={dataToShow} loading={loading} actions={actions} startValue={startValue}/>
        {dataToShow.length > 0 && (
        <Pagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={totalPages}
          totalCount={totalCount}
          startValue={startValue}
          endValue={endValue}
        />
        )}
      </div>
    </>
  );
}

export default CompleteTable;

import React, { useState } from "react";
import { setVerified } from "store/Auth";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GernalInput from "../Common/GeneralInput";
import Button from "../Common/Button";
import AuthWrapper from "Components/Common/Pages/AuthWrapper";
import AuthAPI from "api/auth/auth";
import { toast  } from "react-toastify";
const ForgetPassWord = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const passwordToken = queryParams.get("reset_password_token");
  const handleResetPassWord = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthAPI.resetPassword({
      user: {
        password: formData.password,
        password_confirmation: formData.confirmpassword,
        reset_password_token: passwordToken,
      },
    })
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        dispatch(
          setVerified({
            verified: true,
          })
        );
        Navigate("/login");
      })
      .catch((error) => {
        toast.error(error.response ? error.response.data.message : "An error occurred");
        setLoading(false);
        
      });
  };
  const inputFields = [
    {
      title: "New Password",
      value: formData.password,
      onChange: (e) => setFormData({ ...formData, password: e.target.value }),
      type: "password",
      className: "mb-9",
      name: "password",
      required: true,
      paraClassName: "font-bold mb-4",
    },
    {
      title: "Password Confirmation",
      value: formData.confirmpassword,
      onChange: (e) =>
        setFormData({ ...formData, confirmpassword: e.target.value }),
      placeholder: "",
      type: "password",
      className: "mb-9",
      name: "confirmpassword",
      required: true,
      paraClassName: "font-bold mb-4",
    },
  ];
  const handleNavigate = () => {
    Navigate("/login");
  };
  return (
    <AuthWrapper>
      <h1 className="mb-12 text-36 font-bold text-dark dark:text-grey-mode text-center md:text-left">
        Change&nbsp;
        <p className="mt-3 inline-block md:block">your PassWord</p>
      </h1>
      <form onSubmit={handleResetPassWord}>
        {inputFields.map((input, index) => (
        <GernalInput
          key={index}
          title={input.title}
          value={input.value}
          onChange={input.onChange}
          placeholder={input.placeholder}
          type={input.type}
          className={input.className}
          name={input.name}
          required={input.required}
          paraClassName={input.paraClassName}
        />
        ))}
        <div className="text-right">
          <button
            className="mb-9 underline text-blue border-0 cursor-pointer mb-9 font-bold"
            onClick={handleNavigate}
            type="button"
          >
            Back to login?
          </button>
        </div>
        <Button loading={loading} type="submit" className="w-full">
          Continue
        </Button>

      </form>
    </AuthWrapper>
  );
};
export default ForgetPassWord;
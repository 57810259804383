import axiosClient from "..";

class AccountsApi {
    static createAccount(data) {
        return axiosClient.post(`/api/admin/account_codes.json`, data);
    }
    static showAllAccount(page) {
        return axiosClient.get(`/api/admin/account_codes.json?page=${page}`);
    }
    static viewAccount(id) {
        return axiosClient.get(`/api/admin/account_codes/${id}.json`);
    }
    static updateAccount(id,data) {
        return axiosClient.put(`/api/admin/account_codes/${id}.json`, data);
    }
    static deleteAccount(id) {
        return axiosClient.delete(`/api/admin/account_codes/${id}.json`);
    }
}
export default AccountsApi;

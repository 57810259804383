import React from "react";
import numeral from "configurations/default_money";
const SaleItemsTable = ({
  tableData,
  dataLoading,
  handleQtyChange,
  removeProductFromTable,
  className,
  handleProductSelect,
  handleOnBlurQuantity,
}) => {
  return (
    <div>
      <div className="text-[#287DFD] flex justify-center py-3 font-bold">
        <div className="w-1/4 flex justify-start">
          <span>Product</span>
        </div>
        <div className="w-1/6 flex justify-center">
          <span>Qty</span>
        </div>
        <div className="w-1/6 flex justify-center">
          <span>Price</span>
        </div>
        <div className="w-1/6 flex justify-center">
          <span>Discount</span>
        </div>
        <div className="w-1/6 flex justify-center">
          <span>VAT</span>
        </div>
        <div className="w-1/6 flex justify-center">
          <span>Amount</span>
        </div>
        <div className="w-[10%] flex justify-center">
          <span></span>
        </div>
      </div>
      <div>
        {tableData.length === 0 ? (
          <div className="flex justify-center w-full">No Product Selected</div>
        ) : (
          tableData.map((product) => {
            return (
              <div
                className="flex justify-center items-center w-full pt-3 pb-3 border-b border-b-[#D9D9D9]"
                key={product.product_id}
              >
                <div className="w-1/4 flex justify-start">
                  <span>{product.name || product.product_name}</span>
                </div>
                <div className="w-1/6 flex justify-center px-1">
                  <div className="w-full relative flex justify-center items-center">
                    <button
                      className="border-0 cursor-pointer bg-[#287DFD] rounded-full outline-none absolute left-0 h-[19px] w-[19px] text-white"
                      onClick={(e) => handleQtyChange(product, product.qty - 1)}
                      disabled={product.qty == 1 || dataLoading}
                    >
                      -
                    </button>
                    <input
                      className="hideNumberArrow text-center h-[19px] border border-[#D9D9D9] rounded-full w-full"
                      type="number"
                      value={product.input_field_value || product.quantity}
                      onChange={(e) => handleQtyChange(product, e.target.value)}
                      onBlur={(e) =>
                        handleOnBlurQuantity(product, e.target.value)
                      }
                    />
                    <button
                      className="border-0 cursor-pointer bg-[#287DFD] rounded-full absolute right-0 outline-none h-[19px] w-[19px] text-white"
                      onClick={() => handleProductSelect(product)}
                      disabled={product.qty > product.quantity || dataLoading}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="w-1/6 flex justify-center">
                  <span>{numeral(product.price).format("0[.]00")}</span>
                </div>
                <div className="w-1/6 flex justify-center">
                  <span>{numeral(product.discount).format("0[.]00")}</span>
                </div>
                <div className="w-1/6 flex justify-center">
                  <span>
                    {numeral(product.total_tax_amount).format("0[.]00")}
                  </span>
                </div>
                <div className="w-1/6 flex justify-center">
                  <span>{numeral(product.amount).format("0[.]00")}</span>
                </div>
                <div className="w-[10%] flex justify-center">
                  <span
                    className="text-[#287DFD]"
                    onClick={() => removeProductFromTable(product)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
export default SaleItemsTable;

import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import TransfersAPI from "api/posapi/transfers";
import { useSelector } from "react-redux";

function ViewStock() {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location_id = useSelector(
    (state) => state.auth.currentUser.default_loc
  );

  useEffect(() => {
    setLoading(true);
    TransfersAPI.productInventoriesAPI({ location_id })
      .then((response) => {
        const stockData = response.data.allProducts.map((data) => ({
          product_name: data.product_name,
          quantity: data.quantity || 0,
          product_barcode: data.product_barcode,
          product_sku: data.product_sku,
        }));

        setAllProducts(stockData);
        setFilteredProducts(stockData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [location_id]);

  useEffect(() => {
    // Filter products based on search term
    const filtered = allProducts.filter(
      (product) =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.product_barcode.includes(searchTerm) ||
        product.product_sku.includes(searchTerm)
    );
    setFilteredProducts(filtered);
  }, [searchTerm, allProducts]);

  return (
    <PagesWrapper>
      <div>
        <h1 className="font-bold">View Stock</h1>
      </div>

      <div className="container overflow-auto mx-auto mt-8">
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search by name, barcode, or SKU"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 p-4 border w-1/2  border-gray-300"
          />
        </div>
        <table className="min-w-full bg-white border border-gray-300 mb-5">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Product Name</th>
              <th className="py-2 px-4 border-b">Product Quantity</th>
              <th className="py-2 px-4 border-b">Product Sku</th>
              <th className="py-2 px-4 border-b">Product Barcode</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.length === 0 ? (
              <tr>
                <td colSpan="4" className="py-2 px-4 text-24 text-center">
                  No data available
                </td>
              </tr>
            ) : (
              filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td className="py-2 px-4 border-b">{product.product_name}</td>
                  <td className="py-2 px-4 border-b">{product.quantity}</td>
                  <td className="py-2 px-4 border-b">{product.product_sku}</td>
                  <td className="py-2 px-4 border-b">
                    {product.product_barcode}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </PagesWrapper>
  );
}

export default ViewStock;

import React, { useEffect, useState } from 'react'
import GeneralInput from 'Components/Common/GeneralInput'
import AuthAPI from 'api/auth/auth'
import Button from 'Components/Common/Button'
import { toast } from 'react-toastify'

const AccessCode = ({ onAccess }) => {
    const [accessCode, setAccessCode] = useState("")
    const handleOnChange = e => {
        const { value } = e.target
        setAccessCode(value)
    }
    const handleInput = e => {
        const { id } = e.target
        setAccessCode(accessCode => accessCode + id)
    }
    const handleAccess = () => {
        AuthAPI.verfiyAccess(accessCode).then(res => {
            if (res.data.status) {
                onAccess()
                setAccessCode("")
            } else {
                toast.error("Invalid Access Code")
                setAccessCode("")
            }
        }).catch(err => toast.error("Invalid Access Code")
        )
    }
    const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    return (
        <div>
            <GeneralInput type="number" value={accessCode} onChange={handleOnChange} placeholder="Access Code" focus={true} bold={true} />
            <div className='flex flex-wrap'>
                {
                    buttons.map(button => <button key={button} id={button} className='w-1/3 h-[50px] bg-transparent border border-gray-500' onClick={handleInput}>{button}</button>)
                }
            </div>
            <div className='w-full'>
                <Button className="w-full mt-3" onClick={handleAccess}>Ok</Button>
            </div>
        </div>
    )
}

export default AccessCode
import React from "react";
import Chart from "react-apexcharts";

const GernalChartData = ({ dataName, dataQty, dataDate, xAxisTitle, yAxisTitle, type }) => {
  const mappingData = () => {
    const data = {};

    for (let i = 0; i < dataName.length; i++) {
      const dataNameValue = dataName[i];
      const dataDateValue = dataDate[i];
      const dataQtyValue = dataQty[i];
      if (dataNameValue !== undefined && dataNameValue !== '') {
        if (!data[dataNameValue]) {
          data[dataNameValue] = {};
        }
        data[dataNameValue][dataDateValue] = dataQtyValue;
      }
    }
    const seriesData = Object.keys(data).map((dataNameValue) => {
      const dataDates = Object.keys(data[dataNameValue]);
      const values = dataDate.map((currentDate) =>
        dataDates.includes(currentDate) ? data[dataNameValue][currentDate] : 0
      );

      return {
        name: dataNameValue,
        data: values,
      };
    });

    return seriesData;
  };

  const series = mappingData();
  const shouldSetColumnWidth = series.length <2;
  const columnWidthValue = shouldSetColumnWidth ? "4%" : "5%";

  const chartOptions = {
    plotOptions: {
      bar: {
        columnWidth: columnWidthValue,
      },
    },
    xaxis: {
      crosshairs: {
        show: false
      },
      categories: dataDate,
      title: {
        text: xAxisTitle,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "start",
      fontSize: "12px",
      offsetY: 10,
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
      markers: {
        width: 10,
        height: 10,
        radius: 10,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    stroke: {
      width: 20,
      colors: ['transparent'],
    },
    colors: [
      "#4db6ac",
      "#a3a85e",
      "#cddc39",
      "#f30018",
      "#8d1947",
      "#a030d3",
      "#451959",
      "#5f27b9",
      "#2793b9",
      "#044860",
      "#bd3d28",
      "#0f5603",
      "#e48d7f",
      "#457d3c",
      "#97a214",
      "#ffae00",
      "#855877",
      "#e91e63",
      "#880e4f",
      "#b71c1c",
      "#e57373",
    ],
  };

  return( 
  <Chart options={chartOptions} series={series} type={type} width="100%" height="400" />)
};

export default GernalChartData;

import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import HoliDaysApi from "api/hrmapi/holiDays";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CreateHoliDayCalandar from "./CreateHoliDayCalandar";

function AllHoliDays({ setPopupId, setFetchHolidays, popupId, fetchHolidays }) {
  const [holiDays, setHoliDays] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    HoliDaysApi.allHoliDaysApi()
      .then((response) => {
        setHoliDays(response.data.holiday_calenders);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [fetchHolidays]);

  const handleDelete = (data) => {
    HoliDaysApi.deleteHoliDaysApi(data.id)
      .then((response) => {
        toast.success("Holiday Deleted Successfully");
        setHoliDays(holiDays.filter((item) => item.id !== data.id));
        setTimeout(() => {
          setFetchHolidays(true);
          setPopupId("");
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleTabClick = (data) => {
    setSelectedTab(data.id === selectedTab ? null : data.id);
    setSelectedCountry(data);
  };

  return (
    <>
      <ShowComponent condition={popupId === "multiAddholidays"}>
        <Popup setPopup={() => setPopupId("")} className="w-[50%] ">
          <CreateHoliDayCalandar
            setPopupId={setPopupId}
            selectedCountry={selectedCountry}
            setFetchHolidays={setFetchHolidays}
          />
        </Popup>
      </ShowComponent>
      <div className="w-full p-5">
        {holiDays.map((obj) => (
          <div
            key={obj.id}
            className="w-full border  border-gray-300 p-5 cursor-pointer m-2"
            onClick={() => handleTabClick(obj)}
          >
            <div className="flex">
              <div className="w-1/2">{obj.name}</div>
              <div className="w-1/2 flex">
                <div>
                  <button onClick={() => handleDelete(obj)}>Delete</button>
                </div>
                <div>
                  <img
                    src={
                      obj.id === selectedTab
                        ? "/icons/upicon.jpg"
                        : "/icons/downicon.jpg"
                    }
                    alt=""
                    className="w-10 h-10"
                  />
                </div>
              </div>
            </div>
            {obj.id === selectedTab && (
              <div className="w-full ">
                <button
                  className="mb-2 mt-5 btn-primary w-[10%]"
                  onClick={() => setPopupId("multiAddholidays")}
                >
                  Add New
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default AllHoliDays;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EmployeeDocumentTypesApi from "api/hrmapi/employeDocType";

function UpdateEmployeeDocType({
  upDateId,
  setPopupId,
  setFetchEmployeeDocumentTypes,
}) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const initialState = {
    name: "",
    isMandatory: false,
    hasExpiryDate: false,
    hasDocument: false,
    hasImage: false,
    expiryReminders: false,
    monthsBeforeExpiry: "",
  };
  const [documentData, setDocumentData] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleUpdateDocument = (e) => {
    e.preventDefault();

    if (!documentData.name) {
      toast.error("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    const requestData = {
      document: {
        name: documentData.name,
        is_mandatory: documentData.isMandatory,
        has_expiry_date: documentData.hasExpiryDate,
        has_document: documentData.hasDocument,
        has_image: documentData.hasImage,
        expiry_reminders: documentData.expiryReminders,
        months_before_expiry: documentData.monthsBeforeExpiry,
        required: documentData.isMandatory,
        company_id: company_id,
      },
    };

    EmployeeDocumentTypesApi.updateEmployeeDocumentTypesApi(
      upDateId,
      requestData
    )
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`${Message}`);
        setTimeout(() => {
          setFetchEmployeeDocumentTypes(true);
          setPopupId("");
        }, 2000);
        setDocumentData(initialState);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentData({
      ...documentData,
      [name]: value,
    });
  };

  useEffect(() => {
    EmployeeDocumentTypesApi.getEmployeeDocumentTypesApi(upDateId)
      .then((res) => {
        const responseData = res.data;
        setDocumentData({
          name: responseData.name,
          isMandatory: responseData.is_mandatory,
          hasExpiryDate: responseData.has_expiry_date,
          hasDocument: responseData.has_document,
          hasImage: responseData.has_image,
          expiryReminders: responseData.expiry_reminders,
          monthsBeforeExpiry: responseData.months_before_expiry,
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, [upDateId]);

  const handleSelectChange = (option, key) => {
    setDocumentData({
      ...documentData,
      [key]: option.value,
    });
  };

  const options = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
  ];

  const handleCancel = () => {
    setPopupId("");
    setDocumentData(initialState);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  return (
    <div className="w-full">
      <div className="text-22 p-2">Update Document Type</div>
      <form onSubmit={handleUpdateDocument}>
        <div className="w-full flex flex-wrap gap-5 ">
          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Name</div>
              <div className="text-red">*</div>
            </div>
            <div>
              <input
                required
                className="form-control"
                type="text"
                name="name"
                value={documentData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Is Mandatory?</div>
            </div>
            <Select
              required
              value={{
                value: documentData.isMandatory,
                label: documentData.isMandatory ? "Yes" : "No",
              }}
              onChange={(option) => handleSelectChange(option, "isMandatory")}
              options={options}
              styles={customStyles}
            />
          </div>

          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Has Expiry Date?</div>
            </div>
            <Select
              value={{
                value: documentData.hasExpiryDate,
                label: documentData.hasExpiryDate ? "Yes" : "No",
              }}
              onChange={(option) => handleSelectChange(option, "hasExpiryDate")}
              options={options}
              styles={customStyles}
            />
          </div>

          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Expiry Reminders</div>
            </div>
            <Select
              value={{
                value: documentData.expiryReminders,
                label: documentData.expiryReminders ? "Yes" : "No",
              }}
              onChange={(option) =>
                handleSelectChange(option, "expiryReminders")
              }
              options={options}
              styles={customStyles}
            />
          </div>

          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Months Before Expiry</div>
            </div>
            <input
              className="form-control"
              type="text"
              name="monthsBeforeExpiry"
              value={documentData.monthsBeforeExpiry}
              onChange={handleInputChange}
            />
          </div>

          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Has Document No?</div>
            </div>
            <Select
              required
              value={{
                value: documentData.hasDocument,
                label: documentData.hasDocument ? "Yes" : "No",
              }}
              onChange={(option) => handleSelectChange(option, "hasDocument")}
              options={options}
              styles={customStyles}
            />
          </div>

          <div className="w-[30%]">
            <div className="flex w-full pt-5 pb-5">
              <div>Has Image?</div>
            </div>
            <Select
              required
              value={{
                value: documentData.hasImage,
                label: documentData.hasImage ? "Yes" : "No",
              }}
              onChange={(option) => handleSelectChange(option, "hasImage")}
              options={options}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="w-full flex justify-end">
          <div className="w-[40%] flex justify-between p-4">
            <div className="w-[48%]">
              <button
                className="btn-primary w-full"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
            <div className="w-[48%]">
              <button
                className={`w-full ${
                  loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                }`}
                type="submit"
                disabled={loading}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateEmployeeDocType;

import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BranchesApi from "api/hrmapi/branches";
import CustomerApi from "api/customerapi/customer";
import { useNavigate } from "react-router-dom";
function CreateBranches({ contentOnly = false, setPopupId }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
  const handleNavgetAll = () => {
    setTimeout(() => {
      navigate(`/all-branches`);
    }, 2000);
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    CustomerApi.AllCountries()
      .then((res) => {
        const options = res.data.map((country) => ({
          label: country.country_name,
          value: country.country_name,
        }));
        setCountries(options);
      })
      .catch((error) => {
      });
  }, []);

  const handleCreateBranches = (e) => {
    e.preventDefault();
    setLoading(true);
    BranchesApi.createBranches({
      branch: {
        name: name,
        location: selectedOption.value,
        company_id: company_id,
      },
    })
      .then((response) => {
        const Message = response ? response.data.message : "An error occurred";
        handleNavgetAll()
        toast.success(`${Message}`);
        if (contentOnly) {
          setPopupId("");
        }
        setName("");
        setSelectedOption("");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };

  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Create Branch</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[60vh]">
          <form onSubmit={handleCreateBranches}>
            <div className="w-full flex  gap-5 p-4">
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Name</div>
                  <div className="text-red">*</div>
                </div>
                <div>
                  <input
                    required
                    className="form-control"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <div className="flex w-full pt-5 pb-5">
                  <div>Location</div>
                  <div className="text-red">*</div>
                </div>
                <Select
                  value={selectedOption}
                  required
                  onChange={handleChange}
                  options={countries}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className={`${contentOnly ? "w-[20%] p-4" : "w-[20%] p-4"}`}>
                <button
                  className={`w-full ${
                    loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  Create Branch
                </button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CreateBranches;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import Button from "Components/Common/Button";
import AccountsApi from "api/inventorymanagement/accounts";
import TableDetails from "Components/Common/TableDetails";

function AccountsDetail() {
  const [accountData, setAccountData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    AccountsApi.viewAccount(params.id)
      .then((response) => {
        setLoading(false);
        setAccountData(response?.data?.account_code)
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-accounts");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Account Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="w-1/2">
            <TableDetails data={accountData} loading={loading} header={false} />
          </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AccountsDetail;

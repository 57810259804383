import React, { useState, useEffect } from "react";
import Select from "react-select";
import officesApi from "api/hrmapi/companySettingOffices";
import { toast } from "react-toastify";

function Location({ setPopupId }) {
  const [searchValue, setSearchValue] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [map, setMap] = useState(null);
  const [circle, setCircle] = useState(null);
  const [marker, setMarker] = useState(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [radius, setRadius] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    officesApi
      .createOffice({
        location: {
          type: selectedOption ? selectedOption.value : "",
          address: officeName,
          phone: phone,
          zip: zip,
          radius: `${radius}m`,
          longitude: `${longitude}`,
          latitude: `${latitude}`,
          location: searchValue,
        },
      })
      .then((res) => {
        toast.success("Office created successfully!");
        setPhone("");
        setZip("");
        setSearchValue("");
        setOfficeName("");
        setPredictions([]);
        setCircle(null);
        setMarker(null);
        setLatitude(0);
        setLongitude(0);
        setRadius(0);
        setLoading(false);
        setPopupId("");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANJ-pAJHbKOlp-nryIQzrpCJeGoOs_eKw&libraries=places&callback=initMap`;
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  window.initMap = () => {
    const initialLatLng = { lat: latitude, lng: longitude };
    const initialZoom = 2;
    const initialMap = new window.google.maps.Map(
      document.getElementById("map"),
      {
        center: initialLatLng,
        zoom: initialZoom,
      }
    );

    window.google.maps.event.addListener(initialMap, "click", function (e) {
      handleMapClick(e.latLng);
    });

    setMap(initialMap);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const options = [
    { value: "shop", label: "Shop" },
    { value: "warehouse", label: "Warehouse" },
    { value: "factory", label: "Factory" },
    { value: "other", label: "Other" },
  ];

  const handleMapClick = (clickedLocation) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: clickedLocation }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setSearchValue(results[0].formatted_address);
          setLatitude(clickedLocation.lat());
          setLongitude(clickedLocation.lng());
          if (map) {
            if (circle) {
              circle.setCenter(clickedLocation);
              circle.setRadius(radius);
            } else {
              const newCircle = new window.google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map,
                center: clickedLocation,
                radius: radius,
              });
              setCircle(newCircle);
            }

            if (marker) {
              marker.setPosition(clickedLocation);
            } else {
              const newMarker = new window.google.maps.Marker({
                position: clickedLocation,
                map,
              });
              setMarker(newMarker);
            }

            map.setZoom(10);
            map.setCenter(clickedLocation);
          }
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.trim() !== "") {
      handleAutocomplete();
    } else {
      setPredictions([]);
      setLatitude(0);
      setLongitude(0);
    }
  };

  const handleAutocomplete = () => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: searchValue },
      (predictions, status) => {
        if (status === "OK" && predictions) {
          setPredictions(predictions);
        } else {
          setPredictions([]);
        }
      }
    );
  };

  const handlePredictionClick = (prediction) => {
    if (map && prediction.place_id) {
      const placesService = new window.google.maps.places.PlacesService(map);
      placesService.getDetails(
        { placeId: prediction.place_id },
        (placeResult, status) => {
          if (status === "OK" && placeResult) {
            const location = placeResult.geometry.location;
            map.setCenter(location);
            setSearchValue(placeResult.formatted_address);
            setPredictions([]);
            handleMapClick(location);
          }
        }
      );
    }
  };

  const handleRadiusChange = (event) => {
    const newRadius = parseInt(event.target.value);
    setRadius(newRadius);
    if (map && circle) {
      circle.setRadius(newRadius);
    }
  };

  const handleCancel = () => {
    setPhone("");
    setZip("");
    setSearchValue("");
    setOfficeName("");
    setPredictions([]);
    setCircle(null);
    setMarker(null);
    setLatitude(0);
    setLongitude(0);
    setRadius(0);
    setLoading(false);
    setPopupId("");
  };

  return (
    <div className="flex ">
      <div className="w-1/2 border border-gray-300 p-5">
        <form onSubmit={handleSubmit}>
          <div className="text-22 mb-2">New Office</div>
          <div className="text-16 mb-2">
            Adding a new office with location data so employees can check-in and
            check-out
          </div>
          <div className="text-16 mb-2">Name of the office</div>
          <input
            type="text"
            required
            value={officeName}
            placeholder="Enter name of office"
            onChange={(e) => setOfficeName(e.target.value)}
            className="border border-gray-300 px-3 py-2 w-full mb-4"
          />
          <div className="text-16 mb-2"> location of the office</div>
          <input
            type="text"
            required
            value={searchValue}
            onChange={handleInputChange}
            placeholder="Search for offices..."
            className="border border-gray-300 px-3 py-2 w-full mb-4"
          />
          <ul>
            {predictions.map((prediction, index) => (
              <li
                key={index}
                onClick={() => handlePredictionClick(prediction)}
                className="cursor-pointer p-2"
              >
                {prediction.description}
              </li>
            ))}
          </ul>

          <div className="flex w-full justify-between mb-2">
            <div className="w-1/2 ">Latitude: {latitude}</div>
            <div className="w-1/2">Longitude: {longitude}</div>
          </div>
          <div className="text-16 mb-2">Radius (in meters)</div>
          <input
            type="number"
            value={radius}
            onChange={handleRadiusChange}
            placeholder="Enter radius in meters"
            className="border border-gray-300 px-3 py-2 w-full mb-4"
          />
          <div className="mb-2">
            <Select
              value={selectedOption}
              required
              onChange={handleChange}
              options={options}
            />
          </div>
          <div>
            <input
              type="number"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter phone"
              className="border border-gray-300 px-3 py-2 w-full mb-4"
            />
          </div>
          <input
            type="number"
            required
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            placeholder="Enter zip"
            className="border border-gray-300 px-3 py-2 w-full mb-4"
          />

          <div className="w-full justify-end gap-2 flex mt-2">
            <div className="w-[25%]">
              <button
                className={`w-full ${
                  loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                }`}
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
            <div className="w-[25%]">
              <button
                className={`w-full ${
                  loading ? "btn-primary cursor-not-allowed " : " btn-primary"
                }`}
                type="submit"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div id="map" className="h-screen w-1/2 mt-5"></div>
    </div>
  );
}

export default Location;

import axiosClient from "..";
const Api = "/api/admin/create-transfer";
class TransfersAPI {
  static createTransfersAPI(data) {
    return axiosClient.post(`${Api}.json`, data);
  }  
  static productInventoriesAPI(data) {
    return axiosClient.get(`/api/admin/product_inventories.json?per_page_count=9999`, {
      params: data,
    });
  }
  static viewTransfersAPI(data) {
    return axiosClient.get(`/api/admin/transfer-inventory/${data.id}.json`);
  }
  static historyTranferAPI() {
    return axiosClient.get(`/api/admin/transfers.json?per_page_count=9999`);
  }
  static approveTransferAPI(data) {
    return axiosClient.post(`/api/admin/approve-transfer.json`,data);
  }
  static receiveTransferAPI(data) {
    return axiosClient.post(`/api/admin/receive-transfer.json`,data);
  }
  static rejectTransferAPI(data) {
    return axiosClient.delete(`/api/admin/reject-transfer/${data.id}`);
  }
  static accessibleLocationsAPI() {
    return axiosClient.get(`/api/admin/accessible-locations.json`);
  }
}
export default TransfersAPI;

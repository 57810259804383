import axiosClient from "..";
const API = "/api/admin/pos/products";
class ProductsAPI {
  static allProductsAPI(
    page,
    filterPerpage,
    filter,
    idPredicate,
    filterId,
    categoryPredicate,
    filtercategoryname,
    productnamePredicate,
    filterProductname,
    skuPredicate,
    filterSku,
    startDate,
    endDate
  ) {
    return axiosClient.get(
      `${API}.json?page=${page}&per_page_count=${filterPerpage}/${
        filter &&
        `&q[id_${idPredicate}]=${filterId}&q[category_name_${categoryPredicate}]=${filtercategoryname}&q[name_${productnamePredicate}]=${filterProductname}&q[sku_${skuPredicate}]=${filterSku}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }
  static viewProductAPI(data) {
    return axiosClient.get(`${API}/${data.id}.json`);
  }
  static uploadProductImgAPI(id, formData) {
    return axiosClient.patch(`${API}/upload_avatar/${id}`, formData);
  }
  static updateProductAPI(id, data) {
    return axiosClient.patch(`${API}/${id}`, data);
  }
  static createProductAPI(data) {
    return axiosClient.post(`${API}.json`, data);
  }
  static uploadCSVFile(formData) {
    return axiosClient.post("/api/upload/csv/products", formData);
  }
  static trackProduct(searchQuery, page) {
    return axiosClient.get(
      `/api/admin/pos/products/search.json?query=${searchQuery}&page=${page}`
    );
  }
}
export default ProductsAPI;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import CustomerApi from "api/customerapi/customer";
import Button from "Components/Common/Button";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PosSidebar from "Components/Common/PosSidebar";

const UpdateCustomer = ({
  setPopupId,
  onCustomerUpdated,
  contentOnly = false,
  setCustomerMode,
}) => {
  const getCustomerData = useSelector(
    (state) => state.sales?.customer?.customerId
  );

  const [loading, setLoading] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [birthdayDate, setBirthdayDate] = useState(null);
  const [customerNationality, setCustomerNationality] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [customerCountry, setCustomerCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const isAccounts = location.pathname.startsWith("/accounts/customer/edit");

  const [updateCustomerData, setUpdateCustomerData] = useState({
    customerName: "",
    customerPhoneNumber: "",
    customerCountry: "",
    customerType: 0,
    customerEmail: "",
    customerAddress: "",
    customerGender: "",
    customerAge: "",
    customerAgeGroup: "",
    customerAgeBracket: "",
  });

  let customerToFetch = id ? id : getCustomerData;
  useEffect(() => {
    CustomerApi.viewCustomer({ id: customerToFetch })
      .then((response) => {
        const CustomersData = response?.data?.customer;
        setSelectedCountry(CustomersData.country);
        setSelectedNationality(CustomersData.nationality);
        if (CustomersData) {
          const birthdate = CustomersData.birthdate;
          if (birthdate) {
            const [day, month, year] = birthdate.split("-");
            const parsedBirthDate = new Date(`${year}-${month}-${day}`);
            setBirthDate(parsedBirthDate);
          }
        }
        setUpdateCustomerData({
          customerName: CustomersData.name,
          customerPhoneNumber: CustomersData.phone_no,
          customerType: CustomersData.customer_type,
          customerCountry: CustomersData.country,
          customerAddress: CustomersData.address,
          customerEmail: CustomersData.email,
          customerNationality: CustomersData.nationality,
          customerGender: CustomersData.gender,
          customerAgeBracket: CustomersData.age_bracket,
          customerAgeGroup: CustomersData.age_group,
          customerBirthdayDate: CustomersData.birthday,
          customerBirthDate: CustomersData.birthdate,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    CustomerApi.AllCountries().then((response) => {
      const nationalities = response?.data.map((item) => item.nationality);
      setCustomerNationality(nationalities);
      const countriesNames = response?.data.map((item) => item.country_name);
      setCustomerCountry(countriesNames);
    });
  }, [selectedCountry]);

  const handleFormattedBirthDate = (date) => {
    if (date !== null) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${day}-${month}-${year}`;
    }
    return "";
  };

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setUpdateCustomerData({
      ...updateCustomerData,
      [name]: value,
    });
  };

  const inputOnChangeAddress = (e) => {
    setUpdateCustomerData({
      ...updateCustomerData,
      customerAddress: e.target.value,
    });
  };

  const inputOnChangePhone = (e) => {
    setUpdateCustomerData({
      ...updateCustomerData,
      customerPhoneNumber: e.target.value,
    });
  };

  const inputOnChangeEmail = (e) => {
    setUpdateCustomerData({
      ...updateCustomerData,
      customerEmail: e.target.value,
    });
  };

  const handleFormattedBirthday = (date) => {
    if (date) {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${day}-${month}`;
    } else {
      return "";
    }
  };

  const calculateAge = (date) => {
    const today = new Date();
    const birthDateData = new Date(date);
    let age = today.getFullYear() - birthDateData.getFullYear();
    const monthDiff = today.getMonth() - birthDateData.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateData.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    let ageGroup = "";
    if (updateCustomerData.birthDate) {
      const age = calculateAge(updateCustomerData.birthDate);
      ageGroup = determineAgeGroup(age);
    }
    setUpdateCustomerData((prevData) => ({
      ...prevData,
      customerAgeGroup: ageGroup,
    }));
  }, [updateCustomerData.birthDate]);

  const determineAgeGroup = (age) => {
    if (age !== "") {
      if (age >= 18 && age <= 24) {
        return "18-24";
      } else if (age > 25 && age <= 34) {
        return "25-34";
      } else if (age > 35 && age <= 44) {
        return "35-44";
      } else if (age > 45 && age <= 54) {
        return "45-54";
      } else if (age > 55 && age <= 64) {
        return "55-64";
      } else if (age > 65 && age <= 74) {
        return "65-74";
      } else if (age >= 75) {
        return "75 +";
      } else {
        return "smaller than 18";
      }
    } else {
      return "";
    }
  };

  const countryOptions = customerCountry.map((country, index) => ({
    value: country,
    label: country,
  }));
  const nationalityOptions = customerNationality.map((nationality, index) => ({
    value: nationality,
    label: nationality,
  }));

  const handleAgeInputChange = (event) => {
    const userInput = event.target.value.trim();
    setUpdateCustomerData({
      ...updateCustomerData,
      customerAge: userInput,
    });

    const selectedOption = document.getElementById("ageSelect").value;

    if (selectedOption === "between") {
      if (userInput.length === 3 && userInput[2] !== "-") {
        setUpdateCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput.substring(0, 2) + "-" + userInput.substring(2),
        }));
      } else if (userInput.length === 2 && userInput[1] === "-") {
        setUpdateCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput.substring(0, 1),
        }));
      } else {
        setUpdateCustomerData((prevData) => ({
          ...prevData,
          customerAge: userInput,
        }));
      }
    } else {
      setUpdateCustomerData((prevData) => ({
        ...prevData,
        customerAge: userInput,
      }));
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleNationalityChange = (e) => {
    setSelectedNationality(e.target.value);
  };

  const handleGenderSelection = (e) => {
    setUpdateCustomerData((prevData) => ({
      ...prevData,
      customerGender: e.target.value,
    }));
  };

  const handleCustomerUpdate = (e) => {
    const formattedBirthDate = handleFormattedBirthDate(new Date(birthDate));
    const formattedBirthday = handleFormattedBirthday(new Date(birthDate));
    e.preventDefault();
    setLoading(true);
    CustomerApi.updateCustomer(customerToFetch, {
      customer: {
        name: updateCustomerData.customerName,
        phone_no: updateCustomerData.customerPhoneNumber,
        customer_type: updateCustomerData.customerType,
        country: selectedCountry,
        address: updateCustomerData.customerAddress,
        email: updateCustomerData.customerEmail,
        nationality: selectedNationality,
        gender: updateCustomerData.customerGender,
        age_bracket: updateCustomerData.customerAge,
        age_group: updateCustomerData.customerAgeGroup,
        birthday: formattedBirthday,
        birthdate: formattedBirthDate,
      },
    })
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        if (contentOnly) {
          setPopupId("");
          setCustomerMode("search");
          onCustomerUpdated(customerToFetch);
        } else {
          setTimeout(() => {
            if (isAccounts) {
              navigate("/accounts-customers");
            } else {
              navigate("/pos-customers");
            }
          }, 1500);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
        toast.error(error.response.data.errors);
      });
  };

  useEffect(() => {
    let ageGroup;
    if (birthDate) {
      setBirthdayDate(handleFormattedBirthday(birthDate));
      const age = calculateAge(birthDate);
      ageGroup = determineAgeGroup(age);
    } else {
      setBirthdayDate("");
      ageGroup = determineAgeGroup("");
    }
    setUpdateCustomerData((prevData) => ({
      ...prevData,
      customerAgeGroup: ageGroup,
    }));
  }, [birthDate]);

  const handleCancel = () => {
    setCustomerMode("search");
  };

  return (
    <AppWrapper
      sideBar={isAccounts ? <AccountsSidebar /> : <PosSidebar />}
      contentOnly={contentOnly}
    >
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className={contentOnly ?"text-24 w-full text-center":"flex items-center text-24 font-semibold"}>
            Update Customer
          </div>
        </div>
        <div className={contentOnly ? "" : "border-t-2 border-blue bg-white rounded-md"}>
          <form onSubmit={handleCustomerUpdate}>
            <div className="px-4">
              <div className="flex flex-col mt-4">
                <label className="mb-2 font-semibold">Name:</label>
                <input
                  className="flex w-full form-control"
                  type="text"
                  name="customerName"
                  value={updateCustomerData.customerName}
                  onChange={inputOnChange}
                  required
                />
              </div>
              <div className="flex w-full">
                <div className="w-1/2">
                  <div className="w-full mt-3">
                    <label className="font-semibold">Country:</label>
                    <select
                      className="form-control mt-2"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Phone No:</label>
                    <input
                      readOnly
                      disabled
                      className="form-control mt-2"
                      value={updateCustomerData.customerPhoneNumber}
                      onChange={inputOnChangePhone}
                      name="customerPhoneNumber"
                      required
                    />
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Email: </label>
                    <input
                      className="form-control mt-2"
                      type="email"
                      name="customerEmail"
                      value={updateCustomerData.customerEmail}
                      onChange={inputOnChangeEmail}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Nationality:</label>
                    <select
                      className="form-control mt-2"
                      value={selectedNationality}
                      onChange={handleNationalityChange}
                    >
                      <option value="" disabled>
                        Select Nationality
                      </option>
                      {nationalityOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full mt-12 flex flex-row items-center">
                    <label className="font-semibold mr-24">Gender:</label>
                    <div className="flex flex-row items-center">
                      <input
                        className="mr-2 h-5 w-5"
                        type="radio"
                        id="maleGender"
                        name="customerGender"
                        value="male"
                        checked={updateCustomerData.customerGender === "male"}
                        onChange={handleGenderSelection}
                      />
                      <label
                        htmlFor="maleGender"
                        className={contentOnly? "font-semibold mr-5":"font-semibold mr-20"}
                      >
                        Male
                      </label>
                      <input
                        className="mr-2 h-5 w-5"
                        type="radio"
                        id="femaleGender"
                        name="customerGender"
                        value="female"
                        checked={updateCustomerData.customerGender === "female"}
                        onChange={handleGenderSelection}
                      />
                      <label htmlFor="femaleGender" className="font-semibold">
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="w-full mt-12">
                    <label className="font-semibold">Birthday:</label>
                    <input
                      className="form-control mt-2"
                      placeholderText="dd-MM"
                      dateFormat="dd-MM"
                      type="text"
                      value={birthdayDate}
                      readOnly
                      onChange={() => {
                        setBirthdayDate(
                          handleFormattedBirthday(new Date(birthDate))
                        );
                      }}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Birth Date:</label>
                    <div className="flex w-full mt-2">
                      <DatePicker
                        className="form-control w-full"
                        placeholderText="dd-MM-yyyy"
                        dateFormat="dd-MM-yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        selected={birthDate}
                        yearDropdownItemNumber={80}
                        onChange={(date) => {
                          setBirthDate(date);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/2 pl-5">
                  <div className="w-full mt-4">
                    <label className="font-semibold">Address: </label>
                    <textarea
                      className="w-full mt-2 border rounded-lg block border-gray-300 p-2.5"
                      type="text"
                      value={updateCustomerData.customerAddress}
                      name="customerAddress"
                      onChange={inputOnChangeAddress}
                      rows={22}
                    ></textarea>
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Age Bracket:</label>
                    <div className="flex w-full mt-2">
                      <select
                        className="w-4/12 rounded-r-none rounded-l-lg form-control"
                        disabled
                      >
                        <option>Select a option</option>
                        <option value="less">Less Than</option>
                        <option value="between">Between</option>
                        <option value="greater">Greater Than</option>
                      </select>
                      <input
                        className="w-6/12 form-control rounded-r-lg rounded-l-none"
                        id="customerAgeInput"
                        type="text"
                        name="customerAge"
                        value={updateCustomerData.customerAgeBracket}
                        onChange={(event) => handleAgeInputChange(event)}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4">
                    <label className="font-semibold">Age Group:</label>
                    <div className="flex w-full mt-2">
                      <input
                        className="w-10/12 form-control"
                        type="text"
                        value={updateCustomerData.customerAgeGroup}
                        readOnly
                      />
                    </div>
                  </div>
                  {contentOnly ? (
                    <div className="w-full flex justify-end mt-14 mb-4">
                      <div>
                        <Button
                          className="w-[120%] mb-3"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="ml-8">
                        <Button
                          className={`w-[120%] mb-3 ${
                            loading
                              ? "disabled btn-progress "
                              : "btn btn-primary"
                          }`}
                          type="submit"
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex justify-end mt-2 mb-10">
                      <Button
                        className={`w-[20%] mb-3 ${
                          loading ? "disabled btn-progress " : "btn btn-primary"
                        }`}
                        type="submit"
                      >
                        Update
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
};

export default UpdateCustomer;

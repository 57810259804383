import React from "react";
import Sidebar from "./Sidebar";

function HrmSidebar() {
  const menuItemsHrm = [
    {
      key: "maindashboard",
      text: "Main Dashboard",
      src: "/icons/dashboard.svg",
      path: "/",
      allowedRoles: [2, 3],
    },
    {
      key: "dashboardHrm",
      text: "Dashboard",
      src: "/icons/dashboard.svg",
      path: "/hrm-dashboard",
      allowedRoles: [2, 3],
    },
    {
      key: "Employ Data Management ",
      text: "Employee Data Management",
      src: "/icons/manageUser.svg",
      dropdown: true,
      allowedRoles: [2, 3],
      subMenuItems: [
        {
          key: "allEmployee Data Management",
          text: "Employee Data Management",
          path: "/employee-data-management",
          src: "/icons/manageUser.svg",
        },
      ],
    },
    {
      key: "payroll",
      text: "Payroll Processing",
      src: "/icons/manageUser.svg",
      dropdown: true,
      allowedRoles: [2, 3],
      subMenuItems: [
        {
          key: "allPayroll Processing",
          text: "Payroll Processing",
          path: "/payroll-processing",
          src: "/icons/manageUser.svg",
        },
      ],
    },
    {
      key: "setting",
      text: "Settings",
      src: "/icons/setting.svg",
      dropdown: true,
      allowedRoles: [2, 3],
      subMenuItems: [
        {
          key: "Company",
          text: "Company",
          path: "/company-setting",
        },
        // {
        //   key: "Payroll",
        //   text: "Payroll",
        //   path: "/payroll-setting",
        // },
        // {
        //   key: "Leaves",
        //   text: "Leaves",
        //   path: "/leaves-setting",
        // },
        // {
        //   key: "Attendance",
        //   text: "Attendance",
        //   path: "/attendance-setting",
        // },
        // {
        //   key: "workexpenses",
        //   text: "Work Expenses",
        //   path: "/workexpenses-setting",
        // },
        // {
        //   key: "performance",
        //   text: "Profomance",
        //   path: "/profomance-setting",
        // },
      ],
    },
 
  ];
  return (
    <>
      <Sidebar menuItems={menuItemsHrm} />
    </>
  );
}
export default HrmSidebar;

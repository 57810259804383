import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import SupplierApi from "api/supplierapi/supplier";
import GernalInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";

function CreateSupplier({ contentOnly = false, fetchSuppliers, setPopupId }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [supplierData, setSupplierData] = useState({
    supplierName: "",
    phoneNumber: "",
  });

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setSupplierData({
      ...supplierData,
      [name]: value,
    });
  };

  const handleCreateSupplier = (e) => {
    e.preventDefault();
    SupplierApi.createSupplier({
      supplier: {
        name: supplierData.supplierName,
        phone_no: supplierData.phoneNumber,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res?.data?.message === "supplier created successfully") {
          fetchSuppliers();
          toast.success(res?.data.message);
          setPopupId("");
          setSupplierData({
            supplierName: "",
            phoneNumber: "",
          });
        }
        toast.error(res?.data.errors);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const inputData = [
    {
      title: "Name",
      value: supplierData.supplierName,
      onChange: inputOnChange,
      placeholder: "Enter name",
      type: "text",
      name: "supplierName",
      required: true,
    },
    {
      title: "Phone Number",
      value: supplierData.phoneNumber,
      onChange: inputOnChange,
      placeholder: "Enter phone number",
      type: "number",
      name: "phoneNumber",
      required: true,
    },
  ];

  return (
    <>
      <AppWrapper sideBar={<AccountsSidebar />} contentOnly={contentOnly}>
        <PagesWrapper>
          <div>
            <div className="py-2">
              <h1 className="text-24 w-full text-center"> Create Supplier</h1>
            </div>
            <form onSubmit={handleCreateSupplier}>
              <div className="w-full">
                {inputData.map((Item, index) => (
                  <GernalInput
                    className="form-group mx-3 mt-5"
                    key={index}
                    title={Item.title}
                    value={Item.value}
                    name={Item.name}
                    placeholder={Item.placeholder}
                    onChange={Item.onChange}
                    type={Item.type}
                    required={Item.required}
                  />
                ))}
              </div>
              <div className="w-full flex justify-center">
                <Button
                  className={`${
                    loading
                      ? "w-[42%] btn-lg btn-block disabled btn-progress"
                      : "w-[42%] justify-end ml-10 mt-5 mb-2"
                  }`}
                  type="submit"
                >
                  Create Supplier
                </Button>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default CreateSupplier;

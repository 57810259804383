import axiosClient from "..";
const Api = "/api/hrm/settings/company";
class CompanySettingApi {

  static allLogoApi() {
    return axiosClient.get(`${Api}.json`);
  }
 
  static updateLogoApi(id, data) {
    return axiosClient.put(`${Api}/${id}.json`, data);
  }
  static deleteLogoApi(id) {
    return axiosClient.delete(`${Api}/${id}.json`);
  }
}
export default CompanySettingApi;

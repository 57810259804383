import axiosClient from "..";
class CircularAPi {
    static fetch_circulars(locationId) {
        return axiosClient.get(`/api/admin/locations/${locationId}/circular-views.json`);
    }
    static upload_circulard(locationId, file) {
        return axiosClient.post(`/api/admin/locations/${locationId}/circular-views.json`, file);
    }

}
export default CircularAPi;

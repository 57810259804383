import ShowComponent from "Components/ShowComponent";
import React, { useEffect, useRef } from "react";

function GeneralInput({
  title,
  value,
  onChange = () => console.log(""),
  placeholder,
  type,
  className,
  name,
  required,
  lableAlign = "top",
  focus = false,
  bold = false,
  errorMessage = "",
  min = 0,
  paraClassName = "",
}) {
  const inputRef = useRef(null);
  useEffect(() => {
    if (focus) {
      if (!inputRef.current) return;
      inputRef.current.focus();
    }
  }, [inputRef]);
  return (
    <div
      className={`${className} ${
        lableAlign === "left" ? "flex justify-center items-center" : ""
      }`}
    >
      <ShowComponent condition={lableAlign === "left"}>
        <span
          className={lableAlign === "left" ? "font-bold w-[20%] mb-2" : ""}
          htmlFor={title}
        >
          {title}
        </span>
      </ShowComponent>
      <ShowComponent condition={lableAlign === "top"}>
        <p
          className={`${paraClassName} ${
            lableAlign === "left" ? "font-bold mb-2" : ""
          }`}
          htmlFor={title}
        >
          {title}
        </p>
      </ShowComponent>

      <input
        ref={inputRef}
        className={`form-control hideNumberArrow [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
          bold && "font-bold text-[24px]"
        } ${errorMessage && "border-red"} ${errorMessage && "text-red"}`}
        value={value}
        name={name}
        min={min}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        required={required}
      />
      <ShowComponent condition={errorMessage.length}>
        <p
          className={`text-red ${
            lableAlign === "left" ? "font-bold mb-2" : ""
          } text-xs`}
          htmlFor={title}
        >
          {errorMessage}
        </p>
      </ShowComponent>
    </div>
  );
}

export default GeneralInput;

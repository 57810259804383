import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import SupplierApi from "api/supplierapi/supplier";
import GernalInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";

function UpdateSupplier() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [supplierDataUpdate, setSupplierDataUpdate] = useState({
    supplierName: "",
    phoneNumber: "",
  });

  const inputOnChange = (e) => {
    const { name, value } = e.target;
    setSupplierDataUpdate({
      ...supplierDataUpdate,
      [name]: value,
    });
  };

  useEffect(() => {
    SupplierApi.viewSupplier({ id })
      .then((response) => {
        setSupplierDataUpdate({
          supplierName: response.data.name,
          phoneNumber: response.data.phone_no,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleSupplierUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    SupplierApi.updateSupplier(id, {
      supplier: {
        name: supplierDataUpdate.supplierName,
        phone_no: supplierDataUpdate.phoneNumber,
      },
    })
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        setTimeout(() => {
          if (!response.data.errors) {
            navigate("/all-supplier");
          }
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
        toast.error(error.response.data.errors);
      });
  };

  const inputData = [
    {
      title: "Name",
      value: supplierDataUpdate.supplierName,
      onChange: inputOnChange,
      placeholder: "Enter name",
      type: "text",
      name: "supplierName",
      required: true,
    },
    {
      title: "Phone Number",
      value: supplierDataUpdate.phoneNumber,
      onChange: inputOnChange,
      placeholder: "Enter phone number",
      type: "number",
      name: "phoneNumber",
      required: true,
    },
  ];

  const handleBack = () => {
    navigate("/all-supplier");
  };

  return (
    <>
      <AppWrapper sideBar={<AccountsSidebar />}>
        <PagesWrapper>
          <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Update Supplier
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
            <div className="border-t-2 border-blue bg-white rounded-md">
              <form onSubmit={handleSupplierUpdate}>
                <div className="flex">
                  {inputData.map((Item, index) => (
                    <GernalInput
                      className="form-group ml-3 my-5"
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      name={Item.name}
                      placeholder={Item.placeholder}
                      onChange={Item.onChange}
                      type={Item.type}
                      required={Item.required}
                    />
                  ))}
                </div>
                <div className="flex justify-end pr-3 pb-5">
                  <Button
                    className={`${
                      loading ? "disabled btn-progress " : "btn btn-primary"
                    }`}
                    type="submit"
                  >
                    Update Supplier
                  </Button>
                </div>
              </form>
            </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default UpdateSupplier;

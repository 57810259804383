import React, { useEffect, useState, useRef } from "react";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import LocationsAPI from "api/location/location";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Country, City } from "country-state-city";
import Select from "react-select";

function CreateLocation() {
  const selectInputRef = useRef();
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [currency, setCurrency] = useState("");
  const [locationNo, setLocationNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const handleNavgetAllLocation = () => {
    setTimeout(() => {
      navigate(`/all-locations`);
    }, 2000);
  };
  const handleErrorMessage = (error) => {
    const errorMessage = error?.response?.data?.errors
      ? error?.response?.data?.errors
      : "An error occurred";
      toast.error(`Error: ${errorMessage}`);
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`);
  };
  const handleSendToBackend = (e) => {
    e.preventDefault();
    setLoading(true);
    const country = countriesList.find(
      ({ isoCode }) => isoCode === selectedCountry
    ).name;
    LocationsAPI.createLocationsAPI({
      location: {
        type: type,
        address: address,
        phone: phone,
        zip: zip,
        email: email,
        currency: currency,
        country: country,
        city: selectedCity,
        location_no: parseInt(locationNo)
      },
    })
      .then((response) => {
        handleSuccessMessage(response);
        handleNavgetAllLocation();
        setAddress("");
        setPhone("");
        setZip("");
        setEmail("");
        setCurrency("");
        setLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setLoading(false);
      });
  };
  
  useEffect(() => {
    const fetchedCountriesList = Country.getAllCountries()
      .filter(country => City.getCitiesOfCountry(country.isoCode).length > 0)
      .map(({ isoCode, name, currency }) => ({
        name,
        isoCode,
        currency,
      }));
    setCountriesList(fetchedCountriesList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const fetchedCitiesList = City.getAllCities().filter(
        ({ countryCode }) => countryCode === selectedCountry
      );
      setCitiesList(fetchedCitiesList);
      const selectedCountryCurrency = countriesList.find(
        ({ isoCode }) => isoCode === selectedCountry
      ).currency;
      setCurrency(selectedCountryCurrency);
    } else {
      setCitiesList([]);
      setSelectedCity("");
    }
  }, [selectedCountry, countriesList]);
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  
  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="text-24 mb-4">
            <h1> Create Location</h1>
          </div>
          <div className="w-full border-t-2 border-blue bg-white rounded-md">
            <form onSubmit={handleSendToBackend}>
              <div className="flex flex-wrap -mx-2">
                <div className="w-1/3 p-4">
                  <div className="mb-2">Type</div>
                  <select
                    className="form-control"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    required
                  >
                    <option>Please Select a Type</option>
                    <option value="shop">Shop </option>
                    <option value="warehouse">WareHouse </option>
                    <option value="factory">Factory </option>
                  </select>
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Address</div>
                  <input
                    className="form-control"
                    name="Address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Phone No:</div>
                  <input
                    className="form-control hideNumberArrow"
                    name="phone"
                    type="number"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Country</div>
                  <Select
                    required
                    classNames={{control: () => "form-control mt-2" }}
                    value={ selectedCountry
                        ? { label: countriesList.find((country) => country.isoCode === selectedCountry).name, value: selectedCountry }
                        : null
                    }
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption ? selectedOption.value : '');
                      onClear()}}
                    options={countriesList.map(({ name, isoCode }) => ({ label: name, value: isoCode }))}
                    placeholder="Select Country"
                    isClearable={true}
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">City</div>
                  <Select
                    required
                    classNames={{control: () => "form-control mt-2" }}
                    value= { selectedCity ? { label: selectedCity, value: selectedCity }: null }
                    onChange={(selectedOption) => { setSelectedCity( selectedOption ? selectedOption.value : "");}}
                    ref={selectInputRef}
                    options={citiesList.map(({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                    placeholder="Select City"
                    isDisabled={!selectedCountry}
                    isClearable={true}
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Currency</div>
                  <Select
                    classNames={{control: () => "form-control mt-2" }}
                    value={currency ? { label: currency, value: currency } : null}
                    onChange={(selectedOption) => {
                      setCurrency(selectedOption ? selectedOption.value : '');
                    }}
                    options={""}
                    placeholder="Select Currency"
                    isDisabled={true}
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Zip code</div>
                  <input
                    className="form-control"
                    name="zip"
                    type="text"
                    value={zip}
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Email</div>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="w-1/3 p-4">
                  <div className="mb-2">Location No</div>
                  <input
                    className="form-control hideNumberArrow"
                    type="number"
                    value={locationNo}
                    onChange={(e) => {
                      setLocationNo(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full justify-end pb-4 pr-2">
                <button
                  className={
                    loading
                      ? "w-[15%] btn btn-primary btn-progress"
                      : "w-[15%] btn btn-primary"
                  }
                  type="submit"
                >
                  Create Location
                </button>
              </div>
            </form>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default CreateLocation;
import React, { useState, useEffect } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import TableDetails from "Components/Common/TableDetails";
import EmployeesApi from "api/hrmapi/employee";

function EmployeeDetails() {
  let params = useParams();
  const [personalInfo, setPersonalInfo] = useState([]);
  const [travelInfo, setTravelInfo] = useState([]);
  const [employeeAssets, setEmployeeAssets] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState([]);
  const [employeeDocuments, setEmployeeDocuments] = useState([]);
  const [familyInfo, setFamilyInfo] = useState([]);
  const [activeComponent, setActiveComponent] = useState("PersonalInfo");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    EmployeesApi.viewEmployeesApi(params)
      .then((response) => {
        const dataShow = response.data?.employee?.travel_info.ticket_entitled;
        if (dataShow) {
          setTravelInfo([response.data.employee?.travel_info]);
        } else {
          const data = [
            {
              id: response.data.employee?.travel_info.id,
              ticket_entitled: "No",
            },
          ];
          setTravelInfo(data);
        }

        const employementInfo = {
          id: response.data.employee.employement_info.id,
          position: response.data.employee.employement_info.position,
          department:
            response.data.employee.employement_info.departments[0].name,
          professional_title:
            response.data.employee.employement_info.professional_title,
          employement_type:
            response.data.employee.employement_info.employement_type,
          sponsorship: response.data.employee.employement_info.sponsorship,
          joining_date: response.data.employee.employement_info.joining_date,
          termination_date:
            response.data.employee.employement_info.termination_date,
          probation_end: response.data.employee.employement_info.probaion_end,
          working_from: response.data.employee.employement_info.working_from,
          company: response.data.employee.employement_info.company,
          work_permit: response.data.employee.employement_info.work_permit,
        };
        setEmployeeInfo([employementInfo]);
        setPersonalInfo([response.data.employee.employee_info]);
        setFamilyInfo(response.data.employee.employee_family_infos);
        setEmployeeDocuments(response.data.employee.employee_documents);
        const filteredAssets = response?.data.employee.employee_assets.map(asset => {
          const { asset_type, ...rest } = asset;
          return rest;
        });
        setEmployeeAssets(filteredAssets);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        setLoading(false);
      });
  }, []);
  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Employee Details #{params.id}</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[80vh]">
          <div className=" w-full flex justify-around pt-5 border-b ">
            <div
              className={
                activeComponent === "PersonalInfo" &&
                "border-b-2 border-blue text-blue"
              }
            >
              <button
                className="pb-3"
                type="button"
                onClick={() => setActiveComponent("PersonalInfo")}
              >
                Personal Info
              </button>
            </div>
            <div
              className={
                activeComponent === "EmploymentInfo" &&
                "border-b-2 border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("EmploymentInfo")}
              >
                Employment Info
              </button>
            </div>
            <div
              className={
                activeComponent === "DocumentInfo" &&
                "border-b-2 border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("DocumentInfo")}
              >
                Document Info
              </button>
            </div>
            <div
              className={
                activeComponent === "FamilyInfo" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("FamilyInfo")}
              >
                Family Info
              </button>
            </div>
            {/* <div
              className={
                activeComponent === "PayrollInfo" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("PayrollInfo")}
              >
                Payroll Info
              </button>
            </div> */}
            <div
              className={
                activeComponent === "employeeAssets" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("employeeAssets")}
              >
                Employee Assets
              </button>
            </div>
            <div
              className={
                activeComponent === "TravelInfo" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("TravelInfo")}
              >
                Travel Info
              </button>
            </div>
            {/* <div
              className={
                activeComponent === "OnBoarding" &&
                "border-b-2  border-blue text-blue"
              }
            >
              <button
                type="button"
                onClick={() => setActiveComponent("OnBoarding")}
              >
                On Boarding
              </button>
            </div> */}
          </div>
          {activeComponent === "TravelInfo" && (
            <>
              {travelInfo.length > 0 ? (
                <TableDetails data={travelInfo[0]} loading={loading} />
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}
          {activeComponent === "PersonalInfo" && (
            <>
              {personalInfo.length > 0 ? (
                <TableDetails data={personalInfo[0]} loading={loading} />
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}
          {activeComponent === "EmploymentInfo" && (
            <>
              {employeeInfo.length > 0 ? (
                <TableDetails data={employeeInfo[0]} loading={loading} />
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}
          {activeComponent === "employeeAssets" && (
            <>
              {employeeAssets.length > 0 ? (
                <div>
                  {employeeAssets.map((asset, index) => (
                    <TableDetails key={index} data={asset} loading={loading} />
                  ))}
                </div>
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}

          {activeComponent === "FamilyInfo" && (
            <>
              {familyInfo.length > 0 ? (
                <div>
                  {familyInfo.map((member, index) => (
                    <TableDetails key={index} data={member} loading={loading} />
                  ))}
                </div>
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}
          {activeComponent === "DocumentInfo" && (
            <>
              {employeeDocuments.length > 0 ? (
                <div>
                  {employeeDocuments.map((member, index) => (
                    <TableDetails key={index} data={member} loading={loading} />
                  ))}
                </div>
              ) : (
                <div className="text-center mt-5">No data found.</div>
              )}
            </>
          )}
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default EmployeeDetails;

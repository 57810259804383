import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Table from "Components/Common/GeneralTable";
import { useNavigate } from "react-router-dom";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateVariablePay from "./CreateVariablePay";
import CreateDeduction from "./CreateDeduction";
function UpdatePayroll({ contentOnly }) {
  const company_id = useSelector((state) => state.auth.currentUser.company_id);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [popupId, setPopupId] = useState("");
  const [adjustment, setAdjustment] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  let params = useParams();
  const [loading, setLoading] = useState(false);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.75rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
    }),
  };
  return (
    <AppWrapper sideBar={<HrmSidebar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "variablePay"}>
          <Popup setPopup={() => setPopupId("")} className="w-[75%]">
            <CreateVariablePay contentOnly={true} setPopupId={setPopupId} />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "newDeduction"}>
          <Popup setPopup={() => setPopupId("")} className="w-[75%]">
            <CreateDeduction contentOnly={true} setPopupId={setPopupId} />
          </Popup>
        </ShowComponent>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24">Payroll #{params.id}</div>
        </div>
        <div className="bg-white rounded-3xl w-full mt-5 min-h-[80vh]">
          <div className="flex p-5">
            <div className="text-24 text-blue pr-2">January 2024 </div>
            <div className="">Currency in AED</div>
          </div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2">Doc Name</div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>Amount</div>
              <div>Processed</div>
              <div>Unpaid</div>
            </div>
          </div>
          <div className="border-b"></div>
          <div className="p-5 flex">
            <div className="text-22 bold pr-2 ">
              Basic Salary and Allowances
            </div>
            <div className="flex text-progress">
              <div>View</div>/<div>Edit</div>
            </div>
          </div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2">Basic Salary</div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>300</div>
              <div>0</div>
              <div>8000</div>
            </div>
          </div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2">Mobile Phone</div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>300</div>
              <div>0</div>
              <div>8000</div>
            </div>
          </div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2">Housing</div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>1000</div>
              <div>0</div>
              <div>1000</div>
            </div>
          </div>
          <div className="border-b"></div>
          <div className="p-5 flex">
            <div className="text-22 bold pr-2 ">Variable Pay </div>
            <div className="flex text-progress">
              <div>View</div>/<div>Edit</div>
            </div>
          </div>
          <div className="p-5 flex">
            <div className="flex text-progress">
              <button type="button" onClick={() => setPopupId("variablePay")}>
                Add
              </button>
              /<div>Edit Variable Pay Item</div>
            </div>
          </div>
          <div className="border-b"></div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2"></div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>1000</div>
              <div>0</div>
              <div>1000</div>
            </div>
          </div>
          <div className="border-b"></div>
          <div className="p-5 flex">
            <div className="text-22 bold pr-2 ">Adjustments</div>
            <div className="flex text-progress">
              <div>View</div>/<div>Edit</div>
            </div>
          </div>
          <div className="p-5">Deductions</div>
          <div className="w-full p-5 flex ">
            <div className="w-1/2">Leave Salary</div>
            <div className="w-1/2 flex justify-between items-center ">
              <div>1000</div>
              <div>0</div>
              <div>1000</div>
            </div>
          </div>
          <div className="text-progress pl-5 pr-5 pt-5">
            <button className="" onClick={() => setAdjustment(!adjustment)}>
              Add an Adjustment
            </button>
          </div>
          <div className="w-[30%] pl-5 pb-5 pt-2 justify-start items-start ">
          {adjustment && (
            <div className="border p-5 leading-loose rounded-xl">
              <div>New Addition</div>
              <button onClick={()=>setPopupId("newDeduction")}>New Deduction</button>
              <div>New Recurring Deduction</div>
            </div>
          )}</div>
          <div className="border-b"></div>
          <div className="p-5 flex">
            <div className="text-22 bold pr-2 ">Work Expenses</div>
            <div className="flex text-progress">
              <div>View</div>/<div>Edit</div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default UpdatePayroll;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {}

const taxfreeSlice = createSlice({
  name: "taxfree",
  initialState,
  reducers: {
    setTaxFreeData: (state, action) => {
        state.shopper = action.payload.shopper;
        state.is_tax_free = action.payload.is_tax_free;
    },
  },
});
export const { setTaxFreeData} = taxfreeSlice.actions;
export default taxfreeSlice.reducer;

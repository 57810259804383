import React from "react";
import MultiLanguage from "../MultiLanguage";
import DarkLightModeToggle from "../DarkLightModeToggle";
import { toast ,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthWrapper = ({ children }) => {
  return (
    <div className="py-16 bg-[url('/public/assets/svg/login-register-bg.svg')] dark:bg-[url('/public/assets/svg/login-register-bg-dark-mode.svg')] rtl:bg-[url('/public/assets/svg/rtl-login-register-bg.svg')] rtl:dark:bg-[url('/public/assets/svg/rtl-login-register-bg-dark-mode.svg')] bg-no-repeat bg-[center_top]">
      <div className="container px-4 m-auto">
      <div className="flex justify-center md:!justify-start mb-10 md:mb-16 md:mb-28">
        <img src="/assets/svg/logo-login-register.svg" className="dark:hidden" alt="" />
        <img src="/assets/svg/logo-login-register-dark-mode.svg" className="hidden dark:inline" alt="" />
        <div className="flex content-center items-center gap-6">
          {/* <DarkLightModeToggle/> */}
          {/* <MultiLanguage/> */}
        </div>
      </div>
      </div>
      <div className="container px-4 m-auto">
        <div className="grid grid-cols-12 gap-x-6">
          <div className="md:col-span-6 col-span-12">
            <h1 className="mb-10 md:mb-20 text-28 md:text-48 font-bold text-dark dark:text-grey-mode text-center md:text-left">
              Welcome to
              <p className="text-36 md:text-64 text-blue font-semibold mt-3">
                NimBuzz ERP
              </p>
            </h1>
            <div className="flex mb-10 md:mb-24 gap-6">
              <p className="w-2 bg-blue rounded-md"></p>
              <p className="text-24 w-full md:w-96 leading-10">
                Get your data at one place including Finance, POS and HRM{" "}
              </p>
            </div>
            <div className="flex justify-end mb-0 md:mb-28">
              <img
                src="/assets/img/login-register-img.webp"
                className="md:inline-block hidden"
                alt=""
              />
            </div>
          </div>
          <div className="md:col-span-6 col-span-12">
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
            {children}
            </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;

import React, { useState } from "react";

function DiscountSelectableList({ items, AddItem, type }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleItemClicked = (item) => {
    AddItem(item);
    setSearchQuery("");
  };

  const filteredItems = items.filter((item) =>
    type === "category"
      ? item.category_name.toLowerCase().includes(searchQuery.toLowerCase())
      : item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.barcode.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-[50%]">
      <div className="w-full flex justify-between">
        <div className="w-[50%] flex items-center">
          <label className="text-[#34395e] font-bold text-base mx-2">
            {type === "category" ? "Categories" : "Products"}:
          </label>
        </div>
        <div className="w-[50%] pr-5">
          <input
            className="form-control mt-2"
            type="text"
            placeholder={`Search ${
              type === "category" ? "by Name" : "by Name or Barcode"
            }`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="overflow-y-scroll max-h-400 mt-4">
        <div className="grid grid-cols-2 mx-2">
          {filteredItems.map((item, index) => (
            <div
              key={index}
              className={`flex flex-row justify-between p-2 border border-gray-300 cursor-pointer`}
              onClick={() => handleItemClicked(item)}
            >
              <span className="flex flex-row justify-center items-center text-xs">
                {type === "category" ? item.category_name : item.product_name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DiscountSelectableList;